/*
 * File: FriendsFamilyMember.styles.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on December 6, 2022 at 11:55 AM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: April 9, 2023 at 10:39 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';


/**
 * Styles
 */

export const Wrapper = styled.div`
	display: flex;
	width: 96px;
	flex-direction: column;
	align-items: center;
	border-radius: 6px;

	${({ $isSelectable }) => $isSelectable && css`
		cursor: pointer;
		padding: 12px 10px 12px 10px;
		height: 100%;
	`}
	${({ $isSelected }) => $isSelected && css`
		background-color: ${({ theme }) => theme.placeholderPrimary};
	`}
	${({ $isDisabled }) => $isDisabled && css`
		cursor: default;
		opacity: 0.4;
	`}
`;

export const ProfileImage = styled.div`
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background-image: ${({ image }) => `url('${image}')`};
	background-size: cover;
	background-repeat: no-repeat;
`;

export const NameContainer = styled.div`
	margin: 7px 0px 0px;

	p {
		border-radius: 6px;
		line-height: normal;
		margin: 0px;
	}
`;
