/*
 * File: LocaleLink.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 9, 2022 at 9:36 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 21, 2023 at 3:36 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';

// Utilities
import { getCookie } from '../../utilities/cookies';

// Constants
import { VIEW_MODE_COOKIE } from '../../../Constants';

// Styles
import * as S from './LocaleLink.styles';


/**
 * Component
 */

export const LocaleLink = forwardRef(({
	to, className, back, identifier, data, ...rest
}, ref) => {

	// Get current locale from hook
	const locale = useSelector((state) => state.locale.value);

	// Get location state
	const location = useLocation();

	// Get query parameters from hook
	const [searchParams] = useSearchParams();
	let isMobileApp = searchParams.get('mobileapp') === 'true';
	if (!isMobileApp) {
		const mobileAppCookie = getCookie(VIEW_MODE_COOKIE);
		if (mobileAppCookie === 'mobileapp') isMobileApp = true;
	}

	// Get if link is external
	const isExternal = to != null && (to.startsWith('http://') || to.startsWith('https://') || to.startsWith('www.'));

	// Return component
	if (isExternal) {

		// Build analytics url
		let url = `${process.env.APP_URL}/_external/link?url=${encodeURIComponent(to)}`;
		if (identifier) url += `&identifier=${identifier}`;

		// Add data parameters to url
		if (data != null) {
			Object.keys(data).forEach((key) => {
				if (data[key] != null) {
					url += `&${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`;
				}
			});
		}

		// Return component
		return <S.BaseLink {...rest} href={url} ref={ref} rel="noopener" className={className ? `${className} animate` : 'animate'} />;

	} if (to != null && (to.startsWith('mailto:') || to.startsWith('tel:'))) {

		// Return component
		return <S.BaseLink {...rest} href={to} ref={ref} rel="noopener" className={className ? `${className} animate` : 'animate'} />;
	}

	// Return component
	return <S.LocaleLink {...rest} to={!isMobileApp ? `${locale.localePath}${to}` : `${locale.localePath}${to}?mobileapp=true`} state={{ back: { path: location.pathname, title: back?.title } }} ref={ref} className={className ? `${className} animate` : 'animate'} />;
});


/**
 * Configuration
 */

LocaleLink.displayName = 'LocaleLink';
LocaleLink.propTypes = {
	to: PropTypes.string,
	className: PropTypes.string,
	target: PropTypes.string,
	identifier: PropTypes.string,
	back: PropTypes.shape(),
	data: PropTypes.shape(),
};
LocaleLink.defaultProps = {
	to: null,
	className: null,
	target: null,
	identifier: null,
	back: {},
	data: {}
};
