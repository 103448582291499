/*
 * File: DragDrop.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on November 3, 2022 at 11:22 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: March 6, 2023 at 1:31 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import * as S from './DragDrop.styles';


/**
 * Component
 */

export const DragDrop = ({
	dropzone, children, className
}) => {

	// Get dropzone parameters
	const {
		getRootProps,
		getInputProps,
		isFocused,
		isDragAccept,
		isDragReject
	} = dropzone;

	// Render component
	return (
		<S.DragContainer {...getRootProps({ isFocused, isDragAccept, isDragReject })} className={className ? `${className} animate` : 'animate'}>
			<input {...getInputProps()} />
			{children}
		</S.DragContainer>
	);
};


/**
 * Configuration
 */

DragDrop.displayName = 'DragDrop';
DragDrop.propTypes = {
	dropzone: PropTypes.shape({
		getRootProps: PropTypes.func,
		getInputProps: PropTypes.func,
		isDragActive: PropTypes.bool,
		isFocused: PropTypes.bool,
		isDragAccept: PropTypes.bool,
		isDragReject: PropTypes.bool
	}).isRequired,
	className: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
DragDrop.defaultProps = {
	className: null,
	children: null
};
