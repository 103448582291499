/*
 * File: AuthRouteWrapper.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on November 18, 2022 at 12:48 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: May 31, 2023 at 10:46 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Utilities
import { globalQuery } from '../utilities/state';


/**
 * Route Wrapper
 */

export const AuthRouteWrapper = ({
	children, redirect, user, locale
}) => {

	// Get query parameters from hook
	const [searchParams] = useSearchParams();

	// Get current user
	let userObj = useSelector((state) => state.user.value);
	if (!userObj && user) userObj = user;

	// Get current locale from hook
	let localeObj = useSelector((state) => state.locale.value);
	if (locale && locale.isSet) localeObj = locale;

	// Allow access to component
	return !userObj || userObj.isAnonymous === true ? children : <Navigate to={`${localeObj.localePath}${redirect}${globalQuery(searchParams)}`} />;
};


/**
 * Configuration
 */

AuthRouteWrapper.propTypes = {
	children: PropTypes.element,
	redirect: PropTypes.string,
	user: PropTypes.shape(),
	locale: PropTypes.shape()
};
AuthRouteWrapper.defaultProps = {
	children: null,
	redirect: '/',
	user: null,
	locale: null
};
