/*
 * File: TripCard.styles.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on December 6, 2022 at 11:55 AM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: June 18, 2023 at 1:54 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';

// Components
import { LocaleLink } from '../LocaleLink';
import { Card } from '../Card';

// Styles
import { ColorValuesCreator } from '../../styles/colors';

// Constants
import { mediumBreakpoint } from '../../styles/constants';

// Create color values
const ColorValues = ColorValuesCreator();


/**
 * Styles
 */

export const PromptLink = styled(LocaleLink)`
	width: 100%;
	text-decoration: none;

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		width: 227px;
	}
`;

export const Wrapper = styled(Card)`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	align-items: center;
	overflow: hidden;
	background-color: ${ColorValues.white};
	color: ${ColorValues.dark};

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		width: 227px;
	}
	${({ isLoading, isDetail }) => !isLoading && !isDetail && css`
		&:hover {
			box-shadow: ${ColorValues.elementDarkShadow};
		}
	`}
	${({ isDark }) => isDark && css`
		p, span {
			color: ${ColorValues.dark};
		}
	`}
	.shimmer {
		animation : shimmer 2s infinite;
		background: linear-gradient(to right, ${ColorValues.neutralBackground} 4%, ${ColorValues.neutralHighlight} 25%, ${ColorValues.neutralBackground} 36%);
		background-size: 1000px 100%;
	}
`;

export const CardInner = styled.div`
	padding: 10px 20px;
	width: 100%;
	display: flex;
	flex-direction: column;
`;

export const ProfileImage = styled.div`
	width: 35px;
	height: 35px;
	border-radius: 50%;
	background-image: ${({ image }) => `url('${image}')`};
	background-size: cover;
	background-repeat: no-repeat;
	background-color: ${ColorValues.neutralBackground};
	margin-right: -10px;
	border: 1px solid white;
`;

export const StatusWrapper = styled.div`
	width: 100%;
	background-color: ${({ status }) => (status === 'success' ? ColorValues.statusSuccessBase : ColorValues.brandPrimaryYellow)};
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 8px 0px;
	gap: 5px;

	span {
		color: ${ColorValues.white};
	}
	svg {
		color: ${ColorValues.white};
		font-size: 0.75rem;
	}
`;

export const CardContainer = styled.div`
	margin: 0px 0px 12px;

	& > p {
		font-size: 0.8rem;
	}
	* {
		text-align: left;
	}
`;

export const PartyContainer = styled.div`
	display: flex;
	gap: 0px;
	margin-top: 4px;
	flex-wrap: wrap;
`;

export const PlacesContainer = styled.div`
	display: flex;
	gap: 5px;
	flex-wrap: wrap;
	flex-direction: column;
	margin-top: 3px;
`;

export const PlaceItem = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;

export const PlaceIcon = styled.div`
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-size: cover;
	background-repeat: no-repeat;
	background-color: ${ColorValues.neutralBackground};
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		color: ${ColorValues.dark};
	}
`;

export const ActionSection = styled.div`
	background-color: ${ColorValues.neutralBackground};
	width: 100%;
	padding: 6px 0px;
	margin-top: auto;

	p {
		text-align: center;
		color: ${ColorValues.brandPrimaryMiddleBlue} !important;
	}
`;

export const TripTitle = styled.div`
	margin-top: 5px;

	h4 {
		font-size: 1.1rem;
		line-height: 1.4;
	}
`;

export const DateContainer = styled.div`
	display: flex;
	align-items: flex-start;
	gap: 10px;
`;

export const DateItem = styled.div`
	.dateText {
		margin-top: -4px;
	}
`;

export const DateDivider = styled.div`
	margin-top: -5px;
`;

export const DaysToGo = styled.div`
	background-color: ${ColorValues.brandPrimaryLightBlue};
	display: inline-block;
	padding: 1px 8px;
	border-radius: 4px;
	margin-top: 5px;
	margin-bottom: 3px;

	* {
		color: ${ColorValues.white} !important;
	}
	.shimmer-element {
		color: transparent !important;
	}
`;
