/*
 * File: Impersonation.styles.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on August 3, 2023 at 5:49 AM
 * Copyright © 2023 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: August 3, 2023 at 5:49 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: block;
`;
