/*
 * File: ReservationCreatorModal.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: October 2, 2023 at 10:54 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

// Utilities
import { toastError } from '../../utilities/toaster';
import { parseFirstLastName, isSameMember } from '../../../utilities/utilities';
import { parseDateString } from '../../../utilities/dateTime';
import { getCookie } from '../../utilities/cookies';
import { renderProfileImage } from '../../utilities/image';

// Services
import { getFriendsFamily } from '../../services/user';

// Slices
import { updateFamily } from '../../store/slices/family/family.slice';

// Constants
import {
	PARK_ENTITY_IDS, PARK_ENTITY_OPTIONS, SCHEDULED_EVENT_TYPES, VIEW_MODE_COOKIE
} from '../../../Constants';

// Components
import { Button } from '../Button';
import { EmptyComponent } from '../EmptyComponent';
import { FriendsFamilyMember } from '../FriendsFamilyMember';
import { Modal } from '../Modal';
import { Typography } from '../Typography';
import { AttractionSearchWidget } from '../AttractionSearchWidget';
import { IconButton } from '../IconButton';
import { RadioGroup } from '../RadioGroup';

// Styles
import * as S from './ReservationCreatorModal.styles';


/**
 * Constants
 */

const EARLIEST_POSSIBLE = 'earliest';

const PLACEHOLDER_FAMILY = [
	{
		guestIdentifiers: { swid: 1 },
		name: { firstName: 'Brendan', lastName: 'M' }
	},
	{
		guestIdentifiers: { swid: 2 },
		name: { firstName: 'Cathy', lastName: 'G' }
	},
	{
		guestIdentifiers: { swid: 3 },
		name: { firstName: 'Lucia', lastName: 'S' }
	},
	{
		guestIdentifiers: { swid: 4 },
		name: { firstName: 'Molly', lastName: 'F' }
	},
	{
		guestIdentifiers: { swid: 5 },
		name: { firstName: 'Walt', lastName: 'D' }
	},
	{
		guestIdentifiers: { swid: 6 },
		name: { firstName: 'Lucia', lastName: 'S' }
	},
	{
		guestIdentifiers: { swid: 7 },
		name: { firstName: 'Molly', lastName: 'F' }
	},
	{
		guestIdentifiers: { swid: 8 },
		name: { firstName: 'Walt', lastName: 'D' }
	}
];


/**
 * Component
 */

export const ReservationCreatorModal = ({
	className, isOpen, handleClose, onSave, isLoading, editingEvent, day, parkEntity
}) => {

	/**
	 * Booking Rules (Disney World)
	 *
	 * 1. Can't reserve the same LL or ILL ride twice in one day ✓
	 *
	 * 2. Can't reserve more than 2 ILL rides in one day ✓
	 *
	 * 3. Maximum of 12 party members for LL, ILL, and Virtual Queue reservations ✓
	 *
	 * 4. A new LL reservation can be made:
	 *		- As soon as you redeem your last reservation ✓
	 *		- 2 hours after you made your last reservation if after park open (not possible until day of, which defeats the point of scheduling in advance)
	 *		- 2 hours after park opens if you made your last reservation before park open (not possible until day of, which defeats the point of scheduling in advance)
	 */

	/**
	 * Booking Rules (Disneyland)
	 *
	 * 1. Can't reserve the same LL or ILL ride twice in one day ✓
	 *
	 * 2. Can't reserve more than 2 ILL rides in one day ✓
	 *
	 * 3. Maximum of 12 party members for LL, ILL, and Virtual Queue reservations ✓
	 *
	 * 4. A new LL reservation can be made:
	 *		- As soon as you redeem your last reservation ✓
	 *		- 2 hours after you made your last reservation (not possible until day of, which defeats the point of scheduling in advance)
	 *
	 * 5. Can't select a specific time for ILLs ✓
	 */

	// Create state handlers
	const [friendsFamily, setFriendsFamily] = useState([]);
	const [selectedParty, setSelectedParty] = useState([]);
	const [selectedAttraction, setSelectedAttraction] = useState(null);
	const [eventType, setEventType] = useState(null);
	const [parkId, setParkId] = useState(null);
	const [timeToSchedule, setTimeToSchedule] = useState(EARLIEST_POSSIBLE);
	const [componentStatus, setComponentStatus] = useState('idle');
	const [selectedHour, setSelectedHour] = useState(null);
	const [selectedMinute, setSelectedMinute] = useState(null);
	const [showMinuteSelector, setShowMinuteSelector] = useState(false);

	// Create reference for components
	const isMounted = useRef(true);

	// Get current friends and family from hook
	const family = useSelector((state) => state.family.value);

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get current user from hook
	const user = useSelector((state) => state.user.value);

	// Get query parameters from hook
	const [searchParams] = useSearchParams();
	let isMobileApp = searchParams.get('mobileapp') === 'true';
	if (!isMobileApp) {
		const mobileAppCookie = getCookie(VIEW_MODE_COOKIE);
		if (mobileAppCookie === 'mobileapp') isMobileApp = true;
	}

	// Get actions from hooks
	const dispatch = useDispatch();

	// Initialize component data function
	const fetchFriendsFamily = async () => {

		// Parse names
		const { firstName, lastName } = parseFirstLastName(user?.name);

		// Create initial party members
		const initialMembers = [{
			name: {
				firstName,
				lastName
			},
			guestIdentifiers: user.disneyGuestIdentifiers,
			avatarId: user.disneyAvatarId,
			profileImage: { url: renderProfileImage(user.profileImage, '200x200', isMobileApp) }
		}];

		// Check if stored friends and family
		if (!family.isSet) {

			// Update page status
			setComponentStatus('loading');
			try {

				// Fetch data
				const { data } = await getFriendsFamily();

				// Check if mounted
				if (isMounted.current) {

					// Set new data state
					setFriendsFamily([
						...initialMembers,
						...data?.family?.withPlans || [],
						...data?.family?.withoutPlans || [],
						...data?.family?.transactional || [],
					]);

					// Update friends and family
					dispatch(updateFamily({ family: data.family }));

					// Update page status
					setComponentStatus('success');
				}
			} catch (error) {

				// Update page status
				if (isMounted.current) {
					setComponentStatus('error');
				}
			}
		} else if (isMounted.current) {

			// Set new data state
			setFriendsFamily([
				...initialMembers,
				...family?.family?.withPlans || [],
				...family?.family?.withoutPlans || [],
				...family?.family?.transactional || [],
			]);

			// Update page status
			setComponentStatus('success');
		}
	};

	// Handle save action
	const handleSaveAction = () => {

		// Validate parameters
		if (selectedAttraction == null) {
			toastError(uiMode, 'Please select a ride or attraction for this reservation.');
			return;
		}
		if (eventType == null) {
			toastError(uiMode, 'Please select the type of reservation.');
			return;
		}
		if (selectedParty.length === 0) {
			toastError(uiMode, 'Please select at least one party member for this reservation.');
			return;
		}
		if ((eventType === SCHEDULED_EVENT_TYPES.LL || eventType === SCHEDULED_EVENT_TYPES.ILL || eventType === SCHEDULED_EVENT_TYPES.VIRTUAL_QUEUE) && selectedParty.length > 12) {
			toastError(uiMode, 'Ride reservations can have a maximum of 12 party members. Update your ride party and try again.');
			return;
		}

		// Handle save action with payload
		onSave({
			...editingEvent ? {
				id: editingEvent.id,
				sortIndex: editingEvent.sortIndex,
			} : undefined,
			parkId,
			earliestPossible: timeToSchedule === EARLIEST_POSSIBLE,
			...timeToSchedule !== EARLIEST_POSSIBLE ? {
				preferredTime: timeToSchedule,
			} : undefined,
			...eventType === SCHEDULED_EVENT_TYPES.DINING ? {
				dining: {
					diningId: `${selectedAttraction.operatorId}` || selectedAttraction.id,
					name: selectedAttraction.name
				}
			} : {
				attraction: {
					attractionId: `${selectedAttraction.operatorId}` || selectedAttraction.id,
					name: selectedAttraction.name
				}
			},
			...eventType === SCHEDULED_EVENT_TYPES.VIRTUAL_QUEUE ? { // NV - ATTRACTION-SPECIFIC FOR DISNEYLAND
				scheduledTime: '07:00'
			} : undefined,
			eventType,
			party: selectedParty
		});
	};

	// Handle party member comparison
	const isSameDisneyMember = (internalMember, disneyMember) => (internalMember.userXID != null && disneyMember.guestIdentifiers.xid != null && internalMember.userXID === disneyMember.guestIdentifiers.xid)
		|| (internalMember.userSWID != null && disneyMember.guestIdentifiers.swid != null && internalMember.userSWID === disneyMember.guestIdentifiers.swid);

	// Handle invalid members
	const generateInvalidMembers = () => {

		// Get matching events for dining or attraction
		const matchingEvents = selectedAttraction ? (day.scheduledEvents || []).filter((event) => {
			if (editingEvent != null && editingEvent.id === event.id) {
				return false;
			}
			if (event.dining && selectedAttraction.diningId) {
				return event.dining.diningId === (`${selectedAttraction.operatorId}` || selectedAttraction.id);
			}
			return event.attraction.attractionId === (`${selectedAttraction.operatorId}` || selectedAttraction.id);
		}) : [];

		// Get invalid party members
		let members = [].concat(...matchingEvents.map((event) => event.party));

		// Deduplicate party
		members = members.filter((v, i, a) => a.findIndex((v2) => ((v2.userSWID && v.userSWID && v2.userSWID.toString() === v.userSWID.toString()) || (v2.userXID && v.userXID && v2.userXID.toString() === v.userXID.toString()))) === i);

		// Return invalid members
		return members;
	};

	// Perform actions on state change
	useEffect(() => {

		// Generate invalid members
		const invalidMembers = generateInvalidMembers();

		// Reset state
		setEventType(editingEvent ? editingEvent.eventType : null);
		setParkId(editingEvent ? editingEvent.parkId : null);
		setSelectedParty(editingEvent ? editingEvent.party.filter((member) => !invalidMembers.some((innerMember) => isSameMember(innerMember, member))) : []);
		setSelectedAttraction(editingEvent ? editingEvent?.attraction?.attraction || editingEvent?.dining?.attraction : null);
		setTimeToSchedule(editingEvent && editingEvent.preferredTime ? editingEvent.preferredTime : EARLIEST_POSSIBLE);
		setSelectedHour(editingEvent && editingEvent.preferredTime ? editingEvent.preferredTime.split(':')[0] : null);
		setSelectedMinute(editingEvent && editingEvent.preferredTime ? editingEvent.preferredTime.split(':')[1] : null);
		setShowMinuteSelector(editingEvent && editingEvent.preferredTime);

	}, [isOpen, editingEvent]);

	// Perform actions on component load
	useEffect(() => {

		// Set state
		isMounted.current = true;

		// Fetch friends and family
		fetchFriendsFamily();

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, []);

	// Perform action on selected attraction change
	useEffect(() => {

		// Generate invalid members
		const invalidMembers = generateInvalidMembers();

		// Remove invalid members from current party
		setSelectedParty(selectedParty.filter((member) => !invalidMembers.some((innerMember) => isSameMember(innerMember, member))));

	}, [selectedAttraction]);

	// Render component
	const renderPartyComponent = () => {
		if (componentStatus !== 'success') {
			return PLACEHOLDER_FAMILY.map((member) => (
				<FriendsFamilyMember
					key={member.guestIdentifiers.swid || member.guestIdentifiers.xid}
					firstName={member.name.firstName}
					lastName={member.name.lastName}
					isLoading
				/>
			));
		}
		if (friendsFamily.length === 0) {

			// Get trip entity parameter
			let mobileAppName = 'My Disney Experience';
			if (parkEntity != null) {
				const parkEntityObj = PARK_ENTITY_OPTIONS.find((entity) => entity.id === parkEntity);
				mobileAppName = parkEntityObj.mobileApp;
			}

			// Render component
			return (
				<EmptyComponent
					isLight
					title="Your Friends & Family list is empty"
					message={`Head on over to your ${mobileAppName} account to add a new member`}
					icon={['far', 'star']}
					style={{ width: '100%' }}
				/>
			);
		}

		// Render components
		return friendsFamily.map((member) => {

			// Generate invalid members
			const invalidMembers = generateInvalidMembers();

			// Check if invalid
			const isInvalid = invalidMembers.some((innerMember) => isSameDisneyMember(innerMember, member));

			// Render member
			return (
				<FriendsFamilyMember
					key={member.guestIdentifiers.swid || member.guestIdentifiers.xid}
					firstName={member.name.firstName}
					lastName={member.name.lastName}
					profileImage={member.profileImage}
					avatarId={member.avatarId}
					isSelectable
					isSelected={!isInvalid ? selectedParty.some((memberObj) => isSameDisneyMember(memberObj, member)) : false}
					isDisabled={isInvalid}
					onChange={!isInvalid ? () => {
						const party = JSON.parse(JSON.stringify(selectedParty));
						if (party.some((memberObj) => isSameDisneyMember(memberObj, member))) {
							setSelectedParty(party.filter((memberObj) => !isSameDisneyMember(memberObj, member)));
						} else {
							party.push({
								firstName: member.name.firstName,
								lastName: member.name.lastName,
								userXID: member.guestIdentifiers.xid,
								userSWID: member.guestIdentifiers.swid,
								avatarId: member.avatarId,
								profileImage: member.profileImage
							});
							setSelectedParty(party);
						}
					} : undefined}
				/>
			);
		});
	};

	// Render component
	return (
		<Modal className={className} isOpen={isOpen} handleClose={handleClose} clickOutsideClose useWrapper variant="xlarge">
			<S.ModalInner>
				<S.PaneContainer>

					{/* Reservation Information */}
					<S.ReservationPane>

						{/* Icon */}
						<S.IconContainer><FontAwesomeIcon icon={['fas', 'clock']} /></S.IconContainer>

						{/* Content */}
						<Typography tag="h3" weight="bold">Create a reservation</Typography>

						{/* Attraction Selector */}
						<S.SelectorContainer>

							{/* Label */}
							<Typography tag="p" weight="semibold" variation="2">Select a ride or attraction</Typography>

							{/* Attraction Search Widget */}
							<AttractionSearchWidget
								attraction={selectedAttraction}
								updateAttraction={setSelectedAttraction}
								updateEventType={setEventType}
								updateParkId={setParkId}
								isVisible={isOpen}
								existingEvents={day.scheduledEvents}
								parkEntity={parkEntity}
							/>
						</S.SelectorContainer>

						{/* Event Type Selector */}
						{selectedAttraction && selectedAttraction.type === 'attraction' && eventType !== SCHEDULED_EVENT_TYPES.DINING && (
							<S.SelectorContainer>

								{/* Label */}
								<Typography tag="p" weight="semibold" variation="2">Select a reservation type</Typography>

								{/* Selector Options */}
								<RadioGroup
									value={eventType}
									onChange={setEventType}
									family="eventType"
									options={[
										selectedAttraction.access.flex === true && {
											value: SCHEDULED_EVENT_TYPES.LL,
											label: 'Lightning Lane',
											subLabel: 'Included with Genie+ for your park day'
										},
										selectedAttraction.access.individual === true && {
											value: SCHEDULED_EVENT_TYPES.ILL,
											label: 'Individual Lightning Lane',
											subLabel: 'Requires an additional cost on your park day'
										},

										/* selectedAttraction.access.virtualQueue === true && { // NV - ENABLE
											value: SCHEDULED_EVENT_TYPES.VIRTUAL_QUEUE,
											label: 'Virtual Queue',
											subLabel: 'Assigns your party a boarding group to ride at a certain time'
										} */
									].filter(Boolean)}
								/>
							</S.SelectorContainer>
						)}

						{/* Time Selector */}
						{((eventType === SCHEDULED_EVENT_TYPES.ILL && parkEntity !== PARK_ENTITY_IDS.DISNEYLAND_RESORT.id)
						|| eventType === SCHEDULED_EVENT_TYPES.DINING) && (
							<S.SelectorContainer>

								{/* Label */}
								<Typography tag="p" weight="semibold" variation="2">Select a time for your reservation</Typography>

								{/* Time Toolbar */}
								{showMinuteSelector && (
									<S.TimeToolbar>
										<IconButton
											size={0.9}
											icon={['fas', 'arrow-left']}
											onClick={() => { setShowMinuteSelector(false); }}
										/>
										<Typography
											weight="medium"
											onClick={() => { setShowMinuteSelector(false); }}
										>
											{parseDateString(selectedHour, 'HH').format('h A')}
										</Typography>
									</S.TimeToolbar>
								)}

								{/* Time Components */}
								<S.TimeContainer>

									{/* Hour Selectors */}
									{!showMinuteSelector && (
										<>
											<S.TimeSelector
												onClick={() => {
													setSelectedHour(null);
													setSelectedMinute(null);
													setTimeToSchedule(EARLIEST_POSSIBLE);
												}}
												$isSelected={timeToSchedule === EARLIEST_POSSIBLE}
												className="animate"
											>
												<Typography weight="medium">Earliest available</Typography>
											</S.TimeSelector>
											<S.TimeGrid>
												{['08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22'].map((hour) => (
													<S.TimeSelector
														key={hour}
														onClick={() => {
															setSelectedHour(hour);
															setSelectedMinute('00');
															setShowMinuteSelector(true);
															setTimeToSchedule(`${hour}:00`);
														}}
														$isSelected={selectedHour === hour}
														className="animate"
													>
														<Typography weight="medium">
															{parseDateString(hour, 'HH').format('h A')}
														</Typography>
													</S.TimeSelector>
												))}
											</S.TimeGrid>
										</>
									)}

									{/* Minute Selectors */}
									{showMinuteSelector && (
										<S.TimeGrid>
											{['00', '15', '30', '45'].map((minute) => (
												<S.TimeSelector
													key={minute}
													weight="medium"
													onClick={() => {
														setSelectedMinute(minute);
														setTimeToSchedule(`${selectedHour}:${minute}`);
													}}
													$isSelected={selectedMinute === minute}
													className="animate"
												>
													<Typography weight="medium">
														{parseDateString(`${selectedHour}:${minute}`, 'HH:mm').format('h:mm A')}
													</Typography>
												</S.TimeSelector>
											))}
										</S.TimeGrid>
									)}
								</S.TimeContainer>
							</S.SelectorContainer>
						)}
					</S.ReservationPane>

					{/* Divider */}
					<S.Divider />

					{/* Party */}
					<S.PartyPane>
						<Typography tag="h4" weight="semibold">
							Your Party (
							{selectedParty.length}
							)
						</Typography>
						<S.FamilyContainer>
							{renderPartyComponent()}
						</S.FamilyContainer>
					</S.PartyPane>
				</S.PaneContainer>

				{/* Actions */}
				<S.ActionContainer>
					<Button disabled={isLoading} isLoading={isLoading} variant="solid" onClick={handleSaveAction}>{!editingEvent ? 'Save' : 'Update'}</Button>
				</S.ActionContainer>

			</S.ModalInner>
		</Modal>
	);
};


/**
 * Configuration
 */

ReservationCreatorModal.displayName = 'ReservationCreatorModal';
ReservationCreatorModal.propTypes = {
	className: PropTypes.string,
	isOpen: PropTypes.bool,
	handleClose: PropTypes.func,
	onSave: PropTypes.func,
	isLoading: PropTypes.bool,
	editingEvent: PropTypes.shape(),
	day: PropTypes.shape(),
	parkEntity: PropTypes.string
};
ReservationCreatorModal.defaultProps = {
	className: null,
	isOpen: false,
	handleClose: null,
	onSave: null,
	isLoading: false,
	editingEvent: null,
	day: {},
	parkEntity: null
};
