/*
 * File: TextArea.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on April 15, 2022 at 1:22 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: January 20, 2023 at 10:06 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

// Components
import { Typography } from '../Typography';

// Styles
import * as S from './TextArea.styles';


/**
 * Component
 */

export const TextArea = forwardRef(({
	id, label, error, type, size, containerClassName, smartField, isDark, rows, ...rest
}, ref) => (!smartField ? (
	<S.Wrapper error={error} className={containerClassName}>
		{label && (
			<Typography tag="label" weight="semibold" htmlFor={id ? `${id}` : null} id={id ? `${id}-label` : null} className="animate">
				{label}
			</Typography>
		)}
		<S.TextArea ref={ref} id={id} error={error} {...rest} type={type} size={size} className={rest.className ? `${rest.className} animate` : 'animate'} rows={rows} />
		{error && error.message && (
			<S.FloatingWrapper title={error.message} className="animate">
				<Typography tag="p" variation="3" className="animate">
					{error.message}
				</Typography>
			</S.FloatingWrapper>
		)}
	</S.Wrapper>
) : (
	<S.SmartFieldContainer className={containerClassName}>
		<S.SmartFieldset>
			<S.SmartTextArea ref={ref} id={id} error={error} {...rest} type={type} size={size} className={rest.className ? `${rest.className} animate` : 'animate'} isDark={isDark} rows={rows} required />
			<S.SmartLabel htmlFor={id ? `${id}` : null} id={id ? `${id}-label` : null} className="animate" isDark={isDark}>{label}</S.SmartLabel>
		</S.SmartFieldset>
		<S.SmartFloatingWrapper title={error?.message} className={error && error.message ? 'show animate errorContainer' : 'animate errorContainer'} isDark={isDark}>
			<Typography tag="p" variation="3" weight="medium" className="animate">
				{error?.message}
			</Typography>
		</S.SmartFloatingWrapper>
	</S.SmartFieldContainer>
)
));


/**
 * Configuration
 */

TextArea.displayName = 'TextArea';
TextArea.propTypes = {
	type: PropTypes.oneOf(['text', 'password', 'email', 'search']),
	size: PropTypes.oneOf(['fit', 'small', 'medium']),
	id: PropTypes.string,
	placeholder: PropTypes.string,
	label: PropTypes.string,
	containerClassName: PropTypes.string,
	smartField: PropTypes.bool,
	isDark: PropTypes.bool,
	rows: PropTypes.number,
	error: PropTypes.shape(),
};
TextArea.defaultProps = {
	type: 'text',
	size: 'medium',
	placeholder: '',
	label: '',
	containerClassName: null,
	error: null,
	smartField: false,
	isDark: false,
	rows: 6,
	id: null
};
