/*
 * File: position.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on July 19, 2022 at 1:20 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: November 14, 2022 at 6:36 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Utilities
 */

export const scrollPageTo = (x, y, options = {}) => {
	if (typeof window !== 'undefined') {
		window.scroll({ top: y, left: x, ...options });
	}
};
