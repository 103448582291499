/*
 * File: Impersonation.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on August 3, 2023 at 5:49 AM
 * Copyright © 2023 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: August 9, 2023 at 2:25 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';

// Utilities
import { createStateLocale } from '../../../utilities/locale';
import { globalQuery } from '../../../utilities/state';

// Services
import { validateImpersonation } from '../../../services/impersonation';

// Slices
import { updateImpersonationProgress, updateUser } from '../../../store/slices/user/user.slice';

// Components
import {
	Meta, Spinner
} from '../../../components';

// Styles
import * as S from './Impersonation.styles';


/**
 * Component
 */

const Impersonation = ({ meta, locale, user }) => {

	// Get component parameters
	const { aToken, bToken } = useParams();

	// Create references for components
	const isMounted = useRef(true);

	// Get actions from hooks
	const navigate = useNavigate();
	const dispatch = useDispatch();

	// Get query parameters from hook
	const [searchParams] = useSearchParams();

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Get current user
	let userObj = useSelector((state) => state.user.value);
	if (!userObj && user) userObj = user;

	// Get current impersonation state
	const impersonationInProgress = useSelector((state) => state.user.impersonationInProgress);

	// Initialize component data function
	const validateImpersonationAttempt = async () => {
		try {

			// Fetch data
			const { data } = await validateImpersonation({ aToken, bToken });

			// Dispatch new user
			dispatch(updateUser(data.user));

			// Update state
			dispatch(updateImpersonationProgress(false));

			// Navigate to the dashboard
			navigate(`${stateLocale.localePath}/trips${globalQuery(searchParams)}`);

		} catch (error) {

			// Navigate to login page
			navigate(`${stateLocale.localePath}/login`);
		}
	};

	// Handle component initialization
	useEffect(() => {

		// Set state
		isMounted.current = true;

		// Check if impersonation in progress
		if (!impersonationInProgress) {

			// Update state
			dispatch(updateImpersonationProgress(true));

			// Validate impersonation attempt
			validateImpersonationAttempt();
		}

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, []);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} />

			{/* Component Content */}
			<S.Wrapper className="gradientBackground">
				<Spinner showMeta meta={meta} />
			</S.Wrapper>
		</>
	);
};


/**
 * Configuration
 */

Impersonation.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
	user: PropTypes.shape(),
};
Impersonation.defaultProps = {
	meta: {},
	locale: {},
	user: null
};


/**
 * Exports
 */

export default Impersonation;
