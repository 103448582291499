/*
 * File: UpdateDisneyAccount.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on November 18, 2022 at 3:58 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: October 22, 2023 at 11:16 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import validator from 'validator';

// Utilities
import { createStateLocale } from '../../../utilities/locale';
import { toastError, toastSuccess } from '../../../utilities/toaster';
import { parseFirstLastName } from '../../../../utilities/utilities';
import { globalQuery } from '../../../utilities/state';
import { displayDisneyAuthError } from '../../../utilities/disney';

// Services
import { attachDisneyAccount, getCurrentUser } from '../../../services/user';

// Slices
import { updateUser } from '../../../store/slices/user/user.slice';

// Components
import {
	Meta, AppNavigation, Typography, TextInput, InputCollection, LocaleLink
} from '../../../components';

// Styles
import * as S from './UpdateDisneyAccount.styles';


/**
 * Constants
 */

const MAX_POLLING_ATTEMPTS = 6;


/**
 * State
 */

let pollingTimer = null;


/**
 * Component
 */

const UpdateDisneyAccount = ({ meta, locale, user }) => {

	// Create state handlers
	const [emailError, setEmailError] = useState(null);
	const [passwordError, setPasswordError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [inputValues, setInputValues] = useState({});

	// Get current user
	let userObj = useSelector((state) => state.user.value);
	if (!userObj && user) userObj = user;

	// Create references for components
	const isMounted = useRef(true);
	const pollingCount = useRef(0);

	// Get actions from hooks
	const dispatch = useDispatch();

	// Get actions from hooks
	const navigate = useNavigate();

	// Get query parameters from hook
	const [searchParams] = useSearchParams();

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Handle update Disney account function
	const handleUpdateDisneyAccount = async () => {

		// Get parameters
		const { email, password } = inputValues;

		// Validate parameters
		if (!email || !validator.isEmail(email)) {
			setEmailError({ message: 'Please enter your My Disney Experience email' });
			return;
		}
		if (!password || validator.isEmpty(password, { ignore_whitespace: true })) {
			setPasswordError({ message: 'Please enter your My Disney Experience password' });
			return;
		}

		// Set loading state
		setIsLoading(true);

		// Update user profile
		attachDisneyAccount({ username: email, pass: password }).then(({ data }) => {

			// Show success toast
			toastSuccess(uiMode, 'Success! Your My Disney Experience account has been connected.');

			// Update user
			dispatch(updateUser(data.user));

			// Handle completion actions
			setTimeout(() => {
				navigate(`${stateLocale.localePath}/trips${globalQuery(searchParams)}`);
			}, 2500);

		}).catch(({ response }) => {

			// Check if timeout error
			if (response?.status === 503) {

				// Set polling interval to check Disney authentication status
				pollingTimer = setInterval(async () => {

					// Fetch current user
					const { data } = await getCurrentUser();

					// Check if user has Disney auth error
					if (data.user.disneyAccountError != null && data.user.disneyAccountError.message != null) {

						// Cancel interval
						if (pollingTimer != null) clearInterval(pollingTimer);
						pollingTimer = null;

						// Set loading state
						setIsLoading(false);
						setEmailError(null);
						setPasswordError(null);

						// Clear password
						setInputValues({ ...inputValues, password: '' });

						// Display Disney auth error
						displayDisneyAuthError(data.user.disneyAccountError.message, uiMode);

						// Return
						return;
					}

					// Check if disney authentication completed
					if (data.user.onboardingComplete === true) {

						// Cancel interval
						if (pollingTimer != null) clearInterval(pollingTimer);
						pollingTimer = null;

						// Show success toast
						toastSuccess(uiMode, 'Success! Your My Disney Experience account has been connected.');

						// Update user
						dispatch(updateUser(data.user));

						// Handle completion actions
						setTimeout(() => {
							navigate(`${stateLocale.localePath}/trips${globalQuery(searchParams)}`);
						}, 2500);
					}

					// Update poll count
					pollingCount.current += 1;

					// Show error if exceeded maxium poll attempts
					if (pollingCount.current > MAX_POLLING_ATTEMPTS) {

						// Cancel interval
						if (pollingTimer != null) clearInterval(pollingTimer);
						pollingTimer = null;

						// Set loading state
						setIsLoading(false);
						setEmailError(null);
						setPasswordError(null);

						// Clear password
						setInputValues({ ...inputValues, password: '' });

						// Show error message
						toastError(uiMode, 'Whoops. We\'re having trouble connecting to your My Disney Experience Account. Please try again.');
					}

				}, 8 * 1000); // 8 seconds

			} else {

				// Set loading state
				setIsLoading(false);
				setEmailError(null);
				setPasswordError(null);

				// Clear password
				setInputValues({ ...inputValues, password: '' });

				// Show error message
				if (response?.data?.message) {
					displayDisneyAuthError(response?.data?.message, uiMode);
				} else {
					toastError(uiMode, 'Whoops. We\'re having trouble connecting to your My Disney Experience Account. Please try again.');
				}
			}
		});
	};

	// Handle on input change action
	const handleOnChange = (event) => {
		const { name, value } = event.target;
		setInputValues({ ...inputValues, [name]: value });
	};

	// Handle component initialization
	useEffect(() => {

		// Set state
		isMounted.current = true;

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, []);

	// Handle render content
	const renderContent = () => (
		<S.Form>

			{/* Logo */}
			<S.LogoMark />

			{/* Content */}
			<Typography tag="h4" weight="bold">
				Hi,
				{' '}
				{parseFirstLastName(userObj.name)?.firstName}
			</Typography>
			<Typography tag="h2" weight="bold">Reconnect to your My Disney Experience account</Typography>
			<Typography tag="p" variation="1" weight="regular">By securely connecting your account, we will be able to reserve Lightning Lanes for you.</Typography>

			{/* Inputs */}
			<InputCollection className="floatShadow" isDark>
				<TextInput
					label="My Disney Experience Email"
					name="email"
					type="text"
					error={emailError}
					value={inputValues.email || ''}
					containerClassName="modalInput"
					autoComplete="email"
					onFocus={() => { setEmailError(null); }}
					onKeyUp={() => { setEmailError(null); }}
					onBlur={() => { setEmailError(null); }}
					onChange={handleOnChange}
					smartField
					isDark
				/>
				<TextInput
					label="My Disney Experience Password"
					type="password"
					name="password"
					error={passwordError}
					value={inputValues.password || ''}
					containerClassName="modalInput"
					autoComplete="current-password"
					onFocus={() => { setPasswordError(null); }}
					onKeyUp={() => { setPasswordError(null); }}
					onBlur={() => { setPasswordError(null); }}
					onChange={handleOnChange}
					smartField
					isDark
				/>
			</InputCollection>

			{/* Sign Up Action */}
			<S.ActionButton disabled={isLoading} isLoading={isLoading} onClick={() => { handleUpdateDisneyAccount(); }} size="large" className="floatShadow">Connect account</S.ActionButton>

			{/* Instruction */}
			<S.Instructions>
				<Typography tag="p" variation="3">
					Can&apos;t find your
					{' '}
					<LocaleLink to="https://disneyworld.disney.go.com/plan/my-disney-experience/" target="_blank">My Disney Experience</LocaleLink>
					{' '}
					login information? It&apos;s the same email and password you use to log into
					{' '}
					<LocaleLink to="https://disneyworld.disney.go.com/login" target="_blank">disneyworld.com</LocaleLink>
					,
					{' '}
					<LocaleLink to="https://disneyland.disney.go.com/login" target="_blank">disneyland.com</LocaleLink>
					,
					{' '}
					or
					{' '}
					<LocaleLink to="https://www.disneyplus.com/" target="_blank">Disney Plus</LocaleLink>
					.
				</Typography>
			</S.Instructions>
		</S.Form>
	);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} />

			{/* Component Content */}
			<AppNavigation containerClassName="gradientBackground" isFixed={false}>
				<S.Wrapper>
					{renderContent()}
				</S.Wrapper>
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

UpdateDisneyAccount.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
	user: PropTypes.shape()
};
UpdateDisneyAccount.defaultProps = {
	meta: {},
	locale: {},
	user: null
};


/**
 * Exports
 */

export default UpdateDisneyAccount;
