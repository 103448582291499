/*
 * File: utilities.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on July 21, 2022 at 10:51 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 17, 2023 at 12:46 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import readTimeEstimate from 'read-time-estimate';


/**
 * Utilities
 */

// eslint-disable-next-line no-promise-executor-return
export const timeout = (ms) => new Promise((res) => setTimeout(res, ms));

export const formatToUSD = (number = 0) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(number);

export const isSameMember = (memberA, memberB) => (memberA.userXID != null && memberB.userXID != null && `${memberA.userXID}` === `${memberB.userXID}`)
	|| (memberA.userSWID != null && memberB.userSWID != null && `${memberA.userSWID}` === `${memberB.userSWID}`);

export const camelize = (str) => str.replace(/[&/\\#,+()$~%.'":*?<>{}]/g, '').replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => (index === 0 ? word.toLowerCase() : word.toUpperCase())).replace(/\s+/g, '');

export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const generateRawTimeToRead = (content) => {

	// Define constants
	const WORDS_PER_MIN = 265; // minutes
	const MAX_IMAGE_TIME = 12.0; // seconds

	// Calculate read time estimate
	const { duration } = readTimeEstimate(content, WORDS_PER_MIN, MAX_IMAGE_TIME, 500, ['img', 'Image']);

	// Return duration
	return duration;
};

export const generateSlugFromContent = (content, limitLength = true) => {
	const a = 'àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;';
	const b = 'aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------';
	const p = new RegExp(a.split('').join('|'), 'g');

	// Build slug
	let slug = content
		.toString()
		.toLowerCase()
		.replace(/\s+/g, '-') // Replace spaces with -
		.replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
		.replace(/&/g, '-and-') // Replace & with 'and'
		.replace(/[^\w-]+/g, '') // Remove all non-word characters
		.replace(/--+/g, '-') // Replace multiple - with single -
		.replace(/^-+/, '') // Trim - from start of text
		.replace(/-+$/, ''); // Trim - from end of text

	// Limit length of slug
	if (limitLength) {
		slug = slug.substring(0, 42)
			.replace(/-+$/, ''); // Trim - from end of text
	}

	// Return slug
	return slug;
};


/**
 * Parsers
 */

export const parseFirstLastName = (name) => {
	if (!name) return {};
	const nameArray = name.split(/\s+/);
	const firstName = nameArray[0].trim();
	const lastName = name.replace(firstName, '').trim();
	return { firstName, lastName };
};
