/*
 * File: UpdateEmailModal.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: March 7, 2023 at 11:47 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';
import { useSelector } from 'react-redux';

// Utilities
import { toastError, toastSuccess } from '../../../../utilities/toaster';

// Services
import { updateUserAccountEmail } from '../../../../services/user';

// Components
import {
	Button, Modal, Typography, TextInput
} from '../../../../components';

// Styles
import * as S from './UpdateEmailModal.styles';


/**
 * Component
 */

export const UpdateEmailModal = ({
	className, isOpen, handleClose, updateUser
}) => {

	// Get current user
	const user = useSelector((state) => state.user.value);

	// Create state handlers
	const [emailError, setEmailError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [inputValues, setInputValues] = useState({});

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Handle update user function
	const handleUpdateUser = async () => {

		// Get parameters
		const { email } = inputValues;

		// Validate parameters
		if (!email || !validator.isEmail(email)) {
			setEmailError({ message: 'Please enter your account email address' });
			return;
		}

		// Set loading state
		setIsLoading(true);

		// Update user profile
		updateUserAccountEmail({ email }).then(({ data }) => {

			// Show success toast
			toastSuccess(uiMode, 'Success! Your account email has been updated.');

			// Set loading state
			setIsLoading(false);
			setEmailError(null);

			// Update user
			if (updateUser) updateUser(data.user);

			// Handle completion actions
			setTimeout(() => {
				if (handleClose) handleClose();
			}, 2500);

		}).catch(({ response }) => {

			// Set loading state
			setIsLoading(false);
			setEmailError(null);

			// Show error message
			if (response?.data?.message === 'This email is already in use.') {
				toastError(uiMode, 'This email is already in use. Please choose another email.');
			} else {
				toastError(uiMode, 'Whoops. We\'re having trouble updating your account email. Please try again.');
			}
		});
	};

	// Handle on input change action
	const handleOnChange = (event) => {
		const { name, value } = event.target;
		setInputValues({ ...inputValues, [name]: value });
	};

	// Handle actions on component load
	useEffect(() => {
		if (isOpen === true) {

			// Set input values
			setInputValues({ ...inputValues, email: user?.email });

			// Hide errors
			setEmailError(null);
		}
	}, [isOpen]);

	// Render component
	return (
		<Modal className={className} isOpen={isOpen} handleClose={handleClose} clickOutsideClose useWrapper>
			<S.ModalInner>

				{/* Content */}
				<Typography tag="h3" weight="bold">Account Email</Typography>
				<Typography tag="p" variation="1" weight="light" className="subtitle">Update your Pixie Dust account email.</Typography>

				{/* Inputs */}
				<TextInput
					label="Account Email"
					placeholder="e.g. hello@acme.com"
					name="email"
					error={emailError}
					value={inputValues.email || ''}
					containerClassName="modalInput"
					autoComplete="email"
					onFocus={() => { setEmailError(null); }}
					onKeyUp={() => { setEmailError(null); }}
					onBlur={() => { setEmailError(null); }}
					onChange={handleOnChange}
				/>

				{/* Actions */}
				<S.ActionContainer>
					<Button disabled={isLoading} isLoading={isLoading} variant="solid" onClick={handleUpdateUser}>Update</Button>
				</S.ActionContainer>

			</S.ModalInner>
		</Modal>
	);
};


/**
 * Configuration
 */

UpdateEmailModal.displayName = 'UpdateEmailModal';
UpdateEmailModal.propTypes = {
	className: PropTypes.string,
	isOpen: PropTypes.bool,
	handleClose: PropTypes.func,
	updateUser: PropTypes.func
};
UpdateEmailModal.defaultProps = {
	className: null,
	isOpen: false,
	handleClose: null,
	updateUser: null
};
