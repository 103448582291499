/*
 * File: index.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on June 17, 2022 at 10:14 AM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: November 18, 2022 at 12:41 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Utilities
import api from '../api';


/**
 * Services
 */

export const loginUser = (data) => api.post('/login', data, { withCredentials: true });

export const registerUser = (data) => api.post('/register', data, { withCredentials: true });

export const logoutUser = (data) => api.post('/logout', data, { withCredentials: true });
