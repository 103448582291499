/*
 * File: PaymentMethodModal.styles.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:53 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: March 9, 2023 at 11:18 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Constants
import { mobileBreakpoint, smallBreakpoint } from '../../../../styles/constants';


/**
 * Styles
 */

export const ModalInner = styled.div`
	padding: 0px 0px 30px;
	text-align: center;
	width: 100%;

	.subtitle {
		margin: 5px 0 35px;
		padding: 0 20px;
	}
`;

export const ElementContainer = styled.div`
	padding: 1rem 0.75rem;
	font-size: 1rem;
	border: 1px solid ${({ theme }) => theme.inputBorder};
	border-radius: 6px;
`;

export const InputContainer = styled.div`
	text-align: left;

	label {
		text-align: left;
	 	color: ${({ theme, error }) => (error ? theme.statusDangerBase : theme.secondaryText)} !important;
		margin-bottom: 0.375em;
		display: block;
 	}
	.formInput {
		margin: 20px 0px 0px 0px;
	}
`;

export const ElementRow = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-gap: 20px;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		grid-gap: 15px;
		grid-template-columns: repeat(2, 1fr);
	}
`;

export const ActionContainer = styled.div`
	display: flex;
	gap: 10px;
	flex-direction: column;
	margin: 30px 0px 10px 0px;

	@media only screen and (min-width: ${smallBreakpoint}em) {
		flex-direction: row;
	}
	button {
		flex-grow: 1;
   	 	flex-basis: 0;
		padding-top: 0.85rem;
		padding-bottom: 0.85rem;
	}
`;

export const IconContainer = styled.div`
	width: 50px;
	height: 50px;
	align-items: center;
	justify-content: center;
	background-color: ${({ $type, theme }) => ($type === 'danger' ? theme.statusDangerLighter : theme.secondaryBackground)};
	border-radius: 5px;
	display: inline-flex;
	margin: 0px 0px 15px 0px;

	svg {
		color: ${({ $type, theme }) => ($type === 'danger' ? theme.statusDangerBase : theme.primaryText)};
		font-size: 1.15rem;
	}
`;
