/*
 * File: Table.styles.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:53 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: March 7, 2023 at 1:13 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Constants
import { mobileBreakpoint } from '../../styles/constants';

// Styles
import { ColorValuesCreator } from '../../styles/colors';

// Create color values
const ColorValues = ColorValuesCreator();


/**
 * Styles
 */

export const Wrapper = styled.div``;

export const Table = styled.table`
	width: 100%;
	border-collapse: collapse;
`;

export const TableHeader = styled.thead``;

export const TableRow = styled.tr``;

export const TableHead = styled.th`
	background: ${({ theme, isDark }) => (isDark ? ColorValues.neutralBackground : theme.secondaryBackground)};
	padding: 6px 10px 8px;

	&:first-child {
		border-top-left-radius: 6px;
		border-bottom-left-radius: 6px;
	}
	&:last-child {
		border-top-right-radius: 6px;
		border-bottom-right-radius: 6px;
	}
`;

export const TableBody = styled.tbody``;

export const TableData = styled.td`
	border-bottom: 1px solid ${({ theme, isDark }) => (isDark ? ColorValues.neutralForm : theme.layoutBorder)};
	padding: 10px 10px 14px;
	vertical-align: ${({ $alignTop }) => ($alignTop ? 'top' : 'middle')};
`;

export const DesktopTableContainer = styled.div`
	display: none;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		display: block;
	}
`;

export const MobileTable = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		display: none;
	}
`;

export const MobileItem = styled.div`
	border-bottom: 1px solid ${({ theme, isDark }) => (isDark ? ColorValues.neutralForm : theme.layoutBorder)};
	padding-bottom: 5px;
`;

export const MobileRow = styled.div``;

export const MobileHeader = styled.div`
	background: ${({ theme, isDark }) => (isDark ? ColorValues.neutralBackground : theme.secondaryBackground)};
	padding: 6px 10px 8px;
	border-radius: 6px;
`;

export const MobileData = styled.div`
	padding: 10px 10px 14px;
`;
