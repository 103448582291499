/*
 * File: UpdateEmailModal.styles.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:53 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: March 7, 2023 at 10:45 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Constants
import { smallBreakpoint } from '../../../../styles/constants';


/**
 * Styles
 */

export const ModalInner = styled.div`
	padding: 15px 0px 30px;
	text-align: center;
	width: 100%;

	.subtitle {
		margin: 5px 0 35px;
		padding: 0 20px;
	}
	.modalInput {
		margin: 20px 0 0;
	}
`;

export const ActionContainer = styled.div`
	display: flex;
	gap: 10px;
	flex-direction: column;
	margin: 40px 0px 0px 0px;

	@media only screen and (min-width: ${smallBreakpoint}em) {
		flex-direction: row;
	}
	button {
		flex-grow: 1;
   	 	flex-basis: 0;
		padding-top: 0.85rem;
		padding-bottom: 0.85rem;
	}
`;
