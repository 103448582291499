/*
 * File: trips.slice.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on November 19, 2022 at 1:21 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: March 1, 2023 at 1:02 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import { createSlice } from '@reduxjs/toolkit';


/**
 * Constants
 */

export const initialState = {
	status: 'idle',
	error: null,
	value: {
		isSet: false,
		trips: [],
		currentTrip: null
	},
};


/**
 * Slices
 */

export const tripsSlice = createSlice({
	name: 'trips',
	initialState,
	reducers: {
		updateTrips: (state, action) => {

			// Set state
			state.value = {
				...action.payload,
				currentTrip: state.value.currentTrip,
				isSet: true
			};
		},
		updateCurrentTrip: (state, action) => {

			// Set state
			state.value.currentTrip = action.payload;
		},
		clearTrips: (state) => {

			// Clear state
			state.value = {
				...initialState.value,
				isSet: true
			};
		},
	},
	extraReducers: {},
});


/**
 * Exports
 */

export const { updateTrips, clearTrips, updateCurrentTrip } = tripsSlice.actions;

export default tripsSlice.reducer;
