/*
 * File: FriendsFamily.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:24 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: October 2, 2023 at 10:53 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

// Services
import { getFriendsFamily } from '../../../../services/user';

// Slices
import { updateFamily } from '../../../../store/slices/family/family.slice';

// Components
import {
	FriendsFamilyMember, EmptyComponent
} from '../../../../components';

// Styles
import * as S from './FriendsFamily.styles';


/**
 * Constants
 */

const PLACEHOLDER_FAMILY = [
	{
		guestIdentifiers: { swid: 1 },
		name: { firstName: 'Brendan', lastName: 'M' }
	},
	{
		guestIdentifiers: { swid: 2 },
		name: { firstName: 'Cathy', lastName: 'G' }
	},
	{
		guestIdentifiers: { swid: 3 },
		name: { firstName: 'Lucia', lastName: 'S' }
	},
	{
		guestIdentifiers: { swid: 4 },
		name: { firstName: 'Molly', lastName: 'F' }
	},
	{
		guestIdentifiers: { swid: 5 },
		name: { firstName: 'Walt', lastName: 'D' }
	}
];


/**
 * Component
 */

export const FriendsFamily = ({ className }) => {

	// Create state handlers
	const [friendsFamily, setFriendsFamily] = useState([]);
	const [componentStatus, setComponentStatus] = useState('idle');

	// Create reference for components
	const isMounted = useRef(true);

	// Get current friends and family from hook
	const family = useSelector((state) => state.family.value);

	// Get actions from hooks
	const dispatch = useDispatch();

	// Initialize component data function
	const fetchFriendsFamily = async () => {

		// Check if stored friends and family
		if (!family.isSet) {

			// Update page status
			setComponentStatus('loading');
			try {

				// Fetch data
				const { data } = await getFriendsFamily();

				// Check if mounted
				if (isMounted.current) {

					// Set new data state
					setFriendsFamily([
						...data?.family?.withPlans || [],
						...data?.family?.withoutPlans || [],
						...data?.family?.transactional || [],
					]);

					// Update friends and family
					dispatch(updateFamily({ family: data.family }));

					// Update page status
					setComponentStatus('success');
				}
			} catch (error) {

				// Update page status
				if (isMounted.current) {
					setComponentStatus('error');
				}
			}
		} else if (isMounted.current) {

			// Set new data state
			setFriendsFamily([
				...family?.family?.withPlans || [],
				...family?.family?.withoutPlans || [],
				...family?.family?.transactional || [],
			]);

			// Update page status
			setComponentStatus('success');
		}
	};

	// Perform actions on component load
	useEffect(() => {

		// Set state
		isMounted.current = true;

		// Fetch friends and family
		fetchFriendsFamily();

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, []);

	// Render component
	const renderComponent = () => {
		if (componentStatus !== 'success') {
			return PLACEHOLDER_FAMILY.map((member) => (
				<FriendsFamilyMember
					key={member.guestIdentifiers.swid || member.guestIdentifiers.xid}
					firstName={member.name.firstName}
					lastName={member.name.lastName}
					isLoading
				/>
			));
		}
		if (friendsFamily.length === 0) {
			return (
				<EmptyComponent
					isLight
					title="Your Friends & Family list is empty"
					message="Head on over to your My Disney Experience account to add a new member"
					icon={['far', 'star']}
					style={{ width: '100%' }}
				/>
			);
		}
		return friendsFamily.map((member) => (
			<FriendsFamilyMember
				key={member.guestIdentifiers.swid || member.guestIdentifiers.xid}
				firstName={member.name.firstName}
				lastName={member.name.lastName}
				profileImage={member.profileImage}
				avatarId={member.avatarId}
			/>
		));
	};

	// Return component
	return (
		<S.Wrapper className={className}>{renderComponent()}</S.Wrapper>
	);
};


/**
 * Configuration
 */

FriendsFamily.displayName = 'FriendsFamily';
FriendsFamily.propTypes = {
	className: PropTypes.string,
};
FriendsFamily.defaultProps = {
	className: null,
};
