/*
 * File: user.slice.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on November 18, 2022 at 12:50 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: August 9, 2023 at 2:03 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

// Services
import { getCurrentUser } from '../../../services/user';

// Constants
import { ROLES } from '../../../../Constants';


/**
 * Functions
 */

const setUserForAnalytics = (user) => {

	// Only set user for analytics if not impersonated
	if (!user.isImpersonation) {

		// Set current user to Sentry
		Sentry.setUser({
			id: user.id,
			username: user.username || undefined,
			email: user.email || undefined,
			ip_address: '{{auto}}'
		});

		// Set current user for GTM
		try {
			window.dataLayer.push({ user_id: user.id });
		} catch (e) {}
	}
};

export const fetchUser = createAsyncThunk('user/fetchUser', async () => {
	try {

		// Fetch current user
		const { data } = await getCurrentUser();

		// Set current user for analytics
		setUserForAnalytics(data.user);

		// Return user
		return data.user;

	} catch (e) {

		// Return null
		return null;
	}
});


/**
 * Slices
 */

export const userSlice = createSlice({
	name: 'user',
	initialState: {
		status: 'idle',
		error: null,
		value: null,
		impersonationInProgress: false
	},
	reducers: {
		updateUser: (state, action) => {

			// Set state
			state.value = action.payload;
			state.status = 'succeeded';

			// Set current user for analytics
			setUserForAnalytics(action.payload);
		},
		clearUser: (state) => {

			// Clear state
			state.value = { isActive: true, isAnonymous: true, role: { primary: ROLES.STANDARD } };

			// Clear current user from Sentry
			Sentry.configureScope((scope) => scope.setUser(null));

			// Clear current user from GTM
			try {
				window.dataLayer.push({ user_id: undefined });
			} catch (e) {}
		},
		updateImpersonationProgress: (state, action) => {

			// Set state
			state.impersonationInProgress = action.payload;
		}
	},
	extraReducers: {
		[fetchUser.pending]: (state) => {
			state.status = 'loading';
		},
		[fetchUser.fulfilled]: (state, action) => {
			state.status = 'succeeded';
			state.value = action.payload;
		},
		[fetchUser.rejected]: (state, action) => {
			state.status = 'failed';
			state.error = action.error.message;
		},
	},
});


/**
 * Exports
 */

export const { updateUser, clearUser, updateImpersonationProgress } = userSlice.actions;

export default userSlice.reducer;
