/*
 * File: DayAssignmentStep.styles.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:25 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: May 2, 2023 at 1:38 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Styles
import { ColorValuesCreator } from '../../../../styles/colors';

// Components
import { DayCollection as BaseDayCollection } from '../../../../components';

// Create color values
const ColorValues = ColorValuesCreator();


/**
 * Styles
 */

export const StepContainer = styled.div`
	width: 100%;
	margin: 0px auto;

	@media only screen {
		padding-top: 20px;
		padding-bottom: 50px;
	}
	@media only screen and (min-width: 40em) {
		padding-top: 60px;
		padding-bottom: 60px;
	}
`;

export const Form = styled.form`
	width: 100%;
	max-width: 400px;
	margin: 0px auto;

	@media only screen {
		padding-top: 60px;
		padding-bottom: 50px;
	}
	@media only screen and (min-width: 40em) {
		padding-top: 140px;
		padding-bottom: 50px;
	}
	h2 {
		text-align: left;
		color: ${ColorValues.white};
		margin: 10px 0px 10px;
	}
	p {
		text-align: left;
		color: ${ColorValues.white};
   		margin-bottom: 20px;
	}
	button {
		width: 100%;
	}
`;

export const DayCollection = styled(BaseDayCollection)``;

export const ActionContainer = styled.div`
	width: 100%;
	max-width: 400px;
	margin: 40px auto 65px;

	button {
		flex-grow: 1;
   	 	flex-basis: 0;
		padding-top: 0.85rem;
		padding-bottom: 0.85rem;
		margin: 0px auto;
	}
	.backButton {
		font-size: 0.8rem;
	}
`;
