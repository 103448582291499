/*
 * File: Padding.styles.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 9, 2022 at 5:45 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: March 29, 2023 at 12:33 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Constants
import { mediumBreakpoint, largeBreakpoint } from '../../styles/constants';


/**
 * Styles
 */

export const Padding = styled.div`
	width: ${({ dynamic, isLarge }) => (dynamic && !isLarge ? '88%' : '100%')};
	margin-left: auto;
	margin-right: auto;
	display: flex;
	padding-left: ${({ left }) => (left ? '1rem' : '0')};
	padding-right: ${({ right }) => (right ? '1rem' : '0')};

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		width: 100%;
		padding-left: ${({ left }) => (left ? '3rem' : '0')};
		padding-right: ${({ right }) => (right ? '3rem' : '0')};
	}

	@media only screen and (min-width: ${largeBreakpoint}em) {
		padding-left: ${({ left }) => (left ? '3rem' : '0')};
		padding-right: ${({ right }) => (right ? '3rem' : '0')};
		max-width: ${({ isLarge }) => (isLarge ? '1500px' : '1150px')};
	}
`;

export const NegativePadding = styled.div`
	width: ${({ dynamic }) => (dynamic ? '112%' : '100%')};
	margin-left: auto;
	margin-right: auto;
	display: flex;
	margin-left: ${({ left }) => (left ? '-1rem' : '0')};
	margin-right: ${({ right }) => (right ? '-1rem' : '0')};

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		width: 100%;
		margin-left: ${({ left }) => (left ? '-3rem' : '0')};
		margin-right: ${({ right }) => (right ? '-3rem' : '0')};
	}

	@media only screen and (min-width: ${largeBreakpoint}em) {
		margin-left: ${({ left }) => (left ? '-3rem' : '0')};
		margin-right: ${({ right }) => (right ? '-3rem' : '0')};
	}
`;
