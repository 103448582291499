/*
 * File: EmailStep.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:24 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 17, 2023 at 5:47 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import validator from 'validator';
import { useNavigate, useSearchParams } from 'react-router-dom';

// Utilities
import { toastError } from '../../../../utilities/toaster';
import { globalQuery } from '../../../../utilities/state';

// Services
import { registerUser } from '../../../../services/authentication';

// Slices
import { updateUser } from '../../../../store/slices/user/user.slice';

// Components
import {
	Typography, TextInput, Button, Meta, InputCollection
} from '../../../../components';

// Styles
import * as S from './EmailStep.styles';


/**
 * Component
 */

export const EmailStep = forwardRef(({
	updateStep, className
}, ref) => {

	// Create state handlers
	const [emailError, setEmailError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [inputValues, setInputValues] = useState({});

	// Get actions from hooks
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { executeRecaptcha } = useGoogleReCaptcha();

	// Get query parameters from hook
	const [searchParams] = useSearchParams();

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get current locale from hook
	const stateLocale = useSelector((state) => state.locale.value);

	// Handle sign up function
	const handleSignUp = async () => {

		// Get parameters
		const { email } = inputValues;

		// Validate parameters
		if (!email || !validator.isEmail(email)) {
			setEmailError({ message: 'Please enter your email' });
			return;
		}

		// Set loading state
		setIsLoading(true);

		// Get reCAPTCHA token
		let recaptchaToken = null;
		try { recaptchaToken = await executeRecaptcha('user_register'); } catch (e) {}

		// Register user
		registerUser({ email, recaptchaToken }).then(({ data }) => {

			// Set loading state
			setIsLoading(false);

			// Dispatch new user
			dispatch(updateUser(data.user));

			// Move to next step
			updateStep(2);

		}).catch(({ response }) => {

			// Set loading state
			setIsLoading(false);
			setEmailError(null);

			// Show error message
			if (response?.data?.message) {
				toastError(uiMode, response?.data?.message);
			} else {
				toastError(uiMode, 'Whoops. We\'re having trouble creating your account. Please try again.');
			}
		});
	};

	// Handle sign up action function
	const logInFunction = () => {
		navigate(`${stateLocale.localePath}/login${globalQuery(searchParams)}`);
	};

	// Handle on input change action
	const handleOnChange = (event) => {
		const { name, value } = event.target;
		setInputValues({ ...inputValues, [name]: value });
	};

	// Return component
	return (
		<S.StepContainer ref={ref} className={className}>

			{/* Meta */}
			<Meta
				meta={{
					title: 'Join • Pixie Dust',
					description: 'Create a Pixie Dust account to easily schedule your Disney World & Disneyland Lightning Lanes.',
					showSocial: false
				}}
			/>

			{/* Form */}
			<S.Form>

				{/* Logo */}
				<S.LogoMark className="isNotMobile" />

				{/* Content */}
				<Typography tag="h2" weight="bold">Sign Up</Typography>
				<Typography tag="p" variation="1" weight="regular">Create a Pixie Dust account to easily schedule your Disney World & Disneyland Lightning Lanes.</Typography>

				{/* Inputs */}
				<InputCollection className="floatShadow" isDark>
					<TextInput
						label="Email Address"
						name="email"
						type="text"
						error={emailError}
						value={inputValues.email || ''}
						containerClassName="modalInput"
						autoComplete="email"
						onFocus={() => { setEmailError(null); }}
						onKeyUp={() => { setEmailError(null); }}
						onBlur={() => { setEmailError(null); }}
						onChange={handleOnChange}
						smartField
						isDark
					/>
				</InputCollection>

				{/* Sign Up Action */}
				<S.ActionButton disabled={isLoading} isLoading={isLoading} onClick={() => { handleSignUp(); }} size="large" className="floatShadow">Sign Up</S.ActionButton>

				{/* Log In Action */}
				<S.BottomActionContainer>
					<Button variant="text" size="small" onClick={() => { logInFunction(); }} disabled={isLoading}>
						<Typography tag="p" variation="2" weight="medium">
							Already have an account?
							{' '}
							<Typography decoration="underline" weight="medium" style={{ fontSize: 'inherit' }}>
								Log In
							</Typography>
						</Typography>
					</Button>
				</S.BottomActionContainer>
			</S.Form>
		</S.StepContainer>
	);
});


/**
 * Configuration
 */

EmailStep.displayName = 'EmailStep';
EmailStep.propTypes = {
	updateStep: PropTypes.func.isRequired,
	className: PropTypes.string
};
EmailStep.defaultProps = {
	className: null
};
