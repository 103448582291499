/*
 * File: UserRouteWrapper.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on November 18, 2022 at 12:48 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 10, 2023 at 2:39 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Utilities
import { globalQuery } from '../utilities/state';

// Components
import {
	Spinner
} from '../components';


/**
 * Route Wrapper
 */

export const UserRouteWrapper = ({
	children, roles, user, locale, ignoreUpdateDisneyAccount
}) => {

	// Get query parameters from hook
	const [searchParams] = useSearchParams();

	// Get current user
	let userObj = useSelector((state) => state.user.value);
	const userStatus = useSelector((state) => state.user.status);
	if (!userObj && user) userObj = user;

	// Get current locale from hook
	let localeObj = useSelector((state) => state.locale.value);
	if (locale && locale.isSet) localeObj = locale;

	// Check if user has required role
	const userHasRequiredRole = userObj?.role && userObj?.role?.primary && roles.includes(userObj?.role?.primary);

	// Render loading state
	if (userStatus !== 'succeeded') {
		return <Spinner showMeta />;
	}

	// Check user state
	if (!userObj) {

		// Redirect to login
		return <Navigate to={`${localeObj.localePath}/login${globalQuery(searchParams)}`} />;
	}
	if (userObj.isAnonymous === true) {

		// Redirect to login
		return <Navigate to={`${localeObj.localePath}/login${globalQuery(searchParams)}`} />;
	}
	if (userObj.isActive !== true) {

		// Redirect to logout
		window.location = '/logout';
		return null;
	}
	if (!userHasRequiredRole) {

		// Redirect to logout
		window.location = '/logout';
		return null;
	}
	if (userObj.accountSetupComplete !== true) {

		// Redirect to create account
		return <Navigate to={`${localeObj.localePath}/join${globalQuery(searchParams)}`} />;
	}
	if (userObj.onboardingComplete !== true) {

		// Redirect to create account
		return <Navigate to={`${localeObj.localePath}/join${globalQuery(searchParams)}`} />;
	}
	if (userObj.onboardingConfirmationComplete !== true) {

		// Redirect to create account
		return <Navigate to={`${localeObj.localePath}/join${globalQuery(searchParams)}`} />;
	}
	if (userObj.requiresDisneyAccountUpdate === true && !ignoreUpdateDisneyAccount) {

		// Redirect to update Disney account
		return <Navigate to={`${localeObj.localePath}/account/disney${globalQuery(searchParams)}`} />;
	}

	// Return wrapper children
	return children;
};


/**
 * Configuration
 */

UserRouteWrapper.propTypes = {
	children: PropTypes.element,
	roles: PropTypes.arrayOf(PropTypes.string),
	user: PropTypes.shape(),
	locale: PropTypes.shape(),
	ignoreUpdateDisneyAccount: PropTypes.bool
};
UserRouteWrapper.defaultProps = {
	children: null,
	roles: [],
	user: null,
	locale: null,
	ignoreUpdateDisneyAccount: false
};
