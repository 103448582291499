/*
 * File: AppNavigation.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:29 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: June 29, 2023 at 9:49 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, {
	useState, createContext, forwardRef
} from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';

// Utilities
import { getCookie } from '../../utilities/cookies';

// Constants
import { VIEW_MODE_COOKIE } from '../../../Constants';

// Components
import { Sidebar } from './Sidebar';
import { Topbar } from './Topbar';
import { CopyrightFooter } from '../CopyrightFooter';

// Styles
import * as S from './AppNavigation.styles';


/**
 * Context
 */

export const AppNavigationContext = createContext();


/**
 * Component
 */

export const AppNavigation = forwardRef(({
	children, isLarge, isLargeContent, containerClassName, hidePadding, isTopbarTransparent, isFixed
}, ref) => {

	// Set state
	const [sideNavigationOpen, setSideNavigationOpen] = useState(false);

	// Get query parameters from hook
	const [searchParams] = useSearchParams();
	let isMobileApp = searchParams.get('mobileapp') === 'true';
	if (!isMobileApp) {
		const mobileAppCookie = getCookie(VIEW_MODE_COOKIE);
		if (mobileAppCookie === 'mobileapp') isMobileApp = true;
	}

	// Toggle navigation function
	const toggleNavigation = () => {

		// Toggle mobile sidebar visibility
		setSideNavigationOpen(!sideNavigationOpen);
	};

	// Render component
	if (isMobileApp) {
		return (
			<S.Content id="scroller" className={containerClassName} isTransparent={containerClassName === 'gradientBackground'} headerVisible={!isMobileApp}>

				{/* Content */}
				{!hidePadding ? (
					<S.ContentPadding left right dynamic isLarge={isLarge || isLargeContent}>
						{children}
					</S.ContentPadding>
				) : (
					<S.ContentPadding isLarge={isLarge || isLargeContent}>
						{children}
					</S.ContentPadding>
				)}

				{/* Footer */}
				{!isMobileApp && <CopyrightFooter isLarge={isLarge} isDark={containerClassName === 'gradientBackground'} showBorder={false} />}
			</S.Content>
		);
	}
	return (
		<S.Navigation ref={ref} isTransparent={containerClassName === 'gradientBackground'}>

			{/* Top Bar */}
			{!isMobileApp && (
				<Topbar
					toggleNavigation={toggleNavigation}
					isLarge={isLarge}
					isDark={containerClassName === 'gradientBackground'}
					isTransparent={isTopbarTransparent || containerClassName === 'gradientBackground'}
					isFixed={isFixed}
				/>
			)}

			{/* Side Navigation */}
			{!isMobileApp && (
				<S.NavigationOverlay isOpen={sideNavigationOpen}>
					<Sidebar
						className="leftSidebar"
						toggleOverlay={toggleNavigation}
						isDark={containerClassName === 'gradientBackground'}
					/>
					<S.Overlay isOpen={sideNavigationOpen} onClick={() => { toggleNavigation(); }} />
				</S.NavigationOverlay>
			)}

			{/* Content */}
			<S.Wrapper isTransparent={containerClassName === 'gradientBackground'}>
				<S.Content id="scroller" className={containerClassName} isTransparent={containerClassName === 'gradientBackground'} headerVisible={!isMobileApp}>

					{/* Content */}
					{!hidePadding ? (
						<S.ContentPadding left right dynamic isLarge={isLarge || isLargeContent}>
							{children}
						</S.ContentPadding>
					) : (
						<S.ContentPadding isLarge={isLarge || isLargeContent}>
							{children}
						</S.ContentPadding>
					)}

					{/* Footer */}
					{!isMobileApp && <CopyrightFooter isLarge={isLarge} isDark={containerClassName === 'gradientBackground'} showBorder={false} />}
				</S.Content>
			</S.Wrapper>
		</S.Navigation>
	);
});


/**
 * Configuration
 */

AppNavigation.displayName = 'AppNavigation';
AppNavigation.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	isLarge: PropTypes.bool,
	isLargeContent: PropTypes.bool,
	containerClassName: PropTypes.string,
	hidePadding: PropTypes.bool,
	isTopbarTransparent: PropTypes.bool,
	isFixed: PropTypes.bool,
};
AppNavigation.defaultProps = {
	children: null,
	isLarge: false,
	isLargeContent: false,
	containerClassName: null,
	hidePadding: false,
	isTopbarTransparent: false,
	isFixed: true
};
