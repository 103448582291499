/*
 * File: Topbar.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:51 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: September 2, 2023 at 10:07 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// Utilities
import { getCookie } from '../../../utilities/cookies';
import { renderProfileImage } from '../../../utilities/image';
import { globalQuery } from '../../../utilities/state';

// Slices
import { updateUI } from '../../../store/slices/ui/ui.slice';

// Components
import { Padding } from '../../Padding';
import { LocaleLink } from '../../LocaleLink';
import { Button } from '../../Button';
import { Emoji } from '../../Emoji';

// Constants
import { navigationDropdownDelay } from '../../../styles/constants';
import { UI_MODE_OPTIONS, VIEW_MODE_COOKIE } from '../../../../Constants';

// Styles
import * as S from './Topbar.styles';


/**
 * State
 */

let navigationDropdownTimer = null;


/**
 * Component
 */

export const Topbar = ({
	toggleNavigation, isLarge, isDark, isTransparent, isFixed
}) => {

	// Set state
	const [showNavigationDropdown, setShowNavigationDropdown] = useState(false);
	const [isScrolled, setIsScrolled] = useState(false);

	// Get query parameters from hook
	const [searchParams] = useSearchParams();
	let isMobileApp = searchParams.get('mobileapp') === 'true';
	if (!isMobileApp) {
		const mobileAppCookie = getCookie(VIEW_MODE_COOKIE);
		if (mobileAppCookie === 'mobileapp') isMobileApp = true;
	}

	// Create references for components
	const isMounted = useRef(true);

	// Use hooks
	const navigate = useNavigate();

	// Get current user from hook
	const user = useSelector((state) => state.user.value);

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Use hooks
	const dispatch = useDispatch();

	// Get CDN URL
	const CDNURL = process.env.CDN_URL;

	// Handle actions on component load
	useEffect(() => {

		// Initialize temporary state
		isMounted.current = true;

		// Attach scroll listener
		if (isMounted.current) {
			document.addEventListener('scroll', () => {
				if (isMounted.current) {

					// Get current scroll status
					const st = document.scrollingElement.scrollTop;

					// Update scrolled status
					setIsScrolled(st >= 20);
				}
			}, true);
		}

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, []);

	// Handle navigation action rendering
	const renderNavigationActions = () => {
		if (user && !user.isAnonymous) {
			return (
				<>
					<S.LinkContainer className="isNotMobile">
						<S.NavigationLink to="/about" $isDark={isDark} $isTransparent={isTransparent} $isScrolled={isScrolled}>
							About Us
						</S.NavigationLink>
						<S.NavigationLink to="/faq" $isDark={isDark} $isTransparent={isTransparent} $isScrolled={isScrolled}>
							FAQ
						</S.NavigationLink>
						<S.NavigationLink to="/blog" $isDark={isDark} $isTransparent={isTransparent} $isScrolled={isScrolled}>
							Blog
						</S.NavigationLink>
					</S.LinkContainer>
					{user.isImpersonation && (
						<S.ImpersonationIndicator onClick={() => {
							window.location = '/logout';
						}}
						>
							<Emoji symbol="🤖" label="robot" size={1.8} />
						</S.ImpersonationIndicator>
					)}
					<S.ActionPadding>
						<S.ProfileContainer
							onMouseEnter={() => {
								clearTimeout(navigationDropdownTimer);
								setShowNavigationDropdown(true);
							}}
							onMouseLeave={() => {
								clearTimeout(navigationDropdownTimer);
								navigationDropdownTimer = setTimeout(() => {
									setShowNavigationDropdown(false);
								}, navigationDropdownDelay);
							}}
						>
							<S.ProfileImage src={renderProfileImage(user.profileImage, '200x200', isMobileApp)} title="My Profile" alt="My Profile" />
							<S.NavigationDropdown
								className={showNavigationDropdown ? 'animate auth show' : 'auth animate'}
								items={[
									{
										icon: ['far', 'user'],
										title: 'My Profile',
										link: '/me'
									},
									{
										icon: ['far', 'star'],
										title: 'Trips',
										link: '/trips',
									},
									{
										icon: ['fa', 'plus'],
										title: 'Create trip',
										link: '/trip/create'
									},
									{
										icon: uiMode.mode === 'light' ? ['far', 'moon'] : ['far', 'sun'],
										title: uiMode.mode === 'light' ? 'Dark Mode' : 'Light Mode',
										action: () => {
											setShowNavigationDropdown(false);
											dispatch(updateUI({ isSet: true, mode: uiMode.mode === 'light' ? 'dark' : 'light' }));
										}
									},
									{
										icon: ['far', 'question-circle'],
										title: 'Feedback and help',
										link: '/contact'
									}
								]}
								footerItems={[
									{
										icon: ['fa', 'arrow-right-from-bracket'],
										title: 'Log out',
										action: () => { window.location = '/logout'; }
									}
								]}
							/>
						</S.ProfileContainer>
					</S.ActionPadding>
				</>
			);
		}
		return (
			<>
				<S.LinkContainer className="isNotMobile">
					<S.NavigationLink to="/#features" $isDark={isDark} $isTransparent={isTransparent} $isScrolled={isScrolled}>
						How It Works
					</S.NavigationLink>
					<S.NavigationLink to="/about" $isDark={isDark} $isTransparent={isTransparent} $isScrolled={isScrolled}>
						About Us
					</S.NavigationLink>
					<S.NavigationLink to="/faq" $isDark={isDark} $isTransparent={isTransparent} $isScrolled={isScrolled}>
						FAQ
					</S.NavigationLink>
					<S.NavigationLink to="/blog" $isDark={isDark} $isTransparent={isTransparent} $isScrolled={isScrolled}>
						Blog
					</S.NavigationLink>
					<S.NavigationLink to="/login" $isDark={isDark} $isTransparent={isTransparent} $isScrolled={isScrolled}>
						Log In
					</S.NavigationLink>
				</S.LinkContainer>
				<Button onClick={() => {
					navigate(`/join${globalQuery(searchParams)}`);
				}}
				>
					Get started
				</Button>
			</>
		);
	};

	// Render component
	return (
		<S.Topbar isTransparent={isTransparent} isFixed={isFixed} isDark={isDark} isScrolled={isScrolled} className="animate">
			<Padding left right isLarge={isLarge}>
				<S.TopbarWrapper>
					<S.NavigationToggle icon={['fa', 'bars']} size={1.25} isDark={isDark || isTransparent} isScrolled={isScrolled} isFixed={isFixed} onClick={() => { toggleNavigation(); }} />
					<LocaleLink to={user && !user.isAnonymous ? '/trips' : '/'}>
						<S.LogoImage
							className="isMobile"
							alt="Pixie Dust Logo"
							title="Pixie Dust Logo"
							src={`${CDNURL}/public/assets/logos/pixie-dust-logo-solo.png`}
						/>
						<S.LogoImage
							className="isNotMobile"
							alt="Pixie Dust Logo"
							title="Pixie Dust Logo"
							src={uiMode.mode === UI_MODE_OPTIONS.DARK || (isDark && ((isFixed && !isScrolled) || !isFixed)) || (isTransparent && ((isFixed && !isScrolled) || !isFixed)) ? `${CDNURL}/public/assets/logos/pixie-dust-logo-white.png` : `${CDNURL}/public/assets/logos/pixie-dust-logo.png`}
						/>
					</LocaleLink>
				</S.TopbarWrapper>
				<S.TopbarWrapper fullWidth />
				<S.TopbarWrapper>
					{renderNavigationActions()}
				</S.TopbarWrapper>
			</Padding>
		</S.Topbar>
	);
};


/**
 * Configuration
 */

Topbar.displayName = 'Topbar';
Topbar.propTypes = {
	toggleNavigation: PropTypes.func,
	isLarge: PropTypes.bool,
	isDark: PropTypes.bool,
	isTransparent: PropTypes.bool,
	isFixed: PropTypes.bool,
};
Topbar.defaultProps = {
	toggleNavigation: null,
	isLarge: false,
	isDark: false,
	isTransparent: false,
	isFixed: true
};
