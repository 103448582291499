/*
 * File: Button.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: February 24, 2023 at 12:50 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie-player';
import { useSelector } from 'react-redux';
import { flatten } from 'lottie-colorify';

// Animation
import animationData from '../../assets/animations/spinner.json';

// Constants
import { UI_MODE_OPTIONS } from '../../../Constants';

// Styles
import * as S from './Button.styles';
import { ColorValuesCreator, DarkTheme, LightTheme } from '../../styles/colors';

// Create color values
const ColorValues = ColorValuesCreator();


/**
 * Component
 */

export const Button = forwardRef(({
	children, size, variant, variation, type, className, isLoading, ...rest
}, ref) => {

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Define current theme
	const currentTheme = uiMode.mode === UI_MODE_OPTIONS.DARK ? DarkTheme() : LightTheme();

	// Render animation data
	const renderAnimationData = () => {
		switch (variation) {
			case 'default': {
				if (variant === 'solid') {
					return flatten(currentTheme.primaryBackground, animationData);
				} if (variant === 'outline') {
					return flatten(currentTheme.primaryButton, animationData);
				}
				return flatten(currentTheme.primaryButton, animationData);
			}
			case 'light': {
				if (variant === 'solid') {
					return flatten(currentTheme.primaryButton, animationData);
				} if (variant === 'outline') {
					return flatten(currentTheme.primaryButton, animationData);
				}
				return flatten(currentTheme.primaryButton, animationData);
			}
			case 'white': {
				if (variant === 'solid') {
					return flatten(ColorValues.dark, animationData);
				} if (variant === 'outline') {
					return flatten(ColorValues.white, animationData);
				}
				return flatten(ColorValues.white, animationData);
			}
			case 'dark': {
				if (variant === 'solid') {
					return flatten(currentTheme.primaryBackground, animationData);
				} if (variant === 'outline') {
					return flatten(currentTheme.darkButton, animationData);
				}
				return flatten(currentTheme.darkButton, animationData);
			}
			case 'secondary': {
				if (variant === 'solid') {
					return flatten(currentTheme.primaryBackground, animationData);
				} if (variant === 'outline') {
					return flatten(currentTheme.secondaryButton, animationData);
				}
				return flatten(currentTheme.secondaryButton, animationData);
			}
			case 'warning': {
				if (variant === 'solid') {
					return flatten(currentTheme.primaryBackground, animationData);
				} if (variant === 'outline') {
					return flatten(currentTheme.statusDangerBase, animationData);
				}
				return flatten(currentTheme.statusDangerBase, animationData);
			}
			default:
				break;
		}
		return flatten(currentTheme.primaryButton, animationData);
	};

	// Render component
	return (
		<S.Button ref={ref} size={size} type={type} variant={variant} variation={variation} className={className ? `${className} animate` : 'animate'} {...rest}>
			{isLoading && (
				<S.SpinnerContainer>
					<Lottie loop play animationData={renderAnimationData()} style={{ height: '100%' }} />
				</S.SpinnerContainer>
			)}
			{React.Children.map(children, (child) => {
				if (React.isValidElement(child)) {
					return React.cloneElement(child, { style: { visibility: isLoading ? 'hidden' : 'visible' } });
				}
				return <S.Span style={{ visibility: isLoading ? 'hidden' : 'visible' }}>{child}</S.Span>;
			})}
		</S.Button>
	);
});


/**
 * Configuration
 */

Button.displayName = 'Button';
Button.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	size: PropTypes.oneOf(['large', 'medium', 'small']),
	variant: PropTypes.oneOf(['solid', 'outline', 'text']),
	variation: PropTypes.oneOf(['default', 'light', 'white', 'dark', 'secondary', 'warning']),
	className: PropTypes.string,
	type: PropTypes.string,
	isLoading: PropTypes.bool
};
Button.defaultProps = {
	children: null,
	size: 'medium',
	variant: 'solid',
	variation: 'default',
	className: null,
	type: 'button',
	isLoading: false
};
