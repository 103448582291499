/*
 * File: DayAssignmentStep.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:24 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 18, 2023 at 10:21 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

// Utilities
import { toastError } from '../../../../utilities/toaster';
import { globalQuery } from '../../../../utilities/state';

// Services
import { createUpdateTrip } from '../../../../services/trip';

// Slices
import { updateCurrentTrip, updateTrips } from '../../../../store/slices/trips/trips.slice';

// Components
import {
	Button
} from '../../../../components';

// Styles
import * as S from './DayAssignmentStep.styles';


/**
 * Component
 */

export const DayAssignmentStep = forwardRef(({
	className, updateStep, isVisible
}, ref) => {

	// Get current trip
	const { currentTrip, trips, isSet } = useSelector((state) => state.trips.value);

	// Create state handlers
	const [isLoading, setIsLoading] = useState(false);
	const [days, updateDays] = useState(currentTrip?.days || []);
	const [buttonTitle, setButtonTitle] = useState('Schedule & Continue');

	// Get actions from hooks
	const dispatch = useDispatch();
	const navigate = useNavigate();

	// Get query parameters from hook
	const [searchParams] = useSearchParams();

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get current locale from hook
	const stateLocale = useSelector((state) => state.locale.value);

	// Get current user
	const user = useSelector((state) => state.user.value);

	// Handle next action function
	const handleNext = async () => {

		// Check if should schedule and continue
		if (!days.some((day) => day.scheduledEvents.length > 0)) {
			navigate(`${stateLocale.localePath}/trip/${currentTrip.id}${globalQuery(searchParams)}`);
			return;
		}

		// Update state
		setIsLoading(true);

		// Update trip
		createUpdateTrip({
			id: currentTrip?.id,
			name: currentTrip?.name,
			days,
			shouldPurchaseGeniePlus: currentTrip?.shouldPurchaseGeniePlus,
			parkEntity: currentTrip?.parkEntity?.id
		}).then(({ data }) => {

			// Set loading state
			setIsLoading(false);

			// Update trip
			dispatch(updateCurrentTrip(data.trip));

			// Update trips
			if (isSet) {
				const newTrips = [...(trips || [])];
				const tripIndex = newTrips.findIndex((v) => v.id === data.trip.id);
				if (tripIndex > -1) newTrips[tripIndex] = data.trip;
				else newTrips.push(data.trip);
				dispatch(updateTrips({ trips: newTrips }));
			}

			// Move to next step
			if (!user?.hasInternalPaymentMethod) {
				updateStep(4); // Move to payment method step
			} else {
				updateStep(5); // Move to confirmation step
			}
		}).catch(({ response }) => {

			// Set loading state
			setIsLoading(false);

			// Show error message
			if (response?.data?.message) {
				toastError(uiMode, 'Whoops. We\'re having trouble scheduling your reservations. Please try again.');
			} else {
				toastError(uiMode, 'Whoops. We\'re having trouble scheduling your reservations. Please try again.');
			}
		});
	};

	// Handle back action
	const handleBack = () => {

		// Update step
		updateStep(2); // Move to day selection
	};

	// Perform actions on days change
	useEffect(() => {

		// Update button text
		if (days.some((day) => day.scheduledEvents.length > 0)) {
			setButtonTitle('Save & Continue');
		} else {
			setButtonTitle('Save & Continue Later');
		}
	}, [days]);

	// Perform actions on current trip change
	useEffect(() => {

		// Update days
		updateDays(currentTrip?.days);

	}, [currentTrip]);

	// Return component
	return (
		<S.StepContainer ref={ref} className={className}>

			{/* Day Collection */}
			<S.DayCollection days={days} updateDays={updateDays} editingEnabled isVisible={isVisible} />

			{/* Actions */}
			<S.ActionContainer>
				<Button disabled={isLoading} isLoading={isLoading} variant="solid" onClick={handleNext}>{buttonTitle}</Button>
				<Button disabled={isLoading} variant="text" className="backButton" onClick={handleBack}>Back to Calendar</Button>
			</S.ActionContainer>

		</S.StepContainer>
	);
});


/**
 * Configuration
 */

DayAssignmentStep.displayName = 'DayAssignmentStep';
DayAssignmentStep.propTypes = {
	className: PropTypes.string,
	updateStep: PropTypes.func,
	isVisible: PropTypes.bool,
};
DayAssignmentStep.defaultProps = {
	className: null,
	updateStep: null,
	isVisible: false
};
