/*
 * File: PublicRouteWrapper.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on June 17, 2022 at 10:14 AM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: May 31, 2023 at 10:47 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Utilities
import { globalQuery } from '../utilities/state';


/**
 * Route Wrapper
 */

export const PublicRouteWrapper = ({
	children, user, locale, requireAuth
}) => {

	// Get query parameters from hook
	const [searchParams] = useSearchParams();

	// Get current user
	let userObj = useSelector((state) => state.user.value);
	if (!userObj && user) userObj = user;

	// Get current locale from hook
	let localeObj = useSelector((state) => state.locale.value);
	if (locale && locale.isSet) localeObj = locale;

	// Handle auth actions if requires auth
	if (requireAuth === true) {

		// Check user state
		if (!userObj) {

			// Redirect to login
			return <Navigate to={`${localeObj.localePath}/login${globalQuery(searchParams)}`} />;
		}
		if (userObj.isAnonymous === true) {

			// Redirect to login
			return <Navigate to={`${localeObj.localePath}/login${globalQuery(searchParams)}`} />;
		}
		if (userObj.isActive !== true) {

			// Redirect to logout
			window.location = '/logout';
			return null;
		}
	}

	// Return children
	return children;
};


/**
 * Configuration
 */

PublicRouteWrapper.propTypes = {
	children: PropTypes.element,
	requireAuth: PropTypes.bool,
	user: PropTypes.shape(),
	locale: PropTypes.shape()
};
PublicRouteWrapper.defaultProps = {
	children: null,
	requireAuth: false,
	user: null,
	locale: null
};
