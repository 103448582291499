/*
 * File: Constants.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on January 16, 2021 at 9:11 PM
 * Copyright © 2021 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: February 25, 2024 at 9:23 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Roles
 */

export const ROLES = {
	ALL: 'all',
	STANDARD: 'standard',
	ADMIN: 'admin'
};


/**
 * Background Jobs
 */

export const BACKGROUND_JOBS = {
	HANDLE_REVOKABLE_SESSIONS: 'handle_revokable_sessions',
	PROCESS_PROFILE_IMAGE_VARIANTS: 'process_profile_image_variants',
	REFRESH_ACCOUNT_AUTHENTICATION: 'refresh_account_authentication',
	VALIDATE_SEND_REMINDERS: 'validate_send_reminders',
	SEND_PAYMENT_REMINDERS: 'send_payment_reminders',
	SEND_MARKETING_NUDGES: 'send_marketing_nudges',
	PROCESS_SCHEDULED_EVENTS: 'process_scheduled_events',
	HANDLE_SCHEDULED_NOTIFICATIONS: 'handle_scheduled_notifications',
	PROCESS_PARK_OPERATION_UPDATES: 'process_park_operation_updates',
	REFRESH_ATTRACTION_ACCESS: 'refresh_attraction_access',
	SEND_ADMIN_REMINDERS: 'send_admin_reminders',
	PROCESS_SYSTEM_ACTIONS: 'process_system_actions'
};


/**
 * Available Locales
 */

export const AVAILABLE_LOCALES = ['en'];
export const DEFAULT_LOCALE = 'en';
export const AVAILABLE_I18N_NAMESPACES = {
	TRANSLATIONS: 'translations'
};


/**
 * UI Mode Options
 */

export const UI_MODE_OPTIONS = {
	LIGHT: 'light',
	DARK: 'dark'
};


/**
 * General
 */

export const DEFAULT_TIMEZONE = 'America/New_York';
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/; // Enforce password with at least 8 char with at least 1 lower case, 1 upper case and 1 digit
export const MAX_QUERY_LIMIT = 1000;


/**
 * Payments
 */

export const AMOUNT_PER_RESERVATION_DAY = 10.00; // 10.00 USD


/**
 * Payment Statuses
 */

export const PAYMENT_STATUSES = {
	COMPLETE: 'complete',
	IN_PROGRESS: 'in_progress',
	PENDING: 'pending',
	CANCELED: 'canceled',
	REFUNDED: 'refunded'
};


/**
 * Placeholders
 */

export const PLACEHOLDER_USER_PROFILE_IMAGE_BASE = `${process.env.CDN_URL}/public/assets/profile/user-profile-image-904239848230`;
export const CHARACTER_USER_PROFILE_IMAGE_BASE = `${process.env.CDN_URL}/public/assets/profile/user-profile-image-character`;


/**
 * Cookies
 */

export const SESSION_TOKEN_COOKIE = 'e_s_token';
export const LOCALE_COOKIE = 'pl_locale';
export const UI_MODE_COOKIE = 'ui_mode';
export const VIEW_MODE_COOKIE = 'p_ui_view_mode';
export const CONSENT_ACTION_TAKEN_COOKIE = 'c_action_taken';
export const CONSENT_PERFORMANCE_ENABLED_COOKIE = 'c_performance_enabled';
export const CONSENT_ANALYTICS_ENABLED_COOKIE = 'c_analytics_enabled';
export const CONSENT_TARGETING_ENABLED_COOKIE = 'c_targeting_enabled';


/**
 * Admin Email
 */

export const ADMIN_EMAIL = 'admin@pixiedustguide.com';


/**
 * Cookie Domain
 */

export const COOKIE_DOMAIN = () => {
	switch (process.env.ENV) {
		case 'development':
			return null;
		case 'qa':
			return 'qa.pixiedustguide.com';
		case 'staging':
			return 'staging.pixiedustguide.com';
		case 'production':
			return 'pixiedustguide.com';
		default:
			return null;
	}
};


/**
 * Headers
 */

export const SESSION_TOKEN_HEADER = 'x-pd-e-s-token';
export const CLIENT_PLATFORM_HEADER = 'x-pd-client-platform';
export const CLIENT_VERSION_HEADER = 'x-pd-client-version';
export const SECRET_JOB_TOKEN_HEADER = 'x-pd-jb-token';


/**
 * Expirations
 */

export const SESSION_EXPIRATION = 365 * 24 * 60 * 60 * 1000; // One year
export const CONSENT_COOKIE_EXPIRATION = 365 * 24 * 60 * 60 * 1000; // One year
export const LOCALE_COOKIE_EXPIRATION = 365 * 24 * 60 * 60 * 1000; // One year
export const UI_MODE_COOKIE_EXPIRATION = 365 * 24 * 60 * 60 * 1000; // One year
export const IMPERSONATION_EXPIRATION = 2 * 60 * 60 * 1000; // 2 hours
export const RESET_REQUEST_EXPIRATION = 2 * 60 * 60 * 1000; // 2 hours


/**
 * Account Lockout Policy
 */

export const LOCKOUT_DURATION = 5; // Duration policy setting determines the number of minutes that a locked-out account remains locked out before automatically becoming unlocked.
export const LOCKOUT_THRESHOLD = 3; // Threshold policy setting determines the number of failed sign-in attempts that will cause a user account to be locked.


/**
  * File Upload Limits (Bytes)
  */

export const FILE_UPLOAD_LIMITS = {
	PROFILE_IMAGE: {
		min: 1000, // 1 KB
		max: 10000000, // 10 MB
	}
};


/**
  * Acceptable File Types
  */

export const ACCEPTABLE_FILE_TYPES = {
	PROFILE_IMAGE: {
		EXTENSIONS: [
			'png',
			'jpeg',
			'jpg'
		],
		MIME_TYPES: [
			'image/png',
			'image/jpeg',
			'image/jpg'
		]
	}
};


/**
 * Core Domain Whitelists
 */

export const CORE_DOMAIN_WHITELIST = () => [
	'https://*.pixiedustguide.com',
	'https://pixie-dust-web-*.herokuapp.com/',
	process.env.ENV === 'development' && [
		'http://localhost:*'
	]
].filter(Boolean);


/**
 * Alert Types
 */

export const ALERT_STYLES = {
	ERROR: 'error',
	INFO: 'info'
};


/**
 * Error Messages
 */

export const GENERAL_ERROR_MESSAGE = 'Something went wrong, try again later!';


/**
 * Analytics Action Types
 */

export const ANALYTICS_ACTION_TYPES = {
	USER_CREATED: 'user_created',
	USER_CREATED_ACCOUNT: 'user_created_account',
	USER_LOGGED_OUT: 'user_logged_out',
	USER_LOGGED_IN: 'user_logged_in',
	BEGIN_PASS_RESET: 'begin_pass_reset',
	CONTACT_SUBMISSION: 'contact_submission',
	OUTBOUND_URL: 'outbound_url'
};


/**
 * Query Actions
 */

export const QUERY_ACTIONS = {
	ADD_CARD: 'addcard',
	UPDATE_DIS_ACCOUNT: 'updatedisaccount'
};


/**
 * System Actions
 */

export const SYSTEM_ACTIONS = {
	RESTART_DYNOS: 'restart_dynos'
};


/**
 * SendGrid Lists
 */

export const LIST_CONTACT_SUBMISSIONS = '467ea8a7-e649-41a6-b3e7-9f0667c2fe31';


/**
 * State Options
 */

export const STATE_ABBREVIATIONS = [
	'AL',
	'AK',
	'AS',
	'AZ',
	'AR',
	'CA',
	'CO',
	'CT',
	'DE',
	'DC',
	'FM',
	'FL',
	'GA',
	'GU',
	'HI',
	'ID',
	'IL',
	'IN',
	'IA',
	'KS',
	'KY',
	'LA',
	'ME',
	'MH',
	'MD',
	'MA',
	'MI',
	'MN',
	'MS',
	'MO',
	'MT',
	'NE',
	'NV',
	'NH',
	'NJ',
	'NM',
	'NY',
	'NC',
	'ND',
	'MP',
	'OH',
	'OK',
	'OR',
	'PW',
	'PA',
	'PR',
	'RI',
	'SC',
	'SD',
	'TN',
	'TX',
	'UT',
	'VT',
	'VI',
	'VA',
	'WA',
	'WV',
	'WI',
	'WY'
];

export const STATE_OPTIONS = [
	{
		name: 'Alabama',
		abbreviation: 'AL'
	},
	{
		name: 'Alaska',
		abbreviation: 'AK'
	},
	{
		name: 'American Samoa',
		abbreviation: 'AS'
	},
	{
		name: 'Arizona',
		abbreviation: 'AZ'
	},
	{
		name: 'Arkansas',
		abbreviation: 'AR'
	},
	{
		name: 'California',
		abbreviation: 'CA'
	},
	{
		name: 'Colorado',
		abbreviation: 'CO'
	},
	{
		name: 'Connecticut',
		abbreviation: 'CT'
	},
	{
		name: 'Delaware',
		abbreviation: 'DE'
	},
	{
		name: 'District Of Columbia',
		abbreviation: 'DC'
	},
	{
		name: 'Federated States Of Micronesia',
		abbreviation: 'FM'
	},
	{
		name: 'Florida',
		abbreviation: 'FL'
	},
	{
		name: 'Georgia',
		abbreviation: 'GA'
	},
	{
		name: 'Guam',
		abbreviation: 'GU'
	},
	{
		name: 'Hawaii',
		abbreviation: 'HI'
	},
	{
		name: 'Idaho',
		abbreviation: 'ID'
	},
	{
		name: 'Illinois',
		abbreviation: 'IL'
	},
	{
		name: 'Indiana',
		abbreviation: 'IN'
	},
	{
		name: 'Iowa',
		abbreviation: 'IA'
	},
	{
		name: 'Kansas',
		abbreviation: 'KS'
	},
	{
		name: 'Kentucky',
		abbreviation: 'KY'
	},
	{
		name: 'Louisiana',
		abbreviation: 'LA'
	},
	{
		name: 'Maine',
		abbreviation: 'ME'
	},
	{
		name: 'Marshall Islands',
		abbreviation: 'MH'
	},
	{
		name: 'Maryland',
		abbreviation: 'MD'
	},
	{
		name: 'Massachusetts',
		abbreviation: 'MA'
	},
	{
		name: 'Michigan',
		abbreviation: 'MI'
	},
	{
		name: 'Minnesota',
		abbreviation: 'MN'
	},
	{
		name: 'Mississippi',
		abbreviation: 'MS'
	},
	{
		name: 'Missouri',
		abbreviation: 'MO'
	},
	{
		name: 'Montana',
		abbreviation: 'MT'
	},
	{
		name: 'Nebraska',
		abbreviation: 'NE'
	},
	{
		name: 'Nevada',
		abbreviation: 'NV'
	},
	{
		name: 'New Hampshire',
		abbreviation: 'NH'
	},
	{
		name: 'New Jersey',
		abbreviation: 'NJ'
	},
	{
		name: 'New Mexico',
		abbreviation: 'NM'
	},
	{
		name: 'New York',
		abbreviation: 'NY'
	},
	{
		name: 'North Carolina',
		abbreviation: 'NC'
	},
	{
		name: 'North Dakota',
		abbreviation: 'ND'
	},
	{
		name: 'Northern Mariana Islands',
		abbreviation: 'MP'
	},
	{
		name: 'Ohio',
		abbreviation: 'OH'
	},
	{
		name: 'Oklahoma',
		abbreviation: 'OK'
	},
	{
		name: 'Oregon',
		abbreviation: 'OR'
	},
	{
		name: 'Palau',
		abbreviation: 'PW'
	},
	{
		name: 'Pennsylvania',
		abbreviation: 'PA'
	},
	{
		name: 'Puerto Rico',
		abbreviation: 'PR'
	},
	{
		name: 'Rhode Island',
		abbreviation: 'RI'
	},
	{
		name: 'South Carolina',
		abbreviation: 'SC'
	},
	{
		name: 'South Dakota',
		abbreviation: 'SD'
	},
	{
		name: 'Tennessee',
		abbreviation: 'TN'
	},
	{
		name: 'Texas',
		abbreviation: 'TX'
	},
	{
		name: 'Utah',
		abbreviation: 'UT'
	},
	{
		name: 'Vermont',
		abbreviation: 'VT'
	},
	{
		name: 'Virgin Islands',
		abbreviation: 'VI'
	},
	{
		name: 'Virginia',
		abbreviation: 'VA'
	},
	{
		name: 'Washington',
		abbreviation: 'WA'
	},
	{
		name: 'West Virginia',
		abbreviation: 'WV'
	},
	{
		name: 'Wisconsin',
		abbreviation: 'WI'
	},
	{
		name: 'Wyoming',
		abbreviation: 'WY'
	}
];


/**
 * Country Options
 */

export const COUNTRY_OPTIONS = [
	{ name: 'United States', code: 'US' },
	{ name: 'Afghanistan', code: 'AF' },
	{ name: 'Åland Islands', code: 'AX' },
	{ name: 'Albania', code: 'AL' },
	{ name: 'Algeria', code: 'DZ' },
	{ name: 'American Samoa', code: 'AS' },
	{ name: 'AndorrA', code: 'AD' },
	{ name: 'Angola', code: 'AO' },
	{ name: 'Anguilla', code: 'AI' },
	{ name: 'Antarctica', code: 'AQ' },
	{ name: 'Antigua and Barbuda', code: 'AG' },
	{ name: 'Argentina', code: 'AR' },
	{ name: 'Armenia', code: 'AM' },
	{ name: 'Aruba', code: 'AW' },
	{ name: 'Australia', code: 'AU' },
	{ name: 'Austria', code: 'AT' },
	{ name: 'Azerbaijan', code: 'AZ' },
	{ name: 'Bahamas', code: 'BS' },
	{ name: 'Bahrain', code: 'BH' },
	{ name: 'Bangladesh', code: 'BD' },
	{ name: 'Barbados', code: 'BB' },
	{ name: 'Belarus', code: 'BY' },
	{ name: 'Belgium', code: 'BE' },
	{ name: 'Belize', code: 'BZ' },
	{ name: 'Benin', code: 'BJ' },
	{ name: 'Bermuda', code: 'BM' },
	{ name: 'Bhutan', code: 'BT' },
	{ name: 'Bolivia', code: 'BO' },
	{ name: 'Bosnia and Herzegovina', code: 'BA' },
	{ name: 'Botswana', code: 'BW' },
	{ name: 'Bouvet Island', code: 'BV' },
	{ name: 'Brazil', code: 'BR' },
	{ name: 'British Indian Ocean Territory', code: 'IO' },
	{ name: 'Brunei Darussalam', code: 'BN' },
	{ name: 'Bulgaria', code: 'BG' },
	{ name: 'Burkina Faso', code: 'BF' },
	{ name: 'Burundi', code: 'BI' },
	{ name: 'Cambodia', code: 'KH' },
	{ name: 'Cameroon', code: 'CM' },
	{ name: 'Canada', code: 'CA' },
	{ name: 'Cape Verde', code: 'CV' },
	{ name: 'Cayman Islands', code: 'KY' },
	{ name: 'Central African Republic', code: 'CF' },
	{ name: 'Chad', code: 'TD' },
	{ name: 'Chile', code: 'CL' },
	{ name: 'China', code: 'CN' },
	{ name: 'Christmas Island', code: 'CX' },
	{ name: 'Cocos (Keeling) Islands', code: 'CC' },
	{ name: 'Colombia', code: 'CO' },
	{ name: 'Comoros', code: 'KM' },
	{ name: 'Congo', code: 'CG' },
	{ name: 'Congo, The Democratic Republic of the', code: 'CD' },
	{ name: 'Cook Islands', code: 'CK' },
	{ name: 'Costa Rica', code: 'CR' },
	{ name: 'Croatia', code: 'HR' },
	{ name: 'Cuba', code: 'CU' },
	{ name: 'Cyprus', code: 'CY' },
	{ name: 'Czech Republic', code: 'CZ' },
	{ name: 'Denmark', code: 'DK' },
	{ name: 'Djibouti', code: 'DJ' },
	{ name: 'Dominica', code: 'DM' },
	{ name: 'Dominican Republic', code: 'DO' },
	{ name: 'Ecuador', code: 'EC' },
	{ name: 'Egypt', code: 'EG' },
	{ name: 'El Salvador', code: 'SV' },
	{ name: 'Equatorial Guinea', code: 'GQ' },
	{ name: 'Eritrea', code: 'ER' },
	{ name: 'Estonia', code: 'EE' },
	{ name: 'Ethiopia', code: 'ET' },
	{ name: 'Falkland Islands (Malvinas)', code: 'FK' },
	{ name: 'Faroe Islands', code: 'FO' },
	{ name: 'Fiji', code: 'FJ' },
	{ name: 'Finland', code: 'FI' },
	{ name: 'France', code: 'FR' },
	{ name: 'French Guiana', code: 'GF' },
	{ name: 'French Polynesia', code: 'PF' },
	{ name: 'French Southern Territories', code: 'TF' },
	{ name: 'Gabon', code: 'GA' },
	{ name: 'Gambia', code: 'GM' },
	{ name: 'Georgia', code: 'GE' },
	{ name: 'Germany', code: 'DE' },
	{ name: 'Ghana', code: 'GH' },
	{ name: 'Gibraltar', code: 'GI' },
	{ name: 'Greece', code: 'GR' },
	{ name: 'Greenland', code: 'GL' },
	{ name: 'Grenada', code: 'GD' },
	{ name: 'Guadeloupe', code: 'GP' },
	{ name: 'Guam', code: 'GU' },
	{ name: 'Guatemala', code: 'GT' },
	{ name: 'Guernsey', code: 'GG' },
	{ name: 'Guinea', code: 'GN' },
	{ name: 'Guinea-Bissau', code: 'GW' },
	{ name: 'Guyana', code: 'GY' },
	{ name: 'Haiti', code: 'HT' },
	{ name: 'Heard Island and Mcdonald Islands', code: 'HM' },
	{ name: 'Holy See (Vatican City State)', code: 'VA' },
	{ name: 'Honduras', code: 'HN' },
	{ name: 'Hong Kong', code: 'HK' },
	{ name: 'Hungary', code: 'HU' },
	{ name: 'Iceland', code: 'IS' },
	{ name: 'India', code: 'IN' },
	{ name: 'Indonesia', code: 'ID' },
	{ name: 'Iran, Islamic Republic Of', code: 'IR' },
	{ name: 'Iraq', code: 'IQ' },
	{ name: 'Ireland', code: 'IE' },
	{ name: 'Isle of Man', code: 'IM' },
	{ name: 'Israel', code: 'IL' },
	{ name: 'Italy', code: 'IT' },
	{ name: 'Jamaica', code: 'JM' },
	{ name: 'Japan', code: 'JP' },
	{ name: 'Jersey', code: 'JE' },
	{ name: 'Jordan', code: 'JO' },
	{ name: 'Kazakhstan', code: 'KZ' },
	{ name: 'Kenya', code: 'KE' },
	{ name: 'Kiribati', code: 'KI' },
	{ name: 'Korea, Republic of', code: 'KR' },
	{ name: 'Kuwait', code: 'KW' },
	{ name: 'Kyrgyzstan', code: 'KG' },
	{ name: 'Latvia', code: 'LV' },
	{ name: 'Lebanon', code: 'LB' },
	{ name: 'Lesotho', code: 'LS' },
	{ name: 'Liberia', code: 'LR' },
	{ name: 'Libyan Arab Jamahiriya', code: 'LY' },
	{ name: 'Liechtenstein', code: 'LI' },
	{ name: 'Lithuania', code: 'LT' },
	{ name: 'Luxembourg', code: 'LU' },
	{ name: 'Macao', code: 'MO' },
	{ name: 'North Macedonia', code: 'MK' },
	{ name: 'Madagascar', code: 'MG' },
	{ name: 'Malawi', code: 'MW' },
	{ name: 'Malaysia', code: 'MY' },
	{ name: 'Maldives', code: 'MV' },
	{ name: 'Mali', code: 'ML' },
	{ name: 'Malta', code: 'MT' },
	{ name: 'Marshall Islands', code: 'MH' },
	{ name: 'Martinique', code: 'MQ' },
	{ name: 'Mauritania', code: 'MR' },
	{ name: 'Mauritius', code: 'MU' },
	{ name: 'Mayotte', code: 'YT' },
	{ name: 'Mexico', code: 'MX' },
	{ name: 'Micronesia, Federated States of', code: 'FM' },
	{ name: 'Moldova, Republic of', code: 'MD' },
	{ name: 'Monaco', code: 'MC' },
	{ name: 'Mongolia', code: 'MN' },
	{ name: 'Montserrat', code: 'MS' },
	{ name: 'Morocco', code: 'MA' },
	{ name: 'Mozambique', code: 'MZ' },
	{ name: 'Myanmar', code: 'MM' },
	{ name: 'Namibia', code: 'NA' },
	{ name: 'Nauru', code: 'NR' },
	{ name: 'Nepal', code: 'NP' },
	{ name: 'Netherlands', code: 'NL' },
	{ name: 'Netherlands Antilles', code: 'AN' },
	{ name: 'New Caledonia', code: 'NC' },
	{ name: 'New Zealand', code: 'NZ' },
	{ name: 'Nicaragua', code: 'NI' },
	{ name: 'Niger', code: 'NE' },
	{ name: 'Nigeria', code: 'NG' },
	{ name: 'Niue', code: 'NU' },
	{ name: 'Norfolk Island', code: 'NF' },
	{ name: 'Northern Mariana Islands', code: 'MP' },
	{ name: 'Norway', code: 'NO' },
	{ name: 'Oman', code: 'OM' },
	{ name: 'Pakistan', code: 'PK' },
	{ name: 'Palau', code: 'PW' },
	{ name: 'Palestinian Territory, Occupied', code: 'PS' },
	{ name: 'Panama', code: 'PA' },
	{ name: 'Papua New Guinea', code: 'PG' },
	{ name: 'Paraguay', code: 'PY' },
	{ name: 'Peru', code: 'PE' },
	{ name: 'Philippines', code: 'PH' },
	{ name: 'Pitcairn Islands', code: 'PN' },
	{ name: 'Poland', code: 'PL' },
	{ name: 'Portugal', code: 'PT' },
	{ name: 'Puerto Rico', code: 'PR' },
	{ name: 'Qatar', code: 'QA' },
	{ name: 'Reunion', code: 'RE' },
	{ name: 'Romania', code: 'RO' },
	{ name: 'Russian Federation', code: 'RU' },
	{ name: 'Rwanda', code: 'RW' },
	{ name: 'Saint Helena', code: 'SH' },
	{ name: 'Saint Kitts and Nevis', code: 'KN' },
	{ name: 'Saint Lucia', code: 'LC' },
	{ name: 'Saint Pierre and Miquelon', code: 'PM' },
	{ name: 'Saint Vincent and the Grenadines', code: 'VC' },
	{ name: 'Samoa', code: 'WS' },
	{ name: 'San Marino', code: 'SM' },
	{ name: 'Sao Tome and Principe', code: 'ST' },
	{ name: 'Saudi Arabia', code: 'SA' },
	{ name: 'Senegal', code: 'SN' },
	{ name: 'Serbia and Montenegro', code: 'CS' },
	{ name: 'Seychelles', code: 'SC' },
	{ name: 'Sierra Leone', code: 'SL' },
	{ name: 'Singapore', code: 'SG' },
	{ name: 'Slovakia', code: 'SK' },
	{ name: 'Slovenia', code: 'SI' },
	{ name: 'Solomon Islands', code: 'SB' },
	{ name: 'Somalia', code: 'SO' },
	{ name: 'South Africa', code: 'ZA' },
	{ name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
	{ name: 'Spain', code: 'ES' },
	{ name: 'Sri Lanka', code: 'LK' },
	{ name: 'Sudan', code: 'SD' },
	{ name: 'Suriname', code: 'SR' },
	{ name: 'Svalbard and Jan Mayen', code: 'SJ' },
	{ name: 'Swaziland', code: 'SZ' },
	{ name: 'Sweden', code: 'SE' },
	{ name: 'Switzerland', code: 'CH' },
	{ name: 'Syrian Arab Republic', code: 'SY' },
	{ name: 'Taiwan', code: 'TW' },
	{ name: 'Tajikistan', code: 'TJ' },
	{ name: 'Tanzania, United Republic of', code: 'TZ' },
	{ name: 'Thailand', code: 'TH' },
	{ name: 'Timor-Leste', code: 'TL' },
	{ name: 'Togo', code: 'TG' },
	{ name: 'Tokelau', code: 'TK' },
	{ name: 'Tonga', code: 'TO' },
	{ name: 'Trinidad and Tobago', code: 'TT' },
	{ name: 'Tunisia', code: 'TN' },
	{ name: 'Turkey', code: 'TR' },
	{ name: 'Turkmenistan', code: 'TM' },
	{ name: 'Turks and Caicos Islands', code: 'TC' },
	{ name: 'Tuvalu', code: 'TV' },
	{ name: 'Uganda', code: 'UG' },
	{ name: 'Ukraine', code: 'UA' },
	{ name: 'United Arab Emirates', code: 'AE' },
	{ name: 'United Kingdom', code: 'GB' },
	{ name: 'United States Minor Outlying Islands', code: 'UM' },
	{ name: 'Uruguay', code: 'UY' },
	{ name: 'Uzbekistan', code: 'UZ' },
	{ name: 'Vanuatu', code: 'VU' },
	{ name: 'Venezuela', code: 'VE' },
	{ name: 'Vietnam', code: 'VN' },
	{ name: 'Virgin Islands, British', code: 'VG' },
	{ name: 'Virgin Islands, U.S.', code: 'VI' },
	{ name: 'Wallis and Futuna', code: 'WF' },
	{ name: 'Western Sahara', code: 'EH' },
	{ name: 'Yemen', code: 'YE' },
	{ name: 'Zambia', code: 'ZM' },
	{ name: 'Zimbabwe', code: 'ZN' }
];


/**
 * Days of Week
 */

export const DAYS_OF_WEEK = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday'
];


/**
 * Notification Types
 */

export const NOTIFICATION_TYPES = { // Update preferences check in utilities/notification.js when added new types
	SYSTEM: 'system'
};


/**
 * Notification Channels
 */

export const NOTIFICATION_CHANNELS = {
	IN_APP: 'inapp',
	PUSH: 'push',
	SMS: 'sms',
	EMAIL: 'email'
};


/**
 * Email Templates
 */

export const EMAIL_TEMPLATES = {
	PASSWORD_RESET_EMAIL: 'passwordResetEmail',
	UPDATE_DISNEY_ACCOUNT_EMAIL: 'updateDisneyAccountEmail',
	PAYMENT_METHOD_REMINDER_EMAIL: 'paymentMethodReminderEmail',
	WELCOME_EMAIL: 'welcomeEmail',
};


/**
 * Share Options
 */

export const SHARE_OPTIONS = {
	FACEBOOK: 'facebook',
	TWITTER: 'twitter',
	LINKEDIN: 'linkedin',
	REDDIT: 'reddit',
	PINTEREST: 'pinterest',
	EMAIL: 'email'
};


/**
 * Park Entities
 */

export const PARK_ENTITY_IDS = {
	WALT_DISNEY_WORLD_RESORT: {
		id: 'walt-disney-world'
	},
	DISNEYLAND_RESORT: {
		id: 'disneyland-resort'
	}
};

export const PARK_ENTITY_OPTIONS = [
	{
		id: 'walt-disney-world',
		name: 'Walt Disney World',
		location: 'Orlando, Florida',
		timezone: 'America/New_York',
		icon: '🏰',
		mobileApp: 'My Disney Experience',
	},
	{
		id: 'disneyland-resort',
		name: 'Disneyland Resort',
		location: 'Anaheim, California',
		timezone: 'America/Los_Angeles',
		icon: '🎡',
		mobileApp: 'Disneyland',
	}
];

export const PARK_ENTITIES = {
	WALT_DISNEY_WORLD_RESORT: {
		id: 'walt-disney-world',
		name: 'Walt Disney World Resort',
		locationName: 'Walt Disney World',
		shortName: 'Disney World',
		timezone: 'America/New_York',
		mobileApp: 'My Disney Experience',
		api: {
			domain: 'disneyworld.disney.go.com',
			secondaryDomain: 'www.disneyworld.eu',
			secondaryDomainLang: 'en-eu',
			baseId: 'wdw',
			itineraryId: 'wdw-itinerary-api',
			itineraryAppId: 'advanced-wdw-itinerary-spa',
			mobileAppId: 'WDW-MDX-IOS',
			mobileAppVersion: '7.33',
			userAgentPrefix: 'WDW/',
			merlinPrefix: 'merlin',
			calendarId: '80007798',
			paymentClientId: 'WALLETWDW97d4e8185e53498dab38c2919194a93c',
			walletAPIKey: 'WalletUIparksWDW-125c4e0b-959b-4eea-9f01-8afdb5c64b77',
			clientId: 'TPR-WDW',
			hoursAPI: {
				useThemeParksWiki: false
			}
		},
		scheduling: {
			retriesAllowed: 18, // Every 10 minutes
			paymentRetriesAllowed: 2
		}
	},
	DISNEYLAND_RESORT: {
		id: 'disneyland-resort',
		name: 'Disneyland Resort',
		locationName: 'Disneyland Resort',
		shortName: 'Disneyland',
		timezone: 'America/Los_Angeles',
		mobileApp: 'Disneyland',
		api: {
			domain: 'disneyland.disney.go.com',
			secondaryDomain: 'disneyland.disney.go.com',
			secondaryDomainLang: 'en_US',
			baseId: 'dlr',
			itineraryId: 'dlr-itinerary-web-api',
			itineraryAppId: 'advanced-dlr-itinerary-spa',
			mobileAppId: 'DLR-IOS',
			mobileAppVersion: '7.26.1',
			userAgentPrefix: 'DLR/',
			merlinPrefix: 'merlin-dlr',
			calendarId: '80008297',
			paymentClientId: 'WALLETDLR3a7c7274e1fe40b0bcaff2fc3452434c',
			walletAPIKey: 'WalletUIparksDLR-43c505dd-b30d-4fb0-a054-b25bdee894d2',
			clientId: 'TPR-DLR',
			hoursAPI: {
				useThemeParksWiki: true,
				entityId: 'disneylandresort'
			}
		},
		scheduling: {
			retriesAllowed: 22, // Every 10 minutes
			paymentRetriesAllowed: 2
		}
	}
};


/**
 * Parks
 */

export const PARKS = {
	MAGIC_KINGDOM: {
		id: '80007944',
		primaryLookup: 'MAGIC_KINGDOM',
		lookups: [
			'MAGIC_KINGDOM',
			'MAGIC KINGDOM',
			'MAGICKINGDOM',
			'MAGIC'
		],
		name: 'Magic Kingdom',
		entityId: PARK_ENTITIES.WALT_DISNEY_WORLD_RESORT.id
	},
	EPCOT: {
		id: '80007838',
		primaryLookup: 'EPCOT',
		lookups: [
			'EPCOT'
		],
		name: 'Epcot',
		entityId: PARK_ENTITIES.WALT_DISNEY_WORLD_RESORT.id
	},
	ANIMAL_KINGDOM: {
		id: '80007823',
		primaryLookup: 'ANIMAL_KINGDOM',
		lookups: [
			'ANIMAL_KINGDOM',
			'ANIMAL KINGDOM',
			'ANIMALKINGDOM',
			'ANIMAL',
		],
		name: 'Animal Kingdom',
		entityId: PARK_ENTITIES.WALT_DISNEY_WORLD_RESORT.id
	},
	HOLLYWOOD_STUDIOS: {
		id: '80007998',
		primaryLookup: 'HOLLYWOOD_STUDIOS',
		lookups: [
			'HOLLYWOOD_STUDIOS',
			'HOLLYWOOD STUDIOS',
			'HOLLYWOODSTUDIOS',
			'HOLLYWOOD',
			'STUDIO'
		],
		name: 'Hollywood Studios',
		entityId: PARK_ENTITIES.WALT_DISNEY_WORLD_RESORT.id
	},
	DISNEYLAND: {
		id: '330339',
		primaryLookup: 'DISNEYLAND',
		lookups: [
			'DISNEYLAND',
			'DISNEY LAND',
			'DISNEYLAND PARK'
		],
		name: 'Disneyland',
		entityId: PARK_ENTITIES.DISNEYLAND_RESORT.id
	},
	CALIFORNIA_ADVENTURE: {
		id: '336894',
		primaryLookup: 'CALIFORNIA_ADVENTURE',
		lookups: [
			'CALIFORNIA_ADVENTURE',
			'CALIFORNIA ADVENTURE',
			'CALIFORNIAADVENTURE',
			'CALIFORNIA',
			'DISNEY CALIFORNIA ADVENTURE PARK'
		],
		name: 'California Adventure',
		entityId: PARK_ENTITIES.DISNEYLAND_RESORT.id
	}
};


/**
 * Icons
 */

export const ICONS = {
	MAGIC_KINGDOM: {
		icon: '🏰',
		standard: true
	},
	EPCOT: {
		icon: '🌎',
		standard: true
	},
	ANIMAL_KINGDOM: {
		icon: '🦒',
		standard: true
	},
	HOLLYWOOD_STUDIOS: {
		icon: '🎬',
		standard: true
	},
	WALT_DISNEY_WORLD: {
		icon: '🚝',
		standard: true
	},
	DISNEYLAND: {
		icon: '🏰',
		standard: true
	},
	CALIFORNIA_ADVENTURE: {
		icon: '🎡',
		standard: true
	},
	DISNEY_VACATION_CLUB: {
		icon: '⛱',
		standard: true
	},
	DINING: {
		icon: '🍽',
		standard: true
	},
	ENTERTAINMENT: {
		icon: '🍿',
		standard: true
	},
	SHOPPING: {
		icon: '🛍',
		standard: true
	},
	RIDES_ATTRACTIONS: {
		icon: '🎠',
		standard: true
	},
	HOTELS_RESORTS: {
		icon: '🛏',
		standard: true
	},
	OUTSIDE_THE_PARKS: {
		icon: '🛳',
		standard: true
	},
	HOLIDAY_PARTIES: {
		icon: '🎃',
		standard: false
	},
	DOWNTOWN_DISNEY: {
		icon: '🎈',
		standard: true
	},
	DISNEY_SPRINGS: {
		icon: '🎳',
		standard: true
	},
	WATER_PARKS: {
		icon: '🤿',
		standard: false
	},
	ERROR: {
		icon: '❌',
		standard: false
	},
	GENIE_PLUS: {
		icon: '⭐️',
		standard: true
	}
};


/**
 * Event Types
 */

export const SCHEDULED_EVENT_TYPES = {
	LL: 'll',
	ILL: 'ill',
	DINING: 'dining',
	VIRTUAL_QUEUE: 'virtualqueue',
	GENIE_PLUS: 'genieplus'
};
export const AVAILABLE_SCHEDULED_EVENT_TYPES = [
	SCHEDULED_EVENT_TYPES.LL,
	SCHEDULED_EVENT_TYPES.ILL
];


/**
 * Ticket Types
 */

export const SPECIAL_TICKET_TYPES = {
	WDW_MAGIC_4_PARK_TICKET: {
		name: 'Walt Disney World 4-Park Magic Ticket'
	}
};


/**
 * Entity Types
 */

export const ENTITY_TYPES = {
	ATTRACTION: 'attraction',
	DINING: 'dining',
	HOTEL: 'hotel'
};


/**
 * Disney Validation Errors
 */

export const VALIDATION_ERRORS = {
	REQUIRES_DISNEY_ACCOUNT_UPDATE: 'REQUIRES_DISNEY_ACCOUNT_UPDATE',
	NO_VALID_DISNEY_PAYMENT_METHOD: 'NO_VALID_DISNEY_PAYMENT_METHOD',
	NO_VALID_PIXIE_DUST_PAYMENT_METHOD: 'NO_VALID_PIXIE_DUST_PAYMENT_METHOD',
	MISSING_PARK_TICKET: 'MISSING_PARK_TICKET',
	MISSING_PARK_RESERVATION: 'MISSING_PARK_RESERVATION',
	MISSING_GENIE_PLUS: 'MISSING_GENIE_PLUS',
	MISSING_GENIE_PLUS_REQUIRED: 'MISSING_GENIE_PLUS_REQUIRED',
	RESERVATION_ERROR: 'RESERVATION_ERROR'
};


/**
 * Disney Authentication Errors
 */

export const DISNEY_AUTHENTICATION_ERRORS = {
	AUTHORIZATION_ACCOUNT_LOCKED_OUT: 'AUTHORIZATION_ACCOUNT_LOCKED_OUT',
	INVALID_AUTHORIZATION_CREDENTIALS: 'INVALID_AUTHORIZATION_CREDENTIALS',
	REQUIRES_TERMS_CONDITIONS_ACCEPTANCE: 'REQUIRES_TERMS_CONDITIONS_ACCEPTANCE'
};


/**
 * Disney Authentication Attempts
 */

export const DISNEY_AUTH_ATTEMPTS = [
	{ attempt: 1, useBrowser: true },
	{ attempt: 2, useBrowser: true },
	{ attempt: 3, useBrowser: true },
	{ attempt: 4, useBrowser: true },
	{ attempt: 5, useBrowser: true }
];


/**
 * Placeholders
 */

export const PLACEHOLDER_TRIPS = () => {

	// Create dates
	const startDate = new Date();
	const endDate = new Date();

	// Update dates
	startDate.setDate(startDate.getDate() + 4);
	endDate.setDate(endDate.getDate() + 10);

	// Return trips
	return [
		{
			id: '1',
			name: 'My First Trip',
			days: [{
				date: `${startDate.getFullYear()}-${(startDate.getMonth() + 1) < 10 ? `0${startDate.getMonth() + 1}` : `${startDate.getMonth() + 1}`}-${startDate.getDate() < 10 ? `0${startDate.getDate()}` : `${startDate.getDate()}`}`,
				dayOfWeek: 'Wednesday',
				parks: [
					{ parkId: '80007944', name: 'Magic Kingdom' },
					{ parkId: '80007998', name: 'Hollywood Studios' }
				],
				scheduledEvents: [{
					sortIndex: 0,
					parkId: '80007944',
					party: [
						{ userSWID: '1', avatarId: '1', profileImage: 'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/90/90/75/dam/wdpro-assets/avatars/180x180/RetAvatar_180x180_Sorcerer-Mickey.png?1623766847073' },
						{ userSWID: '2', avatarId: '2', profileImage: 'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/90/90/75/dam/disney-world/50th-anniversary/avatars/RetAvatar_180x180_50th_Mickey.png?1633029600643' },
						{ userSWID: '3', avatarId: '3', profileImage: 'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/90/90/75/dam/wdpro-assets/avatars/180x180/RetAvatar_180x180_Default_SignedIn.png?1623767113914' },
						{ userSWID: '4', avatarId: '4', profileImage: 'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/90/90/75/dam/wdpro-assets/avatars/180x180/RetAvatar_180x180_Princess-Jasmine.png?1658168498859' }
					],
					attraction: { attractionId: '80010107', name: 'Astro Orbiter' },
				}]
			}, {
				date: `${startDate.getFullYear()}-${(endDate.getMonth() + 1) < 10 ? `0${endDate.getMonth() + 1}` : `${endDate.getMonth() + 1}`}-${endDate.getDate() < 10 ? `0${endDate.getDate()}` : `${endDate.getDate()}`}`,
				dayOfWeek: 'Thursday',
				parks: [
					{ parkId: '80007944', name: 'Magic Kingdom' },
					{ parkId: '80007998', name: 'Hollywood Studios' }
				],
				scheduledEvents: [{
					sortIndex: 0,
					parkId: '80007944',
					party: [
						{ userSWID: '1', avatarId: '1', profileImage: 'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/90/90/75/dam/wdpro-assets/avatars/180x180/RetAvatar_180x180_Sorcerer-Mickey.png?1623766847073' },
						{ userSWID: '2', avatarId: '2', profileImage: 'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/90/90/75/dam/disney-world/50th-anniversary/avatars/RetAvatar_180x180_50th_Mickey.png?1633029600643' },
						{ userSWID: '3', avatarId: '3', profileImage: 'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/90/90/75/dam/wdpro-assets/avatars/180x180/RetAvatar_180x180_Default_SignedIn.png?1623767113914' },
						{ userSWID: '4', avatarId: '4', profileImage: 'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/90/90/75/dam/wdpro-assets/avatars/180x180/RetAvatar_180x180_Princess-Jasmine.png?1658168498859' }
					],
					attraction: { attractionId: '80010107', name: 'Astro Orbiter' },
				}]
			}],
			startDate,
			endDate,
			party: [
				{
					firstName: 'Brendan', lastName: 'Michaelsen', userSWID: '1', avatarId: '1', profileImage: 'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/90/90/75/dam/wdpro-assets/avatars/180x180/RetAvatar_180x180_Sorcerer-Mickey.png?1623766847073'
				},
				{
					firstName: 'Cathy', lastName: 'Gomes', userSWID: '2', avatarId: '2', profileImage: 'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/90/90/75/dam/disney-world/50th-anniversary/avatars/RetAvatar_180x180_50th_Mickey.png?1633029600643'
				},
				{
					firstName: 'Cindy', lastName: 'Gomes', userSWID: '3', avatarId: '3', profileImage: 'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/90/90/75/dam/wdpro-assets/avatars/180x180/RetAvatar_180x180_Default_SignedIn.png?1623767113914'
				},
				{
					firstName: 'Jacob', lastName: 'Burke', userSWID: '4', avatarId: '4', profileImage: 'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/90/90/75/dam/wdpro-assets/avatars/180x180/RetAvatar_180x180_Princess-Jasmine.png?1658168498859'
				}
			],
			validParkTickets: true,
			validParkReservations: true,
			validDisneyGeniePlus: true,
			validResortReservation: true,
			validEventsToSchedule: true,
			requiresDisneyGeniePlus: true,
			parkEntity: PARK_ENTITIES.WALT_DISNEY_WORLD_RESORT
		},
		{
			id: '2',
			name: 'My First Trip',
			days: [{
				date: `${startDate.getFullYear()}-${(startDate.getMonth() + 1) < 10 ? `0${startDate.getMonth() + 1}` : `${startDate.getMonth() + 1}`}-${startDate.getDate() < 10 ? `0${startDate.getDate()}` : `${startDate.getDate()}`}`,
				dayOfWeek: 'Wednesday',
				parks: [
					{ parkId: '80007944', name: 'Magic Kingdom' },
					{ parkId: '80007998', name: 'Hollywood Studios' }
				],
				scheduledEvents: [{
					sortIndex: 0,
					parkId: '80007944',
					party: [
						{ userSWID: '1', avatarId: '1', profileImage: 'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/90/90/75/dam/wdpro-assets/avatars/180x180/RetAvatar_180x180_Default_SignedIn.png?1623767113914' },
						{ userSWID: '2', avatarId: '2', profileImage: 'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/90/90/75/dam/wdpro-assets/avatars/180x180/RetAvatar_180x180_Default_SignedIn.png?1623767113914' },
						{ userSWID: '3', avatarId: '3', profileImage: 'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/90/90/75/dam/wdpro-assets/avatars/180x180/RetAvatar_180x180_Default_SignedIn.png?1623767113914' },
						{ userSWID: '4', avatarId: '4', profileImage: 'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/90/90/75/dam/wdpro-assets/avatars/180x180/RetAvatar_180x180_Default_SignedIn.png?1623767113914' }
					],
					attraction: { attractionId: '80010107', name: 'Astro Orbiter' },
				}]
			}],
			party: [
				{
					firstName: 'Brendan', lastName: 'Michaelsen', userSWID: '1', avatarId: '1', profileImage: 'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/90/90/75/dam/wdpro-assets/avatars/180x180/RetAvatar_180x180_Sorcerer-Mickey.png?1623766847073'
				},
				{
					firstName: 'Cathy', lastName: 'Gomes', userSWID: '2', avatarId: '2', profileImage: 'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/90/90/75/dam/disney-world/50th-anniversary/avatars/RetAvatar_180x180_50th_Mickey.png?1633029600643'
				},
				{
					firstName: 'Cindy', lastName: 'Gomes', userSWID: '3', avatarId: '3', profileImage: 'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/90/90/75/dam/wdpro-assets/avatars/180x180/RetAvatar_180x180_Default_SignedIn.png?1623767113914'
				},
				{
					firstName: 'Jacob', lastName: 'Burke', userSWID: '4', avatarId: '4', profileImage: 'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/90/90/75/dam/wdpro-assets/avatars/180x180/RetAvatar_180x180_Princess-Jasmine.png?1658168498859'
				},
			],
			startDate,
			endDate,
			validParkTickets: true,
			validParkReservations: true,
			validDisneyGeniePlus: true,
			validResortReservation: true,
			validEventsToSchedule: true,
			requiresDisneyGeniePlus: true,
			parkEntity: PARK_ENTITIES.WALT_DISNEY_WORLD_RESORT
		},
		{
			id: '3',
			name: 'My First Trip',
			days: [{
				date: `${startDate.getFullYear()}-${(startDate.getMonth() + 1) < 10 ? `0${startDate.getMonth() + 1}` : `${startDate.getMonth() + 1}`}-${startDate.getDate() < 10 ? `0${startDate.getDate()}` : `${startDate.getDate()}`}`,
				dayOfWeek: 'Wednesday',
				parks: [
					{ parkId: '80007944', name: 'Magic Kingdom' },
					{ parkId: '80007998', name: 'Hollywood Studios' }
				],
				scheduledEvents: [{
					sortIndex: 0,
					parkId: '80007944',
					party: [
						{ userSWID: '1', avatarId: '1', profileImage: 'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/90/90/75/dam/wdpro-assets/avatars/180x180/RetAvatar_180x180_Default_SignedIn.png?1623767113914' },
						{ userSWID: '2', avatarId: '2', profileImage: 'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/90/90/75/dam/wdpro-assets/avatars/180x180/RetAvatar_180x180_Default_SignedIn.png?1623767113914' },
						{ userSWID: '3', avatarId: '3', profileImage: 'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/90/90/75/dam/wdpro-assets/avatars/180x180/RetAvatar_180x180_Default_SignedIn.png?1623767113914' },
						{ userSWID: '4', avatarId: '4', profileImage: 'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/90/90/75/dam/wdpro-assets/avatars/180x180/RetAvatar_180x180_Default_SignedIn.png?1623767113914' }
					],
					attraction: { attractionId: '80010107', name: 'Astro Orbiter' },
				}]
			}],
			party: [
				{
					firstName: 'Brendan', lastName: 'Michaelsen', userSWID: '1', avatarId: '1', profileImage: 'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/90/90/75/dam/wdpro-assets/avatars/180x180/RetAvatar_180x180_Sorcerer-Mickey.png?1623766847073'
				},
				{
					firstName: 'Cathy', lastName: 'Gomes', userSWID: '2', avatarId: '2', profileImage: 'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/90/90/75/dam/disney-world/50th-anniversary/avatars/RetAvatar_180x180_50th_Mickey.png?1633029600643'
				},
				{
					firstName: 'Cindy', lastName: 'Gomes', userSWID: '3', avatarId: '3', profileImage: 'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/90/90/75/dam/wdpro-assets/avatars/180x180/RetAvatar_180x180_Default_SignedIn.png?1623767113914'
				},
				{
					firstName: 'Jacob', lastName: 'Burke', userSWID: '4', avatarId: '4', profileImage: 'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/90/90/75/dam/wdpro-assets/avatars/180x180/RetAvatar_180x180_Princess-Jasmine.png?1658168498859'
				},
			],
			startDate,
			endDate,
			validParkTickets: true,
			validParkReservations: true,
			validDisneyGeniePlus: true,
			validResortReservation: true,
			validEventsToSchedule: true,
			requiresDisneyGeniePlus: true,
			parkEntity: PARK_ENTITIES.WALT_DISNEY_WORLD_RESORT
		}
	];
};
