/*
 * File: colors.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on June 16, 2022 at 1:32 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: March 3, 2023 at 4:10 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Color Values
 */

export const ColorValuesCreator = () => ({

	// Brand
	brandPrimaryYellow: '#f1be46',
	brandPrimaryLightBlue: '#0d9be2',
	brandPrimaryMiddleBlue: '#1166a5',
	brandPrimaryDarkBlue: '#15356d',
	brandPrimaryOrange: '#fe812d',
	brandPrimaryRed: '#f45b68',

	// Primary
	white: '#FFFFFF',
	dark: '#2d2d2d',
	black: '#000000',

	// Neutral
	neutralButtonBase: '#747888',
	neutralButtonLighter: '#EEF0F6',
	neutralBackground: '#EFF1F3',
	neutralBackgroundDarker: '#39393B',
	neutralHighlight: '#E2E2E2',
	neutralHighlightDarker: '#505054',
	neutralForm: '#cbcbcb',
	neutralText: '#7F8496',
	neutralBorder: 'rgba(74,74,74,.05)',

	// Success
	statusSuccessBase: '#25953E',
	statusSuccessLighter: '#EAFFEE',
	statusSuccessDarker: '#04290D',

	// Warning
	statusWarningBase: '#E1D037',
	statusWarningLighter: '#FFFDE7',
	statusWarningDarker: '#302B05',

	// Danger
	statusDangerBase: '#E13737',
	statusDangerLighter: '#FFEBEB',
	statusDangerDarker: '#360404',

	// Info
	statusInfoBase: '#1A4ED4',
	statusInfoLighter: '#E7EDFF',
	statusInfoDarker: '#0B1D4D',

	// Shadows
	navigationShadow: '0px 1px 1px rgb(0 0 0 / 15%)',
	elementShadow: '0px -3px 20px rgba(0, 0, 0, 0.06)',
	elementDarkShadow: '0px 4px 16px rgba(0 0 0 / 22%)',

	// Brands
	whatsAppBrand: '#25d366',
	facebookBrand: '#0075fa',
	twitterBrand: '#1da1f2',
	linkedinBrand: '#0a66c2',
	redditBrand: '#ff4500',
	pinterestBrand: '#e60019'
});


/**
 * Themes
 */

export const DarkTheme = () => {

	// Create color values
	const ColorValues = ColorValuesCreator();

	// Return theme
	return {

		// Brand
		brandPrimaryYellow: ColorValues.brandPrimaryYellow,
		brandPrimaryLightBlue: ColorValues.brandPrimaryLightBlue,
		brandPrimaryDarkBlue: ColorValues.brandPrimaryDarkBlue,
		brandPrimaryOrange: ColorValues.brandPrimaryOrange,
		brandPrimaryRed: ColorValues.brandPrimaryRed,

		// Success
		statusSuccessBase: ColorValues.statusSuccessBase,
		statusSuccessLighter: ColorValues.statusSuccessDarker,

		// Warning
		statusWarningBase: ColorValues.statusWarningBase,
		statusWarningLighter: ColorValues.statusWarningDarker,

		// Danger
		statusDangerBase: ColorValues.statusDangerBase,
		statusDangerLighter: ColorValues.statusDangerDarker,

		// Info
		statusInfoBase: ColorValues.statusInfoBase,
		statusInfoLighter: ColorValues.statusInfoDarker,

		// Background
		primaryBackground: ColorValues.black,
		secondaryBackground: ColorValues.neutralBackgroundDarker,
		cardBackground: ColorValues.black,

		// Text
		primaryText: ColorValues.white,
		secondaryText: ColorValues.neutralText,

		// Button
		primaryButton: ColorValues.brandPrimaryYellow,
		secondaryButton: ColorValues.neutralButtonBase,
		darkButton: ColorValues.black,
		lightButton: ColorValues.neutralBackgroundDarker,

		// Decoration
		primaryDecoration: ColorValues.white,
		secondaryDecoration: ColorValues.neutralBorder,
		layoutBorder: ColorValues.neutralText,
		navigationBorder: ColorValues.neutralText,
		inactiveElement: ColorValues.neutralText,
		placeholderPrimary: ColorValues.neutralBackgroundDarker,
		placeholderHighlight: ColorValues.neutralHighlightDarker,

		// Gradient
		primaryGradientStart: 'rgba(0,0,0,0)',
		primaryGradientEnd: 'rgba(0,0,0,1)',

		// Input
		inputBackground: ColorValues.dark,
		inputSecondayBackground: ColorValues.neutralBackground,
		inputBorder: ColorValues.neutralText,
		inputFocusBorder: ColorValues.neutralButtonBase,
		inputPlaceholder: ColorValues.neutralText,
	};
};

export const LightTheme = () => {

	// Create color values
	const ColorValues = ColorValuesCreator();

	// Return theme
	return {

		// Brand
		brandPrimaryYellow: ColorValues.brandPrimaryYellow,
		brandPrimaryLightBlue: ColorValues.brandPrimaryLightBlue,
		brandPrimaryDarkBlue: ColorValues.brandPrimaryDarkBlue,
		brandPrimaryOrange: ColorValues.brandPrimaryOrange,
		brandPrimaryRed: ColorValues.brandPrimaryRed,

		// Success
		statusSuccessBase: ColorValues.statusSuccessBase,
		statusSuccessLighter: ColorValues.statusSuccessLighter,

		// Warning
		statusWarningBase: ColorValues.statusWarningBase,
		statusWarningLighter: ColorValues.statusWarningLighter,

		// Danger
		statusDangerBase: ColorValues.statusDangerBase,
		statusDangerLighter: ColorValues.statusDangerLighter,

		// Info
		statusInfoBase: ColorValues.statusInfoBase,
		statusInfoLighter: ColorValues.statusInfoLighter,

		// Background
		primaryBackground: ColorValues.white,
		secondaryBackground: ColorValues.neutralBackground,
		cardBackground: ColorValues.white,

		// Text
		primaryText: ColorValues.dark,
		secondaryText: ColorValues.neutralText,

		// Button
		primaryButton: ColorValues.brandPrimaryYellow,
		secondaryButton: ColorValues.neutralButtonBase,
		darkButton: ColorValues.dark,
		lightButton: ColorValues.neutralHighlight,

		// Decoration
		primaryDecoration: ColorValues.dark,
		secondaryDecoration: ColorValues.neutralBorder,
		layoutBorder: ColorValues.neutralForm,
		navigationBorder: 'transparent',
		inactiveElement: ColorValues.neutralText,
		placeholderPrimary: ColorValues.neutralBackground,
		placeholderHighlight: ColorValues.neutralHighlight,

		// Gradient
		primaryGradientStart: 'rgba(255,255,255,0)',
		primaryGradientEnd: 'rgba(255,255,255,1)',

		// Input
		inputBackground: ColorValues.white,
		inputSecondayBackground: ColorValues.neutralBackground,
		inputBorder: ColorValues.neutralForm,
		inputFocusBorder: ColorValues.neutralButtonBase,
		inputPlaceholder: ColorValues.neutralForm,
	};
};
