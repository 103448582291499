/*
 * File: Table.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 19, 2023 at 1:29 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useMemo } from 'react';
import { useTable } from 'react-table';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Utilities
import { formatToUSD } from '../../../utilities/utilities';
import { formatDateString, parseDateString } from '../../../utilities/dateTime';

// Components
import { Typography } from '../Typography';

// Styles
import * as S from './Table.styles';


/**
 * Currency Cell
 */

// Component
export const CurrencyCell = ({ value }) => <Typography variation="2" weight="medium">{formatToUSD(value / 100)}</Typography>;

// Configuration
CurrencyCell.propTypes = {
	value: PropTypes.number,
};
CurrencyCell.defaultProps = {
	value: null
};


/**
 * Date Cell
 */

// Component
export const DateCell = ({ column, value }) => {

	// Format date string
	const date = parseDateString(value, column.dateFormatOptions.input);

	// Get current user from hook
	const user = useSelector((state) => state.user.value);

	// Return formatted date
	return <Typography variation="2" weight="medium">{formatDateString(date, column.dateFormatOptions.output, user?.timezone)}</Typography>;
};

// Configuration
DateCell.propTypes = {
	value: PropTypes.string,
	column: PropTypes.shape()
};
DateCell.defaultProps = {
	value: null,
	column: {
		dateFormatOptions: {
			input: 'YYYY-MM-DD',
			output: 'MMM D, YYYY'
		}
	}
};


/**
 * Component
 */

export const Table = ({
	className, data, columns, alignTop, isDark
}) => {

	// Create memoized data
	const tableData = useMemo(
		() => data,
		[data]
	);

	// Create table columns
	const tableColumns = useMemo(
		() => columns,
		[columns]
	);

	// Handle actions on component load
	useEffect(() => {}, []);

	// Build table instance
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
	} = useTable({ columns: tableColumns, data: tableData });

	// Render component
	return (
		<>
			<S.DesktopTableContainer>
				<S.Table {...getTableProps()} className={className}>
					<S.TableHeader>
						{headerGroups.map((headerGroup) => (
							<S.TableRow {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									<S.TableHead
										{...column.getHeaderProps()}
										isDark={isDark}
									>
										<Typography variation="2" weight="semibold">{column.render('Header')}</Typography>
									</S.TableHead>
								))}
							</S.TableRow>
						))}
					</S.TableHeader>
					<S.TableBody {...getTableBodyProps()}>
						{rows.map((row) => {
							prepareRow(row);
							return (
								<S.TableRow {...row.getRowProps()}>
									{row.cells.map((cell) => (
										<S.TableData
											$alignTop={alignTop}
											{...cell.getCellProps()}
											isDark={isDark}
										>
											{cell.render('Cell')}
										</S.TableData>
									))}
								</S.TableRow>
							);
						})}
					</S.TableBody>
				</S.Table>
			</S.DesktopTableContainer>
			<S.MobileTable>
				{rows.map((row) => {
					prepareRow(row);
					return (
						<S.MobileItem key={row.id} isDark={isDark}>
							{columns.map((column, index) => (
								<S.MobileRow key={column.Header}>
									<S.MobileHeader isDark={isDark}>
										<Typography variation="2" weight="semibold">{column.Header}</Typography>
									</S.MobileHeader>
									<S.MobileData>
										{row.cells[index].render('Cell')}
									</S.MobileData>
								</S.MobileRow>
							))}

						</S.MobileItem>
					);
				})}
			</S.MobileTable>
		</>
	);
};


/**
 * Configuration
 */

Table.displayName = 'Table';
Table.propTypes = {
	className: PropTypes.string,
	data: PropTypes.arrayOf(PropTypes.shape()),
	columns: PropTypes.arrayOf(PropTypes.shape()),
	alignTop: PropTypes.bool,
	isDark: PropTypes.bool
};
Table.defaultProps = {
	className: null,
	data: [],
	columns: [],
	alignTop: false,
	isDark: false
};
