/*
 * File: FAQ.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on May 28, 2023 at 11:19 AM
 * Copyright © 2023 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: September 5, 2023 at 10:39 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import { Provider, useSelector } from 'react-redux';
import { StaticRouter } from 'react-router-dom/server';
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';
import { I18nextProvider } from 'react-i18next';
import { useLocation } from 'react-router-dom';

// Utilities
import { createStateLocale } from '../../../utilities/locale';
import { generateSlugFromContent } from '../../../../utilities/utilities';

// Store
import store from '../../../store';

// Constants
import { AMOUNT_PER_RESERVATION_DAY } from '../../../../Constants';

// Components
import {
	Meta, AppNavigation, Typography, SchemaScript, LocaleLink, Emoji
} from '../../../components';

// Styles
import * as S from './FAQ.styles';


/**
 * Content
 */

const QUESTIONS = [
	{
		question: 'Why do I see a login to my Disney account in Orlando, Florida?',
		answer:
	<div>
		<Typography tag="p">
			This is the primary way Pixie Dust is able to book reservations on your behalf! Our booking system is connected to the Disney booking
			service in Orlando, Florida, so that we can be as close physically to Disney World as possible.
		</Typography>
		<Typography tag="p">
			If you are ever uncomfortable with having your Disney account connected to Pixie Dust, please reach out to us as we will remove
			the connection.
		</Typography>
	</div>
	},
	{
		question: 'How does Pixie Dust work?',
		answer:
	<div>

		{/* Step 1 */}
		<Typography tag="p">
			<b>Step 1:</b>
			{' '}
			Sign up for Pixie Dust. If you haven’t created an account
			with us yet, it’s completely free to do that
			{' '}
			<LocaleLink to="https://www.pixiedustguide.com/join" target="_blank">here</LocaleLink>
			. We just ask for a bit of information to
			get started!
		</Typography>

		{/* Step 2 */}
		<Typography tag="p">
			<b>Step 2:</b>
			{' '}
			Connect your My Disney Experience account. Pixie Dust
			connects directly to your Disney account, so we ask you to securely connect to your My
			Disney Experience or Disneyland account, and we&#x27;ll do the automatic linking of your scheduled experiences to
			Genie+ and Lightning Lane reservations.
		</Typography>
		<Typography tag="p">
			<img
				alt="Pixie Dust screen to connect to My Disney Experience account"
				src="https://a-us.storyblok.com/f/1003989/3456x1340/e26a8346bc/pixie-dust-connect-to-my-disney-experience.png"
				title=""
			/>
		</Typography>

		{/* Step 3 */}
		<Typography tag="p">
			<b>Step 3:</b>
			{' '}
			Create a Pixie Dust trip. Pixie Dust organizes your Disney
			experiences into &quot;trips&quot;, where you can organize your park days and add all your party
			members to your reservations. And, you can start to plan your next Disney trip with Pixie Dust as
			soon as you like, even months in advance!
		</Typography>
		<Typography tag="p">
			<img
				alt="Pixie Dust dashboard screen with Schedule Trip button"
				src="https://a-us.storyblok.com/f/1003989/3442x1578/72516a8a5a/pixie-dust-dashboard.png"
				title=""
			/>
		</Typography>

		{/* Step 4 */}
		<Typography tag="p">
			<b>Step 4:</b>
			{' '}
			Select the dates of your trip. When you create a new trip,
			Pixie Dust will ask for your Disney dates. We&#x27;ll use this to schedule your reservations.
		</Typography>
		<Typography tag="p">
			<img
				alt="Pixie Dust screen select dates when scheduling trip"
				src="https://a-us.storyblok.com/f/1003989/3450x1504/cf15ef7d4a/pixie-dust-select-dates-trip.png"
				title=""
			/>
		</Typography>

		{/* Step 5 */}
		<Typography tag="p">
			<b>Step 5:</b>
			{' '}
			Build your perfect park day. Once your days are selected,
			you&#x27;ll be able to add reservations to them. This is as easy as clicking the &quot;Add
			Reservation&quot; button and searching for your favorite Disney attractions.
			{' '}
		</Typography>
		<Typography tag="p">
			<img
				alt="Pixie Dust schedule screen"
				src="https://a-us.storyblok.com/f/1003989/3456x1508/52578ce973/pixie-dust-schedule-ll-guardians.png"
				title=""
			/>
		</Typography>

		{/* Step 6 */}
		<Typography tag="p">
			<b>Step 6:</b>
			{' '}
			Select your ride party. On the same screen as the
			selected attractions, you will see a list of all of your available friends and family. These are party members
			that you are connected to on your My Disney Experience account. Go ahead and select the people you
			want to ride with you. Make sure that each person has a valid Disney park ticket and park
			reservation.
		</Typography>
		<Typography tag="p">
			<img
				alt="Pixie Dust screen for selecting party for Lightning Lane"
				src="https://a-us.storyblok.com/f/1003989/3456x1692/89ac673995/pixie-dust-select-party-ll.png"
				title=""
			/>
		</Typography>

		{/* Step 7 */}
		<Typography tag="p">
			<b>Step 7:</b>
			{' '}
			Save and schedule. As soon as you&#x27;re done selecting
			your party, click the complete button save your new reservation. You&#x27;ll see it appear on your
			trip day. When you&#x27;ve scheduled all the reservations you want, click the &quot;Schedule&quot;
			button below your trip days. Voila! Your reservations are ready to go. On your park
			day, We will automagically reserve your bookings the instant they become available.
		</Typography>
		<Typography tag="p">
			<img
				alt="Pixie Dust trip scheduling screen with scheduled Lightning Lane"
				src="https://a-us.storyblok.com/f/1003989/3456x1494/05019f6707/pixie-dust-schedule-day-guardians-ll.png"
				title=""
			/>
		</Typography>

		{/* Step 8 */}
		<Typography tag="p">
			<b>Step 8:</b>
			{' '}
			Have a blast!
		</Typography>
	</div>
	},
	{
		question: 'Can Pixie Dust book Individual Lightning Lanes and Virtual Queues?',
		answer:
	<div>
		<Typography tag="p">
			Pixie Dust certainly can book your Individual Lightning Lanes! You can even select the timeslot you would like to ride
			on your park day. As long as you have a credit or debit card attached to your Disney account, we can request that Disney
			make the reservation for you.
		</Typography>
		<Typography tag="p">
			We are currently working on the ability to schedule and reserve Virtual Queues for select attractions. When
			this feature is ready, we&apos;ll let you know!
		</Typography>
	</div>
	},
	{
		question: 'Do I have to purchase Genie+ in order to use Pixie Dust?',
		answer:
	<div>
		<Typography tag="p">
			This is something Pixie Dust can automatically do for you! If you&apos;ve scheduled any Lightning Lanes that require
			Genie+, we will automagically add Genie+ to your account at 6:00 AM on your park day at Disney World and as soon as you enter the park at Disneyland.
			All you need is a credit or debit card attached to your Disney account.
		</Typography>
		<Typography tag="p">This is a huge help since Genie+ can&apos;t be added to park tickets at time of purchase anymore.</Typography>
	</div>
	},
	{
		question: 'How do you make my reservations?',
		answer:
	<div>
		<Typography tag="p">
			We actually connect directly to your My Disney Experience or Disneyland account to make your reservations. This allows us to let
			Disney know when you would like to reserve a Lightning Lane faster than using the My Disney Experience or Disneyland app.
		</Typography>
		<Typography tag="p">All emails, passwords, and data are encrypted by Pixie Dust as well for your security.</Typography>
	</div>
	},
	{
		question: 'How many guests can I select per reservation?',
		answer:
	<div>
		<Typography tag="p">You can select up to 12 guests for each Pixie Dust reservation.</Typography>
	</div>
	},
	{
		question: 'What if I have more than 12 guests in my party?',
		answer:
	<div>
		<Typography tag="p">
			If you have more than 12 guests in your party, the best way to book an experience for everyone is to create
			multiple reservations in Pixie Dust and select the same timeslot for each. We will automagically attempt to
			book these reservations for the exact same time during your park day.
		</Typography>
	</div>
	},
	{
		question: 'Does using Pixie Dust guarantee a Lightning Lane?',
		answer:
	<div>
		<Typography tag="p">
			While we can&apos;t 100% guarantee that we can get you the exact reservation you&apos;re looking for, we always
			try our best. Problems with the WDW booking system or other technical issues may make it difficult to schedule
			reservations in rare cases.
		</Typography>
	</div>
	},
	{
		question: 'When can I purchase Pixie Dust?',
		answer:
	<div>
		<Typography tag="p">
			Since Pixie Dust is here to make your Disney planning as easy as possible, we don&apos;t make you purchase it beforehand. Instead,
			on the days that you have scheduled Lightning Lanes, we charge you a $
			{AMOUNT_PER_RESERVATION_DAY.toFixed(0)}
			{' '}
			fee to book your experiences. This price includes your whole party, so feel free to make as many reservations as you want each day!
		</Typography>
	</div>
	},
	{
		question: 'Can Pixie Dust book my 7:00 AM Lightning Lane?',
		answer:
	<div>
		<Typography tag="p">
			Yes! This is one of our favorite features, and the reason we built Pixie Dust. We will automagically book
			your Lightning Lanes right at 7:00 AM and add Genie+ to your account if it&apos;s needed. Note that Lightning Lanes
			can only be purchases before the parks open at Walt Disney World.
		</Typography>
		<Typography tag="p">
			Since Pixie Dust is connected directly to your Disney account, we are able to make your
			reservations the instant the available window opens. This means that we&apos;re able to get you almost the exact timeslot
			you&apos;re looking for every time!
		</Typography>
	</div>
	},
	{
		question: 'Can I use Pixie Dust With Park Hopping?',
		answer:
	<div>
		<Typography tag="p">
			Yes! As long as you have a valid park ticket and park reservations, Pixie Dust can make reservations
			for you across multiple parks in a single day.
		</Typography>
	</div>
	},
	{
		question: 'Can I still book Lightning Lanes through the My Disney Experience app?',
		answer:
	<div>
		<Typography tag="p">
			Absolutely! Pixie Dust is simply telling the WDW booking system what reservations you want to make at certain times.
			You will be able to use your My Disney Experience or Disneyland app exactly as you usually do.
		</Typography>
		<Typography tag="p">
			You can even update or cancel any reservations that Pixie Dust makes on your behalf in the My Disney
			Experience app.
		</Typography>
	</div>
	},
	{
		question: 'Can I change the LL return time Pixie Dust found for me?',
		answer:
	<div>
		<Typography tag="p">
			Yes! All Lighting Lane reservation made by Pixie Dust will appear in your My Disney Experience or Disneyland mobile app, just like
			reservations you book yourself. You can update, change or cancel these reservations at any time.
		</Typography>
	</div>
	},
	{
		question: 'Can I stack Lightning Lanes with Pixie Dust?',
		answer:
	<div>
		<Typography tag="p">
			Yes! We will automagically make your next Lightning Lane reservations for your party as
			soon as the window for the previous one is complete.
		</Typography>
	</div>
	},
	{
		question: 'I didn’t use the Pixie Dust app during my trip. Can I get a refund?',
		answer:
	<div>
		<Typography tag="p">
			No, unfortunately Pixie Dust is non refundable. Please see our
			{' '}
			<LocaleLink to="/legal/terms">Terms of Service</LocaleLink>
			{' '}
			for more information.
		</Typography>
	</div>
	},
	{
		question: 'What parks does Pixie Dust support?',
		answer:
	<div>
		<Typography tag="p">
			Pixie Dust currently supports Magic Kingdom, EPCOT, Animal Kingdom, and Hollywood Studios at Walt Disney World
			in Florida and Disneyland and Disney California Adventure in California.
		</Typography>
		<Typography tag="p">
			We are working to expand our features to other parks in the future.
			{' '}
			<Emoji symbol="😀" label="stress" size={1.1} />
		</Typography>
	</div>
	},
	{
		question: 'Does Pixie Dust work with the Disability Access Service (DAS)?',
		answer:
	<div>
		<Typography tag="p">
			Pixie Dust is only able to reserve rides and attractions that are part of the Genie+ service or an Individual
			Lightning Lane. Because of this, we can&apos;t make DAS bookings for you. However, some Disney World and Disneyland rides
			aren&apos;t offered on the DAS Advance booking service, and Pixie Dust is able to book those for you.
		</Typography>
		<Typography tag="p">
			For a full list of bookable rides offered as part of DAS, check out this
			{' '}
			<LocaleLink to="https://disneyworld.disney.go.com/faq/guests-with-disabilities/attractions-available-for-das-advance/" target="_blank">
				FAQ post from Disney.
			</LocaleLink>
		</Typography>
	</div>
	},
	{
		question: 'Are you affiliated with Disney?',
		answer:
	<div>
		<Typography tag="p">
			We are not! Pixie Dust is a 100% community-created and supported platform. All Disney licenses, copyrights,
			and property are owned exclusively by the Walt Disney Company. We&apos;re just here to enjoy the magic!
		</Typography>
	</div>
	},
	{
		question: 'What do I do if I still have more questions?',
		answer:
	<div>
		<Typography tag="p">
			We&apos;re here to answer any questions you might have! Reach out to us
			{' '}
			<LocaleLink to="/contact">here</LocaleLink>
			{' '}
			and we&apos;ll get back to you as soon as we can.
		</Typography>
		<Typography tag="p">
			Also, feel free to get in touch with us on social media. We&apos;re on
			{' '}
			<LocaleLink target="_blank" to="https://www.facebook.com/pixiedustguide">Facebook</LocaleLink>
			,
			{' '}
			<LocaleLink target="_blank" to="https://www.instagram.com/pixiedustguide">Instagram</LocaleLink>
			, and
			{' '}
			<LocaleLink target="_blank" to="https://www.tiktok.com/@pixiedustguide">TikTok</LocaleLink>
			.
		</Typography>
	</div>
	}
];


/**
 * Component
 */

const FAQ = ({ meta, locale }) => {

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Use hooks
	const location = useLocation();

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} data={{}} locale={stateLocale} />

			{/* Schema.org */}
			<SchemaScript schema={{
				'@context': 'http://schema.org',
				'@type': ['FAQPage'],
				'@id': `${process.env.APP_URL}/faq/#WebPage`,
				name: meta.title,
				url: `${process.env.APP_URL}${stateLocale.localePath}/faq`,
				inLanguage: stateLocale.localeBaseId,
				lastReviewed: new Date().toISOString(),
				isPartOf: {
					'@id': `${process.env.APP_URL}/#WebSite`
				},
				publisher: {
					'@id': 'https://www.pixiedustguide.com/#Organization',
				},
				breadcrumb: {
					'@id': `${process.env.APP_URL}/faq/#BreadcrumbList`
				},
				mainEntity: QUESTIONS.map(({ question, answer }) => {

					// Return html
					const html = ReactDOMServer.renderToString(
						<Provider store={store}>
							<I18nextProvider>
								<StaticRouter location={location.pathname}>
									{answer}
								</StaticRouter>
							</I18nextProvider>
						</Provider>
					);

					// Return question
					return ({
						'@type': 'Question',
						'@id': `${process.env.APP_URL}/faq/${generateSlugFromContent(question)}/#Question`,
						name: question,
						acceptedAnswer: {
							'@type': 'Answer',
							text: html
						}
					});
				})
			}}
			/>

			{/* Component Content */}
			<AppNavigation isTopbarTransparent={false}>
				<S.Wrapper>

					{/* Hero Section */}
					<S.HeroSection>
						<S.HeroContentContainer>

							{/* Content */}
							<S.TitleContainer>
								<Emoji symbol="👋" size={2.5} label="Wave" />
								<Typography tag="h1" weight="extrabold" className="animate">
									Frequently Asked Questions
								</Typography>
							</S.TitleContainer>
						</S.HeroContentContainer>
					</S.HeroSection>

					{/* Links Section */}
					<S.LinksSection>

						{/* Parse and add question links */}
						{QUESTIONS.map(({ question }) => (
							<LocaleLink to={`#${generateSlugFromContent(question)}`} key={generateSlugFromContent(question)}>
								<S.QuestionLink>{question}</S.QuestionLink>
							</LocaleLink>
						))}

					</S.LinksSection>

					{/* Questions Section */}
					<S.QuestionsSection className="paragraphContent">

						{/* Parse and add question links */}
						{QUESTIONS.map(({ question, answer }) => (
							<S.QuestionContainer key={generateSlugFromContent(question)}>
								<S.Signpost id={generateSlugFromContent(question)} />
								<S.Question><Typography tag="h3" weight="bold">{question}</Typography></S.Question>
								<S.Answer>{answer}</S.Answer>
							</S.QuestionContainer>
						))}

					</S.QuestionsSection>

				</S.Wrapper>
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

FAQ.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
};
FAQ.defaultProps = {
	meta: {},
	locale: {}
};


/**
 * Exports
 */

export default FAQ;
