/*
 * File: fontawesome.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on June 16, 2022 at 1:32 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: June 18, 2023 at 2:25 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import { library } from '@fortawesome/fontawesome-svg-core';

// Icons
import {
	faBagShopping,
	faBars,
	faTimes,
	faHeart,
	faCheck,
	faPlus,
	faPlusCircle,
	faArrowRightFromBracket,
	faHashtag,
	faEllipsis,
	faTriangleExclamation,
	faArrowRightLong,
	faArrowLeftLong,
	faArrowRight,
	faArrowLeft,
	faChevronDown,
	faChevronUp,
	faMagnifyingGlass,
	faPen,
	faEllipsisV,
	faRotate,
	faExclamationTriangle,
	faQuestion,
	faInfo,
	faArrowUpFromBracket,
	faKey,
	faRotateRight,
	faArrowUpRightFromSquare,
	faCreditCard as faCreditCardSolid,
	faAngleRight,
	faAngleLeft,
	faStar as faStarSolid,
	faClock as faClockSolid,
	faCircleInfo
} from '@fortawesome/free-solid-svg-icons';
import {
	faUser,
	faBell,
	faMoon,
	faSun,
	faQuestionCircle,
	faStar,
	faCircle,
	faCircleCheck,
	faClock,
	faCalendar,
	faEnvelope,
	faTrashCan,
	faPenToSquare,
	faCreditCard,
} from '@fortawesome/free-regular-svg-icons';
import {
	faFacebook,
	faFacebookF,
	faInstagram,
	faTwitter,
	faRedditAlien,
	faPinterestP,
	faLinkedinIn
} from '@fortawesome/free-brands-svg-icons';


/**
 * Init
 */

export function loadIcons() {

	// Add icons to library
	library.add(
		faBagShopping,
		faBars,
		faTimes,
		faHeart,
		faCheck,
		faFacebook,
		faFacebookF,
		faInstagram,
		faUser,
		faBell,
		faPlus,
		faPlusCircle,
		faMoon,
		faSun,
		faQuestionCircle,
		faArrowRightFromBracket,
		faStar,
		faCircle,
		faCircleCheck,
		faHashtag,
		faEllipsis,
		faTriangleExclamation,
		faClock,
		faArrowRight,
		faArrowRightLong,
		faArrowLeftLong,
		faChevronDown,
		faChevronUp,
		faCalendar,
		faTwitter,
		faRedditAlien,
		faPinterestP,
		faLinkedinIn,
		faEnvelope,
		faMagnifyingGlass,
		faPen,
		faEllipsisV,
		faRotate,
		faTrashCan,
		faExclamationTriangle,
		faQuestion,
		faInfo,
		faPenToSquare,
		faArrowUpFromBracket,
		faKey,
		faRotateRight,
		faCreditCard,
		faArrowUpRightFromSquare,
		faCreditCardSolid,
		faAngleRight,
		faAngleLeft,
		faStarSolid,
		faClockSolid,
		faArrowLeft,
		faCircleInfo
	);
}
