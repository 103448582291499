/*
 * File: Sidebar.styles.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:51 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: November 28, 2022 at 1:09 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { LocaleLink as BaseLink } from '../../LocaleLink';
import { IconButton } from '../../IconButton';

// Constants
import { AppNavSidebarWidth, AppNavTopbarHeight } from '../../../styles/constants';


/**
 * Styles
 */

export const Sidebar = styled.div`
	width: 98%;
	max-width: ${AppNavSidebarWidth}px;
	height: 100%;
	background-color: ${({ theme }) => theme.primaryBackground};
	border-right: 1px solid ${({ theme }) => theme.layoutBorder};
	padding: 15px 30px 60px 30px;
	overflow: auto;
	position: relative;
	z-index: 2;

	& > div {
		flex-direction: column;
	}
`;

export const SidebarSection = styled.div`
	padding: 20px 0;
	width: 100%;

	* {
		text-align: left;
	}

	h5,
	.secondary {
		color: ${({ theme }) => theme.secondaryText};
	}

	button.minimal {
		margin: 15px 0px 0px;
		padding: 0px;
		font-weight: 600;
		font-size: 12px;
	}

	button.full {
		margin: 15px 0px 0px;
		width: 100%;
	}
`;

export const MobileNavigation = styled.div`
	margin-top: -15px;
	height: ${AppNavTopbarHeight}px;
	display: flex;
	align-items: center;
`;

export const NavigationToggle = styled(IconButton)`
	pointer-events: all;
`;

export const PrimaryLink = styled(BaseLink)`
	text-decoration: none;
	display: flex;
	padding: 8px 0 8px 0;
	color: ${({ theme }) => theme.primaryText} !important;

	* {
		color: ${({ theme }) => theme.primaryText} !important;
		font-size: 1.2rem;
	}
	&:hover {
		text-decoration: underline;
	}
`;

export const Link = styled(BaseLink)`
	text-decoration: none;
	margin: 0px 10px 8px 0px;

	&:hover {
		text-decoration: underline;
	}
`;

export const LinkSection = styled.div`
	margin: 0px 0px 12px;
	display: flex;
	flex-wrap: wrap;

	* {
		color: ${({ theme }) => theme.secondaryText};
		font-size: 0.72rem;
	}
	svg {
		font-size: 0.72rem;
		position: relative;
	}

	.fullWidth {
		margin: 0px 0px 3px 0px;
		width: 100%;
	}
`;

export const LogoMark = styled.div`
	width: 40px;
	height: 40px;
	background-image: url('${process.env.CDN_URL}/public/assets/logos/pixie-dust-logo-icon.png');
	background-size: cover;
	background-repeat: no-repeat;
	margin-bottom: 10px;
`;
