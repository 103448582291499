/*
 * File: MyTrips.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on December 6, 2022 at 11:54 AM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: March 15, 2023 at 9:14 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

// Services
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getTrips } from '../../../../services/trip';

// Slices
import { updateTrips } from '../../../../store/slices/trips/trips.slice';

// Components
import {
	EmptyComponent, TripCard, Typography
} from '../../../../components';

// Constants
import { PLACEHOLDER_TRIPS } from '../../../../../Constants';

// Styles
import * as S from './MyTrips.styles';


/**
 * Component
 */

export const MyTrips = ({ className, showAddNewAction }) => {

	// Create state handlers
	const [trips, setTrips] = useState([]);
	const [componentStatus, setComponentStatus] = useState('idle');

	// Get current trips from hook
	const tripsState = useSelector((state) => state.trips.value);

	// Get actions from hooks
	const dispatch = useDispatch();

	// Initialize component data function
	const fetchMyTrips = async () => {

		// Check if stored friends and family
		if (!tripsState.isSet) {

			// Update page status
			setComponentStatus('loading');
			try {

				// Fetch data
				const { data } = await getTrips();

				// Update trips
				dispatch(updateTrips({ trips: data?.trips }));

				// Set new data state
				setTrips(data?.trips);

				// Update page status
				setComponentStatus('success');

			} catch (error) {

				// Update page status
				setComponentStatus('error');
			}
		} else {

			// Set new data state
			setTrips(tripsState?.trips);

			// Update page status
			setComponentStatus('success');
		}
	};

	// Perform actions on component load
	useEffect(() => {

		// Fetch trips
		fetchMyTrips();

	}, []);

	// Render component
	const renderComponent = () => {
		if (componentStatus !== 'success') {
			return PLACEHOLDER_TRIPS().map((trip) => (
				<TripCard
					key={trip.id}
					trip={trip}
					isLoading
					isDark
				/>
			));
		}
		if (!showAddNewAction && trips.length === 0) {
			return (
				<S.EmptyCard>
					<EmptyComponent
						isLight
						title="You don't have any trips"
						message="Start creating a new trip to schedule your Lightning Lanes."
						icon={['far', 'star']}
					/>
				</S.EmptyCard>
			);
		}
		return trips.map((trip) => (
			<TripCard
				key={trip.id}
				trip={trip}
				isDark
			/>
		));
	};

	// Return component
	return (
		<S.Wrapper className={className}>
			{showAddNewAction && (
				<S.PromptLink to="/trip/create">
					<S.AddTripPrompt className="animate">
						<FontAwesomeIcon icon={['fa', 'circle-plus']} />
						<Typography weight="medium">Schedule a trip</Typography>
						<S.AddTripPromptBackground />
					</S.AddTripPrompt>
				</S.PromptLink>
			)}
			{renderComponent()}
		</S.Wrapper>
	);
};


/**
 * Configuration
 */

MyTrips.displayName = 'MyTrips';
MyTrips.propTypes = {
	className: PropTypes.string,
	showAddNewAction: PropTypes.bool
};
MyTrips.defaultProps = {
	className: null,
	showAddNewAction: true
};
