/*
 * File: DisneyAccountStep.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:24 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: October 22, 2023 at 11:15 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useState, forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import validator from 'validator';

// Utilities
import { toastError } from '../../../../utilities/toaster';
import { displayDisneyAuthError } from '../../../../utilities/disney';

// Services
import { attachDisneyAccount, getCurrentUser } from '../../../../services/user';

// Slices
import { updateUser } from '../../../../store/slices/user/user.slice';

// Components
import {
	Typography, TextInput, Meta, InputCollection, LocaleLink
} from '../../../../components';

// Styles
import * as S from './DisneyAccountStep.styles';


/**
 * Constants
 */

const MAX_POLLING_ATTEMPTS = 6;


/**
 * State
 */

let pollingTimer = null;


/**
 * Component
 */

export const DisneyAccountStep = forwardRef(({
	updateStep, className
}, ref) => {

	// Create state handlers
	const [usernameError, setUsernameError] = useState(null);
	const [passwordError, setPasswordError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [inputValues, setInputValues] = useState({});

	// Create references
	const pollingCount = useRef(0);

	// Get actions from hooks
	const dispatch = useDispatch();

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Handle attach function
	const handleAttachDisneyAccount = async () => {

		// Get parameters
		const { username, password } = inputValues;

		// Validate parameters
		if (!username || validator.isEmpty(username, { ignore_whitespace: true })) {
			setUsernameError({ message: 'Please enter your email or username' });
			return;
		}
		if (!password || validator.isEmpty(password, { ignore_whitespace: true })) {
			setPasswordError({ message: 'Please enter your password' });
			return;
		}

		// Set loading state
		setIsLoading(true);

		// Log in user
		attachDisneyAccount({ username, pass: password }).then(({ data }) => {

			// Set loading state
			setIsLoading(false);

			// Dispatch new user
			dispatch(updateUser(data.user));

			// Move to next step
			updateStep(4);

		}).catch(({ response }) => {

			// Check if timeout error
			if (response?.status === 503) {

				// Set polling interval to check Disney authentication status
				pollingTimer = setInterval(async () => {

					// Fetch current user
					const { data } = await getCurrentUser();

					// Check if user has Disney auth error
					if (data.user.disneyAccountError != null && data.user.disneyAccountError.message != null) {

						// Cancel interval
						if (pollingTimer != null) clearInterval(pollingTimer);
						pollingTimer = null;

						// Set loading state
						setIsLoading(false);
						setUsernameError(null);
						setPasswordError(null);

						// Clear password
						setInputValues({ ...inputValues, password: '' });

						// Display Disney auth error
						displayDisneyAuthError(data.user.disneyAccountError.message, uiMode);

						// Return
						return;
					}

					// Check if disney authentication completed
					if (data.user.onboardingComplete === true) {

						// Cancel interval
						if (pollingTimer != null) clearInterval(pollingTimer);
						pollingTimer = null;

						// Dispatch new user
						dispatch(updateUser(data.user));

						// Move to next step
						updateStep(4);
					}

					// Update poll count
					pollingCount.current += 1;

					// Show error if exceeded maxium poll attempts
					if (pollingCount.current > MAX_POLLING_ATTEMPTS) {

						// Cancel interval
						if (pollingTimer != null) clearInterval(pollingTimer);
						pollingTimer = null;

						// Set loading state
						setIsLoading(false);
						setUsernameError(null);
						setPasswordError(null);

						// Clear password
						setInputValues({ ...inputValues, password: '' });

						// Show error message
						toastError(uiMode, 'Whoops. We\'re having trouble connecting to your My Disney Experience Account. Please try again.');
					}

				}, 8 * 1000); // 8 seconds

			} else {

				// Set loading state
				setIsLoading(false);
				setUsernameError(null);
				setPasswordError(null);

				// Clear password
				setInputValues({ ...inputValues, password: '' });

				// Show error message
				if (response?.data?.message) {
					displayDisneyAuthError(response?.data?.message, uiMode);
				} else {
					toastError(uiMode, 'Whoops. We\'re having trouble connecting to your My Disney Experience Account. Please try again.');
				}
			}
		});
	};


	// Handle on input change action
	const handleOnChange = (event) => {
		const { name, value } = event.target;
		setInputValues({ ...inputValues, [name]: value });
	};

	// Return component
	return (
		<S.StepContainer ref={ref} className={className}>

			{/* Meta */}
			<Meta
				meta={{
					title: 'Join • Pixie Dust',
					description: 'By securely connecting your account, we will be able to reserve Lightning Lanes for you.',
					showSocial: false
				}}
			/>

			{/* Form */}
			<S.Form>

				{/* Logo */}
				<S.LogoMark />

				{/* Content */}
				<Typography tag="h2" weight="bold">Connect to your My Disney Experience account</Typography>
				<Typography tag="p" variation="1" weight="regular">By securely connecting your account, we will be able to reserve Lightning Lanes for you.</Typography>

				{/* Inputs */}
				<InputCollection className="floatShadow" isDark>
					<TextInput
						label="My Disney Experience Email"
						name="username"
						type="text"
						error={usernameError}
						value={inputValues.username || ''}
						containerClassName="modalInput"
						autoComplete="email"
						onFocus={() => { setUsernameError(null); }}
						onKeyUp={() => { setUsernameError(null); }}
						onBlur={() => { setUsernameError(null); }}
						onChange={handleOnChange}
						smartField
						isDark
					/>
					<TextInput
						label="My Disney Experience Password"
						type="password"
						name="password"
						error={passwordError}
						value={inputValues.password || ''}
						containerClassName="modalInput"
						autoComplete="current-password"
						onFocus={() => { setPasswordError(null); }}
						onKeyUp={() => { setPasswordError(null); }}
						onBlur={() => { setPasswordError(null); }}
						onChange={handleOnChange}
						smartField
						isDark
					/>
				</InputCollection>

				{/* Sign Up Action */}
				<S.ActionButton disabled={isLoading} isLoading={isLoading} onClick={() => { handleAttachDisneyAccount(); }} size="large" className="floatShadow">Connect account</S.ActionButton>

				{/* Instruction */}
				<S.Instructions>
					<Typography tag="p" variation="3">
						Can&apos;t find your
						{' '}
						<LocaleLink to="https://disneyworld.disney.go.com/plan/my-disney-experience/" target="_blank">My Disney Experience</LocaleLink>
						{' '}
						login information? It&apos;s the same email and password you use to log into
						{' '}
						<LocaleLink to="https://disneyworld.disney.go.com/login" target="_blank">disneyworld.com</LocaleLink>
						,
						{' '}
						<LocaleLink to="https://disneyland.disney.go.com/login" target="_blank">disneyland.com</LocaleLink>
						,
						{' '}
						or
						{' '}
						<LocaleLink to="https://www.disneyplus.com/" target="_blank">Disney Plus</LocaleLink>
						.
					</Typography>
				</S.Instructions>

			</S.Form>
		</S.StepContainer>
	);
});


/**
 * Configuration
 */

DisneyAccountStep.displayName = 'DisneyAccountStep';
DisneyAccountStep.propTypes = {
	updateStep: PropTypes.func.isRequired,
	className: PropTypes.string
};
DisneyAccountStep.defaultProps = {
	className: null
};
