/*
 * File: TripCard.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on December 6, 2022 at 11:55 AM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: October 2, 2023 at 11:07 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

// Utilities
import { dateObj, parseDateString } from '../../../utilities/dateTime';
import { parseFirstLastName } from '../../../utilities/utilities';
import { renderProfileImage } from '../../utilities/image';
import { getCookie } from '../../utilities/cookies';

// Components
import { Typography } from '../Typography';
import { Emoji } from '../Emoji';
import { ToolTip } from '../ToolTip';

// Constants
import {
	DEFAULT_TIMEZONE, ICONS, PARKS, VIEW_MODE_COOKIE, PARK_ENTITY_OPTIONS
} from '../../../Constants';

// Styles
import * as S from './TripCard.styles';


/**
 * Component
 */

export const TripCard = ({
	className, trip, isLoading, isDetail, isDark
}) => {

	// Get current user from hook
	const user = useSelector((state) => state.user.value);

	// Get query parameters from hook
	const [searchParams] = useSearchParams();
	let isMobileApp = searchParams.get('mobileapp') === 'true';
	if (!isMobileApp) {
		const mobileAppCookie = getCookie(VIEW_MODE_COOKIE);
		if (mobileAppCookie === 'mobileapp') isMobileApp = true;
	}

	// Parse names
	const { firstName, lastName } = parseFirstLastName(user?.name);

	// Get entity parameters
	let timezone = DEFAULT_TIMEZONE;
	let mobileAppName = 'My Disney Experience';
	if (trip != null && trip?.parkEntity != null) {
		const parkEntity = PARK_ENTITY_OPTIONS.find((entity) => entity.id === trip?.parkEntity.id);
		timezone = parkEntity.timezone;
		mobileAppName = parkEntity.mobileApp;
	}

	// Get trip dates
	const firstDate = trip.days.length > 0 ? parseDateString(trip.days[0].date, 'YYYY-MM-DD', timezone) : dateObj(new Date(), timezone);
	const lastDate = trip.days.length > 0 ? parseDateString(trip.days[trip.days.length - 1].date, 'YYYY-MM-DD', timezone) : dateObj(new Date(), timezone);

	// Get current date
	const now = new Date();

	// Handle party rendering
	const getPartyList = () => {

		// Parse all party members
		let party = [];
		trip?.days.forEach((day) => {
			day.scheduledEvents.forEach((ev) => {
				const formattedParty = ev.party.map((member) => {

					// Create profile image
					let profileImage = member.profileImage?.media?.characterSmall?.url || member.profileImage?.url;
					if (member.profileImage?.isInternal || (isMobileApp && member.profileImage?.isDisneyDefault === true && member.profileImage?.internal)) {
						profileImage = member.profileImage?.internal?.['200x200'];
					}

					// Create member
					return {
						...member,
						profileImage
					};
				});
				party.push(...formattedParty);
			});
		});

		// Deduplicate party
		party = party.filter((v, i, a) => a.findIndex((v2) => ((v2.userSWID && v.userSWID && v2.userSWID.toString() === v.userSWID.toString()) || (v2.userXID && v.userXID && v2.userXID.toString() === v.userXID.toString()))) === i);

		// Limit size if necessary
		if (party.length > 7) {
			party.length = 7;
		} else if (party.length === 0) {
			party = [{
				firstName,
				lastName,
				userSWID: user.id,
				userXID: user.id,
				profileImage: renderProfileImage(user.profileImage, '200x200', isMobileApp),
				avatarId: user.id
			}];
		}

		// Return party
		return party;
	};

	// Handle park rendering
	const getParkList = () => {

		// Parse all parks
		let parks = [];
		trip?.days.forEach((day) => {
			parks.push(...day.parks);
		});

		// Deduplicate parks
		parks = parks.filter((v, i, a) => a.findIndex((v2) => (v2.parkId.toString() === v.parkId.toString())) === i);

		// Limit park size if necessary
		const finalParks = [];
		if (parks.length === 0) {
			finalParks.push({ parkId: false });
		} else if (parks.length <= 3) {
			finalParks.push(...parks);
		} else {
			finalParks.push(...[parks[0], parks[1]]);
			finalParks.push({ parkId: false, additional: parks.length - 2 });
		}

		// Return parks
		return finalParks;
	};

	// Handle render trip status
	const renderStatus = () => {
		if (!trip.validEventsToSchedule) {

			// Render status
			return (
				<S.StatusWrapper status="warning">
					<Typography variation="3" weight="medium">Continue editing</Typography>
					<FontAwesomeIcon icon={['fa', 'arrow-right-long']} />
				</S.StatusWrapper>
			);
		}
		if (!trip.validParkTickets
			|| !trip.validParkReservations
			|| (!user.disneyAccountHasPaymentMethod && trip.requiresDisneyPaymentMethod)
			|| !user.hasInternalPaymentMethod) {

			// Create tooltip
			let tooltip = '';
			if (!trip.validParkTickets) {
				tooltip = 'We aren\'t able to find a valid theme park ticket for your visit';
			} else if (!trip.validParkReservations) {
				tooltip = 'We aren\'t able to find a valid theme park reservation for your visit';
			} else if (!user.disneyAccountHasPaymentMethod && trip.requiresDisneyPaymentMethod) {
				tooltip = `We aren't able to find a payment method attached to your ${mobileAppName} account`;
			} else if (!user.hasInternalPaymentMethod) {
				tooltip = 'It looks like you haven\'t added a payment method to your Pixie Dust account';
			} /* } else if (!trip.validDisneyGeniePlus && trip.requiresDisneyGeniePlus) {
				tooltip = 'It looks like Genie+ is needed for your park day';
			*/

			// Render status
			return (
				<S.StatusWrapper status="warning" data-tip={tooltip}>
					<FontAwesomeIcon icon={['fa', 'triangle-exclamation']} />
					<Typography variation="3" weight="medium">Needs attention</Typography>
				</S.StatusWrapper>
			);
		}
		if (now.getTime() >= new Date(trip.startDate).getTime() && now.getTime() <= new Date(trip.endDate).getTime()) {

			// Render status
			return (
				<S.StatusWrapper status="success">
					<FontAwesomeIcon icon={['far', 'clock']} />
					<Typography variation="3" weight="medium">Day Scheduled</Typography>
				</S.StatusWrapper>
			);
		}
		if (now.getTime() < new Date(trip.startDate).getTime()) {

			// Render status
			return (
				<S.StatusWrapper status="success">
					<FontAwesomeIcon icon={['fa', 'check']} />
					<Typography variation="3" weight="medium">Scheduled</Typography>
				</S.StatusWrapper>
			);
		}
		return null;
	};

	// Handle render time left
	const renderTimeLeft = () => {
		const timeLeft = firstDate.diff(now, 'day');
		if (timeLeft === 0) {
			return 'Coming up tomorrow!';
		}
		return timeLeft === 1 ? '1 day to go!' : `${timeLeft} days to go!`;
	};

	// Handle component rendering
	const renderComponent = () => (
		<S.Wrapper className={className ? `${className} animate` : 'animate'} isLoading={isLoading} isDark={isDark} isDetail={isDetail}>

			{/* Tooltip */}
			<ToolTip size="medium" />

			{/* Status */}
			{!isLoading && !isDetail && renderStatus()}

			{/* Card Inner */}
			<S.CardInner>

				{/* Title Container */}
				<S.CardContainer>

					{/* Days to Go */}
					{trip.validEventsToSchedule && now.getTime() < new Date(trip?.startDate).getTime() && (
						<S.DaysToGo className={isLoading && 'shimmer'}>
							<Typography tag="p" variation="3" weight="medium" className={isLoading && 'shimmer-element'}>{renderTimeLeft()}</Typography>
						</S.DaysToGo>
					)}

					{/* Title */}
					{!isDetail && (
						<S.TripTitle className={isLoading && 'shimmer shimmer-element'}>
							<Typography tag="h4" weight="semibold" className={isLoading && 'shimmer-element'}>{trip.name}</Typography>
						</S.TripTitle>
					)}

				</S.CardContainer>

				{/* Date Container */}
				<S.CardContainer>

					{/* Dates */}
					<S.DateContainer className={isLoading && 'shimmer shimmer-element'}>
						{!firstDate.isSame(lastDate) ? (
							<>
								<S.DateItem>
									<Typography tag="p" variation="3" weight="medium" className={isLoading && 'shimmer-element'}>{firstDate.format('dddd')}</Typography>
									<Typography tag="p" variation="1" weight="semibold" className={isLoading ? 'dateText shimmer-element' : 'dateText'}>{firstDate.format('MMM D')}</Typography>
								</S.DateItem>
								<S.DateDivider>
									<Typography tag="p" variation="1" weight="medium" className={isLoading && 'shimmer-element'}>–</Typography>
								</S.DateDivider>
								<S.DateItem>
									<Typography tag="p" variation="3" weight="medium" className={isLoading && 'shimmer-element'}>{lastDate.format('dddd')}</Typography>
									<Typography tag="p" variation="1" weight="semibold" className={isLoading ? 'dateText shimmer-element' : 'dateText'}>{lastDate.format('MMM D')}</Typography>
								</S.DateItem>
							</>
						) : (
							<S.DateItem>
								<Typography tag="p" variation="3" weight="medium" className={isLoading && 'shimmer-element'}>{firstDate.format('dddd')}</Typography>
								<Typography tag="p" variation="1" weight="semibold" className={isLoading ? 'dateText shimmer-element' : 'dateText'}>{firstDate.format('MMM D')}</Typography>
							</S.DateItem>
						)}
					</S.DateContainer>
				</S.CardContainer>

				{/* Places */}
				<S.CardContainer>
					<Typography tag="p" variation="2" weight="semibold" className={isLoading && 'shimmer shimmer-element'}>Places</Typography>
					<S.PlacesContainer>
						{getParkList().map((park) => (
							<S.PlaceItem key={park.parkId}>
								<S.PlaceIcon className={isLoading && 'shimmer'}>
									{!isLoading && (
										<div>
											{park.parkId === false ? <FontAwesomeIcon icon={['fa', 'plus']} /> : <Emoji symbol={ICONS[Object.values(PARKS).find((p) => p.id === park.parkId).primaryLookup].icon} label="look" size={1.1} />}
										</div>
									)}
								</S.PlaceIcon>
								<Typography tag="p" variation="3" weight="semibold" className={isLoading && 'shimmer shimmer-element'}>{park.parkId === false ? `${park.additional != null ? `${park.additional} more` : 'Add park'}` : park.name}</Typography>
							</S.PlaceItem>
						))}
					</S.PlacesContainer>
				</S.CardContainer>

				{/* Party */}
				{!isDetail && (
					<S.CardContainer>
						<Typography tag="p" variation="2" weight="semibold" className={isLoading && 'shimmer shimmer-element'}>My party</Typography>
						<S.PartyContainer>
							{getPartyList().map((member) => <S.ProfileImage className={isLoading && 'shimmer'} key={member.userSWID || member.userXID} image={member.profileImage} />)}
						</S.PartyContainer>
					</S.CardContainer>
				)}
			</S.CardInner>

			{/* Action Section */}
			{!isLoading && !isDetail && now.getTime() < new Date(trip.endDate).getTime() && (
				<S.ActionSection>
					<Typography tag="p" variation="3" weight="semibold">Update trip</Typography>
				</S.ActionSection>
			)}
		</S.Wrapper>
	);

	// Render component
	return (
		isLoading || isDetail ? renderComponent() : (
			<S.PromptLink to={`/trip/${trip.id}`}>
				{renderComponent()}
			</S.PromptLink>
		)
	);
};


/**
 * Configuration
 */

TripCard.displayName = 'TripCard';
TripCard.propTypes = {
	className: PropTypes.string,
	trip: PropTypes.shape(),
	isLoading: PropTypes.bool,
	isDetail: PropTypes.bool,
	isDark: PropTypes.bool,
};
TripCard.defaultProps = {
	className: null,
	trip: null,
	isLoading: false,
	isDetail: false,
	isDark: false
};
