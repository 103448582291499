/*
 * File: Dashboard.styles.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on November 18, 2022 at 3:44 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: December 13, 2022 at 7:41 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Styles
import { ColorValuesCreator } from '../../../styles/colors';

// Create color values
const ColorValues = ColorValuesCreator();


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 50px;
	margin: 40px 0px 80px;
`;

export const DashboardSection = styled.div``;

export const TitleContainer = styled.div`
	margin-bottom: 10px;

	h4 {
		color: ${ColorValues.white};
		text-align: left;
		margin: 0px;
	}
`;
