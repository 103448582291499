/*
 * File: Topbar.styles.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:52 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: August 8, 2023 at 7:54 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';

// Colors
import { ColorValuesCreator } from '../../../styles/colors';

// Components
import { IconButton } from '../../IconButton';
import { LocaleLink } from '../../LocaleLink';
import { NavigationDropdown as Dropdown } from '../../NavigationDropdown';

// Constants
import { AppNavTopbarHeight } from '../../../styles/constants';

// Create color values
const ColorValues = ColorValuesCreator();


/**
 * Helpers
 */

const getBackgroundColor = (theme, isDark, isTransparent) => {
	if (isTransparent) return 'transparent';
	if (isDark) return ColorValues.white;
	return theme.primaryBackground;
};


/**
 * Styles
 */

export const Topbar = styled.div`
	height: ${AppNavTopbarHeight}px;
	min-height: ${AppNavTopbarHeight}px;
	display: flex;
	background-color: ${({ theme, isDark, isTransparent }) => getBackgroundColor(theme, isDark, isTransparent)};
	z-index: 1000;
	padding: 0.78rem 0;
	pointer-events: all;
	top: 0px !important;
	width: 100%;

	${({ isFixed }) => isFixed && css`
		position: fixed !important;
	`}
	${({ isScrolled, isFixed, isTransparent }) => isScrolled && isFixed && isTransparent && css`
		background-color: ${({ theme }) => theme.primaryBackground};
	`}
	${({ isScrolled, isFixed }) => (isScrolled && isFixed ? css`
		box-shadow: ${ColorValues.navigationShadow};
		border-bottom: 1px solid ${({ theme }) => theme.navigationBorder};
	` : css`
		box-shadow: none;
		border-bottom: 1px solid transparent;
	`)};
	* :not(svg,path) {
		height: 100%;
	}
	button {
		pointer-events: auto;
		align-items: center;
	}
	.paddingLeft {
		margin-left: 20px;
	}
`;

export const TopbarWrapper = styled.div`
	display: flex;
	align-items: center;

	${({ fullWidth }) => fullWidth && css`
		justify-content: center;
		flex: 1;
	`}
`;

export const NavigationTabContainer = styled.div`
	height: ${AppNavTopbarHeight}px !important;

	& div {
		display: flex;
	}
`;

export const LogoImage = styled.img`
	padding: 0.3rem 0px 0.3rem 0;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
`;

export const ActionPadding = styled.div`
	height: 100%;
	padding: 3px 0;
`;

export const ProfileContainer = styled.div`
	position: relative;
	height: 100%;
`;

export const ProfileImage = styled.img`
	background-color: ${({ theme }) => theme.placeholderPrimary};
	border-radius: 50%;
	margin-left: 16px;
	height: 100%;
	cursor: pointer;
`;

export const Divider = styled.div`
	height: 100%;
	width: 1px;
	background-color: ${({ theme }) => theme.layoutBorder};
	margin: 0px 30px;
`;

export const NavigationToggle = styled(IconButton)`
	height: auto;
	margin-right: 18px;

	${({
		theme, isDark, isScrolled, isFixed
	}) => isDark && css`
		color: ${isScrolled && isFixed ? theme.primaryText : ColorValues.white};
	`}
`;

export const ButtonSpan = styled.span`
	height: auto;
	margin: 0px;
`;

export const DropdownContainer = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	margin-left: 12px;
`;

export const DropdownToggle = styled(IconButton)`
	height: auto;
`;

export const NavigationDropdown = styled(Dropdown)`
	top: ${AppNavTopbarHeight - 20}px;

	&.auth {
		right: -9px;
	}
`;

export const LinkContainer = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
	margin-right: 40px;
`;

export const NavigationLink = styled(LocaleLink)`
	height: auto !important;
	text-decoration: none;
	color: ${({
		theme, $isDark, $isTransparent, $isScrolled
	}) => ($isDark || ($isTransparent && !$isScrolled) ? ColorValues.white : theme.primaryText)};
	margin-left: 40px;
`;

export const ImpersonationIndicator = styled.div`
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: -4px;

	span {
		height: auto;
	}
`;
