/*
 * File: Profile.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on November 18, 2022 at 3:44 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 21, 2023 at 3:38 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useLocation, useSearchParams } from 'react-router-dom';

// Utilities
import { createStateLocale } from '../../../utilities/locale';
import { toastError, toastSuccess } from '../../../utilities/toaster';
import { renderProfileImage } from '../../../utilities/image';
import { getCookie } from '../../../utilities/cookies';

// Services
import { deleteUserAccount, refreshDisneyAccount } from '../../../services/user';
import { removePaymentMethod } from '../../../services/payment';

// Slices
import { updateUser } from '../../../store/slices/user/user.slice';

// Components
import {
	Meta, AppNavigation, Typography, NotificationSettings, Button, LoadingModal, ConfirmationModal, ProfileImageModal
} from '../../../components';
import { FriendsFamily } from './FriendsFamily';
import { MyTrips } from './MyTrips';
import { PaymentHistory } from './PaymentHistory';
import { UpdateEmailModal } from './UpdateEmailModal';
import { UpdateProfileModal } from './UpdateProfileModal';
import { UpdatePasswordModal } from './UpdatePasswordModal';
import { PaymentMethodModal } from './PaymentMethodModal/PaymentMethodModal';
import { UpdateDisneyAccountModal } from './UpdateDisneyAccountModal/UpdateDisneyAccountModal';

// Constants
import { navigationDropdownDelay } from '../../../styles/constants';
import { QUERY_ACTIONS, VIEW_MODE_COOKIE } from '../../../../Constants';

// Styles
import * as S from './Profile.styles';


/**
 * Constants
 */

const TABS = {
	PROFILE: 'profile',
	NOTIFICATIONS: 'notifications',
	ACCOUNT: 'account'
};


/**
 * State
 */

let optionsDropdownTimer = null;


/**
 * Component
 */

const Profile = ({ meta, locale, user }) => {

	// Get location state
	const location = useLocation();
	const { hash } = location;

	// Get initial tab
	let initialTab = TABS.PROFILE;
	if (hash) {
		switch (hash.replace('#', '')) {
			case 'profile':
				initialTab = TABS.PROFILE;
				break;
			case 'notifications':
				initialTab = TABS.NOTIFICATIONS;
				break;
			case 'account':
				initialTab = TABS.ACCOUNT;
				break;
			default:
				break;
		}
	}

	// Set state
	const [currentTab, setCurrentTab] = useState(initialTab);
	const [showOptionsDropdown, setShowOptionsDropdown] = useState(false);
	const [profileImageModalOpen, setProfileImageModalOpen] = useState(false);
	const [editProfileModalOpen, setEditProfileModalOpen] = useState(false);
	const [editEmailModalOpen, setEditEmailModalOpen] = useState(false);
	const [editDisneyAccountModalOpen, setEditDisneyAccountModalOpen] = useState(false);
	const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
	const [paymentMethodModalOpen, setPaymentMethodModalOpen] = useState(false);
	const [activePaymentMethod, setActivePaymentMethod] = useState(null);
	const [refreshModalVisible, showRefreshModal] = useState(false);
	const [deleteAccountConfirmationVisible, showDeleteAccountConfirmation] = useState(false);
	const [deletePaymentConfirmationVisible, showDeletePaymentConfirmation] = useState(false);
	const [deleteInProgress, setDeleteInProgress] = useState(false);

	// Create references for elements
	const stripePromiseRef = useRef(null);

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get current user
	let userObj = useSelector((state) => state.user.value);
	if (!userObj && user) userObj = user;

	// Get query parameters from hook
	const [searchParams] = useSearchParams();
	let isMobileApp = searchParams.get('mobileapp') === 'true';
	if (!isMobileApp) {
		const mobileAppCookie = getCookie(VIEW_MODE_COOKIE);
		if (mobileAppCookie === 'mobileapp') isMobileApp = true;
	}

	// Get actions from hooks
	const dispatch = useDispatch();

	// Generate country flag for phone number
	const generatePhoneFlag = (phone) => {
		const phoneNumber = parsePhoneNumber(phone);
		if (phoneNumber) {
			return `https://catamphetamine.gitlab.io/country-flag-icons/3x2/${phoneNumber.country}.svg`;
		}
		return 'https://catamphetamine.gitlab.io/country-flag-icons/3x2/US.svg';
	};

	// Generate payment method name
	const generatePaymentMethodName = (card) => {
		const comparison = card?.funding || card?.cardType.toLowerCase();
		if (comparison === 'credit' || comparison === 'credit_card') {
			return 'Credit Card';
		} if (comparison === 'debit' || comparison === 'debit_card') {
			return 'Debit Card';
		} if (comparison === 'prepaid') {
			return 'Prepaid Card';
		}
		return 'Card';
	};

	// Generate payment expiration date
	const generatePaymentExpirationDate = (card) => {
		let expirationDate = '';
		if (card?.exp_month) {
			expirationDate += `${card?.exp_month}`;
			if (expirationDate.length === 1) expirationDate = `0${expirationDate}`;
		}
		if (card?.exp_year) {
			expirationDate += `/${card?.exp_year}`;
		}
		if (card?.expirationDate) {
			expirationDate = card?.expirationDate.replace('-', '/');
		}
		return expirationDate;
	};

	// Generate payment brand image
	const generatePaymentBrandImage = (card) => {
		switch (card?.brand?.toLowerCase()) {
			case 'amex':
				return `${process.env.CDN_URL}/public/assets/icons/amex.png`;
			case 'diners':
				return `${process.env.CDN_URL}/public/assets/icons/diners-club.png`;
			case 'discover':
				return `${process.env.CDN_URL}/public/assets/icons/discover.png`;
			case 'jcb':
				return `${process.env.CDN_URL}/public/assets/icons/jcb.png`;
			case 'mastercard':
				return `${process.env.CDN_URL}/public/assets/icons/mastercard.png`;
			case 'unionpay':
				return `${process.env.CDN_URL}/public/assets/icons/china-union.png`;
			case 'visa':
				return `${process.env.CDN_URL}/public/assets/icons/visa.png`;
			default:
				return `${process.env.CDN_URL}/public/assets/icons/credit-card.png`;
		}
	};

	// Handle refreshing account
	const promptRefreshAccount = async () => {
		try {

			// Show refreshing modal
			showRefreshModal(true);

			// Refresh Disney account
			const { data: { user: refreshUser } } = await refreshDisneyAccount();

			// Update user
			dispatch(updateUser({ ...refreshUser, lastFetched: (new Date()).toISOString() }));

			// Hide refreshing modal
			showRefreshModal(false);

		} catch (e) {

			// Show error
			toastError(uiMode, 'Whoops. We\'re having trouble refreshing your Disney account information. Please try again.');

			// Hide refreshing modal
			showRefreshModal(false);
		}
	};

	// Handle prompt to delete account
	const promptDeleteAccount = async () => {

		// Show confirmation modal
		showDeleteAccountConfirmation(true);
	};

	// Handle delete account
	const handleDeleteAccount = async () => {
		try {

			// Update deletion state
			setDeleteInProgress(true);

			// Delete account
			await deleteUserAccount();

			// Show success state
			toastSuccess(uiMode, 'Your account has successfully been deleted. We\'ll redirect you back home.');

			// Log user out
			setTimeout(() => {
				window.location = '/logout';
			}, 2500);

		} catch (e) {

			// Show error
			toastError(uiMode, 'Whoops. We\'re having trouble deleting your account. Please try again.');

			// Update deletion state
			setDeleteInProgress(false);
		}
	};

	// Handle prompt to delete payment method
	const promptDeletePaymentMethod = async () => {

		// Show confirmation modal
		showDeletePaymentConfirmation(true);
	};

	// Handle delete payment method
	const handleDeletePaymentMethod = async () => {
		try {

			// Update deletion state
			setDeleteInProgress(true);

			// Delete payment method
			const { data } = await removePaymentMethod({ id: userObj.paymentMethod.id });

			// Update user
			dispatch(updateUser({ ...data.user, lastFetched: (new Date()).toISOString() }));

			// Show success state
			toastSuccess(uiMode, 'Your payment method has successfully been deleted.');

			// Update deletion state
			setDeleteInProgress(false);

			// Hide modal
			showDeletePaymentConfirmation(false);

		} catch (e) {

			// Show error
			toastError(uiMode, 'Whoops. We\'re having trouble deleting your payment method. Please try again.');

			// Update deletion state
			setDeleteInProgress(false);
		}
	};

	// Handle update user
	const handleUpdateUser = (newUser) => {

		// Update user
		dispatch(updateUser({ ...newUser, lastFetched: (new Date()).toISOString() }));
	};

	// Handle changes to current tab
	useEffect(() => {

		// Update url
		window.history.replaceState(null, null, `${stateLocale.localePath}/me#${currentTab}`);

	}, [currentTab]);

	// Handle component initialization
	useEffect(() => {

		// Load Stripe
		stripePromiseRef.current = loadStripe(process.env.STRIPE_PUBLIC_KEY);

		// Handle query actions
		const action = searchParams.get('action');
		if (action === QUERY_ACTIONS.ADD_CARD) {
			setPaymentMethodModalOpen(true);
		} else if (action === QUERY_ACTIONS.UPDATE_DIS_ACCOUNT) {
			setEditDisneyAccountModalOpen(true);
		}
	}, []);

	// Handle render content
	const renderContent = () => (
		<S.Wrapper>
			<S.LeftContainer>

				{/* Profile Image Container */}
				<S.ProfileImageContainer>

					{/* Profile Image */}
					<S.ProfileImage image={renderProfileImage(userObj?.profileImage, '600x600', isMobileApp)} />

					{/* Account Action */}
					<S.AccountAction
						onMouseEnter={() => {
							clearTimeout(optionsDropdownTimer);
							setShowOptionsDropdown(true);
						}}
						onMouseLeave={() => {
							clearTimeout(optionsDropdownTimer);
							optionsDropdownTimer = setTimeout(() => {
								setShowOptionsDropdown(false);
							}, navigationDropdownDelay);
						}}
					>

						{/* Dropdown Toggle */}
						<S.DropdownToggle size={1.0} icon={['fas', 'pen']} />

						{/* Navigation Dropdown */}
						<S.NavigationDropdown
							className={showOptionsDropdown ? 'animate auth show' : 'auth animate'}
							adjustOnMobile
							flipDirection
							items={[
								{
									icon: ['fas', 'arrow-up-from-bracket'],
									title: 'Update Image',
									action: () => {
										setShowOptionsDropdown(false);
										setProfileImageModalOpen(true);
									}
								},
								{
									icon: ['far', 'pen-to-square'],
									title: 'Edit Profile',
									action: () => {
										setShowOptionsDropdown(false);
										setEditProfileModalOpen(true);
									}
								},
								{
									icon: ['fas', 'rotate-right'],
									title: 'Change Password',
									action: () => {
										setShowOptionsDropdown(false);
										setChangePasswordModalOpen(true);
									}
								}
							]}
							footerItems={[
								{
									icon: ['fa', 'arrow-right-from-bracket'],
									title: 'Log out',
									action: () => { window.location = '/logout'; }
								}
							]}
						/>
					</S.AccountAction>
				</S.ProfileImageContainer>

				{/* Name */}
				<Typography tag="h4" weight="semibold">{userObj?.name}</Typography>

				{/* Profile Details */}
				<S.ProfileDetails>
					<Typography tag="p">{userObj?.email}</Typography>
					<S.PhoneContainer>
						<S.PhoneFlag src={generatePhoneFlag(userObj?.phone)} alt="Phone number country flag" />
						<Typography tag="p">{formatPhoneNumber(userObj?.phone)}</Typography>
					</S.PhoneContainer>
				</S.ProfileDetails>

				{/* Profile Navigation */}
				<S.NavigationContainer>

					{/* Profile Option */}
					<S.NavigationOption onClick={() => { setCurrentTab(TABS.PROFILE); }} isActive={currentTab === TABS.PROFILE} className="animate">
						<Typography weight="regular" tag="h4">
							My Profile
						</Typography>
					</S.NavigationOption>

					{/* Notification Option */}
					<S.NavigationOption onClick={() => { setCurrentTab(TABS.NOTIFICATIONS); }} isActive={currentTab === TABS.NOTIFICATIONS} className="animate">
						<Typography weight="regular" tag="h4">
							Notifications
						</Typography>
					</S.NavigationOption>

					{/* Account Option */}
					<S.NavigationOption onClick={() => { setCurrentTab(TABS.ACCOUNT); }} isActive={currentTab === TABS.ACCOUNT} className="animate">
						<Typography weight="regular" tag="h4">
							Account
						</Typography>
					</S.NavigationOption>

				</S.NavigationContainer>
			</S.LeftContainer>
			<S.RightContainer>

				{/* Profile Tab */}
				<S.TabContainer isActive={currentTab === TABS.PROFILE}>

					{/* Trips Section */}
					<S.ProfileSection>

						{/* Title */}
						<S.TitleContainer>
							<Typography tag="h4">My Trips</Typography>
						</S.TitleContainer>

						{/* Trips */}
						<MyTrips showAddNewAction={false} />

					</S.ProfileSection>

					{/* Friends & Family Section */}
					<S.ProfileSection>

						{/* Title */}
						<S.TitleContainer>
							<Typography tag="h4">Friends & Family</Typography>
						</S.TitleContainer>

						{/* Friends & Family */}
						<FriendsFamily />

					</S.ProfileSection>
				</S.TabContainer>

				{/* Notification Tab */}
				<S.TabContainer isActive={currentTab === TABS.NOTIFICATIONS}>

					{/* Notification Settings */}
					<NotificationSettings isDark preferences={userObj?.notificationPreferences} />

				</S.TabContainer>

				{/* Account Tab */}
				<S.TabContainer isActive={currentTab === TABS.ACCOUNT}>

					{/* Pixie Dust Account Section */}
					<S.ProfileSection>
						<S.AccountContainer>

							{/* Account Information */}
							<S.AccountCardContainer>

								{/* Title Container */}
								<S.TitleContainer>

									{/* Title */}
									<Typography tag="h4">My Account</Typography>

									{/* Spacer */}
									<S.TitleSpacer />

									{/* Edit Button */}
									<S.EditButton variant="text" onClick={() => { setEditEmailModalOpen(true); }}>
										<FontAwesomeIcon icon={['fa', 'pen']} />
										<Typography variation="1" weight="semibold">Edit</Typography>
									</S.EditButton>

								</S.TitleContainer>

								{/* Account Information Card */}
								<S.AccountCard $shouldCollapse>
									<S.CardCenter>

										{/* Email */}
										<Typography tag="h5" variation="3" weight="semibold" className="fieldTitle">
											Email
										</Typography>
										<Typography tag="p" variation="1" weight="medium" truncate>
											{userObj?.email}
										</Typography>

										{/* Password */}
										<Typography tag="h5" variation="3" weight="semibold" className="fieldTitle">
											Password
										</Typography>
										<Typography tag="p" variation="1" truncate>
											***********
										</Typography>

									</S.CardCenter>

									{/* Reset Password Button */}
									<S.ResetButton variant="text" onClick={() => { setChangePasswordModalOpen(true); }}>
										<FontAwesomeIcon icon={['fas', 'rotate-right']} />
										<Typography variation="1" weight="semibold">Change</Typography>
									</S.ResetButton>

								</S.AccountCard>
							</S.AccountCardContainer>

							{/* Payment Method */}
							<S.AccountCardContainer>

								{/* Title Container */}
								<S.TitleContainer>

									{/* Spacer */}
									<S.TitleSpacer />

									{/* Remove Button */}
									{userObj.paymentMethod && (
										<S.RemoveButton
											variant="text"
											variation="warning"
											onClick={promptDeletePaymentMethod}
										>
											<FontAwesomeIcon icon={['far', 'trash-can']} />
											<Typography variation="1" weight="semibold">Remove</Typography>
										</S.RemoveButton>
									)}

									{/* Edit Button */}
									{userObj.paymentMethod && (
										<S.EditButton
											variant="text"
											onClick={() => {
												setActivePaymentMethod(userObj.paymentMethod);
												setPaymentMethodModalOpen(true);
											}}
										>
											<FontAwesomeIcon icon={['fa', 'pen']} />
											<Typography variation="1" weight="semibold">Edit</Typography>
										</S.EditButton>
									)}

								</S.TitleContainer>

								{/* Payment Method Card */}
								{userObj.paymentMethod ? (
									<S.AccountCard id="payment" $shouldCollapse={false}>
										<S.CardLeft $isFlex>
											<S.PaymentTop>
												<Typography tag="h5" variation="3" weight="semibold">
													{generatePaymentMethodName(userObj.paymentMethod?.card)}
												</Typography>
												<Typography tag="p" variation="1" className="monospace" style={{ marginTop: '6px' }}>
													**** **** ****
													{' '}
													{userObj.paymentMethod?.card?.last4}
												</Typography>
											</S.PaymentTop>
											<Typography tag="p" variation="2" className="monospace secondary">
												{`Expires ${generatePaymentExpirationDate(userObj.paymentMethod?.card)}`}
											</Typography>
										</S.CardLeft>
										<S.CardRight $isFlex $shouldExpand={false}>
											<S.CardLogo src={generatePaymentBrandImage(userObj.paymentMethod?.card)} alt="Payment method brand logo" />
										</S.CardRight>
									</S.AccountCard>
								) : (
									<S.AddMethodPrompt
										className="animate"
										onClick={() => {
											setActivePaymentMethod(null);
											setPaymentMethodModalOpen(true);
										}}
									>
										<FontAwesomeIcon icon={['fa', 'circle-plus']} />
										<Typography weight="medium">Add a payment method</Typography>
										<S.AddMethodPromptBackground />
									</S.AddMethodPrompt>
								)}

							</S.AccountCardContainer>
						</S.AccountContainer>
					</S.ProfileSection>

					{/* Disney Account Account Section */}
					<S.ProfileSection>
						<S.AccountContainer>

							{/* Disney Account Information */}
							<S.AccountCardContainer>

								{/* Title Container */}
								<S.TitleContainer>

									{/* Title */}
									<Typography tag="h4">Disney Account</Typography>

									{/* Spacer */}
									<S.TitleSpacer />

									{/* Edit Button */}
									<S.EditButton variant="text" onClick={() => { setEditDisneyAccountModalOpen(true); }}>
										<FontAwesomeIcon icon={['fa', 'pen']} />
										<Typography variation="1" weight="semibold">Edit</Typography>
									</S.EditButton>

								</S.TitleContainer>

								{/* Disney Account Information Card */}
								<S.AccountCard $shouldCollapse>
									<S.CardCenter>

										{/* Email */}
										<Typography tag="h5" variation="3" weight="semibold" className="fieldTitle">
											Email
										</Typography>
										<Typography tag="p" variation="1" weight="medium" truncate>
											{userObj?.disneyCredentials?.username}
										</Typography>

										{/* Password */}
										<Typography tag="h5" variation="3" weight="semibold" className="fieldTitle">
											Password
										</Typography>
										<Typography tag="p" variation="1" truncate>
											********
										</Typography>

										{/* Refresh Button */}
										<Button
											onClick={promptRefreshAccount}
											variation="light"
											variant="solid"
											className="refreshButton"
										>
											<Typography variation="2" weight="semibold">Refresh account</Typography>
										</Button>

									</S.CardCenter>
								</S.AccountCard>
							</S.AccountCardContainer>

							{/* Payment Method */}
							<S.AccountCardContainer>

								{/* Title Container */}
								<S.TitleContainer>

									{/* Spacer */}
									<S.TitleSpacer />

									{/* Update Button */}
									{userObj.disneyDefaultPaymentMethod && (
										<S.UpdateButton
											variant="text"
											onClick={() => {
												window.open('https://disneyworld.disney.go.com/profile/payment-methods', '_blank');
											}}
										>
											<Typography variation="1" weight="semibold">Update</Typography>
											<FontAwesomeIcon icon={['fas', 'arrow-up-right-from-square']} />
										</S.UpdateButton>
									)}

								</S.TitleContainer>

								{/* Payment Method Card */}
								{userObj.disneyDefaultPaymentMethod ? (
									<S.AccountCard id="payment" $shouldCollapse={false}>
										<S.CardLeft $isFlex>
											<S.PaymentTop>
												<Typography tag="h5" variation="3" weight="semibold">
													{generatePaymentMethodName(userObj.disneyDefaultPaymentMethod)}
												</Typography>
												<Typography tag="p" variation="1" className="monospace" style={{ marginTop: '6px' }}>
													**** **** ****
													{' '}
													{userObj.disneyDefaultPaymentMethod.displayNumber.slice(-4)}
												</Typography>
											</S.PaymentTop>
											<Typography tag="p" variation="2" className="monospace secondary">
												{`Expires ${generatePaymentExpirationDate(userObj.disneyDefaultPaymentMethod)}`}
											</Typography>
										</S.CardLeft>
										<S.CardRight $isFlex $shouldExpand={false}>
											<S.CardLogo src={generatePaymentBrandImage(userObj.disneyDefaultPaymentMethod)} alt="Payment method brand logo" />
										</S.CardRight>
									</S.AccountCard>
								) : (
									<S.AddMethodPrompt
										className="animate"
										onClick={() => {
											window.open('https://disneyworld.disney.go.com/profile/payment-methods', '_blank');
										}}
									>
										<FontAwesomeIcon icon={['fa', 'circle-plus']} />
										<Typography weight="medium">Add a Disney payment method</Typography>
										<S.AddMethodPromptBackground />
									</S.AddMethodPrompt>
								)}

							</S.AccountCardContainer>
						</S.AccountContainer>
					</S.ProfileSection>

					{/* Payment History Section */}
					<S.ProfileSection>

						{/* Title */}
						<S.TitleContainer>
							<Typography tag="h4">Payment History</Typography>
						</S.TitleContainer>

						{/* Payment History Card */}
						<S.AccountCard $shouldCollapse>
							<S.CardLeft>
								<PaymentHistory paymentHistory={userObj.paymentHistory} />
							</S.CardLeft>
						</S.AccountCard>
					</S.ProfileSection>

					{/* Danger Zone Section */}
					<S.ProfileSection>

						{/* Title */}
						<S.TitleContainer>
							<Typography tag="h4">Danger Zone</Typography>
						</S.TitleContainer>

						{/* Danger Zone Card */}
						<S.AccountCard $shouldCollapse>

							{/* Delete Account */}
							<S.CardLeft>
								<Typography tag="h5" weight="semibold">
									<FontAwesomeIcon className="dangerIcon" icon={['fas', 'triangle-exclamation']} />
									Delete your account
								</Typography>
								<Typography tag="p" variation="2">
									Once you request to delete your account, there is no going back. Please be certain.
								</Typography>
							</S.CardLeft>
							<S.CardRight $shouldExpand>
								<Button
									onClick={promptDeleteAccount}
									variation="light"
									variant="solid"
									className="dangerButton"
								>
									<Typography variation="2" weight="semibold">Delete account</Typography>
								</Button>
							</S.CardRight>
						</S.AccountCard>
					</S.ProfileSection>
				</S.TabContainer>
			</S.RightContainer>

			{/* Modals */}
			<LoadingModal
				prompt="Refreshing My Disney Experience account..."
				isOpen={refreshModalVisible}
				handleClose={() => { showRefreshModal(false); }}
			/>
			<ConfirmationModal
				isOpen={deleteAccountConfirmationVisible}
				handleClose={() => { showDeleteAccountConfirmation(false); }}
				type="danger"
				title="Delete your account?"
				message="Once you delete your account, there is no going back. Please be certain."
				primaryButtonTitle="Yes, delete"
				secondaryButtonTitle="Cancel"
				isLoading={deleteInProgress}
				primaryButtonAction={handleDeleteAccount}
				secondaryButtonAction={() => { showDeleteAccountConfirmation(false); }}
			/>
			<ConfirmationModal
				isOpen={deletePaymentConfirmationVisible}
				handleClose={() => { showDeletePaymentConfirmation(false); }}
				type="danger"
				title="Delete your payment method?"
				message="Once you delete your payment method, you won't be able to schedule any reservations until you add a new one."
				primaryButtonTitle="Yes, delete"
				secondaryButtonTitle="Cancel"
				isLoading={deleteInProgress}
				primaryButtonAction={handleDeletePaymentMethod}
				secondaryButtonAction={() => { showDeletePaymentConfirmation(false); }}
			/>
			<UpdateEmailModal
				isOpen={editEmailModalOpen}
				handleClose={() => { setEditEmailModalOpen(false); }}
				updateUser={handleUpdateUser}
			/>
			<UpdateProfileModal
				isOpen={editProfileModalOpen}
				handleClose={() => { setEditProfileModalOpen(false); }}
				updateUser={handleUpdateUser}
			/>
			<UpdatePasswordModal
				isOpen={changePasswordModalOpen}
				handleClose={() => { setChangePasswordModalOpen(false); }}
			/>
			<UpdateDisneyAccountModal
				isOpen={editDisneyAccountModalOpen}
				handleClose={() => { setEditDisneyAccountModalOpen(false); }}
				updateUser={handleUpdateUser}
			/>
			<ProfileImageModal
				isOpen={profileImageModalOpen}
				handleClose={() => { setProfileImageModalOpen(false); }}
				updateUser={handleUpdateUser}
			/>
			<Elements
				stripe={stripePromiseRef.current}
				options={{
					fonts: [{
						cssSrc: 'https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap'
					}]
				}}
			>
				<PaymentMethodModal
					isOpen={paymentMethodModalOpen}
					handleClose={() => { setPaymentMethodModalOpen(false); }}
					updateUser={handleUpdateUser}
					paymentMethod={activePaymentMethod}
				/>
			</Elements>

		</S.Wrapper>
	);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} />

			{/* Component Content */}
			<AppNavigation containerClassName="gradientBackground" isFixed={false}>
				{renderContent()}
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

Profile.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
	user: PropTypes.shape()
};
Profile.defaultProps = {
	meta: {},
	locale: {},
	user: null
};


/**
 * Exports
 */

export default Profile;
