/*
 * File: image.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on March 8, 2023 at 8:02 PM
 * Copyright © 2023 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: June 24, 2023 at 7:46 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Utilities
 */

export const dataURItoBlob = (dataURI) => {

	// Convert base64 to raw binary data held in a string
	const byteString = atob(dataURI.split(',')[1]);

	// Separate out the mime component
	const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

	// Write the bytes of the string to an ArrayBuffer
	const ab = new ArrayBuffer(byteString.length);
	const ia = new Uint8Array(ab);
	for (let i = 0; i < byteString.length; i += 1) {
		ia[i] = byteString.charCodeAt(i);
	}

	// Return image blob
	return new Blob([ab], { type: mimeString });
};


export const renderProfileImage = (profileImage, size, useInternal = false) => {
	if (!profileImage) return '';
	if (useInternal) {
		return profileImage.isDisneyDefault ? profileImage.internal[size] : profileImage[size];
	}
	return profileImage[size];
};
