/*
 * File: PaymentMethodStep.styles.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:25 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: March 20, 2023 at 8:59 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';
import { Button, IconButton } from '../../../../components';

// Styles
import { ColorValuesCreator } from '../../../../styles/colors';

// Create color values
const ColorValues = ColorValuesCreator();


/**
 * Styles
 */

export const StepContainer = styled.div``;

export const Form = styled.form`
	width: 100%;
	max-width: 450px;
	margin: 0px auto;

	@media only screen {
		padding-top: 40px;
		padding-bottom: 50px;
	}
	@media only screen and (min-width: 40em) {
		padding-top: 110px;
		padding-bottom: 60px;
	}
	h2 {
		text-align: left;
		color: ${ColorValues.white};
		margin: 10px 0px 10px;
	}
	p {
		text-align: left;
		color: ${ColorValues.white};
   		margin-bottom: 20px;
	}
	button {
		width: 100%;
	}
	.spacer {
		margin-top: 16px;
	}
	.startSpacer {
		margin-top: 12px;
	}
	.buttonPadding {
		padding-right: 105px;
	}
`;

export const IconContainer = styled.div``;

export const Icon = styled.div`
	display: flex;
	width: 46px;
	height: 46px;
	background-color: ${ColorValues.white};
	border-radius: 50%;
	align-items: center;
	justify-content: center;

	svg {
		color: ${ColorValues.brandPrimaryDarkBlue};
		font-size: 1.2rem;
	}
`;

export const ElementContainer = styled.div`
	padding: 20px 18px;
	font-size: 1rem;
	border-radius: 6px;
`;

export const ActionContainer = styled.div`
	margin: 40px 0px 65px 0px;

	button {
		flex-grow: 1;
   	 	flex-basis: 0;
		padding-top: 0.85rem;
		padding-bottom: 0.85rem;
	}
`;

export const PromoCodeContainer = styled.div`
	display: flex;
	margin: 20px 0px 0px 0px;
`;

export const PromoCode = styled.div`
	background-color: ${ColorValues.white};
	border-radius: 6px;
	padding: 10px;
	display: flex;
	align-items: center;
	gap: 6px;

	span {
		color: ${ColorValues.dark};
		font-size: 0.85rem;
		flex-shrink: 0;
	}
`;

export const RemoveButton = styled(IconButton)`
	svg {
		color: ${({ theme }) => theme.secondaryText};
	}
`;

export const PromoButton = styled(Button)`
	position: absolute;
	width: auto !important;
	top: 13px;
	right: 13px;
`;
