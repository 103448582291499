/*
 * File: SecurityPromise.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 8, 2022 at 3:21 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: January 7, 2023 at 11:07 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Utilities
import { createStateLocale } from '../../../../utilities/locale';

// Components
import {
	Meta, AppNavigation, LegalNavigation, Typography, Emoji, LocaleLink
} from '../../../../components';

// Styles
import * as S from './SecurityPromise.styles';
import { HighlightSection, HighlightTitle } from '../../../../components/LegalNavigation/LegalNavigation.styles';


/**
 * Component
 */

const SecurityPromise = ({ meta, locale }) => {

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Handle component initialization
	useEffect(() => {}, []);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} />

			{/* Component Content */}
			<AppNavigation hidePadding>
				<LegalNavigation>
					<S.Wrapper>

						{/* Title */}
						<Typography tag="h1" weight="bold">
							Security Promise
						</Typography>

						{/* TL;DR */}
						<HighlightSection>
							<HighlightTitle tag="h5" weight="bold">
								TL;DR
								<Emoji symbol="👀" label="smile" size={1.5} />
							</HighlightTitle>
							<Typography tag="p" weight="light">
								At Pixie Dust, we use industry-standard security techniques to protect your data. These include encrypted data storage and transfer, frequent backups, and regular platform updates. We also encourage you to take time to secure your own Pixie Dust account with a strong password and up-to-date contact information.
							</Typography>
						</HighlightSection>

						{/* Last Updated */}
						<Typography tag="p" weight="light" fontStyle="italic">
							Last updated January 2023
						</Typography>

						{/* Content */}
						<Typography tag="p" weight="light">
							At Pixie Dust, privacy and data security are important to us, and both are paramount to the safety and security of our users and visitors. So is transparency. This promise aims to serve as a clear, transparent and easily digestible summary of Pixie Dust’s long-standing policies with respect to data privacy, security, and protection.
						</Typography>
						<Typography tag="p" weight="light">
							Below are a few examples of our active security policies and precautions at Pixie Dust. These measures, along with many others, serve to create a secure, reliable, and safe system for our users and community.
						</Typography>

						{/* Strong Login Details */}
						<br />
						<Typography tag="h3" weight="bold">
							Strong Login Details
						</Typography>
						<Typography tag="p" weight="light">
							We generate passwords using a strong password generator and avoid predictable usernames and credentials. This approach makes it much harder for “brute force” attacks to successfully gain access to our platform. We don’t just apply this principle to website logins; the same level of security is applied to databases and account logins as well.
						</Typography>

						{/* Regular Software Upgrades */}
						<br />
						<Typography tag="h3" weight="bold">
							Regular Software Upgrades
						</Typography>
						<Typography tag="p" weight="light">
							We strive to ensure that the software that powers our platform is kept up to date, minimizing the risk of security vulnerabilities and reducing the chance of exploitation. Updates are often made in response to new security fixes and overall improvements.
						</Typography>

						{/* Encrypted Infrastructure */}
						<br />
						<Typography tag="h3" weight="bold">
							Encrypted Infrastructure
						</Typography>
						<Typography tag="p" weight="light">
							Our databases and data storage volumes are designed to encrypt data at rest. We endeavor to encrypt all data submitted to Pixie Dust, including account information, emails, records, and feedback, and make it visible only to internal Pixie Dust administrators and third-party partners pursuant to our
							{' '}
							<LocaleLink to="/legal/privacy" target="_blank">Privacy Policy</LocaleLink>
							.
						</Typography>

						{/* Infrastructure Backups */}
						<br />
						<Typography tag="h3" weight="bold">
							Infrastructure Backups
						</Typography>
						<Typography tag="p" weight="light">
							Our system is designed to capture and encrypt all databases and preferences on a daily basis and store backups for at least one week. This provides extra insurance in the unlikely event of serious infrastructure failure.
						</Typography>

						{/* Secure Hosting Over TLS */}
						<br />
						<Typography tag="h3" weight="bold">
							Secure Hosting Over TLS
						</Typography>
						<Typography tag="p" weight="light">
							All of our platform websites, APIs, and databases are hosted on secure servers designed to run an TLS/SSL certificate. This essentially means that information is encrypted when transferred over the wire. This encryption makes it much more difficult for unscrupulous hackers to see those details.
						</Typography>
						<Typography tag="p" weight="light">
							We also run active monitoring tools to protect our users from things like malware, suspicious code and unexpected file changes, as well as logging platform activity to flag any unusual behavior.
						</Typography>

						{/* Securing Your Account */}
						<br />
						<Typography tag="h3" weight="bold">
							Securing Your Account
						</Typography>
						<Typography tag="p" weight="light">
							As we continuously work to keep our community’s information secure, we also empower people with tools and information for good security habits. Some tips:
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="bold">Keep your contact information updated:</Typography>
							{' '}
							Link your most up to date phone number and/or email address to your Pixie Dust account. Email may be a method of alerting you of any suspicious activity on your account and can be used in confirming your identity if you ever get locked out.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="bold">Create a strong and unique password:</Typography>
							{' '}
							Choose a password that’s memorable for you but difficult for others to guess. Unique means having a different password for each of your accounts, and strong means making the password complex by using at least 12 characters including numbers and symbols. Be sure to set up strong and unique passwords/PINs for all of the devices you use to access Pixie Dust such as laptop, phone, and other mobile devices. A password manager can help take the guesswork out of remembering dozens of unique usernames and passwords.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="bold">Security alerts:</Typography>
							{' '}
							Our teams continuously monitor for suspicious or unauthorized activity. Keep track of recent unusual security events that you do not recognize.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="bold">Verify your devices:</Typography>
							{' '}
							You can view phones and other mobile devices that are currently using or have recently accessed your Pixie Dust account. This will display all devices associated with your account, how you logged into the account, and when. Check this information to make sure no one else has signed in to your account.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="bold">Avoid phishing attacks:</Typography>
							{' '}
							Phishing is a common method attackers use to trick others into giving up their personal information such as passwords, credit card numbers, social security, or other sensitive data. Never trust any third-party websites that promise to give away free likes, fans, followers, or other incentives as they may be able to take your login info. Be sure to always verify any links sent to you via private message before accessing the page.
						</Typography>
						<Typography tag="p" weight="light" fontStyle="italic">
							Don’t be fooled if someone contacts you pretending to be from Pixie Dust. Remember: any legitimate email from our teams will never ask you for your password!
						</Typography>
						<Typography tag="p" weight="light">
							If you find videos or receive messages on Pixie Dust that you think might be spam or phishing, please report them so our teams can continue to keep our community safe. You can also report videos by holding down on the detail indicator on the video and tapping ‘report.’
						</Typography>

						{/* In Closing */}
						<br />
						<Typography tag="h3" weight="bold">
							In Closing
						</Typography>
						<Typography tag="p" weight="light">
							At Pixie Dust, we are dedicated to earning and keeping your trust as you use our platform and participate in our community. Our goal is to make it easy for you to understand your privacy choices and we are fully committed to providing the data privacy and security that you expect. As we strive to maintain the highest standards of security and transparency, we welcome your feedback, comments, and questions at
							{' '}
							<LocaleLink to="mailto:hello@pixiedustguide.com?Subject=Security Question">hello@pixiedustguide.com</LocaleLink>
							.
						</Typography>
					</S.Wrapper>
				</LegalNavigation>
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

SecurityPromise.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape()
};
SecurityPromise.defaultProps = {
	meta: {},
	locale: {}
};


/**
 * Exports
 */

export default SecurityPromise;
