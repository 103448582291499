/*
 * File: Pagination.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: March 13, 2023 at 8:07 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import { useMediaQuery } from 'beautiful-react-hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Constants
import { mobileBreakpoint } from '../../styles/constants';

// Styles
import * as S from './Pagination.styles';


/**
 * Component
 */

export const Pagination = ({
	className,
	currentPage,
	totalPages,
	updatePage,
	position
}) => {

	// Check if mobile screen size
	const isMobile = useMediaQuery(`(max-width: ${mobileBreakpoint}em)`);

	// Handle action on page click
	const handlePageClick = (event) => {

		// Update page
		updatePage(event.selected);
	};

	// Handle actions on component load
	useEffect(() => {}, []);

	// Render component
	return (
		<S.Wrapper className={className} $position={position}>
			<ReactPaginate
				breakLabel={<S.BreakLabel>...</S.BreakLabel>}
				nextLabel={<S.NextLabel><FontAwesomeIcon icon={['fas', 'angle-right']} /></S.NextLabel>}
				onPageChange={handlePageClick}
				pageRangeDisplayed={isMobile ? 0 : 3}
				marginPagesDisplayed={isMobile ? 1 : 2}
				pageCount={totalPages}
				previousLabel={<S.PreviousLabel><FontAwesomeIcon icon={['fas', 'angle-left']} /></S.PreviousLabel>}
				initialPage={currentPage}
				disableInitialCallback
				renderOnZeroPageCount={null}
				breakClassName="animate"
				pageClassName="animate"
				pageLinkClassName="animate"
				previousClassName="animate"
				previousLinkClassName="animate"
				nextClassName="animate"
				nextLinkClassName="animate"
			/>
		</S.Wrapper>
	);
};


/**
 * Configuration
 */

Pagination.displayName = 'Pagination';
Pagination.propTypes = {
	className: PropTypes.string,
	currentPage: PropTypes.number,
	totalPages: PropTypes.number,
	updatePage: PropTypes.func,
	position: PropTypes.oneOf(['left', 'center', 'right'])
};
Pagination.defaultProps = {
	className: null,
	currentPage: 0,
	totalPages: 1,
	updatePage: null,
	position: 'center'
};
