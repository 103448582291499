/*
 * File: Routes.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on June 16, 2022 at 1:03 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: September 5, 2023 at 10:36 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import { matchPath } from 'react-router-dom';

// Utilities
import { createLocaleVariants } from '../utilities/locale';

// Slice
import { initialState as initialLocale } from '../store/slices/locale/locale.slice';

// Navigation
import { PublicRouteWrapper } from '../navigation/PublicRouteWrapper';
import { AuthRouteWrapper } from '../navigation/AuthRouteWrapper';
import { UserRouteWrapper } from '../navigation/UserRouteWrapper';

// Components
import {
	ContactPreferences,
	Dashboard,
	DynamicGuide,
	Error,
	ForgotPassword,
	Home,
	Impersonation,
	Join,
	Login,
	TripDetail,
	TripFlow,
	ResetPassword,
	Profile,
	LegalOverview,
	CookiePolicy,
	PrivacyPolicy,
	SecurityPromise,
	Terms,
	About,
	Contact,
	Blog,
	BlogSearch,
	BlogStory,
	BlogTopic,
	FAQ,
	UpdateDisneyAccount
} from './pages';

// Constants
import { ROLES } from '../../Constants';


/**
 * Helpers
 */

const filterProps = (path, props) => {

	// If data is undefined, return props
	if (!props || !props.data) return props;

	// Search for route path
	const routes = Object.keys(props.data).filter((key) => key.startsWith('/'));
	const matchRoute = routes.find((route) => matchPath(path, route));

	// Check for exact match
	let exactMatch = true;
	if (typeof window !== 'undefined') {
		const fullPath = (props?.locale?.url?.fullPath !== '' ? props?.locale?.url?.fullPath?.replace(/\/$/, '') : props?.locale?.url?.fullPath) || '';
		let basePath = fullPath.startsWith(props?.locale?.localePath) ? fullPath.replace(props?.locale?.localePath, '') : fullPath;
		if (basePath === '') basePath = '/';
		exactMatch = fullPath === window.location.pathname || `${fullPath}/` === window.location.pathname || basePath === window.location.pathname;
	}

	// Return data
	return (props.data ? {
		...props,
		data: {
			...props?.data.global,
			...matchRoute && exactMatch ? props?.data[matchRoute] : undefined
		}
	} : props);
};

const prepareComponent = (routeObj) => (Array.isArray(routeObj) ? routeObj.map((route) => ({
	...route,
	component: route.component(route.path)
})) : {
	...routeObj,
	component: routeObj.component(routeObj.path)
});


/**
 * Routes
 */

export const Routes = (props) => {

	// Get current locale from props
	const localeObj = props?.locale?.localePath != null ? props?.locale : initialLocale.value;

	// Get current meta from props
	const metaObj = props?.meta;

	// Get current user from props
	const userObj = props?.user;

	// Render routes
	return [].concat(...[

		/**
		 * Marketing Pages
		 */

		prepareComponent(createLocaleVariants({
			path: '/',
			exact: true,
			shouldIndex: true,
			priority: '1.00',
			functions: [],
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<Home
							{...filterProps(path, props)}
							meta={{
								title: 'Schedule Your Disney World & Disneyland Lightning Lanes • Pixie Dust',
								description: 'Tired of waking up early to reserve your Disney World and Disneyland experiences? Let Pixie Dust do it for you!',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/about',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			functions: [],
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<About
							{...filterProps(path, props)}
							meta={{
								title: 'About Us • Pixie Dust',
								description: 'Pixie Dust allows you to schedule your Disney Lightning Lanes weeks or months in advance.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/contact',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			functions: [],
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<Contact
							{...filterProps(path, props)}
							meta={{
								title: 'Contact Us • Pixie Dust',
								description: 'One of our team members will follow up shortly. In the meantime, check out our blog for other helpful topics.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/faq',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			functions: [],
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<FAQ
							{...filterProps(path, props)}
							meta={{
								title: 'Frequently Asked Questions about Pixie Dust',
								description: '',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),


		/**
		 * Dynamic Guide Pages
		 */

		prepareComponent(createLocaleVariants({
			path: '/guide/:slug/:operatorId',
			exact: true,
			shouldIndex: true,
			isDynamic: true,
			priority: '0.8',
			functions: [
				{ function: 'guide/getDynamicGuide', restrictToPath: true },
			],
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<DynamicGuide
							{...filterProps(path, props)}
							meta={{
								title: '{{guide.metaTitle}}',
								description: '{{guide.metaDescription}}',
								image: '{{guide.images.cover.1200}}',
								bodyClasses: 'hide-captcha',
								ogType: 'article',
								showArticle: true,
								fbSiteName: 'https://www.facebook.com/pixiedustguide',
								publishedTime: '{{guide.publishedTime}}',
								modifiedTime: '{{guide.modifiedTime}}',
								ogTitle: '{{guide.metaTitle}}',
								twTitle: '{{guide.metaTitle}}',
								ogDescription: '{{guide.metaDescription}}',
								twDescription: '{{guide.metaDescription}}',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),


		/**
		 * Blog Pages
		 */

		prepareComponent(createLocaleVariants({
			path: '/blog',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			functions: [
				{ function: 'blog/getBlogPosts', restrictToPath: true },
				{ function: 'blog/getBlogTopics', restrictToPath: false },
			],
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<Blog
							{...filterProps(path, props)}
							meta={{
								title: 'Our Blog • Pixie Dust',
								description: 'The best place to stay plugged in with the latest Pixie Dust stories, news, and events.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/story/:slug',
			exact: true,
			shouldIndex: true,
			isDynamic: true,
			priority: '0.8',
			functions: [
				{ function: 'blog/getBlogPost', restrictToPath: true },
			],
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<BlogStory
							{...filterProps(path, props)}
							meta={{
								title: '{{story.title}} • Pixie Dust Blog',
								description: '{{story.description}}',
								image: '{{story.featureImage.url}}',
								bodyClasses: 'hide-captcha',
								ogType: 'article',
								showArticle: true,
								fbSiteName: 'https://www.facebook.com/pixiedustguide',
								author: '{{story.author.name}}',
								publishedTime: '{{story.publishedTime}}',
								modifiedTime: '{{story.modifiedTime}}',
								ogTitle: '{{story.metaTitle}}',
								twTitle: '{{story.metaTitle}}',
								ogDescription: '{{story.metaDescription}}',
								twDescription: '{{story.metaDescription}}',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/blog/topic/:slug',
			exact: true,
			shouldIndex: true,
			isDynamic: true,
			priority: '0.8',
			functions: [
				{ function: 'blog/getBlogTopic', restrictToPath: true },
				{ function: 'blog/getBlogTopics', restrictToPath: false },
			],
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<BlogTopic
							{...filterProps(path, props)}
							meta={{
								title: '{{topic.title}} • Pixie Dust Blog',
								description: 'Explore updates and stories about {{topic.title}} on the Pixie Dust Blog',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/blog/search',
			exact: true,
			shouldIndex: false,
			isDynamic: true,
			functions: [
				{ function: 'blog/getBlogPosts', restrictToPath: true },
				{ function: 'blog/getBlogTopics', restrictToPath: false },
			],
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<BlogSearch
							{...filterProps(path, props)}
							meta={{
								title: 'Results for "{{searchQuery}}" • Pixie Dust Blog',
								description: 'Explore updates and stories about "{{searchQuery}}" on the Pixie Dust Blog',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),


		/**
		 * Dashboard Pages
		 */

		prepareComponent(createLocaleVariants({
			path: '/trips',
			exact: true,
			shouldIndex: false,
			priority: '0.8',
			functions: [],
			component(path) {
				return (
					<UserRouteWrapper roles={[ROLES.STANDARD]} user={userObj} locale={localeObj}>
						<Dashboard
							{...filterProps(path, props)}
							meta={{
								title: 'My Trips • Pixie Dust',
								description: 'View your upcoming trips to Disney and schedule your experiences.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</UserRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			paths: [
				'/me',
				'/profile'
			],
			exact: true,
			shouldIndex: false,
			priority: '0.8',
			functions: [],
			component(path) {
				return (
					<UserRouteWrapper roles={[ROLES.STANDARD]} user={userObj} locale={localeObj}>
						<Profile
							{...filterProps(path, props)}
							meta={{
								title: 'My Profile • Pixie Dust',
								description: 'Customize your Pixie Dust profile to make it easier to schedule your Disney experiences.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</UserRouteWrapper>
				);
			}
		})),


		/**
		 * Account Pages
		 */

		prepareComponent(createLocaleVariants({
			path: '/account/disney',
			exact: true,
			shouldIndex: false,
			component(path) {
				return (
					<UserRouteWrapper roles={[ROLES.STANDARD]} user={userObj} locale={localeObj} ignoreUpdateDisneyAccount>
						<UpdateDisneyAccount
							{...filterProps(path, props)}
							meta={{
								title: 'Update Disney Account • Pixie Dust',
								description: 'Update your Disney account email and password.',
								bodyClasses: 'hide-captcha',
								showSocial: false,
								shouldIndex: false,
								...metaObj
							}}
						/>
					</UserRouteWrapper>
				);
			}
		})),


		/**
		 * Trip Pages
		 */

		prepareComponent(createLocaleVariants({
			paths: [
				'/trip/create',
				'/trip/edit/:id'
			],
			exact: true,
			shouldIndex: false,
			isDynamic: true,
			priority: '0.8',
			functions: [],
			component(path) {
				return (
					<UserRouteWrapper roles={[ROLES.STANDARD]} user={userObj} locale={localeObj}>
						<TripFlow
							{...filterProps(path, props)}
							meta={{
								title: 'Create a Trip • Pixie Dust',
								description: 'Create an upcoming trip to schedule your Lightning Lanes and experiences.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</UserRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/trip/:id',
			exact: true,
			shouldIndex: false,
			isDynamic: true,
			priority: '0.8',
			functions: [],
			component(path) {
				return (
					<UserRouteWrapper roles={[ROLES.STANDARD]} user={userObj} locale={localeObj}>
						<TripDetail
							{...filterProps(path, props)}
							meta={{
								title: '{{trip.name}} • Pixie Dust',
								description: 'View or update your trips, schedule your Lightning Lane reservations, and more.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</UserRouteWrapper>
				);
			}
		})),


		/**
		 * Authentication Pages
		 */

		prepareComponent(createLocaleVariants({
			path: '/login',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			component(path) {
				return (
					<AuthRouteWrapper redirect="/trips" user={userObj} locale={localeObj}>
						<Login
							{...filterProps(path, props)}
							meta={{
								title: 'Log In • Pixie Dust',
								description: 'Log in with your email address or username to get started.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</AuthRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/forgot',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			component(path) {
				return (
					<PublicRouteWrapper>
						<ForgotPassword
							{...filterProps(path, props)}
							meta={{
								title: 'Forgot Password • Pixie Dust',
								description: 'Forgot your password? Enter your account email and we\'ll send you a link to reset your password.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/reset-password/:aToken/:bToken',
			exact: true,
			shouldIndex: false,
			component(path) {
				return (
					<PublicRouteWrapper>
						<ResetPassword
							{...filterProps(path, props)}
							meta={{
								title: 'Reset Password • Pixie Dust',
								description: 'Reset your Pixie Dust account password.',
								bodyClasses: 'hide-captcha',
								showSocial: false,
								shouldIndex: false,
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/join',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			component(path) {
				return (
					<PublicRouteWrapper requireAuth={false} user={userObj} locale={localeObj}>
						<Join
							{...filterProps(path, props)}
							meta={{
								title: 'Join • Pixie Dust',
								description: 'Create a Pixie Dust account to easily schedule your Disney World and Disneyland Lightning Lanes.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/_imp/:aToken/:bToken',
			exact: true,
			shouldIndex: false,
			component(path) {
				return (
					<PublicRouteWrapper>
						<Impersonation
							{...filterProps(path, props)}
							meta={{
								title: 'Authenticate • Pixie Dust',
								bodyClasses: 'hide-captcha',
								showSocial: false,
								shouldIndex: false,
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),


		/**
		 * General Pages
		 */

		prepareComponent(createLocaleVariants({
			path: '/contact-preferences/:token',
			exact: true,
			shouldIndex: false,
			component(path) {
				return (
					<PublicRouteWrapper>
						<ContactPreferences
							{...filterProps(path, props)}
							meta={{
								title: 'Update Preferences • Pixie Dust',
								description: 'Update your Pixie Dust contact preferences.',
								bodyClasses: 'hide-captcha',
								showSocial: false,
								shouldIndex: false,
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),


		/**
		 * Legal Pages
		 */

		prepareComponent(createLocaleVariants({
			path: '/legal',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			component(path) {
				return (
					<PublicRouteWrapper>
						<LegalOverview
							{...filterProps(path, props)}
							meta={{
								title: 'Legal • Pixie Dust',
								description: 'Pixie Dust\'s Terms and Policies, including security guidelines, Terms of Service, cookie policies, privacy, and more.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/legal/cookie-policy',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			component(path) {
				return (
					<PublicRouteWrapper>
						<CookiePolicy
							{...filterProps(path, props)}
							meta={{
								title: 'Cookie Policy • Pixie Dust',
								description: 'Our Cookie Policy explains what these technologies are and why we use them, as well as your rights to control our use of them.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/legal/privacy',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			component(path) {
				return (
					<PublicRouteWrapper>
						<PrivacyPolicy
							{...filterProps(path, props)}
							meta={{
								title: 'Privacy Policy • Pixie Dust',
								description: 'We care about your privacy. Our privacy policy will help you understand what information is collected, how it\'s used, and what choices you have.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/legal/security',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			component(path) {
				return (
					<PublicRouteWrapper>
						<SecurityPromise
							{...filterProps(path, props)}
							meta={{
								title: 'Security Promise • Pixie Dust',
								description: 'At Pixie Dust, privacy and data security are important to us, and both are paramount to the safety and security of our users and visitors. So is transparency.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/legal/terms',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			component(path) {
				return (
					<PublicRouteWrapper>
						<Terms
							{...filterProps(path, props)}
							meta={{
								title: 'Terms of Service • Pixie Dust',
								description: 'Our Terms of Service detail and clarify your rights and responsibilities when using Pixie Dust.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),


		/**
		 * Error
		 */

		prepareComponent({
			path: '*',
			shouldIndex: false,
			isError: true,
			code: 404,
			component(path) {
				return (
					<Error
						code={404}
						{...filterProps(path, props)}
						locale={localeObj}
						meta={{
							title: 'Page Not Found - Pixie Dust',
							description: 'We searched high and low but couldn\'t find what you\'re looking for. Let\'s find a better place for you to go.',
							bodyClasses: 'hide-captcha',
							showSocial: false,
							shouldIndex: false,
							...metaObj
						}}
					/>
				);
			}
		})
	].filter(Boolean));
};
