/*
 * File: consent.slice.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on July 18, 2022 at 10:49 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: November 14, 2022 at 6:36 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import { createSlice } from '@reduxjs/toolkit';


/**
 * Constants
 */

const enabledState = {
	consentActionTaken: true,
	performanceEnabled: true,
	analyticsEnabled: true,
	targetingEnabled: true
};

const disabledState = {
	consentActionTaken: false,
	performanceEnabled: false,
	analyticsEnabled: false,
	targetingEnabled: false
};

const initialState = {
	status: 'initial',
	error: null,
	currentValue: disabledState,
	previousValue: disabledState
};


/**
 * Slices
 */

export const consentSlice = createSlice({
	name: 'consent',
	initialState,
	reducers: {
		updateConsent: (state, action) => {

			// Set state
			state.previousValue = { ...state.currentValue };
			state.currentValue = action.payload;
			state.status = 'final';
		},
		clearConsent: (state) => {

			// Clear state
			state.currentValue = { ...initialState.currentValue };
			state.previousValue = { ...initialState.previousValue };
		},
		updateConsentStatus: (state, action) => {

			// Set state
			state.status = action.payload;
		},
		initializeConsent: (state, action) => {

			// Clear state
			state.currentValue = action.payload === true ? { ...disabledState } : { ...enabledState };
			state.previousValue = { ...disabledState };
			state.status = 'final';
		}
	},
	extraReducers: {},
});


/**
 * Exports
 */

export const {
	updateConsent, clearConsent, updateConsentStatus, initializeConsent
} = consentSlice.actions;

export default consentSlice.reducer;
