/*
 * File: ContactPreferences.styles.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on November 18, 2022 at 3:58 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: June 30, 2023 at 12:02 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Styles
import { ColorValuesCreator } from '../../../styles/colors';

// Create color values
const ColorValues = ColorValuesCreator();


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: block;
`;

export const Form = styled.form`
	width: 100%;
	max-width: 800px;
	margin: 0px auto;

	@media only screen {
		padding-top: 60px;
		padding-bottom: 120px;
	}
	@media only screen and (min-width: 40em) {
		padding-top: 80px;
		padding-bottom: 120px;
	}
	h2 {
		text-align: left;
		color: ${ColorValues.white};
		margin: 10px 0px 10px;
	}
	p {
		text-align: left;
		color: ${ColorValues.white};
   		margin-bottom: 20px;
		opacity: 0.5;
		max-width: 600px;
		width: 100%;
	}
	button {
		width: 100%;
	}
`;

export const LogoMark = styled.div`
	width: 46px;
	height: 46px;
	background-image: url('${process.env.CDN_URL}/public/assets/logos/pixie-dust-logo-icon.png');
	background-size: cover;
	background-repeat: no-repeat;
`;

export const SettingsWrapper = styled.div`
	margin-top: 40px;
	padding-top: 40px;
	border-top: 1px solid ${ColorValues.white}30;
`;
