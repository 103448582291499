/*
 * File: DayCard.styles.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:53 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: May 7, 2023 at 11:11 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { Card } from '../Card';

// Styles
import { ColorValuesCreator } from '../../styles/colors';

// Create color values
const ColorValues = ColorValuesCreator();


/**
 * Styles
 */

export const Wrapper = styled(Card)`
	box-shadow: ${ColorValues.elementShadow};
	width: ${({ $dayWidth }) => `${$dayWidth}px`};
	flex-shrink: 0;
	position: relative;
`;

export const ScrollContainer = styled.div`
	overflow: scroll;
	padding: 20px;
	display: flex;
	flex-direction: column;
	gap: 12px;
	max-height: 700px;
`;

export const CardHeader = styled.div`
	display: flex;
	align-items: center;
	margin: 0px 0px 10px;
`;

export const TitleContainer = styled.div`
	flex-grow: 1;
	display: flex;
	flex-direction: column;

	span {
		text-align: left;
	}
	span:first-child {
		color: ${({ theme }) => theme.secondaryText};
		margin: 0px 0px 2px;
	}
`;

export const PartyContainer = styled.div`
	display: flex;
	gap: 0px;
	flex-wrap: wrap;
	margin-right: 14px;
`;

export const ProfileImage = styled.div`
	width: 32px;
	height: 32px;
	border-radius: 50%;
	background-image: ${({ image }) => `url('${image}')`};
	background-size: cover;
	background-repeat: no-repeat;
	background-color: ${ColorValues.neutralBackground};
	margin-right: -14px;
	border: 1px solid white;
`;

export const CardContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

export const StatusContainer = styled.div`
	text-align: left;
	margin-bottom: -4px;

	svg {
		color: ${({ $isWarning, theme }) => ($isWarning ? theme.brandPrimaryOrange : theme.brandPrimaryYellow)};
	}
`;
