/*
 * File: index.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on November 18, 2022 at 12:52 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: March 8, 2023 at 7:43 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Utilities
import api from '../api';


/**
 * Users Services
 */

export const getCurrentUser = (data) => api.get('/users/current', data);

export const createUserAccount = (data) => api.post('/user/account/create', data);

export const completeUserOnboarding = (data) => api.post('/user/account/complete', data);

export const deleteCurrentUser = () => api.delete('/users/current', { withCredentials: true });

export const deleteUserAccount = () => api.post('/user/account/delete', { withCredentials: true });

export const updateNotificationPreferences = ({ preferences, token }) => api.post('/user/account/notifications', { preferences, token });

export const updateUserAccountEmail = ({ email }) => api.post('/user/account/email', { email });

export const updateUserAccount = ({ name, phone }) => api.post('/user/account', { name, phone });

export const validateGeneralAccessAttempt = ({ token }) => api.get('/user/account/access', { params: { token } });

export const uploadUserProfileAvatar = ({ file, filename = `${(new Date()).getTime().toString()}.jpg`, onProgress = () => {} }) => {

	// Create form data
	const formData = new FormData();
	formData.append('file', file, filename);

	// Post media
	return api.post('/user/profile/media/avatar', formData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		},
		onUploadProgress: onProgress
	});
};


/**
 * Disney Services
 */

export const attachDisneyAccount = ({ username, pass }) => api.post('/user/account/attach', { username, pass });

export const refreshDisneyAccount = (data) => api.post('/user/account/refresh', data);

export const getFriendsFamily = (data) => api.get('/users/current/family', data);

export const getMyDay = (data) => api.get('/users/current/myday', data);

export const getTipBoard = (data) => api.get('/users/current/tipboard', data);
