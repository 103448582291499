/*
 * File: api.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on June 17, 2022 at 10:08 AM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: June 2, 2023 at 10:15 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import axios from 'axios';

// Constants
import { CLIENT_VERSION_HEADER, CLIENT_PLATFORM_HEADER } from '../../Constants';


/**
 * API Handler
 */

const api = axios.create({
	baseURL: `${process.env.API_URL}${process.env.API_MOUNT}`,
	withCredentials: true,
});


/**
 * Configure API
 */

api.interceptors.request.use(async (config) => {
	const headers = { ...config.headers };
	headers[CLIENT_VERSION_HEADER] = process.env.PACKAGE_VERSION;
	headers[CLIENT_PLATFORM_HEADER] = 'web';
	return { ...config, headers };
});

api.interceptors.response.use((response) => response, (error) => {
	if (error && error.response && error.response.status === 401 && !(error.response?.config?.url || '').endsWith('/login')) {
		window.location = '/login';
	}
	return Promise.reject(error);
});


/**
 * Exports
 */

export default api;
