/*
 * File: Profile.styles.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on November 18, 2022 at 3:44 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: June 17, 2023 at 9:32 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';

// Components
import {
	Button, Card, IconButton, NavigationDropdown as Dropdown
} from '../../../components';

// Styles
import { ColorValuesCreator } from '../../../styles/colors';

// Constants
import { largeBreakpoint, mediumBreakpoint, mobileBreakpoint } from '../../../styles/constants';

// Create color values
const ColorValues = ColorValuesCreator();


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	gap: 50px;
	margin: 40px 0px 80px;
	flex-direction: column;

	@media only screen and (min-width: ${largeBreakpoint}em) {
		flex-direction: row;
		margin: 80px 0px 80px;
	}
`;

export const LeftContainer = styled.div`
	min-width: 300px;
	display: flex;
	flex-direction: column;

	h4 {
		color: ${ColorValues.white};
		text-align: left;
		margin-top: 15px;
	}
	p {
		color: ${ColorValues.white};
		text-align: left;
		opacity: 0.5;
	}
`;

export const RightContainer = styled.div`
	flex-grow: 1;
	display: flex;
	gap: 30px;
	flex-direction: column;
`;

export const TabContainer = styled.div`
	display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
	gap: 30px;
	flex-direction: column;
`;

export const ProfileImageContainer = styled.div`
	width: 150px;
	height: 150px;
	margin-top: 10px;
	border-radius: 50%;
	box-shadow: ${ColorValues.elementShadow};
	background-color: ${({ theme }) => theme.primaryBackground};
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 3px;
	position: relative;
`;

export const ProfileImage = styled.div`
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background-image: ${({ image }) => `url('${image}')`};
	background-size: cover;
	background-repeat: no-repeat;
`;

export const DropdownToggle = styled(IconButton)`
	height: auto;
`;

export const NavigationDropdown = styled(Dropdown)`
	top: 54px;
	right: -60px;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		right: -177px;
	}
`;

export const NavigationContainer = styled.div`
	margin: 40px 0px 20px;
`;

export const NavigationOption = styled.div`
	width: 100%;
	opacity: ${({ isActive }) => (isActive ? 1.0 : 0.2)};
	cursor: pointer;
	margin-bottom: 8px;

	h4 {
		color: ${ColorValues.white};
		margin: 0px;
	}
`;

export const PhoneFlag = styled.img`
	width: 18px;
	border-radius: 2px;
	margin: 2px 6px 0px 0px;
`;

export const PhoneContainer = styled.div`
	display: flex;
	align-items: center;
`;

export const ProfileSection = styled.div`
	margin: 0px;
`;

export const ProfileDetails = styled.div`
	margin: 15px 0px 0px;
`;

export const TitleContainer = styled.div`
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	height: 30px;

	h4 {
		color: ${ColorValues.white};
		text-align: left;
		margin: 0px;
	}
`;

export const TitleSpacer = styled.div`
	flex-grow: 1;
`;

export const AccountContainer = styled.div`
	display: flex;
	gap: 25px;
	flex-direction: column;
	position: relative;

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		flex-direction: row;
	}
`;

export const AccountCardContainer = styled.div`
	position: relative;
	flex-grow: 1;
    flex-basis: 0;
	min-width: 0;
	display: flex;
    flex-direction: column;
`;

export const AccountCard = styled(Card)`
	padding: 18px;
	flex-grow: 1;
    flex-basis: 0;
	display: flex;
	align-items: center;
	position: relative;
	background-color: ${ColorValues.white};
	color: ${ColorValues.dark};
	flex-direction: ${({ $shouldCollapse }) => ($shouldCollapse ? 'column' : 'row')};
	gap: 15px;

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		flex-direction: row;
	}
	* {
		text-align: left;
	}
	p {
		margin: 2px 0px 0px 0px;
	}
	.dangerButton {
		background-color: ${ColorValues.neutralHighlight};
		border-color: ${ColorValues.neutralHighlight};
		color: ${ColorValues.statusDangerBase};
		width: 100%;

		* {
			font-size: 0.8rem;
		}
	}
	.refreshButton {
		background-color: ${ColorValues.neutralHighlight};
		border-color: ${ColorValues.neutralHighlight};
		color: ${ColorValues.brandPrimaryLightBlue};
		width: 100%;
		margin: -10px 0px 0px 0px;
	}
	svg.dangerIcon {
		font-size: 0.9rem;
		margin: 0px 6px 0px 0px;
		color: ${ColorValues.statusDangerBase}
	}
`;

export const CardLeft = styled.div`
	flex-grow: 1;
	width: 100%;

	${({ $isFlex }) => $isFlex && css`
		height: 100%;
		display: flex;
		flex-direction: column;
	`}
	@media only screen and (min-width: ${mediumBreakpoint}em) {
		width: auto;
	}
	.secondary {
		color: ${ColorValues.neutralText};
	}
`;

export const CardCenter = styled.div`
	width: 100%;

	.fieldTitle {
		font-size: 0.7rem;
		color: ${ColorValues.neutralText};
	}
	p:not(:last-child) {
		margin-bottom: 18px;
	}
	p:last-child {
		margin-bottom: -6px;
	}
`;

export const CardRight = styled.div`
	flex-shrink: 0;
	width: ${({ $shouldExpand }) => ($shouldExpand ? '100%' : 'auto')};

	${({ $isFlex }) => $isFlex && css`
		height: 100%;
		display: flex;
		flex-direction: column;
	`}
	@media only screen and (min-width: ${mediumBreakpoint}em) {
		width: auto;
	}
`;

export const PaymentTop = styled.div`
	flex-grow: 1;
`;

export const CardLogo = styled.img`
	border-radius: 2px;
	height: 26px;
`;

export const EditButton = styled(Button)`
	padding: 0px;

	svg {
		font-size: 0.75rem;
	}
	span {
		font-size: 0.85rem;
		margin: 0px 0px 0px 7px;
	}
`;

export const RemoveButton = styled(Button)`
	padding: 0px;
	margin: 0px 20px 0px 0px;

	svg {
		font-size: 0.75rem;
	}
	span {
		font-size: 0.85rem;
		margin: 0px 0px 0px 7px;
	}
`;

export const UpdateButton = styled(Button)`
	padding: 0px;

	svg {
		font-size: 0.95rem;
		margin: 0px 0px 0px 7px;
	}
	span {
		font-size: 0.85rem;
	}
`;

export const ResetButton = styled(Button)`
	padding: 0px;
	position: absolute;
	right: 18px;
	bottom: 22px;

	svg {
		font-size: 0.7rem;
		color: ${ColorValues.brandPrimaryLightBlue};
	}
	span {
		font-size: 0.8rem;
		margin: 0px 0px 0px 7px;
		color: ${ColorValues.brandPrimaryLightBlue};
	}
`;

export const AccountAction = styled.div`
	background-color: ${({ theme }) => theme.primaryBackground};
	border-radius: 50%;
	box-shadow: ${ColorValues.elementShadow};
	height: 40px;
	width: 40px;
	position: absolute;
	right: 0px;
	bottom: 0px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const AddMethodPrompt = styled.div`
	width: 100%;
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 8px;
	position: relative;
	cursor: pointer;
	height: 140px;

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		height: auto;
	}
	svg {
		font-size: 3.0rem;
		color: ${ColorValues.white};
	}
	span {
		color: ${ColorValues.white};
		margin-top: 10px;
	}
	&:hover {
		box-shadow: ${ColorValues.elementDarkShadow};
	}
`;

export const AddMethodPromptBackground = styled.div`
	background-color: ${ColorValues.white};
	opacity: 0.2;
	position: absolute;
	height: 100%;
	width: 100%;
	border-radius: 8px;
`;
