/*
 * File: cookies.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on June 16, 2022 at 1:39 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: November 18, 2022 at 11:43 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Constants
import { COOKIE_DOMAIN } from '../../Constants';


/**
 * Utilities
 */

export const setCookie = (name, value, expiration = 365 * 24 * 60 * 60 * 1000) => {
	if (typeof document !== 'undefined') {
		let expires = '';
		if (expiration) {
			const date = new Date();
			date.setTime(date.getTime() + expiration);
			expires = `; expires=${date.toUTCString()}`;
		}
		const cookieDomain = process.env.ENV === 'development' ? '' : ` domain=${COOKIE_DOMAIN()};`;
		document.cookie = `${name}=${value || ''}${expires}; path=/;${cookieDomain}`;
	}
};

export const getCookie = (cname) => {
	if (typeof document !== 'undefined') {
		const name = `${cname}=`;
		const decodedCookie = decodeURIComponent(document.cookie);
		const ca = decodedCookie.split(';');
		for (let i = 0; i < ca.length; i += 1) {
			let c = ca[i];
			while (c.charAt(0) === ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) === 0) {
				return c.substring(name.length, c.length);
			}
		}
	}
	return '';
};

export const removeCookie = (cname) => {
	if (typeof document !== 'undefined') {
		const cookieDomain = process.env.ENV === 'development' ? '' : `domain=${COOKIE_DOMAIN()};`;
		document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; ${cookieDomain}`;
	}
};
