/*
 * File: index.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on August 7, 2023 at 2:21 PM
 * Copyright © 2023 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: August 7, 2023 at 2:22 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Utilities
import api from '../api';


/**
 * Services
 */

export const validateImpersonation = (data) => api.post('/user/impersonate/validate', data, { withCredentials: true });
