/*
 * File: constants.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on June 16, 2022 at 1:32 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: May 28, 2023 at 3:10 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Breakpoints
 */

// Max Width
export const smallBreakpoint = 25; // 25em
export const mediumBreakpoint = 45; // 45em
export const largeBreakpoint = 60; // 60em

// Min Width
export const mobileBreakpoint = 62.4; // 62em


/**
 * Dimensions
 */

export const AppNavSidebarWidth = 340; // 340px
export const AppNavCartWidth = 520; // 520px
export const LegalNavSidebarWidth = 250; // 300px
export const AppNavTopbarHeight = 68; // 68px


/**
 * Delays
 */

export const navigationDropdownDelay = 600;
