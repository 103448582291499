/*
 * File: index.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 26, 2023 at 8:35 PM
 * Copyright © 2023 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: March 13, 2023 at 1:49 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Utilities
import api from '../api';


/**
 * Services
 */

export const getBlogPost = ({ slug }) => api.get(`/blog/posts/${slug}`);

export const getBlogPosts = ({
	query, excludedStories, relatedId, relatedContent, pageIndex = 0, pageSize = 12
}) => api.get('/blog/posts', {
	params: {
		query, pageIndex, pageSize, excludedStories, relatedId, relatedContent
	}
});

export const getBlogTopic = ({ slug }) => api.get(`/blog/topics/${slug}`);

export const getBlogTopics = () => api.get('/blog/topics');
