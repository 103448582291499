/*
 * File: CopyrightFooter.styles.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:59 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: June 29, 2023 at 11:59 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';

// Styles
import { ColorValuesCreator } from '../../styles/colors';

// Components
import { LocaleLink } from '../LocaleLink';
import { Padding } from '../Padding';
import { Card } from '../Card';

// Constants
import { mediumBreakpoint, mobileBreakpoint } from '../../styles/constants';

// Create color values
const ColorValues = ColorValuesCreator();


/**
 * Styles
 */

export const ContainerPadding = styled(Padding)`
	flex-direction: column;
`;

export const CopyrightFooter = styled.div`
	position: relative;
	padding: 15px 0px 30px;
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		flex-direction: row;
	}
	${({ showBorder }) => showBorder && css`
		border-top: 1px solid ${({ theme }) => theme.layoutBorder};
	`}
	* {
		text-align: left;
	}
	span {
		color: ${({ isDark, theme }) => (isDark ? ColorValues.white : theme.secondaryText)};
		font-size: 0.8rem;
		font-weight: 400;
	}
	a {
		display: flex;
	}
`;

export const DownloadFooter = styled(Card)`
	position: relative;
	padding: 15px 30px 26px;
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 5px;
	background-color: ${({ isDark, theme }) => (isDark ? 'transparent' : theme.secondaryBackground)};
	border: ${({ isDark }) => (isDark ? '1px solid white' : 'none')};
	border-radius: 10px;
	margin: 0px 0px 10px;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		flex-direction: row;
	}
	h4 {
		color: ${({ isDark, theme }) => (isDark ? ColorValues.white : theme.primaryText)};
	}
	p {
		color: ${({ isDark, theme }) => (isDark ? ColorValues.white : theme.secondaryText)};
		margin: 4px 0px 0px;
	}
`;

export const LeftContainer = styled.div`
	flex-grow: 1;
	display: flex;
	align-items: center;
	margin-top: 10px;

	&.stackTop {
		flex-direction: column;
		align-items: center;

		@media only screen and (min-width: ${mobileBreakpoint}em) {
			align-items: start;
		}
	}
`;

export const RightContainer = styled.div`
	display: flex;
	align-items: center;
	margin-top: 10px;

	&.stackTop {
		flex-direction: column;
	}
	&.badgeContainer {
		gap: 12px;
	}
`;

export const Badge = styled.img`
	width: auto;
	height: 50px;
	border-radius: 8px;

	&:hover {
		box-shadow: ${ColorValues.elementDarkShadow};
	}
`;

export const LogoMark = styled.img`
	width: auto;
	height: 34px;
	margin-right: 10px;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
`;

export const CoreLogoMark = styled.div`
	width: 32px;
	height: 32px;
	margin-right: 8px;
	background-image: url('${process.env.CDN_URL}/public/assets/logos/pixie-dust-logo-icon.png');
	background-size: cover;
	background-repeat: no-repeat;
`;

export const InlineLink = styled(LocaleLink)`
	display: inline !important;
	color: ${({ $isDark, theme }) => ($isDark ? ColorValues.white : theme.secondaryText)} !important;
	margin: 0px 8px;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
`;

export const LinkDivider = styled.div`
	height: auto;
	width: 8px;
`;
