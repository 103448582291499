/*
 * File: index.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on November 19, 2022 at 10:02 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 17, 2023 at 5:27 AM
 * Modified By: Brendan Michaelsen
 */

// Utilities
import api from '../api';


/**
 * Services
 */

export const getDisneyAvatarImage = ({ id }) => api.get(`/disney/avatar/${id}`);

export const searchAttractions = ({
	query, eventTypes, limit, parkEntity
}) => api.post('/disney/search', {
	query, eventTypes, limit, parkEntity
});
