/*
 * File: PasswordInput.styles.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:54 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: November 28, 2022 at 3:28 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Components
import { Typography } from '../Typography';

// Colors
import { ColorValuesCreator } from '../../styles/colors';

// Create color values
const ColorValues = ColorValuesCreator();


/**
 * Styles
 */

export const PasswordInputContainer = styled.div`
	position: relative;
`;

export const PasswordValidator = styled.div`
	position: absolute;
	width: 100%;
	max-width: 370px;
	padding: 15px 15px 10px;
	margin: 10px 0 0;
	background-color: ${({ theme }) => theme.primaryBackground};
	box-shadow: ${ColorValues.elementShadow};
	border-radius: 8px;
	border: 1px solid ${({ theme }) => theme.inputBorder};
	opacity: 0;
	visibility: hidden;
	transform: scale(1.05);
	pointer-events: none !important;
	transition: visibility 0s linear 0.3s, opacity 0.3s 0s, transform 0.3s;
	z-index: 99;

	* {
		pointer-events: none !important;
	}

	p {
		text-align: left;
		color: ${({ theme }) => theme.secondaryText};
		margin: 0px !important;
	}
	.introduction {
		color: ${({ theme }) => theme.secondaryText};
		margin-bottom: 10px !important;
	}
	&.show {
		opacity: 1;
		visibility: visible;
		transform: scale(1);
		pointer-events: all !important;
		transition: visibility 0s linear 0s, opacity 0.3s 0s, transform 0.3s;

		* {
			pointer-events: all !important;
		}
	}
`;

export const ValidatorRule = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	margin: 0 0 7px;
`;

export const ValidatorState = styled(FontAwesomeIcon)`
	color: ${({ theme, validated }) => (validated ? theme.statusSuccessBase : theme.secondaryText)};
	font-size: 1.25em;
	height: 1.0em !important;
	margin-right: 12px;
`;

export const ValidatorText = styled(Typography)`
	color: ${({ theme, validated }) => (validated ? theme.primaryText : theme.secondaryText)} !important;
`;
