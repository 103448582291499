/*
 * File: ConfirmationStep.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:24 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 17, 2023 at 5:47 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, {
	useEffect, forwardRef, useState, useRef
} from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie-player';
import { useDispatch, useSelector } from 'react-redux';
import { flatten } from 'lottie-colorify';

// Services
import { completeUserOnboarding, getCurrentUser } from '../../../../services/user';

// Slices
import { updateUser } from '../../../../store/slices/user/user.slice';

// Components
import {
	Typography
} from '../../../../components';

// Animation
import animationData from '../../../../assets/animations/spinner.json';

// Styles
import * as S from './ConfirmationStep.styles';
import { parseFirstLastName } from '../../../../../utilities/utilities';


/**
 * Constants
 */

const STEP_LENGTHS = [
	4000,
	4000,
	3500
];
const STEP_DELAYS = [
	300,
	0,
	0
];
const START_DELAY = 800;
const CONTINUE_DELAY = 1500;


/**
 * Component
 */

export const ConfirmationStep = forwardRef(({
	updateStep, isVisible, className
}, ref) => {

	// Create state handlers
	const [completedSteps, setCompletedSteps] = useState([]);
	const [accountCreationStepIndex, setAccountCreationStepIndex] = useState(0);

	// Create references
	const completedStepsRef = useRef();
	const accountCreationStepIndexRef = useRef();

	// Set current loop value
	completedStepsRef.current = completedSteps;
	accountCreationStepIndexRef.current = accountCreationStepIndex;

	// Get current user from hook
	const user = useSelector((state) => state.user.value);

	// Get actions from hooks
	const dispatch = useDispatch();

	// Handle confirmation action
	const handleOnboardingConfirmation = async () => {
		completeUserOnboarding({});
	};

	// Begin confirmation flow
	const beginConfirmationFlow = async () => {

		// Update display
		setCompletedSteps([
			...completedStepsRef.current,
			accountCreationStepIndexRef.current
		]);

		// If final step completed, return
		if (accountCreationStepIndexRef.current > 2) {

			// Fetch current user
			const { data } = await getCurrentUser();

			// Update current user
			dispatch(updateUser(data.user));

			// Move to next step
			setTimeout(() => {
				updateStep(5);
			}, CONTINUE_DELAY);

		} else {

			// Get schedule parameters
			const interval = STEP_LENGTHS[accountCreationStepIndexRef.current];
			const delay = STEP_DELAYS[accountCreationStepIndexRef.current];

			// Schedule delay
			setTimeout(() => {

				// Increment step
				setAccountCreationStepIndex(accountCreationStepIndexRef.current + 1);

				// Schedule timer
				setTimeout(() => {
					beginConfirmationFlow();
				}, interval);
			}, delay);
		}
	};

	// Perform actions on visibility change
	useEffect(() => {
		if (isVisible === true) {

			// Handle onboarding confirmation
			handleOnboardingConfirmation();

			// Begin confirmation flow
			setTimeout(() => {
				beginConfirmationFlow();
			}, START_DELAY);
		}
	}, [isVisible]);

	// Return component
	return (
		<S.StepContainer ref={ref} className={className}>

			{/* Form */}
			<S.Form>

				{/* Logo */}
				<S.LogoMark className="isNotMobile" />

				{/* Content */}
				<Typography tag="h2" weight="bold">{`Welcome to Pixie Dust, ${parseFirstLastName(user?.name || '')?.firstName}!`}</Typography>
				<Typography tag="p" variation="1" weight="regular">We&apos;re creating your account with a little bit of magic...</Typography>

				{/* Setup - Step 1 */}
				<S.SetupRow className="first animate">
					<S.ProgressHolder className={completedSteps.includes(1) && 'complete'}>
						{accountCreationStepIndex === 1 && !completedSteps.includes(1) && (
							<S.AnimationContainer>
								<Lottie
									loop
									play
									animationData={flatten('#ffffff', animationData)}
									width={500}
									height={500}
									style={{
										width: '160px',
										position: 'absolute',
										left: '-57px',
										top: '-18px'
									}}
								/>
							</S.AnimationContainer>
						)}
						{accountCreationStepIndex < 1 && (
							<S.PendingState />
						)}
						<S.CompleteIcon icon={['fa', 'check']} className={completedSteps.includes(1) && 'show'} />
					</S.ProgressHolder>
					<S.ContentHolder>
						<Typography tag="p" variation="2">Importing your existing Disney tickets and park reservations.</Typography>
					</S.ContentHolder>
				</S.SetupRow>

				{/* Setup - Step 2 */}
				<S.SetupRow className="animate">
					<S.ProgressHolder className={completedSteps.includes(2) && 'complete'}>
						{accountCreationStepIndex === 2 && !completedSteps.includes(2) && (
							<S.AnimationContainer>
								<Lottie
									loop
									play
									animationData={flatten('#ffffff', animationData)}
									style={{
										width: '160px',
										position: 'absolute',
										left: '-57px',
										top: '-18px'
									}}
								/>
							</S.AnimationContainer>
						)}
						{accountCreationStepIndex < 2 && (
							<S.PendingState />
						)}
						<S.CompleteIcon icon={['fa', 'check']} className={completedSteps.includes(2) && 'show'} />
					</S.ProgressHolder>
					<S.ContentHolder>
						<Typography tag="p" variation="2">Connecting your Friends and Family list and finding your Disney Genie recommendations.</Typography>
					</S.ContentHolder>
				</S.SetupRow>

				{/* Setup - Step 3 */}
				<S.SetupRow className="animate">
					<S.ProgressHolder className={completedSteps.includes(3) && 'complete'}>
						{accountCreationStepIndex === 3 && !completedSteps.includes(3) && (
							<S.AnimationContainer>
								<Lottie
									loop
									play
									animationData={flatten('#ffffff', animationData)}
									style={{
										width: '160px',
										position: 'absolute',
										left: '-57px',
										top: '-18px'
									}}
								/>
							</S.AnimationContainer>
						)}
						{accountCreationStepIndex < 3 && (
							<S.PendingState />
						)}
						<S.CompleteIcon icon={['fa', 'check']} className={completedSteps.includes(3) && 'show'} />
					</S.ProgressHolder>
					<S.ContentHolder>
						<Typography tag="p" variation="2">Polishing your new Pixie Dust profile and tidying up your preferences.</Typography>
					</S.ContentHolder>
				</S.SetupRow>
			</S.Form>
		</S.StepContainer>
	);
});


/**
 * Configuration
 */

ConfirmationStep.displayName = 'ConfirmationStep';
ConfirmationStep.propTypes = {
	updateStep: PropTypes.func.isRequired,
	className: PropTypes.string,
	isVisible: PropTypes.bool
};
ConfirmationStep.defaultProps = {
	className: null,
	isVisible: false
};
