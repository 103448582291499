/*
 * File: index.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on May 25, 2023 at 9:57 AM
 * Copyright © 2023 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: May 25, 2023 at 9:58 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Utilities
import api from '../api';


/**
 * Services
 */

export const getDynamicGuide = ({ operatorId }) => api.get(`/guide/dynamic/${operatorId}`);
