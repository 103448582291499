/*
 * File: PaymentMethodStep.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:24 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 18, 2023 at 10:21 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import validator from 'validator';
import {
	CardElement,
	useElements,
	useStripe
} from '@stripe/react-stripe-js';

// Utilities
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toastError, toastSuccess } from '../../../../utilities/toaster';

// Slices
import { updateUser } from '../../../../store/slices/user/user.slice';

// Services
import { addPaymentMethod, validatePromoCode } from '../../../../services/payment';

// Components
import {
	Button,
	InputCollection,
	Select,
	TextInput,
	Typography
} from '../../../../components';

// Constants
import { COUNTRY_OPTIONS, UI_MODE_OPTIONS } from '../../../../../Constants';

// Styles
import * as S from './PaymentMethodStep.styles';
import { DarkTheme, LightTheme } from '../../../../styles/colors';


/**
 * Constants
 */

const countryOptions = COUNTRY_OPTIONS.map((country) => ({ label: country.name, value: country.code }));


/**
 * Helpers
 */

const stripeStyles = (theme) => ({
	base: {
		iconColor: theme.primaryText,
		color: theme.primaryText,
		fontSize: '16px',
		fontFamily: '"Lexend", sans-serif',
		fontSmoothing: 'antialiased',
		fontWeight: 500,
		'::placeholder': {
			color: theme.inputPlaceholder,
		},
		':focus': {
			color: theme.primaryText,
		}
	},
	invalid: {
		color: theme.statusDangerBase,
		':focus': {
			color: theme.statusDangerBase
		}
	}
});


/**
 * Component
 */

export const PaymentMethodStep = forwardRef(({
	isVisible, className, updateStep
}, ref) => {

	// Create state handlers
	const [countryError, setCountryError] = useState(null);
	const [promoCodeError, setPromoCodeError] = useState(null);
	const [activePromoCode, setActivePromoCode] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [promoIsLoading, setPromoIsLoading] = useState(false);
	const [inputValues, setInputValues] = useState({
		country: 'US'
	});

	// Get Stripe elements
	const elements = useElements();
	const stripe = useStripe();

	// Get actions from hooks
	const dispatch = useDispatch();

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get current theme
	const theme = uiMode.mode === UI_MODE_OPTIONS.DARK ? DarkTheme() : LightTheme();

	// Handle add payment method function
	const handleAddPaymentMethod = async () => {

		// Get parameters
		const {
			country,
		} = inputValues;

		// Update loading state
		setIsLoading(true);

		// Get card element
		const cardElement = elements.getElement(CardElement);

		// Create token
		let cardToken = null;
		try {
			const { token, error } = await stripe.createToken(cardElement, {
				address_country: country
			});
			if (error && error.message) {

				// Show error message
				toastError(uiMode, error.message);

				// Update loading state
				setIsLoading(false);

				// Return
				return;
			}
			if (token) cardToken = token;

		} catch (e) {

			// Update loading state
			setIsLoading(false);
		}

		// Validate parameters
		if (!country || validator.isEmpty(country, { ignore_whitespace: true })) {
			setCountryError({ message: 'Please select the country of your billing address' });
			return;
		}

		// Add payment method
		addPaymentMethod({ source: cardToken.id, ...activePromoCode ? { promoCode: activePromoCode.code } : undefined }).then(({ data }) => {

			// Show success toast
			toastSuccess(uiMode, 'Success! Your new payment method has been added to your account.');

			// Set loading state
			setIsLoading(false);
			setCountryError(null);
			setPromoCodeError(null);

			// Update user
			dispatch(updateUser(data.user));

			// Handle completion actions
			setTimeout(() => {

				// Move to confirmation step
				updateStep(5);

			}, 1500);

		}).catch(({ response }) => {

			// Set loading state
			setIsLoading(false);
			setCountryError(null);
			setPromoCodeError(null);

			// Show error message
			if (response?.data?.message) {
				toastError(uiMode, 'Whoops. We\'re having trouble adding your new payment method. Please try again.');
			} else {
				toastError(uiMode, 'Whoops. We\'re having trouble adding your new payment method. Please try again.');
			}
		});
	};

	// Handle add promo code
	const handleAddPromoCode = async () => {

		// Get parameters
		const {
			promoCode
		} = inputValues;

		// Validate parameters
		if (!promoCode || validator.isEmpty(promoCode, { ignore_whitespace: true })) {
			setPromoCodeError({ message: 'Please enter a valid promo code' });
			return;
		}

		// Update loading state
		setPromoIsLoading(true);

		// Add payment method
		validatePromoCode({ code: promoCode }).then(({ data }) => {

			// Set loading state
			setPromoIsLoading(false);
			setPromoCodeError(null);

			// Update promo code
			setActivePromoCode(data.promoCode);

			// Update input values
			setInputValues({ ...inputValues, promoCode: '' });

		}).catch(({ response }) => {

			// Set loading state
			setPromoIsLoading(false);
			setPromoCodeError(null);

			// Show error message
			if (response?.data?.message) {
				toastError(uiMode, 'We can\'t find a valid promo code with that name. Please enter another one.');
			} else {
				toastError(uiMode, 'We can\'t find a valid promo code with that name. Please enter another one.');
			}
		});
	};

	// Handle remove promo code
	const removePromoCode = () => {
		setActivePromoCode(null);
	};

	// Handle calculate discount from promo code
	const calculateDiscount = (promoCode) => {
		if (promoCode.discountType === 'percent') {
			return promoCode.discount === 100 ? '(100% free)' : `(${promoCode.discount}% off)`;
		} if (promoCode.discountType === 'amount') {
			return `($${promoCode.discount} off)`;
		}
		return '';
	};

	// Handle on input change action
	const handleOnChange = (event) => {
		const {
			name, value
		} = event.target;
		setInputValues({ ...inputValues, [name]: value });
	};

	// Perform actions on visibility change
	useEffect(() => {
		if (isVisible) {
			if (elements) {

				// Get card elements
				const cardElement = elements.getElement(CardElement);

				// Clear card elements
				if (cardElement) cardElement.clear();
			}

			// Clear content
			setInputValues({ country: 'US' });
		}
	}, [isVisible]);

	// Return component
	return (
		<S.StepContainer ref={ref} className={className}>

			{/* Form */}
			<S.Form>

				{/* Icon */}
				<S.IconContainer className="isNotMobile">
					<S.Icon>
						<FontAwesomeIcon icon={['far', 'credit-card']} />
					</S.Icon>
				</S.IconContainer>

				{/* Content */}
				<Typography tag="h2" weight="bold">All ready to schedule?</Typography>
				<Typography tag="p" variation="1" weight="regular">Enter your card details below. We&apos;ll only charge you on the morning that your reservations are made.</Typography>

				{/* Promo Code Container */}
				<S.PromoCodeContainer>
					{activePromoCode && (
						<S.PromoCode>
							<Typography weight="semibold">{activePromoCode.code}</Typography>
							{' '}
							<Typography>{calculateDiscount(activePromoCode)}</Typography>
							<S.RemoveButton
								size={0.85}
								icon={['fas', 'times']}
								onClick={removePromoCode}
							/>
						</S.PromoCode>
					)}
				</S.PromoCodeContainer>

				{/* Inputs */}
				<InputCollection className="floatShadow startSpacer" isDark>
					<S.ElementContainer>
						<CardElement options={{
							style: stripeStyles(theme)
						}}
						/>
					</S.ElementContainer>
					<Select
						label="Country"
						name="country"
						options={countryOptions}
						error={countryError}
						values={Array.isArray(inputValues.country) ? inputValues.country : [inputValues.country || '']}
						containerClassName="formInput"
						onFocus={() => { setCountryError(null); }}
						onKeyUp={() => { setCountryError(null); }}
						onBlur={() => { setCountryError(null); }}
						onChange={handleOnChange}
						smartField
						isDark
					/>

				</InputCollection>
				<InputCollection className="floatShadow spacer" isDark>
					<TextInput
						className="buttonPadding"
						label="Promo code"
						name="promoCode"
						type="text"
						error={promoCodeError}
						value={inputValues.promoCode || ''}
						autoComplete="promoCode"
						containerClassName="modalInput"
						onFocus={() => { setPromoCodeError(null); }}
						onKeyUp={() => { setPromoCodeError(null); }}
						onBlur={() => { setPromoCodeError(null); }}
						onChange={handleOnChange}
						smartField
						isDark
					/>
					<S.PromoButton disabled={promoIsLoading} isLoading={promoIsLoading} onClick={handleAddPromoCode}><Typography variation="2" weight="semibold">Add</Typography></S.PromoButton>
				</InputCollection>

				{/* Actions */}
				<S.ActionContainer>
					<Button disabled={isLoading} isLoading={isLoading} variant="solid" onClick={handleAddPaymentMethod}>Add Card</Button>
				</S.ActionContainer>

			</S.Form>
		</S.StepContainer>
	);
});


/**
 * Configuration
 */

PaymentMethodStep.displayName = 'PaymentMethodStep';
PaymentMethodStep.propTypes = {
	className: PropTypes.string,
	isVisible: PropTypes.bool,
	updateStep: PropTypes.func,
};
PaymentMethodStep.defaultProps = {
	className: null,
	isVisible: false,
	updateStep: null
};
