/*
 * File: ConfirmationStep.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:24 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: November 28, 2023 at 4:25 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, {
	useEffect, forwardRef, useState, useRef
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

// Utilities
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { globalQuery } from '../../../../utilities/state';

// Components
import {
	Typography, Button, ConfettiBurst
} from '../../../../components';

// Constants
import { AMOUNT_PER_RESERVATION_DAY } from '../../../../../Constants';

// Styles
import * as S from './ConfirmationStep.styles';


/**
 * Component
 */

export const ConfirmationStep = forwardRef(({
	isVisible, className
}, ref) => {

	// Create state handlers
	const [fireConfetti, setFireConfetti] = useState(false);

	// Create refs
	const confettiTargetRef = useRef();

	// Use hooks
	const navigate = useNavigate();

	// Get query parameters from hook
	const [searchParams] = useSearchParams();

	// Get current locale from hook
	const stateLocale = useSelector((state) => state.locale.value);

	// Get current trip
	const { currentTrip } = useSelector((state) => state.trips.value);

	// Handle next action
	const handleNext = async () => {

		// Move to trips
		navigate(`${stateLocale.localePath}/trips${globalQuery(searchParams)}`);
	};

	// Perform actions on visibility change
	useEffect(() => {
		if (isVisible === true) {

			// Fire confetti
			setTimeout(() => {
				setFireConfetti(true);
			}, 500);
		}
	}, [isVisible]);

	// Return component
	return (
		<>
			<S.StepContainer ref={ref} className={className}>

				{/* Icon */}
				<S.IconContainer className="isNotMobile">
					<S.Icon>
						<FontAwesomeIcon icon={['fas', 'check']} />
						<S.ConfettiTarget ref={confettiTargetRef} />
					</S.Icon>
				</S.IconContainer>

				{/* Content */}
				<S.ContentContainer>
					<Typography tag="h2" weight="bold">You&apos;re all set!</Typography>
					<Typography tag="p" variation="1" weight="regular">
						We&apos;ve scheduled your reservations for your trip dates. You can make changes to your reservations at any time. When your trip begins, Pixie Dust will charge you $
						{AMOUNT_PER_RESERVATION_DAY}
						{' '}
						for each day that we reserve something for your party.

					</Typography>
				</S.ContentContainer>

				{/* Trip Summary Card */}
				{currentTrip && (
					<S.TripCard
						trip={currentTrip}
						isDark
						isDetail
					/>
				)}

				{/* Actions */}
				<S.ActionContainer>
					<Button variant="solid" onClick={handleNext}>See my trips</Button>
				</S.ActionContainer>
			</S.StepContainer>

			{/* Confetti */}
			{fireConfetti && (
				<ConfettiBurst
					fireAway={fireConfetti}
					targetRef={confettiTargetRef}
					force={0.4}
					duration={3000}
					particleCount={80}
				/>
			)}
		</>
	);
});


/**
 * Configuration
 */

ConfirmationStep.displayName = 'ConfirmationStep';
ConfirmationStep.propTypes = {
	className: PropTypes.string,
	isVisible: PropTypes.bool
};
ConfirmationStep.defaultProps = {
	className: null,
	isVisible: false
};
