/*
 * File: index.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on November 25, 2022 at 4:09 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: March 17, 2023 at 5:43 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Utilities
import api from '../api';


/**
 * Services
 */

export const addPaymentMethod = ({ source, promoCode }) => api.post('/payment', { source, promoCode });

export const updatePaymentMethod = ({
	id, expirationDate, addressPostal, addressCountry
}) => api.post(`/payment/${id}`, { expirationDate, addressPostal, addressCountry });

export const removePaymentMethod = ({ id }) => api.post(`/payment/${id}/delete`);

export const validatePromoCode = ({ code }) => api.post('/payment/promo/validate', { code });
