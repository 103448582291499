/*
 * File: ForgotPassword.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on November 18, 2022 at 3:45 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: May 31, 2023 at 10:15 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import validator from 'validator';

// Utilities
import { createStateLocale } from '../../../utilities/locale';
import { toastError, toastSuccess } from '../../../utilities/toaster';
import { globalQuery } from '../../../utilities/state';

// Services
import { beginPasswordReset } from '../../../services/password';

// Components
import {
	Meta, AppNavigation, Typography, TextInput, Button, InputCollection
} from '../../../components';

// Styles
import * as S from './ForgotPassword.styles';


/**
 * Component
 */

const ForgotPassword = ({ meta, locale }) => {

	// Create state handlers
	const [emailError, setEmailError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [inputValues, setInputValues] = useState({});

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Get actions from hooks
	const navigate = useNavigate();
	const { executeRecaptcha } = useGoogleReCaptcha();

	// Get query parameters from hook
	const [searchParams] = useSearchParams();

	// Handle log in function
	const handleForgotPassword = async () => {

		// Get parameters
		const { email } = inputValues;

		// Validate parameters
		if (!email || !validator.isEmail(email)) {
			setEmailError({ message: 'Please enter your account email address' });
			return;
		}

		// Set loading state
		setIsLoading(true);

		// Get reCAPTCHA token
		let recaptchaToken = null;
		try { recaptchaToken = await executeRecaptcha('begin_password_reset'); } catch (e) {}

		// Begin password reset process
		beginPasswordReset({ email, recaptchaToken }).then(() => {

			// Show success toast
			toastSuccess(uiMode, 'Success! Check your inbox for an email to reset your account password.');

			// Clear email
			setInputValues({ ...inputValues, email: '' });

			// Handle completion actions
			setTimeout(() => {
				navigate(`${stateLocale.localePath}/login${globalQuery(searchParams)}`);
			}, 2500);
		}).catch(({ response }) => {

			// Set loading state
			setIsLoading(false);
			setEmailError(null);

			// Show error message
			if (response?.data?.message) {
				toastError(uiMode, 'Whoops. We can\'t find an account with those details. Please try again.');
			} else {
				toastError(uiMode, 'Whoops. We can\'t find an account with those details. Please try again.');
			}
		});
	};

	// Handle on input change action
	const handleOnChange = (event) => {
		const { name, value } = event.target;
		setInputValues({ ...inputValues, [name]: value });
	};

	// Handle log in action function
	const logInFunction = () => {
		navigate(`${stateLocale.localePath}/login${globalQuery(searchParams)}`);
	};

	// Handle component initialization
	useEffect(() => {}, []);

	// Handle render content
	const renderContent = () => (
		<S.Wrapper>
			<S.Form>

				{/* Logo */}
				<S.LogoMark className="isNotMobile" />

				{/* Content */}
				<Typography tag="h2" weight="bold">Forgot Password?</Typography>
				<Typography tag="p" variation="1" weight="regular">Enter your account email and we&apos;ll send you a link to reset your password.</Typography>

				{/* Inputs */}
				<InputCollection className="floatShadow" isDark>
					<TextInput
						label="Account Email"
						name="email"
						type="text"
						error={emailError}
						value={inputValues.email || ''}
						containerClassName="modalInput"
						autoComplete="email"
						onFocus={() => { setEmailError(null); }}
						onKeyUp={() => { setEmailError(null); }}
						onBlur={() => { setEmailError(null); }}
						onChange={handleOnChange}
						smartField
						isDark
					/>
				</InputCollection>

				{/* Send Button */}
				<S.ActionButton disabled={isLoading} isLoading={isLoading} onClick={() => { handleForgotPassword(); }} size="large" className="floatShadow">Send Reset Link</S.ActionButton>

				{/* Log In Button */}
				<S.BottomActionContainer>
					<Button variant="text" size="small" onClick={() => { logInFunction(); }} disabled={isLoading}>
						<Typography tag="p" variation="2" weight="medium">
							Ready to log in?
							{' '}
							<Typography decoration="underline" weight="medium" style={{ fontSize: 'inherit' }}>
								Back to Log In
							</Typography>
						</Typography>
					</Button>
				</S.BottomActionContainer>
			</S.Form>
		</S.Wrapper>
	);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} />

			{/* Component Content */}
			<AppNavigation containerClassName="gradientBackground" isFixed={false}>
				{renderContent()}
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

ForgotPassword.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape()
};
ForgotPassword.defaultProps = {
	meta: {},
	locale: {}
};


/**
 * Exports
 */

export default ForgotPassword;
