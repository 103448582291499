/*
 * File: Home.styles.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on July 10, 2022 at 6:57 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: January 18, 2023 at 5:48 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Styles
import { ColorValuesCreator } from '../../../styles/colors';

// Constants
import { mediumBreakpoint, largeBreakpoint } from '../../../styles/constants';

// Create color values
const ColorValues = ColorValuesCreator();


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: block;
	padding: 0px 0px 50px;
	margin: 0 auto;

	.devicesHolderHero {
		width: 829px;
		margin-left: 20px;
		top: -150px;
		-webkit-transform: rotate(-4deg);
		transform: rotate(-4deg);
		transform-origin: 100% 0;
		flex-wrap: wrap;
		pointer-events: none;
		position: absolute;
		display: flex;
		z-index: 2;
	}
	@media only screen {
		.devicesHolderHero {
			display: none;
		}
	}
	@media only screen and (min-width: ${mediumBreakpoint}em) {
		.devicesHolderHero {
			left: 73%;
			display: flex;
		}
	}
	@media only screen and (min-width: ${largeBreakpoint}em) {
		.devicesHolderHero {
			left: 70%;
			display: flex;
		}
	}
	.devicesHolder {
		div {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			justify-content: center;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			border-radius: 38px;
			background: ${({ theme }) => theme.primaryBackground};
			-webkit-box-shadow: ${({ theme }) => `inset 0 4px 7px 1px ${theme.primaryBackground}, inset 0 -5px 20px rgba(173, 186, 204, 0.25), 0 2px 6px rgba(0, 21, 64, 0.14), 0 10px 20px rgba(0, 21, 64, 0.08)`};
			box-shadow: ${({ theme }) => `inset 0 4px 7px 1px ${theme.primaryBackground}, inset 0 -5px 20px rgba(173, 186, 204, 0.25), 0 2px 6px rgba(0, 21, 64, 0.14), 0 10px 20px rgba(0, 21, 64, 0.08)`};
		}
		.centered {
			margin: 0px auto;
		}
	}
	.tabletLandscape {
		align-self: flex-end;
		margin-right: 50px;
		width: 512px;
		height: 352px;
	}
	.phoneLarge {
		display: flex;
		width: 267px;
		height: 553px;
	}
	.phoneSmall {
		order: 0;
		margin: 50px 50px 0 0;
		width: 225px;
		height: 467px;
	}
	.tabletPortrait {
		display: flex;
		width: 450px;
		height: 675px;
		margin-top: 50px;
		position: relative;
	}
`;

export const HeroSection = styled.div`
	padding: 40px 0px 80px;
	position: relative;

	@media only screen and (min-width: ${largeBreakpoint}em) {
		padding: 60px 0px 80px;
	}
`;

export const CommunityUpdateContainer = styled.div`
	padding: 15px 20px;
	box-shadow: ${ColorValues.elementShadow};
	background-color: ${({ theme }) => theme.primaryBackground};
	border-radius: 10px;
	position: relative;
	top: -15px;

	p {
		text-align: left;
	}
	span {
		font-size: inherit !important;
	}
`;

export const HeroContentContainer = styled.div`
	width: 100%;
	max-width: 600px;
	margin-top: 30px;
	margin-bottom: 30px;

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		max-width: 500px;
	}
	@media only screen and (min-width: ${largeBreakpoint}em) {
		max-width: 600px;
	}
	* {
		text-align: left;
	}
	h1 {
		color: ${ColorValues.white};
	}
	p {
		margin-top: 20px;
		font-size: 1.2rem;
		color: ${ColorValues.white};
	}
	button {
		margin-top: 20px;
		box-shadow: ${ColorValues.elementShadow};
	}
`;

export const ContentPairSection = styled.div`
	display: flex;
	gap: 60px;
	margin: 80px 0px 60px;
	flex-direction: column;
	align-items: center;
    justify-content: center;
	position: relative;

	@media only screen and (min-width: ${largeBreakpoint}em) {
		flex-direction: row;
		margin: 130px 0px 0px;
	}
`;

export const ContentPairContainer = styled.div`
	flex-grow: 1;
`;

export const ContentPairContent = styled.div`
	width: 100%;
	max-width: 550px;

	* {
		text-align: center;

		@media only screen and (min-width: ${largeBreakpoint}em) {
			text-align: left;
		}
	}
	h4 {
		text-transform: uppercase;
		color: ${({ theme }) => theme.secondaryText};
		font-size: 0.85rem;
		margin-bottom: 15px;
	}
	p {
		margin-top: 15px;
		font-size: 1.2rem;
	}
`;

export const ImagePairContainer = styled.div`
	position: relative;
`;

export const PairImage = styled.img`
	width: 100%;
	max-width: 450px;
	height: auto;
	position: relative;
	z-index: 2;
`;

export const ContentContainer = styled.div`
	width: 100%;
	max-width: 600px;
	margin-top: 30px;

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		max-width: 400px;
	}
	@media only screen and (min-width: ${largeBreakpoint}em) {
		max-width: 600px;
	}
	* {
		text-align: left;
	}
	p {
		margin-top: 20px;
		font-size: 1.2rem;
	}
`;

export const FeatureSection = styled.div`
	padding: 100px 0px;
	text-align: center;
	position: relative;

	h4 {
		text-transform: uppercase;
		color: ${({ theme }) => theme.secondaryText};
		font-size: 0.85rem;
		margin-bottom: 15px;
	}
`;

export const FeatureContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-gap: 25px;
	margin-top: 30px;

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		grid-template-columns: repeat(2, 1fr);
	}
	@media only screen and (min-width: ${largeBreakpoint}em) {
		grid-template-columns: repeat(3, 1fr);
	}
`;

export const Feature = styled.div`
	padding: 20px;
	border-radius: 10px;
	background-color: ${({ theme }) => theme.secondaryBackground};
	text-align: left;
	position: relative;

	p {
		text-align: left;
		font-size: 1.2rem;
		margin-top: 5px;
	}
`;

export const ComingSoonBadge = styled.div`
	background-color: ${({ theme }) => theme.primaryBackground};
	position: absolute;
	padding: 1px 10px 5px;
	border-radius: 20px;
	top: 30px;
	left: 72px;
`;

export const FeatureContentContainer = styled.div`
	width: 100%;
	max-width: 600px;
	margin: 0px auto 40px;

	p {
		margin-top: 15px;
	}
	button {
		margin: 30px auto;
	}
`;

export const PricingSection = styled.div`
	padding: 20px 0px 0px;
	text-align: center;
	position: relative;

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		padding: 20px 0px 100px;
	}
	h4 {
		text-transform: uppercase;
		color: ${({ theme }) => theme.secondaryText};
		font-size: 0.85rem;
		margin-bottom: 15px;
	}
`;

export const PricingContainer = styled.div`
	display: inline-block;
	margin: 0 auto;
	text-align: center;
	color: ${({ theme }) => theme.secondaryText};

	.percentage {
		font-size: 7.0rem;
		margin-right: 8px;
		color: ${({ theme }) => theme.brandPrimaryYellow};
	}
`;

export const ExampleContainer = styled.div`
	margin: 25px 0px -10px;
	background-color: ${({ theme }) => theme.secondaryBackground};
	border-radius: 8px;
	padding: 16px 25px;
	position: relative;

	* {
		margin: 0px !important;
		text-align: left;
	}
	.highlight {
		color: ${({ theme }) => theme.brandPrimaryYellow};
	}
`;

export const HeaderDecoration = styled.div`
	width: 100%;
	background-image: url('${process.env.CDN_URL}/public/assets/wave-decoration-2.png');
	background-size: cover;
	background-position: 25%;
	background-repeat: no-repeat;
	position: absolute !important;
	left: 0px;
	top: 0px;
	height: ${({ decorationHeight }) => `${decorationHeight + 300}px`};
`;

export const DecorationContainer = styled.div`
	width: 100%;
	height: 40vw;
`;

export const DisneyParkDecoration = styled.img`
	width: 100%;
	position: absolute;
	left: 0px;
`;

export const FrameDecoration = styled.img`
	width: 130%;
	height: 130%;
	position: absolute !important;
	top: -15%;
	left: -15%;
	z-index: 1;
`;

export const ParkBadge = styled.div`
	width: 80px;
	height: 80px;
	background-image: url('${process.env.CDN_URL}/public/assets/family-castle.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	position: absolute !important;
	right: -50px;
	top: -30px;
	border-radius: 50%;
	border: 4px solid ${({ theme }) => theme.primaryBackground};
	box-shadow: ${ColorValues.elementDarkShadow};
`;
