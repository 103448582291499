/*
 * File: EmptyComponent.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 22, 2022 at 10:36 AM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: August 2, 2023 at 3:08 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'beautiful-react-hooks';

// Components
import { Typography } from '../Typography';

// Constants
import { mobileBreakpoint } from '../../styles/constants';

// Styles
import * as S from './EmptyComponent.styles';


/**
 * Component
 */

export const EmptyComponent = ({
	title, message, icon, isDark, isLight, className
}) => {

	// Check if mobile screen size
	const isMobile = useMediaQuery(`(max-width: ${mobileBreakpoint}em)`);

	// Render component
	return (
		<S.Wrapper isDark={isDark} isLight={isLight} className={className}>
			<FontAwesomeIcon className="empty-icon" icon={icon} size={isMobile ? '3x' : '5x'} />
			<Typography tag="h4" weight="semibold" center>
				{title}
			</Typography>
			<Typography tag="p" className="description" center>
				{message}
			</Typography>
		</S.Wrapper>
	);
};


/**
 * Configuration
 */

EmptyComponent.displayName = 'EmptyComponent';
EmptyComponent.propTypes = {
	title: PropTypes.string.isRequired,
	message: PropTypes.string.isRequired,
	icon: PropTypes.arrayOf(PropTypes.string),
	isDark: PropTypes.bool,
	isLight: PropTypes.bool,
	className: PropTypes.string,
};
EmptyComponent.defaultProps = {
	icon: ['fa', 'hashtag'],
	isDark: false,
	isLight: false,
	className: null
};
