/*
 * File: PaymentHistory.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on March 5, 2023 at 1:36 PM
 * Copyright © 2023 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: March 7, 2023 at 1:09 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import PropTypes from 'prop-types';

// Components
import {
	Emoji,
	EmptyComponent, Table, Typography
} from '../../../../components';
import { CurrencyCell, DateCell } from '../../../../components/Table/Table';

// Constants
import {
	PAYMENT_STATUSES, ICONS, SCHEDULED_EVENT_TYPES, PARKS
} from '../../../../../Constants';

// Styles
import * as S from './PaymentHistory.styles';


/**
 * Status Cell
 */

// Component
export const StatusCell = ({ value }) => {

	// Generate status label
	const statusLabel = () => {
		switch (value) {
			case PAYMENT_STATUSES.COMPLETE:
				return 'Complete';
			case PAYMENT_STATUSES.IN_PROGRESS:
				return 'In Progress';
			case PAYMENT_STATUSES.PENDING:
				return 'Pending';
			case PAYMENT_STATUSES.CANCELED:
				return 'Canceled';
			case PAYMENT_STATUSES.REFUNDED:
				return 'Refunded';
			default:
				return 'In Progress';
		}
	};

	// Return component
	return (
		<S.StatusContainer>
			<S.StatusIndicator $status={value} />
			<Typography variation="2" weight="medium" truncate>{statusLabel()}</Typography>
		</S.StatusContainer>
	);
};

// Configuration
StatusCell.propTypes = {
	value: PropTypes.string,
};
StatusCell.defaultProps = {
	value: null
};


/**
 * Description Cell
 */

// Component
export const DescriptionCell = ({ row }) => (
	<>
		<Typography variation="2" weight="medium">{row.original.description}</Typography>
		<S.ReservationContainer>
			{row.original.formattedReservations.map(({ name, eventType, park }, index) => {

				// Initialize content
				let icon = '';
				let content = '';
				const key = `${index}-${name}`;

				// Create content for type
				if (eventType === SCHEDULED_EVENT_TYPES.GENIE_PLUS) {
					icon = ICONS.GENIE_PLUS.icon;
					content = 'Genie+ for party members';
				} else if (eventType === SCHEDULED_EVENT_TYPES.LL || eventType === SCHEDULED_EVENT_TYPES.ILL) {
					icon = park ? ICONS[Object.values(PARKS).find((obj) => obj.id === park.parkId).primaryLookup].icon : ICONS.GENIE_PLUS.icon;
					content = name;
				} else if (eventType === SCHEDULED_EVENT_TYPES.DINING) {
					icon = ICONS.GENIE_PLUS.icon;
					icon = ICONS.DINING.icon;
					content = name;
				} else if (eventType === SCHEDULED_EVENT_TYPES.VIRTUAL_QUEUE) {
					icon = park ? ICONS[Object.values(PARKS).find((obj) => obj.id === park.parkId).primaryLookup].icon : ICONS.GENIE_PLUS.icon;
					content = name;
				}

				// Render component
				return (
					<S.Reservation key={key}>
						<Typography variation="2" weight="medium">
							<Emoji label={content} symbol={icon} size={1.0} />
							{' '}
							{content}
						</Typography>
					</S.Reservation>
				);
			})}
		</S.ReservationContainer>
	</>
);

// Configuration
DescriptionCell.propTypes = {
	row: PropTypes.shape(),
};
DescriptionCell.defaultProps = {
	row: null
};


/**
 * Component
 */

export const PaymentHistory = ({ className, paymentHistory }) => {

	// Render component
	const renderComponent = () => {
		if (paymentHistory.length === 0) {
			return (
				<EmptyComponent
					isLight
					title="You don't have any completed payments"
					message="Booking charges from your scheduled trips will appear here."
					icon={['far', 'credit-card']}
				/>
			);
		}
		return (
			<S.TableContainer>
				<Table
					data={paymentHistory}
					alignTop
					isDark
					columns={[
						{
							Header: 'Status',
							accessor: 'status',
							Cell: StatusCell
						},
						{
							Header: 'Date',
							accessor: 'dateString',
							dateFormatOptions: {
								input: 'YYYY-MM-DD',
								output: 'MMM D, YYYY'
							},
							Cell: DateCell
						},
						{
							Header: 'Description',
							accessor: 'description',
							Cell: DescriptionCell
						},
						{
							Header: 'Amount',
							accessor: 'amount',
							Cell: CurrencyCell
						},
					]}
				/>
			</S.TableContainer>
		);
	};

	// Return component
	return (
		<S.Wrapper className={className}>
			{renderComponent()}
		</S.Wrapper>
	);
};


/**
 * Configuration
 */

PaymentHistory.displayName = 'PaymentHistory';
PaymentHistory.propTypes = {
	className: PropTypes.string,
	paymentHistory: PropTypes.arrayOf(PropTypes.shape())
};
PaymentHistory.defaultProps = {
	className: null,
	paymentHistory: []
};
