/*
 * File: BlogTopic.styles.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on July 10, 2022 at 6:57 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: March 10, 2023 at 11:11 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: 0px 0px 60px;
	margin: 0 auto;
`;

export const SectionContainer = styled.div`
	padding: 20px 0px 100px;

	h2,
	h3 {
		text-align: left;
	}
	.title {
		margin: 0px 0px 30px;
	}
`;
