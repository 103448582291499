/*
 * File: index.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on November 19, 2022 at 1:22 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 17, 2023 at 5:27 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Utilities
import api from '../api';


/**
 * Services
 */

export const createUpdateTrip = ({
	id, name, days, shouldPurchaseGeniePlus, parkEntity
}) => api.post('/trips', {
	id, name, days, shouldPurchaseGeniePlus, parkEntity
});

export const deleteTrip = ({ id }) => api.post(`/trips/${id}/delete`, { id });

export const refreshTrip = ({ id }) => api.post(`/trips/${id}/refresh`, { id });

export const getTrip = ({ id }) => api.get(`/trips/${id}`, { id });

export const getTrips = (data) => api.get('/trips', data);
