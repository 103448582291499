/*
 * File: UpdatePasswordModal.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: March 7, 2023 at 12:45 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';
import { useSelector } from 'react-redux';

// Utilities
import { toastError, toastSuccess } from '../../../../utilities/toaster';

// Services
import { resetPassword } from '../../../../services/password';

// Components
import {
	Button, Modal, Typography, TextInput, PasswordInput
} from '../../../../components';

// Styles
import * as S from './UpdatePasswordModal.styles';


/**
 * Component
 */

export const UpdatePasswordModal = ({
	className, isOpen, handleClose
}) => {

	// Get current user
	const user = useSelector((state) => state.user.value);

	// Create state handlers
	const [passwordError, setPasswordError] = useState(null);
	const [passwordRulesError, setPasswordRulesError] = useState(null);
	const [confirmPasswordError, setConfirmPasswordError] = useState(null);
	const [currentPasswordError, setCurrentPasswordError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [inputValues, setInputValues] = useState({});

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Handle update user password
	const updateUserPassword = async () => {

		// Get parameters
		const {
			currentPassword, password, confirmPassword
		} = inputValues;

		// Validate parameters
		if (!currentPassword || validator.isEmpty(currentPassword, { ignore_whitespace: true })) {
			setCurrentPasswordError({ message: 'Please enter your current password' });
			return;
		}
		if (!password || validator.isEmpty(password, { ignore_whitespace: true }) || passwordError || passwordRulesError) {
			setPasswordError({ message: passwordError?.message ? passwordError?.message : 'Please create a password for your Pixie Dust account' });
			return;
		}
		if (!confirmPassword || validator.isEmpty(confirmPassword, { ignore_whitespace: true }) || password !== confirmPassword) {
			setConfirmPasswordError({ message: 'Your passwords must match' });
			return;
		}

		// Set loading state
		setIsLoading(true);

		// Reset user's password
		resetPassword({
			pass: password, existingPass: currentPassword
		}).then(() => {

			// Show success toast
			toastSuccess(uiMode, 'Your password has successfully been updated!');

			// Set loading state
			setIsLoading(false);

			// Clear inputs
			setInputValues({});

			// Handle completion actions
			setTimeout(() => {
				if (handleClose) handleClose();
			}, 2500);

		}).catch(({ response }) => {

			// Set loading state
			setIsLoading(false);
			setPasswordError(null);
			setPasswordRulesError(null);
			setConfirmPasswordError(null);
			setCurrentPasswordError(null);

			// Clear inputs
			setInputValues({});

			// Show error message
			if (response?.data?.message) {
				toastError(uiMode, response?.data?.message);
			} else {
				toastError(uiMode, 'Whoops. We\'re having trouble updating your password. Please try again.');
			}
		});
	};

	// Handle on input change action
	const handleOnChange = (event) => {
		const { name, value } = event.target;
		setInputValues({ ...inputValues, [name]: value });
	};

	// Handle actions on component load
	useEffect(() => {
		if (isOpen === true) {

			// Clear inputs
			setInputValues({});

			// Hide errors
			setPasswordError(null);
			setPasswordRulesError(null);
			setConfirmPasswordError(null);
			setCurrentPasswordError(null);
		}
	}, [isOpen]);

	// Render component
	return (
		<Modal className={className} isOpen={isOpen} handleClose={handleClose} clickOutsideClose useWrapper>
			<S.ModalInner>

				{/* Content */}
				<Typography tag="h3" weight="bold">Account Password</Typography>
				<Typography tag="p" variation="1" weight="light" className="subtitle">Create a strong new password for your account.</Typography>

				{/* Inputs */}
				<TextInput
					label="Current password"
					type="password"
					name="currentPassword"
					error={currentPasswordError}
					value={inputValues.currentPassword || ''}
					containerClassName="modalInput"
					autoComplete="password"
					onFocus={() => { setCurrentPasswordError(null); }}
					onKeyUp={() => { setCurrentPasswordError(null); }}
					onBlur={() => { setCurrentPasswordError(null); }}
					onChange={handleOnChange}
				/>
				<PasswordInput
					label="Create a new password"
					onChange={handleOnChange}
					setError={setPasswordError}
					setRulesError={setPasswordRulesError}
					rulesError={passwordRulesError}
					autoComplete="new-password"
					value={inputValues.password || ''}
					disallowedContent={[
						user?.email
					].filter(Boolean)}
					error={passwordError}
				/>
				<TextInput
					label="Confirm your password"
					type="password"
					name="confirmPassword"
					error={confirmPasswordError}
					value={inputValues.confirmPassword || ''}
					containerClassName="modalInput"
					autoComplete="new-password"
					onFocus={() => { setConfirmPasswordError(null); }}
					onKeyUp={() => { setConfirmPasswordError(null); }}
					onBlur={() => { setConfirmPasswordError(null); }}
					onChange={handleOnChange}
				/>

				{/* Actions */}
				<S.ActionContainer>
					<Button disabled={isLoading} isLoading={isLoading} variant="solid" onClick={updateUserPassword}>Update</Button>
				</S.ActionContainer>

			</S.ModalInner>
		</Modal>
	);
};


/**
 * Configuration
 */

UpdatePasswordModal.displayName = 'UpdatePasswordModal';
UpdatePasswordModal.propTypes = {
	className: PropTypes.string,
	isOpen: PropTypes.bool,
	handleClose: PropTypes.func,
};
UpdatePasswordModal.defaultProps = {
	className: null,
	isOpen: false,
	handleClose: null,
};
