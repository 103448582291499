/*
 * File: DynamicGuide.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on May 25, 2023 at 9:45 AM
 * Copyright © 2023 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: September 5, 2023 at 10:37 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import 'isomorphic-fetch';
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactDOMServer from 'react-dom/server';
import { StaticRouter } from 'react-router-dom/server';
import { I18nextProvider } from 'react-i18next';

// Stores
import store from '../../../store';

// Utilities
import { createStateLocale } from '../../../utilities/locale';
import { scrollPageTo } from '../../../utilities/position';
import { dateObj, formatMinuteLength } from '../../../../utilities/dateTime';
import { generateRawTimeToRead } from '../../../../utilities/utilities';

// Services
import { getDynamicGuide } from '../../../services/guide';

// Components
import {
	Meta, AppNavigation, Typography, BlogSection, Badge, Button, Spinner, ErrorComponent, SchemaScript, LocaleLink, Emoji
} from '../../../components';
import { buildDynamicMeta } from '../../../components/Meta';

// Constants
import { SHARE_OPTIONS, SCHEDULED_EVENT_TYPES } from '../../../../Constants';

// Styles
import * as S from './DynamicGuide.styles';


/**
 * Component
 */

const DynamicGuide = ({ meta, locale, data }) => {

	// Get component parameters
	const { slug, operatorId } = useParams();

	// Create reference for components
	const isMounted = useRef(true);
	const shouldReload = useRef(false);
	const enableDynamicLoad = useRef(false);

	// Create state handlers
	const [pageStatus, setPageStatus] = useState(!data?.guide ? 'idle' : 'success');
	const [guide, setGuide] = useState(data?.guide);
	const [timeToRead, setTimeToRead] = useState(5);

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Handle sharing action
	const shareAction = (action) => {

		// Create share content
		const shareURL = `${process.env.APP_URL}/guide/${guide?.slug}/${guide?.operatorId}`;
		const shareTitle = guide?.metaTitle;
		const shareImage = guide?.images?.cover?.['1200'];

		// Handle action
		switch (action) {
			case SHARE_OPTIONS.FACEBOOK: {

				// Share to Facebook
				const url = `https://www.facebook.com/sharer.php?u=${encodeURIComponent(shareURL)}`;
				window.open(url, '_blank');

				// Break
				break;
			}
			case SHARE_OPTIONS.TWITTER: {

				// Create content
				const content = `Check out ${shareTitle} 👀`;

				// Share to Twitter
				const url = `https://twitter.com/share?url=${encodeURIComponent(shareURL)}&text=${encodeURIComponent(content)}`;
				window.open(url, '_blank');

				// Break
				break;
			}
			case SHARE_OPTIONS.REDDIT: {

				// Create content
				const content = `Check out ${shareTitle}`;

				// Share to Reddit
				const url = `https://reddit.com/submit?url=${encodeURIComponent(shareURL)}&title=${encodeURIComponent(content)}`;
				window.open(url, '_blank');

				// Break
				break;
			}
			case SHARE_OPTIONS.PINTEREST: {

				// Share to Pinterest
				const url = `https://pinterest.com/pin/create/bookmarklet/?media=${encodeURIComponent(shareImage)}&url=${encodeURIComponent(shareURL)}&is_video=true&description=${encodeURIComponent(shareTitle)}`;
				window.open(url, '_blank');

				// Break
				break;
			}
			case SHARE_OPTIONS.LINKEDIN: {

				// Share to LinkedIn
				const url = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(shareURL)}}`;
				window.open(url, '_blank');

				// Break
				break;
			}
			case SHARE_OPTIONS.EMAIL: {

				// Create content
				const content = `Check out ${shareTitle}`;

				// Share to email
				const url = `mailto:?Subject=${encodeURIComponent(content)}&Body=${encodeURIComponent(shareURL)}`;
				window.open(url, '_blank');

				// Break
				break;
			}
			default:
				break;
		}
	};

	// Handle fetch data for page
	const fetchDataForPage = async () => {

		// Update page status
		setPageStatus('loading');
		try {

			// Fetch current blog topic if necessary
			if (!guide || shouldReload.current === true) {

				// Fetch guide
				const { data: datum } = await getDynamicGuide({ operatorId });

				// Set new data state
				if (isMounted.current) {

					// Update component data
					setGuide(datum.guide);
				}
			}

			// Update state
			shouldReload.current = false;

			// Update page status
			if (isMounted.current) {
				setPageStatus('success');
			}
		} catch (error) {

			// Ensure component is mounted
			if (isMounted.current) {

				// Update page status
				setPageStatus('error');
			}
		}
	};

	// Handle content render
	const renderContent = () => {

		// Initialize step
		let step = 0;

		// Render content
		// eslint-disable-next-line no-return-assign
		return (
			<>

				{/* NV - HANDLE DINING CONTENT */}

				{/* Introduction */}
				<Typography tag="p">
					It&#x27;s no secret that
					{' '}
					{guide?.park}
					&#x27;s beloved attraction,
					{' '}
					{guide?.name}
					, is a fantastic ride for
					{' '}
					{guide?.attraction?.intense && guide?.attraction?.frightening ? `${guide?.shortBaseResort} thrill-seekers` : `everyone looking to have a great time at ${guide?.baseResort}`}
					. In this
					guide, we&#x27;ll walk you through how to skip the long lines for
					{' '}
					{guide?.shortName}
					{' '}
					with Pixie Dust and
					{' '}
					{guide?.accessTypeName}
					.
				</Typography>
				<Typography tag="p">
					In case anyone is looking to experience it completely fresh or for the first time, we won&apos;t spoil too much here.
					What we can spoil is that the lines are long. Really long. And pretty much like that all day.
					{' '}
					{guide?.accessTypeName}
					{' '}
					is definitely your best friend when it
					comes to making sure you and your friends and family can experience
					{' '}
					{guide?.shortName}
					. However, even with these options,
					you still have to get up before dawn and have that refresh button ready right at 7:00 am or all the
					slots might be gone within a few minutes.
				</Typography>
				<Typography tag="p">
					This is one of the biggest reasons we built Pixie Dust. The early morning
					stress of Genie+ and
					{' '}
					{guide?.accessTypeName}
					{' '}
					reservations really made the start of each of our park days
					pretty hectic. So, we created an app to work with Disney and make your
					reservations as soon as the available window opens.
				</Typography>

				{/* Highlight Section */}
				<S.HighlightSection>
					<Typography tag="p">
						<Emoji symbol="⭐" label="star" size={1.2} />
						{' '}
						<b>Pixie Dust Tip:</b>
						{' '}
						Since Pixie Dust connects directly to
						your Disney account, we can typically reserve your
						{' '}
						{guide?.accessTypeName}
						s faster than everyone
						not using Pixie Dust. Just a small perk
						{' '}
						<Emoji symbol="😉" label="star" size={1.2} />
					</Typography>
				</S.HighlightSection>

				{/* Guide */}
				<Typography tag="p">
					So, let&#x27;s get to the guide. For the best experience on
					{' '}
					{guide?.name}
					, follow the steps below. Make sure to have the
					{' '}
					<LocaleLink to="https://www.pixiedustguide.com/story/a-step-by-step-guide-to-using-the-my-disney-experience-app-part-1">My Disney Experience or Disneyland app</LocaleLink>
					{' '}
					downloaded before your trip as well - it&#x27;s your best friend around the parks!
				</Typography>
				<br />

				{/* Step 1 */}
				<Typography tag="p">
					<b>{`Step ${step += 1}:`}</b>
					{' '}
					Sign up for Pixie Dust. If you haven’t created an account
					with us yet, it’s completely free to do that
					{' '}
					<LocaleLink to="https://www.pixiedustguide.com/join" target="_blank">here</LocaleLink>
					. We just ask for a bit of information to
					get started.
				</Typography>

				{/* Step 2 */}
				<Typography tag="p">
					<b>{`Step ${step += 1}:`}</b>
					{' '}
					Connect your My Disney Experience account. Pixie Dust
					connects directly to your Disney account, so we ask you to securely connect to your My
					Disney Experience or Disneyland account, and we&#x27;ll do the automatic linking of your scheduled experiences to
					Genie+ and
					{' '}
					{guide?.accessTypeName}
					{' '}
					reservations.

				</Typography>
				<Typography tag="p">
					<img
						alt="Pixie Dust screen to connect to My Disney Experience account"
						src="https://a-us.storyblok.com/f/1003989/3456x1340/e26a8346bc/pixie-dust-connect-to-my-disney-experience.png"
						title=""
					/>
				</Typography>
				<br />

				{/* Step 3 */}
				<Typography tag="p">
					<b>{`Step ${step += 1}:`}</b>
					{' '}
					Create a Pixie Dust trip. Pixie Dust organizes your Disney
					experiences into &quot;trips&quot;, where you can organize your park days and add all your party
					members to your reservations. And, you can start to plan your next Disney trip with Pixie Dust as
					soon as you like, even months in advance!
				</Typography>
				<Typography tag="p">
					<img
						alt="Pixie Dust dashboard screen with Schedule Trip button"
						src="https://a-us.storyblok.com/f/1003989/3442x1578/72516a8a5a/pixie-dust-dashboard.png"
						title=""
					/>
				</Typography>
				<br />

				{/* Step 4 */}
				<Typography tag="p">
					<b>{`Step ${step += 1}:`}</b>
					{' '}
					Select the dates of your trip. When you create a new trip,
					Pixie Dust will ask for your Disney dates. We&#x27;ll use this to schedule your reservations.
				</Typography>
				<Typography tag="p">
					<img
						alt="Pixie Dust screen select dates when scheduling trip"
						src="https://a-us.storyblok.com/f/1003989/3450x1504/cf15ef7d4a/pixie-dust-select-dates-trip.png"
						title=""
					/>
				</Typography>
				<br />

				{/* Step 5 */}
				<Typography tag="p">
					<b>{`Step ${step += 1}:`}</b>
					{' '}
					Build your perfect
					{' '}
					{guide?.park}
					{' '}
					day. Once your days are selected,
					you&#x27;ll be able to add reservations to them. This is as easy as clicking the &quot;Add
					Reservation&quot; button and searching for your favorite Disney attraction. To schedule
					{' '}
					{guide?.name}
					, start typing into the search bar, and select the ride when it comes up.
					{' '}
				</Typography>
				<Typography tag="p">
					<img
						alt={`Pixie Dust schedule screen ${guide?.name}`}
						src="https://a-us.storyblok.com/f/1003989/3456x1508/52578ce973/pixie-dust-schedule-ll-guardians.png"
						title=""
					/>
				</Typography>
				<br />

				{/* Step 6 */}
				{guide?.accessType === SCHEDULED_EVENT_TYPES.ILL && (
					<>
						<Typography tag="p">
							<b>{`Step ${step += 1}:`}</b>
							{' '}
							Select when you want to ride. Since
							{' '}
							{guide?.name}
							{' '}
							is
							{' '}
							{guide?.fullAccessTypeName}
							{' '}
							experience, you can choose what time you would like to ride! Pixie
							Dust will still make the reservation as soon as the system opens in the morning, but we will request
							your preferred ride time. Note that sometimes Disney will give you the closest possible timeslot to
							the one that you&#x27;ve chosen, so we&#x27;ll let you know what is reserved.
						</Typography>
						<Typography tag="p">
							<img
								alt="Pixie Dust schedule lightning lane time"
								src="https://a-us.storyblok.com/f/1003989/3456x1694/26b9aebfb5/pixie-dust-schedule-ll-select-time.png"
								title=""
							/>
						</Typography>
						<br />
					</>
				)}

				{/* Step 7 */}
				<Typography tag="p">
					<b>{`Step ${step += 1}:`}</b>
					{' '}
					Select your ride party. On the same screen as the
					timeslots, you will see a list of all of your available friends and family. These are party members
					that you are connected to on your My Disney Experience account. Go ahead and select the people you
					want to ride
					{' '}
					{guide?.shortName}
					{' '}
					with you. Make sure that each person has a valid Disney park ticket and park
					reservation.
				</Typography>
				<Typography tag="p">
					<img
						alt="Pixie Dust screen for selecting party for Lightning Lane"
						src="https://a-us.storyblok.com/f/1003989/3456x1692/89ac673995/pixie-dust-select-party-ll.png"
						title=""
					/>
				</Typography>
				<br />

				{/* Step 8 */}
				<Typography tag="p">
					<b>{`Step ${step += 1}:`}</b>
					{' '}
					Save and schedule. As soon as you&#x27;re done selecting
					your party, click the complete button save your new reservation. You&#x27;ll see it appear on your
					trip day. When you&#x27;ve scheduled all the reservations you want, click the &quot;Schedule&quot;
					button below your trip days. Voila! Your reservations are ready to go. Starting first thing in the morning on your park
					day, We will automagically schedule your
					{' '}
					{guide?.name}
					{' '}
					{guide?.accessTypeName}
					{' '}
					the instant they become available.
				</Typography>
				<Typography tag="p">
					<img
						alt="Pixie Dust trip scheduling screen with scheduled Lightning Lane"
						src="https://a-us.storyblok.com/f/1003989/3456x1494/05019f6707/pixie-dust-schedule-day-guardians-ll.png"
						title=""
					/>
				</Typography>
				<br />

				{/* Step 9 */}
				<Typography tag="p">
					<b>{`Step ${step += 1}:`}</b>
					{' '}
					Have a blast!
				</Typography>
				<br />

				{/* Conclusion */}
				<Typography tag="p">
					<b>P.S.</b>
					{' '}
					You can schedule reservations for any
					{' '}
					{guide?.accessTypeName}
					{' '}
					attraction at any of the four Disney World parks, Disneyland, and Disney&apos;s California Adventure with Pixie Dust!
				</Typography>
				<Typography tag="p">
					Skipping the line for
					{' '}
					{guide?.name}
					{' '}
					at
					{' '}
					{guide?.park}
					{' '}
					is a great way to
					maximize your time spent exploring the park and getting to the fun part. By using Pixie Dust and
					{' '}
					{guide?.accessTypeName}
					, you can save yourself the time, hassle, and frustration all while enjoying
					everything that this brand new attraction has to offer. Happy skipping!
				</Typography>
			</>
		);
	};

	// Handle actions on app component state change
	useEffect(() => {

		// Ensure initial page loading is not complete
		if (pageStatus === 'idle') {

			// Fetch data state for page
			fetchDataForPage();
		}
	}, [pageStatus]);

	// Handle actions on route change (dynamic page)
	useEffect(() => {

		// Set page status if necessary
		if (enableDynamicLoad.current === true && pageStatus !== 'idle') {

			// Update state
			shouldReload.current = true;

			// Reset page status
			setPageStatus('idle');
		}

		// Scroll page to top
		scrollPageTo(0, 0);

	}, [slug]);

	// Handle actions on guide changed
	useEffect(() => {
		if (guide != null) {

			// Render content
			const content = renderContent();

			// Parse content
			const html = ReactDOMServer.renderToString(
				<Provider store={store}>
					<I18nextProvider>
						<StaticRouter location={window.location}>
							{content}
						</StaticRouter>
					</I18nextProvider>
				</Provider>
			);

			// Generate time to read
			const readTime = generateRawTimeToRead(html);
			setTimeToRead(readTime);
		}
	}, [guide]);

	// Handle component initialization
	useEffect(() => {

		// Set state
		isMounted.current = true;

		// Enable dynamic load
		enableDynamicLoad.current = true;

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, []);

	// Handle component render
	const renderComponent = () => {
		if (pageStatus === 'idle' || pageStatus === 'loading') {
			return <Spinner showMeta meta={meta} />;
		} if (pageStatus === 'error') {
			return <ErrorComponent locale={stateLocale} />;
		}
		return (
			<>
				{/* Content Padding */}
				<S.ContentPadding>

					{/* Topic */}
					<S.TopicContainer>
						<S.LocaleLink to="/blog/topic/how-to-guides">
							<Badge size="large">
								<Typography weight="semibold">
									{guide?.shortBaseResort}
									{' '}
									Guides
								</Typography>
							</Badge>
						</S.LocaleLink>
					</S.TopicContainer>

					{/* Title */}
					<Typography tag="h1" weight="extrabold">{guide?.metaTitle}</Typography>

					{/* Detail Container */}
					<S.DetailContainer>

						{/* Author */}
						<S.Detail>
							<Typography variation="1" weight="semibold">
								By Pixie Dust
							</Typography>
						</S.Detail>

						{/* Update Date */}
						<S.Detail>
							<FontAwesomeIcon icon={['far', 'calendar']} />
							<Typography variation="1">{dateObj(guide.modifiedTime).format('MMMM D, YYYY')}</Typography>
						</S.Detail>

						{/* Read Time */}
						<S.Detail>
							<FontAwesomeIcon icon={['far', 'clock']} />
							<Typography variation="1">{formatMinuteLength(timeToRead)}</Typography>
						</S.Detail>

					</S.DetailContainer>
				</S.ContentPadding>

				{/* Image */}
				<S.BlogImage src={guide?.images?.cover?.['1200']} alt={`${guide?.metaTitle}`} />

				{/* Content Padding */}
				<S.ContentPadding>

					{/* Content */}
					<S.ContentContainer className="paragraphContent">{renderContent()}</S.ContentContainer>

					{/* Share Container */}
					<S.ShareContainer>

						{/* Title */}
						<Typography tag="h4" weight="bold">Share this guide</Typography>

						{/* Share Widgets */}
						<S.ShareWidgetContainer>

							{/* Facebook */}
							<Button variant="solid" className="facebook" onClick={() => { shareAction(SHARE_OPTIONS.FACEBOOK); }}>
								<FontAwesomeIcon icon={['fab', 'facebook']} />
								<Typography weight="semibold">Facebook</Typography>
							</Button>

							{/* Twitter */}
							<Button variant="solid" className="twitter" onClick={() => { shareAction(SHARE_OPTIONS.TWITTER); }}>
								<FontAwesomeIcon icon={['fab', 'twitter']} />
								<Typography weight="semibold">Twitter</Typography>
							</Button>

							{/* Reddit */}
							<Button variant="solid" className="reddit" onClick={() => { shareAction(SHARE_OPTIONS.REDDIT); }}>
								<FontAwesomeIcon icon={['fab', 'reddit-alien']} />
								<Typography weight="semibold">Reddit</Typography>
							</Button>

							{/* Pinterest */}
							<Button variant="solid" className="pinterest" onClick={() => { shareAction(SHARE_OPTIONS.PINTEREST); }}>
								<FontAwesomeIcon icon={['fab', 'pinterest-p']} />
								<Typography weight="semibold">Pinterest</Typography>
							</Button>

							{/* LinkedIn */}
							<Button variant="solid" className="linkedin" onClick={() => { shareAction(SHARE_OPTIONS.LINKEDIN); }}>
								<FontAwesomeIcon icon={['fab', 'linkedin-in']} />
								<Typography weight="semibold">LinkedIn</Typography>
							</Button>

							{/* Email */}
							<Button variant="solid" className="email" onClick={() => { shareAction(SHARE_OPTIONS.EMAIL); }}>
								<FontAwesomeIcon icon={['far', 'envelope']} />
								<Typography weight="semibold">Email</Typography>
							</Button>

						</S.ShareWidgetContainer>
					</S.ShareContainer>

				</S.ContentPadding>

				{/* Related stories */}
				<S.SectionContainer>
					<Typography tag="h3" weight="bold" className="title">More stories</Typography>
					<BlogSection
						key="blogSection"
						perRow={4}
						limit={4}
						fetchParams={{ pageSize: 4 }}
						locale={stateLocale}
					/>
				</S.SectionContainer>
			</>
		);
	};

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} data={{ guide: guide || { metaTitle: 'Loading...' } }} locale={stateLocale} />

			{/* Schema.org BreadcrumbList */}
			<SchemaScript schema={{
				'@context': 'http://schema.org',
				'@type': 'BreadcrumbList',
				'@id': `${process.env.APP_URL}/guide/${slug}/${operatorId}/#BreadcrumbList`,
				itemListElement: [
					{
						'@type': 'ListItem',
						position: 1,
						item: {
							'@id': `${process.env.APP_URL}/guide/${slug}/${operatorId}/#ListItem`,
							name: buildDynamicMeta(meta.title, { guide }),
							url: `${process.env.APP_URL}${stateLocale.localePath}/guide/${slug}/${operatorId}`
						}
					}
				]
			}}
			/>

			{/* Schema.org WebPage */}
			<SchemaScript schema={{
				'@context': 'http://schema.org',
				'@type': 'WebPage',
				'@id': `${process.env.APP_URL}/guide/${slug}/${operatorId}/#WebPage`,
				name: buildDynamicMeta(meta.title, { guide }),
				description: buildDynamicMeta(meta.description, { guide }),
				url: `${process.env.APP_URL}${stateLocale.localePath}/guide/${slug}/${operatorId}`,
				inLanguage: stateLocale.localeBaseId,
				isPartOf: {
					'@id': `${process.env.APP_URL}/#WebSite`
				},
				breadcrumb: {
					'@id': `${process.env.APP_URL}/guide/${slug}/${operatorId}/#BreadcrumbList`
				},
				potentialAction: [
					{
						'@type': 'ReadAction',
						target: `${process.env.APP_URL}${stateLocale.localePath}/guide/${slug}/${operatorId}`
					}
				]
			}}
			/>

			{/* Schema.org Article */}
			<SchemaScript schema={{
				'@context': 'http://schema.org',
				'@type': 'Article',
				'@id': `${process.env.APP_URL}/guide/${slug}/${operatorId}/#Article`,
				headline: guide?.metaTitle,
				image: guide?.images?.cover?.['1200'],
				inLanguage: stateLocale.localeBaseId,
				isPartOf: {
					'@id': `${process.env.APP_URL}/guide/${slug}/${operatorId}/#WebPage`
				},
				publisher: {
					'@id': `${process.env.APP_URL}/#Organization`
				}
			}}
			/>

			{/* Component Content */}
			<AppNavigation>
				<S.Wrapper>{renderComponent()}</S.Wrapper>
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

DynamicGuide.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
	data: PropTypes.shape(),
};
DynamicGuide.defaultProps = {
	meta: {},
	locale: {},
	data: null
};


/**
 * Exports
 */

export default DynamicGuide;
