/*
 * File: EmptyComponent.styles.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 22, 2022 at 10:36 AM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: August 2, 2023 at 3:18 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';

// Styles
import { ColorValuesCreator } from '../../styles/colors';

// Constants
import { mediumBreakpoint } from '../../styles/constants';

// Create color values
const ColorValues = ColorValuesCreator();


/**
 * Styles
 */

export const Wrapper = styled.div`
	display: grid;
	place-items: center;
	height: inherit;
	align-content: center;
	padding: 1rem 2rem;
	width: 100% !important;

	${({ isDark }) => isDark && css`
		h4 {
			color: ${ColorValues.white};
		}
	`}
	${({ isLight }) => isLight && css`
		h4 {
			color: ${ColorValues.black};
		}
	`}

	.description {
		margin-top: 0.3rem;
		font-size: 0.9rem;
		color: ${({ isDark, isLight, theme }) => (isDark ? ColorValues.neutralForm : `${isLight ? ColorValues.neutralText : theme.secondaryText}`)};
	}

	.empty-icon {
		display: block;
		align-self: center;
		margin-bottom: 1.2rem;
		color: ${({ isDark, isLight, theme }) => (isDark ? ColorValues.white : `${isLight ? ColorValues.neutralHighlight : theme.placeholderHighlight}`)};

		@media screen and (min-width: ${mediumBreakpoint}em) {
			margin-bottom: 1.8rem;
		}
	}

	@media screen and (min-width: ${mediumBreakpoint}em) {
		padding: 3.4375rem 1rem;
	}
`;
