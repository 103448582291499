/*
 * File: InputCollection.styles.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 9, 2022 at 5:45 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: March 20, 2023 at 8:57 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Styles
import { ColorValuesCreator } from '../../styles/colors';

// Create color values
const ColorValues = ColorValuesCreator();


/**
 * Styles
 */

export const InputCollection = styled.div`
	border-radius: 6px;
	background-color: ${({ isDark, theme }) => (isDark ? ColorValues.white : theme.primaryBackground)};
	position: relative;
`;

export const FieldDivider = styled.div`
	height: 1px;
	margin: 0px 18px;
	background-color: ${({ isDark, theme }) => (isDark ? ColorValues.neutralHighlight : theme.placeholderHighlight)};
`;

export const FieldContainer = styled.div``;
