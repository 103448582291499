/*
 * File: DayCollection.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: August 8, 2023 at 7:05 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useWindowResize } from 'beautiful-react-hooks';

// Utilities
import { useDispatch, useSelector } from 'react-redux';
import { parseDateString } from '../../../utilities/dateTime';
import { toastError } from '../../utilities/toaster';

// Services
import { createUpdateTrip } from '../../services/trip';

// Slices
import { updateCurrentTrip, updateTrips } from '../../store/slices/trips/trips.slice';

// Constants
import { PARKS } from '../../../Constants';

// Components
import { DayCard } from '../DayCard';
import { Typography } from '../Typography';
import { ReservationCreatorModal } from '../ReservationCreatorModal';
import { ConfirmationModal } from '../ConfirmationModal'; // eslint-disable-line import/no-cycle

// Styles
import * as S from './DayCollection.styles';


/**
 * Constants
 */

const DAY_WIDTH = 350;
const DATE_WIDTH_MAX = 150;
const DATE_WIDTH_MIN = 100;
const DATE_SCALE_MAX = 0.88;
const DATE_SCALE_MIN = 0.72;
const DAY_GAP = 0;
const DATE_GAP = 0;


/**
 * Component
 */

export const DayCollection = ({
	className, days, updateDays, editingEnabled, isVisible
}) => {

	// Get current trip
	const { currentTrip, trips, isSet } = useSelector((state) => state.trips.value);

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Create state handlers
	const [dayObjects, setDayObjects] = useState(days);
	const [currentDay, setCurrentDay] = useState(days[0]);
	const [dateContainerLeftOffset, setDateContainerLeftOffset] = useState(0);
	const [cardContainerLeftOffset, setCardContainerLeftOffset] = useState(0);
	const [dayWidth, setDayWidth] = useState(DAY_WIDTH);
	const [dateWidth, setDateWidth] = useState(DATE_WIDTH_MAX);
	const [scaleModifier, setScaleModifier] = useState(DATE_SCALE_MIN);
	const [creatorIsOpen, setCreatorIsOpen] = useState(false);
	const [removeModalIsOpen, setRemoveModalIsOpen] = useState(false);
	const [deleteInProgress, setDeleteInProgress] = useState(false);
	const [addInProgress, setAddInProgress] = useState(false);
	const [selectedEvent, setSelectedEvent] = useState(null);

	// Get actions from hooks
	const dispatch = useDispatch();

	// Create references
	const collectionContainerRef = useRef();

	// Update element offsets
	const updateElementOffsets = (day, dayEntities) => {

		// Get client width
		const clientWidth = collectionContainerRef?.current?.clientWidth || 800;

		// Update widths based on collection dimensions
		const maximumWidth = clientWidth * 0.8; // 80% of container
		const currentDayWidth = DAY_WIDTH > maximumWidth ? maximumWidth : DAY_WIDTH;
		const currentDateWidth = DAY_WIDTH > maximumWidth ? DATE_WIDTH_MIN : DATE_WIDTH_MAX;
		const currentDateScale = DAY_WIDTH > maximumWidth ? DATE_SCALE_MAX : DATE_SCALE_MIN;

		// Get index of day
		const index = (dayEntities || dayObjects).findIndex((obj) => obj.date === day.date);

		// Calculate starting positions
		const cardStartingPosition = (clientWidth / 2.0) - (currentDayWidth / 2.0);
		const dateStartingPosition = (clientWidth / 2.0) - (currentDateWidth / 2.0);

		// Update state
		setDayWidth(currentDayWidth);
		setDateWidth(currentDateWidth);
		setScaleModifier(currentDateScale);

		// Calculate element offsets
		setCardContainerLeftOffset(cardStartingPosition - ((currentDayWidth + DAY_GAP) * index));
		setDateContainerLeftOffset(dateStartingPosition - ((currentDateWidth + DATE_GAP) * index));
	};

	// Handle move date
	const moveDate = (target) => {

		// Update state
		setCurrentDay(target);

		// Update element offsets
		updateElementOffsets(target);
	};

	// Handle update trip
	const handleUpdateTrip = (dayPayload, action = 'adding') => {

		// Update state
		setDeleteInProgress(true);
		setAddInProgress(true);

		// Set parks for day payload
		const fullPayload = dayPayload.map((dayObj) => {

			// Create parks array for each day
			const parks = [];
			dayObj.scheduledEvents.forEach((eventObj) => {
				if (eventObj.parkId && !parks.some((park) => park.parkId === eventObj.parkId)) {
					parks.push({
						parkId: eventObj.parkId,
						name: Object.values(PARKS).find((park) => park.id === eventObj.parkId).name
					});
				}
			});

			// Return payload
			return {
				...dayObj,
				parks
			};
		});

		// Update trip
		createUpdateTrip({
			id: currentTrip?.id,
			name: currentTrip?.name,
			days: fullPayload,
			shouldPurchaseGeniePlus: currentTrip?.shouldPurchaseGeniePlus,
			parkEntity: currentTrip?.parkEntity?.id
		}).then(({ data }) => {

			// Set loading state
			setDeleteInProgress(false);
			setAddInProgress(false);

			// Get trip from result
			const { trip } = data;

			// Update state
			setCurrentDay(trip.days.find((day) => day.date === currentDay.date));
			setDayObjects(trip.days);
			updateDays(trip.days);
			setSelectedEvent(null);

			// Update trip
			dispatch(updateCurrentTrip(trip));

			// Update trips
			if (isSet) {
				const newTrips = [...(trips || [])];
				const tripIndex = newTrips.findIndex((v) => v.id === trip.id);
				if (tripIndex > -1) newTrips[tripIndex] = trip;
				else newTrips.push(trip);
				dispatch(updateTrips({ trips: newTrips }));
			}

			// Hide modals
			setCreatorIsOpen(false);
			setRemoveModalIsOpen(false);

		}).catch(({ response }) => {

			// Set loading state
			setDeleteInProgress(false);
			setAddInProgress(false);

			// Show error message
			if (response?.data?.message) {
				toastError(uiMode, `Whoops. We're having trouble ${action} your reservation. Please try again.`);
			} else {
				toastError(uiMode, `Whoops. We're having trouble ${action} your reservation. Please try again.`);
			}
		});
	};

	// Handle delete scheduled event
	const handleDeleteEvent = () => {

		// Create shallow copy of day objects for editing
		const editingDays = JSON.parse(JSON.stringify(dayObjects));

		// Remove event from scheduled events
		editingDays.forEach((day, index) => {
			editingDays[index].scheduledEvents = day.scheduledEvents.filter((event) => event.id !== selectedEvent.id);
		});

		// Update trip
		handleUpdateTrip(editingDays, 'removing');
	};

	// Handle add scheduled event
	const addScheduledEvent = (eventPayload) => {

		// Create shallow copy of day objects for editing
		const editingDays = JSON.parse(JSON.stringify(dayObjects));

		// Add / update scheduled event
		editingDays.forEach((day, index) => {
			if (day.date === currentDay.date) {
				if (!eventPayload.id) {
					editingDays[index].scheduledEvents.push(eventPayload);
				} else {
					const eventIndex = day.scheduledEvents.findIndex((event) => event.id === eventPayload.id);
					if (eventIndex > -1) editingDays[index].scheduledEvents[eventIndex] = eventPayload;
				}
			}
		});

		// Update trip
		handleUpdateTrip(editingDays, !eventPayload.id ? 'creating' : 'updating');
	};

	// Handle actions on visibility change
	useEffect(() => {
		if (isVisible) {

			// Update state
			setDayObjects(days);
			setCurrentDay(days[0]);

			// Update element offsets
			updateElementOffsets(days[0], days);
		}
	}, [isVisible]);

	// Handle actions on component load
	useEffect(() => {

		// Update offsets
		updateElementOffsets(currentDay);

	}, []);

	// Handle actions on window resize
	useWindowResize(() => {

		// Update offsets
		updateElementOffsets(currentDay);
	});

	// Render component
	return (
		<S.Wrapper className={className} ref={collectionContainerRef}>

			{/* Date Container */}
			<S.DateContainer className="moveAnimator" $marginLeft={dateContainerLeftOffset} $dateGap={DATE_GAP}>
				{dayObjects.map((day) => {

					// Parse date
					const dateObj = parseDateString(day.date, 'YYYY-MM-DD').set('hour', 23).set('minute', 59).set('second', '59');

					// Generate state
					const isCurrent = currentDay ? currentDay.date === day.date : false;
					const isPast = dateObj.isBefore(new Date());
					const dateDay = dateObj.format('D');

					// Render component
					return (
						<S.DateElement
							key={day.date}
							className={`${isCurrent ? 'selected' : ''} ${isPast ? 'past' : ''}`.trim()}
							onClick={() => { moveDate(day); }}
							$dateWidth={dateWidth}
						>
							<S.ElementDate><Typography weight="medium">{dateDay}</Typography></S.ElementDate>
							<S.ElementDay><Typography>{day.dayOfWeek}</Typography></S.ElementDay>
						</S.DateElement>
					);
				})}
			</S.DateContainer>

			{/* Card Container */}
			<S.CardContainer className="moveAnimator" $marginLeft={cardContainerLeftOffset} $dayGap={DAY_GAP} $scaleModifier={scaleModifier}>
				{dayObjects.map((day) => {

					// Parse date
					const dateObj = parseDateString(day.date, 'YYYY-MM-DD').set('hour', 23).set('minute', 59).set('second', '59');

					// Generate state
					const isCurrent = currentDay ? currentDay.date === day.date : false;
					const isPast = dateObj.isBefore(new Date());

					// Render component
					return (
						<DayCard
							key={day.date}
							className={`${isCurrent ? 'selected' : ''} ${isPast ? 'past' : ''}`.trim()}
							isEditing={isCurrent && !isPast && editingEnabled}
							day={day}
							trip={currentTrip}
							onClick={!isCurrent ? () => { moveDate(day); } : undefined}
							isCurrent={isCurrent}
							dayWidth={dayWidth}
							openCreator={(event) => {
								setSelectedEvent(event);
								setCreatorIsOpen(true);
							}}
							openRemove={(event) => {
								setSelectedEvent(event);
								setRemoveModalIsOpen(true);
							}}
						/>
					);
				})}
			</S.CardContainer>

			{/* Modals */}
			<ReservationCreatorModal
				isOpen={creatorIsOpen}
				handleClose={() => { setCreatorIsOpen(false); }}
				isLoading={addInProgress}
				onSave={addScheduledEvent}
				editingEvent={selectedEvent}
				day={currentDay}
				parkEntity={currentTrip?.parkEntity?.id}
			/>
			<ConfirmationModal
				isOpen={removeModalIsOpen}
				handleClose={() => { setRemoveModalIsOpen(false); }}
				type="danger"
				title="Delete this reservation?"
				message="Are you sure you want to delete this reservation? This cannot be undone."
				primaryButtonTitle="Yes, delete"
				secondaryButtonTitle="Cancel"
				isLoading={deleteInProgress}
				primaryButtonAction={handleDeleteEvent}
				secondaryButtonAction={() => { setRemoveModalIsOpen(false); }}
			/>

		</S.Wrapper>
	);
};


/**
 * Configuration
 */

DayCollection.displayName = 'DayCollection';
DayCollection.propTypes = {
	className: PropTypes.string,
	days: PropTypes.arrayOf(PropTypes.shape()),
	updateDays: PropTypes.func,
	editingEnabled: PropTypes.bool,
	isVisible: PropTypes.bool,
};
DayCollection.defaultProps = {
	className: null,
	days: [],
	updateDays: null,
	editingEnabled: true,
	isVisible: true
};
