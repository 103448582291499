/*
 * File: UpdateTitleModal.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 18, 2023 at 10:03 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';
import { useDispatch, useSelector } from 'react-redux';

// Utilities
import { toastError, toastSuccess } from '../../../../utilities/toaster';

// Services
import { createUpdateTrip } from '../../../../services/trip';

// Slices
import { updateTrips } from '../../../../store/slices/trips/trips.slice';

// Components
import {
	Button, Modal, Typography, TextInput
} from '../../../../components';

// Styles
import * as S from './UpdateTitleModal.styles';


/**
 * Component
 */

export const UpdateTitleModal = ({
	className, isOpen, handleClose, updateTrip, trip
}) => {

	// Create state handlers
	const [nameError, setNameError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [inputValues, setInputValues] = useState({});

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get current trips from hook
	const tripsState = useSelector((state) => state.trips.value);

	// Get actions from hooks
	const dispatch = useDispatch();

	// Handle update trip function
	const handleUpdateTrip = async () => {

		// Get parameters
		const { name } = inputValues;

		// Validate parameters
		if (!name || validator.isEmpty(name, { ignore_whitespace: true })) {
			setNameError({ message: 'Please enter a name for your trip.' });
			return;
		}

		// Set loading state
		setIsLoading(true);

		// Update trip
		createUpdateTrip({
			id: trip?.id,
			name,
			days: trip?.days,
			shouldPurchaseGeniePlus: trip?.shouldPurchaseGeniePlus,
			parkEntity: trip?.parkEntity?.id
		}).then(({ data }) => {

			// Show success toast
			toastSuccess(uiMode, 'Success! Your trip has been updated.');

			// Set loading state
			setIsLoading(false);
			setNameError(null);

			// Update trip
			if (updateTrip) updateTrip(data.trip);

			// Update trips
			if (tripsState?.isSet) {
				const newTrips = [...(tripsState?.trips || [])];
				const tripIndex = newTrips.findIndex((v) => v.id === data.trip.id);
				if (tripIndex > -1) newTrips[tripIndex] = data.trip;
				dispatch(updateTrips({ trips: newTrips }));
			}

			// Handle completion actions
			setTimeout(() => {
				if (handleClose) handleClose();
			}, 2500);

		}).catch(({ response }) => {

			// Set loading state
			setIsLoading(false);
			setNameError(null);

			// Show error message
			if (response?.data?.message) {
				toastError(uiMode, 'Whoops. We\'re having trouble updating your trip. Please try again.');
			} else {
				toastError(uiMode, 'Whoops. We\'re having trouble updating your trip. Please try again.');
			}
		});
	};

	// Handle on input change action
	const handleOnChange = (event) => {
		const { name, value } = event.target;
		setInputValues({ ...inputValues, [name]: value });
	};

	// Handle actions on component load
	useEffect(() => {
		if (isOpen === true) {

			// Set input values
			setInputValues({ ...inputValues, name: trip?.name });

			// Hide errors
			setNameError(null);
		}
	}, [isOpen]);

	// Render component
	return (
		<Modal className={className} isOpen={isOpen} handleClose={handleClose} clickOutsideClose useWrapper>
			<S.ModalInner>

				{/* Content */}
				<Typography tag="h3" weight="bold">Update your trip</Typography>
				<Typography tag="p" variation="1" weight="light" className="subtitle">Give your trip a catchy name below.</Typography>

				{/* Inputs */}
				<TextInput
					label="Trip Name"
					placeholder="Our awesome Disney World trip"
					name="name"
					error={nameError}
					value={inputValues.name || ''}
					containerClassName="modalInput"
					autoComplete="name"
					onFocus={() => { setNameError(null); }}
					onKeyUp={() => { setNameError(null); }}
					onBlur={() => { setNameError(null); }}
					onChange={handleOnChange}
				/>

				{/* Actions */}
				<S.ActionContainer>
					<Button disabled={isLoading} isLoading={isLoading} variant="solid" onClick={handleUpdateTrip}>Update</Button>
				</S.ActionContainer>

			</S.ModalInner>
		</Modal>
	);
};


/**
 * Configuration
 */

UpdateTitleModal.displayName = 'UpdateTitleModal';
UpdateTitleModal.propTypes = {
	className: PropTypes.string,
	isOpen: PropTypes.bool,
	handleClose: PropTypes.func,
	updateTrip: PropTypes.func,
	trip: PropTypes.shape()
};
UpdateTitleModal.defaultProps = {
	className: null,
	isOpen: false,
	handleClose: null,
	updateTrip: null,
	trip: {}
};
