/*
 * File: PaymentHistory.styles.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on March 5, 2023 at 1:37 PM
 * Copyright © 2023 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: March 5, 2023 at 11:18 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Constants
import { PAYMENT_STATUSES } from '../../../../../Constants';


/**
 * Helpers
 */

const statusColor = (theme, status) => {
	switch (status) {
		case PAYMENT_STATUSES.COMPLETE:
			return theme.statusSuccessBase;
		case PAYMENT_STATUSES.IN_PROGRESS:
			return theme.statusWarningBase;
		case PAYMENT_STATUSES.PENDING:
			return theme.statusWarningBase;
		case PAYMENT_STATUSES.CANCELED:
			return theme.statusDangerBase;
		case PAYMENT_STATUSES.REFUNDED:
			return theme.statusDangerBase;
		default:
			return theme.statusWarningBase;
	}
};


/**
 * Styles
 */

export const Wrapper = styled.div`
	display: flex;
`;

export const TableContainer = styled.div`
	max-height: 400px;
	overflow: scroll;
	width: 100%;
`;

export const StatusContainer = styled.div`
	display: flex;
	align-items: center;
	margin: 4px 0px 0px 0px;
`;

export const StatusIndicator = styled.div`
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: ${({ theme, $status }) => statusColor(theme, $status)};
	margin: 0px 5px 0px 0px;
`;

export const ReservationContainer = styled.div`
	margin: 5px 0px 0px 10px;
`;

export const Reservation = styled.div`
	margin: 0px 0px 0px 0px;
`;
