/*
 * File: About.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on July 10, 2022 at 6:57 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: August 10, 2023 at 3:22 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';

// Utilities
import { createStateLocale } from '../../../utilities/locale';
import { globalQuery } from '../../../utilities/state';

// Components
import {
	Meta, AppNavigation, Typography, Emoji, Button, LocaleLink, SchemaScript
} from '../../../components';

// Styles
import * as S from './About.styles';


/**
 * Component
 */

const About = ({ meta, locale }) => {

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Get actions from hooks
	const navigate = useNavigate();

	// Get query parameters from hook
	const [searchParams] = useSearchParams();

	// Handle actions on component load
	useEffect(() => {

		// Activate Curate.io feed
		const d = document; const s = 'script';
		const i = d.createElement('script'); i.async = 1; i.charset = 'UTF-8';
		i.src = 'https://cdn.curator.io/published/ec375231-7652-4f52-9ae7-f3450147518d.js';
		const [e] = d.getElementsByTagName(s);
		e.parentNode.insertBefore(i, e);

	}, []);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} data={{}} locale={stateLocale} />

			{/* Schema.org */}
			<SchemaScript schema={{
				'@context': 'http://schema.org',
				'@type': ['WebPage', 'AboutPage'],
				'@id': `${process.env.APP_URL}/about/#WebPage`,
				name: meta.title,
				description: meta.description,
				url: `${process.env.APP_URL}${stateLocale.localePath}/about`,
				inLanguage: stateLocale.localeBaseId,
				isPartOf: {
					'@id': `${process.env.APP_URL}/#WebSite`
				},
				publisher: {
					'@id': 'https://www.pixiedustguide.com/#Organization',
				},
			}}
			/>

			{/* Component Content */}
			<AppNavigation isTopbarTransparent>
				<S.Wrapper>

					{/* Header Decoration */}
					<S.HeaderDecoration />

					{/* Hero Content */}
					<S.HeroSection>

						{/* Creator Image */}
						<S.ImageContainer>
							<S.CreatorImage src={`${process.env.CDN_URL}/public/assets/about-us-creators.png`} alt="Brendan and Cathy from Pixie Dust" />
						</S.ImageContainer>

						{/* Content */}
						<S.ContentContainer>
							<Typography tag="h2" weight="bold">
								Hi there
								{' '}
								<Emoji symbol="👋" size={2.5} label="Wave" />
							</Typography>
							<Typography tag="p">
								Hello friends! We are Brendan and Cathy - two engineers, best friends, and Disney lovers from Raleigh, North Carolina. We&apos;ve been Disney fans since we can remember, and have loved being a part of the Disney community, especially during the times that we&apos;re not currently wandering around the parks!
							</Typography>
							<Typography tag="p">
								To celebrate the magic (and to satisfy our geekiness), we designed and built Pixie Dust in our free time to make your Disney trip a bit more enjoyable.
							</Typography>
							<Typography tag="p" variation="2">
								<Emoji symbol="❤️" size={0.9} label="Heart" />
								{' '}
								Cathy and Brendan
							</Typography>
						</S.ContentContainer>
					</S.HeroSection>

					{/* What is Pixie Dust? */}
					<S.ContentSection>
						<Emoji symbol="⏰" label="shopping" size={2.7} />
						<Typography tag="h3" weight="bold">What is Pixie Dust?</Typography>
						<Typography tag="p">
							We came up with the idea for Pixie Dust on the last day of our latest Disney trip, after a week of waking up at 6:45am to make our whole family’s Lightning Lane reservations for the day
							{' '}
							<Emoji symbol="😅" label="stress" size={1.1} />
							.
						</Typography>
						<Typography tag="p">
							Pixie Dust allows you to schedule your Disney Lightning Lanes weeks or months in advance. Then, our platform automagically reserves them for you the instant they become available during your park day. No more waking up early or stressing out!
						</Typography>
						<Button size="large" onClick={() => { navigate(`${stateLocale.localePath}/join${globalQuery(searchParams)}`); }}>Try it out for free</Button>
					</S.ContentSection>

					{/* Do we work for Disney? */}
					<S.ContentSection>
						<Emoji symbol="🏰" label="shopping" size={2.7} />
						<Typography tag="h3" weight="bold">Do we work for Disney?</Typography>
						<Typography tag="p">We do not! Pixie Dust is a 100% community-created and supported platform. All Disney licenses, copyrights, and property are owned exclusively by the Walt Disney Company. We&apos;re just here to enjoy the magic!</Typography>
					</S.ContentSection>

					{/* Support Us */}
					<S.ContentSection>
						<Emoji symbol="🤲" label="shopping" size={3.2} />
						<Typography tag="h3" weight="bold">Support Us</Typography>
						<Typography tag="p">
							Pixie Dust is supported by the generosity of our awesome community. The best way to support us is by signing up and letting us help you reserve your Lightning Lanes. If you&apos;re interested in sponsorships or helping us out in additional ways,
							{' '}
							<LocaleLink to="/contact">drop us a line here</LocaleLink>
							. Thank you!
						</Typography>
					</S.ContentSection>

					{/* Social Media Feed */}
					<S.SocialFeedContainer>
						<Typography tag="h3" weight="bold">
							Come say hi on social
							{' '}
							<Emoji symbol="👋" size={2.5} label="Wave" />
						</Typography>
						<div id="curator-feed-default-feed-layout"><a href="https://curator.io" target="_blank" className="crt-logo crt-tag" rel="noreferrer">Powered by Curator.io</a></div>
					</S.SocialFeedContainer>
				</S.Wrapper>
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

About.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
};
About.defaultProps = {
	meta: {},
	locale: {}
};


/**
 * Exports
 */

export default About;
