/*
 * File: NavigationDropdown.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: March 3, 2023 at 12:52 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Styles
import * as S from './NavigationDropdown.styles';


/**
 * Component
 */

export const NavigationDropdown = ({
	items, footerItems, adjustOnMobile, flipDirection, className
}) => {

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Render component
	return (
		<S.NavigationDropdown className={className} adjustOnMobile={adjustOnMobile} uiMode={uiMode?.mode} flipDirection={flipDirection}>
			<S.DropdownArrow
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 24 8"
				width="1em"
				height="1em"
				verticalPropKey="top"
				adjustOnMobile={adjustOnMobile}
				flipDirection={flipDirection}
				uiMode={uiMode?.mode}
			>
				<path d="M0 8c7 0 10-8 12-8s5 8 12 8z" />
			</S.DropdownArrow>
			<S.NavigationContainer>
				{items.map((item) => (item.link ? (
					<S.Link to={item.link} key={item.title}>
						<S.NavigationItem onClick={item.action ? item.action : null} className="animate" uiMode={uiMode?.mode} $isDanger={item.isDanger === true}>
							<S.IconContainer>
								<S.NavigationIcon icon={item.icon} offset={item.iconOffset ? item.iconOffset : 0} />
							</S.IconContainer>
							<span>{item.title}</span>
						</S.NavigationItem>
					</S.Link>
				) : (
					<S.NavigationItem onClick={item.action ? item.action : null} key={item.title} className="animate" uiMode={uiMode?.mode} $isDanger={item.isDanger === true}>
						<S.IconContainer>
							<S.NavigationIcon icon={item.icon} offset={item.iconOffset ? item.iconOffset : 0} />
						</S.IconContainer>
						<span>{item.title}</span>
					</S.NavigationItem>
				)))}
				{footerItems && (
					<S.Footer>
						{footerItems.map((item) => (item.link ? (
							<S.Link to={item.link} key={item.title}>
								<S.NavigationItem onClick={item.action ? item.action : null} className="animate" uiMode={uiMode?.mode} $isDanger={item.isDanger === true}>
									<S.IconContainer>
										<S.NavigationIcon icon={item.icon} offset={item.iconOffset ? item.iconOffset : 0} />
									</S.IconContainer>
									{item.title}
								</S.NavigationItem>
							</S.Link>
						) : (
							<S.NavigationItem onClick={item.action ? item.action : null} key={item.title} className="animate" uiMode={uiMode?.mode} $isDanger={item.isDanger === true}>
								<S.IconContainer>
									<S.NavigationIcon icon={item.icon} offset={item.iconOffset ? item.iconOffset : 0} />
								</S.IconContainer>
								{item.title}
							</S.NavigationItem>
						)))}
					</S.Footer>
				)}
			</S.NavigationContainer>
		</S.NavigationDropdown>
	);
};


/**
 * Configuration
 */

NavigationDropdown.displayName = 'NavigationDropdown';
NavigationDropdown.propTypes = {
	items: PropTypes.arrayOf(PropTypes.shape()),
	footerItems: PropTypes.arrayOf(PropTypes.shape()),
	adjustOnMobile: PropTypes.bool,
	flipDirection: PropTypes.bool,
	className: PropTypes.string
};
NavigationDropdown.defaultProps = {
	items: [],
	footerItems: null,
	adjustOnMobile: null,
	flipDirection: null,
	className: null
};
