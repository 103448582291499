/*
 * File: ConfirmationStep.styles.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:25 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: March 19, 2023 at 8:39 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import {
	TripCard as BaseTripCard
} from '../../../../components';

// Styles
import { ColorValuesCreator } from '../../../../styles/colors';

// Create color values
const ColorValues = ColorValuesCreator();


/**
 * Styles
 */

export const StepContainer = styled.div`
	width: 100%;
	max-width: 450px;
	margin: 0px auto;

	@media only screen {
		padding-top: 40px;
		padding-bottom: 50px;
	}
	@media only screen and (min-width: 40em) {
		padding-top: 110px;
		padding-bottom: 60px;
	}
	button {
		width: 100%;
	}
	.spacer {
		margin-top: 16px;
	}
	.startSpacer {
		margin-top: 32px;
	}
`;

export const ContentContainer = styled.div`
	h2 {
		text-align: left;
		color: ${ColorValues.white};
		margin: 10px 0px 10px;
	}
	p {
		text-align: left;
		color: ${ColorValues.white};
   		margin-bottom: 20px;
	}
`;

export const IconContainer = styled.div``;

export const Icon = styled.div`
	display: flex;
	width: 46px;
	height: 46px;
	background-color: ${ColorValues.white};
	border-radius: 50%;
	align-items: center;
	justify-content: center;
	position: relative;

	svg {
		color: ${ColorValues.brandPrimaryDarkBlue};
		font-size: 1.5rem;
	}
`;

export const TripCard = styled(BaseTripCard)`
	margin: 30px 0px 0px 0px;
	width: 100%;
	height: auto;
`;

export const ActionContainer = styled.div`
	margin: 30px 0px 65px 0px;

	button {
		flex-grow: 1;
   	 	flex-basis: 0;
		padding-top: 0.85rem;
		padding-bottom: 0.85rem;
	}
`;

export const ConfettiTarget = styled.div`
	position: absolute;
`;
