/*
 * File: ProfileImageModal.styles.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:53 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: March 8, 2023 at 8:08 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Constants
import { mobileBreakpoint, smallBreakpoint } from '../../styles/constants';


/**
 * Constants
 */

const MAX_UPLOAD_HEIGHT = {
	MOBILE: '400px',
	DESKTOP: '400px'
};
const MAX_CROP_HEIGHT = {
	MOBILE: '400px',
	DESKTOP: '500px'
};


/**
 * Styles
 */

export const ModalInner = styled.div`
	padding: 15px 0px 30px;
	text-align: center;
	width: 100%;

	.subtitle {
		margin: 5px 0 35px;
		padding: 0 20px;
	}
`;

export const DragZoneInner = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: ${MAX_UPLOAD_HEIGHT.MOBILE};
	position: relative;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		height: ${MAX_UPLOAD_HEIGHT.DESKTOP}
	}
	svg {
		color: ${({ theme }) => theme.secondaryText};
		font-size: 3.0rem;
		margin-bottom: 25px;
	}
	p {
		margin-top: 10px;
		color: ${({ theme }) => theme.secondaryText};
	}
	button {
		margin-top: 25px;
	}
`;

export const ActionContainer = styled.div`
	margin: 20px 0px 0px auto;
	display: flex;
	gap: 10px;
	flex-direction: column;
	width: 100%;
	max-width: 100%;

	@media only screen and (min-width: ${smallBreakpoint}em) {
		flex-direction: row;
		max-width: 300px;
	}
	button {
		flex-grow: 1;
   	 	flex-basis: 0;
		padding-top: 0.85rem;
		padding-bottom: 0.85rem;
	}
`;

export const StepContainer = styled.div`
	position: relative;
	overflow: hidden;
	height: ${({ stepHeight }) => (stepHeight ? `${stepHeight}px` : 'auto')};
`;

export const Step = styled.div`
	opacity: 0;
	visibility: hidden;
	overflow: hidden;
	position: absolute;
	top: 0px;
	pointer-events: none;
	width: 100%;

	&.show {
		opacity: 1;
		visibility: visible;
		transition-delay: 0.3s;
		position: relative;
		pointer-events: initial;
	}
`;

export const FilePreview = styled.div`
	position: relative;
	max-width: 100%;
	max-height: 100%;
`;

export const FileContainer = styled.img`
	height: auto;
	width: auto;
	max-width: 100%;
	max-height: 100%;
	border: 1px solid ${({ theme }) => theme.layoutBorder};
	border-radius: 6px;
`;

export const RemoveButton = styled.button`
	width: 26px;
	height: 26px;
	border-radius: 50%;
	border: 1px solid ${({ theme }) => theme.layoutBorder};
	background-color: ${({ theme }) => theme.secondaryBackground};
	position: absolute;
	top: -13px;
	right: -13px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	z-index: 5;
	margin: 0px !important;

	svg {
		color: ${({ theme }) => theme.secondaryText};
		font-size: 0.9rem;
		margin: 0px !important;
	}
`;

export const SizeContainer = styled.div`
	width: 100%;
	height: 100%;
`;

export const CropContainer = styled.div`
	height: auto;
	position: relative;
	border-radius: 6px;
	border: 1px solid ${({ theme }) => theme.layoutBorder};
	overflow: hidden;
	display: flex;
    align-items: center;
    justify-content: center;
	height: ${MAX_CROP_HEIGHT.MOBILE};

	.ReactCrop {
		max-height: 100%;
		max-width: 100%;
	}
	img {
		max-width: 100%;
		max-height: ${MAX_CROP_HEIGHT.MOBILE};
	}
	@media only screen and (min-width: ${mobileBreakpoint}em) {
		height: ${MAX_CROP_HEIGHT.DESKTOP};

		img {
			max-height: ${MAX_CROP_HEIGHT.DESKTOP};
		}
	}
`;
