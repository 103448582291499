/*
 * File: DayCollection.styles.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:53 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: March 27, 2023 at 1:11 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Styles
import { ColorValuesCreator } from '../../styles/colors';

// Create color values
const ColorValues = ColorValuesCreator();


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;

	.moveAnimator {
		transition: margin 0.3s ease;
		-webkit-transition: margin 0.3s ease;
		-moz-transition: margin 0.3s ease;
		-o-transition: margin 0.3s ease;

		& > div {
			transition: transform 0.3s ease;
			-webkit-transition: transform 0.3s ease;
			-moz-transition: transform 0.3s ease;
			-o-transition: transform 0.3s ease;
		}
	}
`;

export const DateContainer = styled.div`
	display: flex;
	justify-content: start;
	gap: ${({ $dateGap }) => `${$dateGap}px`};
	margin: 0px 0px 30px;
	margin-left: ${({ $marginLeft }) => `${$marginLeft}px`};
`;

export const CardContainer = styled.div`
	display: flex;
	justify-content: start;
	width: 100%;
	gap: ${({ $dayGap }) => `${$dayGap}px`};
	margin-left: ${({ $marginLeft }) => `${$marginLeft}px`};

	& > div {
		transform: ${({ $scaleModifier }) => `scale(${$scaleModifier})`};
		cursor: pointer;
	}
	& > div.past {
		opacity: 0.5;
	}
	& > div.selected {
		transform: scale(1.0);
		opacity: 1.0;
	}
`;

export const DateElement = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	cursor: pointer;
	flex-basis: ${({ $dateWidth }) => `${$dateWidth}px`};
	flex-shrink: 0;
	flex-grow: 0;

	span {
		color: ${ColorValues.white}
	}
	&.past {
		opacity: 0.5;
	}
	&.selected {
		opacity: 1.0;
	}
`;

export const ElementDate = styled.div`
	height: 50px;
	width: 50px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 2px solid ${ColorValues.white};

	.selected > & {
		border: 2px solid ${ColorValues.brandPrimaryLightBlue};
		background-color: ${ColorValues.brandPrimaryLightBlue};
	}
	span {
		font-size: 1.3rem;
	}
`;

export const ElementDay = styled.div`
	margin: 5px 0px 0px 0px;
`;
