/*
 * File: CopyrightFooter.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:31 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 13, 2023 at 5:14 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Components
import { Typography } from '../Typography';
import { LocaleLink } from '../LocaleLink';

// Styles
import * as S from './CopyrightFooter.styles';


/**
 * Component
 */

export const CopyrightFooter = ({ isLarge, isDark, showBorder }) => (
	<S.ContainerPadding left right isLarge={isLarge}>
		<S.DownloadFooter isDark={isDark}>

			{/* Content */}
			<S.LeftContainer className="stackTop">
				<Typography tag="h4" weight="bold">
					Download the Pixie Dust app
				</Typography>
				<Typography tag="p" variation="2">
					Take Pixie Dust with you on your next adventure!
				</Typography>
			</S.LeftContainer>

			{/* Download Badges */}
			<S.RightContainer className="badgeContainer">
				<LocaleLink to="https://apps.apple.com/us/app/pixie-dust-skip-the-line/id6449630026" target="_blank">
					<S.Badge
						src={`${process.env.CDN_URL}/public/assets/badges/pixie-dust-ios-download-badge.png`}
						title="Pixie Dust Apple iOS download badge"
						className="animate"
					/>
				</LocaleLink>
				<LocaleLink to="https://play.google.com/store/apps/details?id=com.pixiedust.PixieDustGuide" target="_blank">
					<S.Badge
						src={`${process.env.CDN_URL}/public/assets/badges/pixie-dust-google-download-badge.png`}
						title="Pixie Dust Android Google Play download badge"
						className="animate"
					/>
				</LocaleLink>
			</S.RightContainer>

		</S.DownloadFooter>
		<S.CopyrightFooter isDark={isDark} showBorder={showBorder}>

			{/* Copyright */}
			<S.LeftContainer>
				<LocaleLink to="/">
					<S.CoreLogoMark alt="Pixie Dust Logo" title="Pixie Dust Logo" />
				</LocaleLink>
				<Typography tag="span">
					<Typography tag="span">
						{`© ${new Date().getFullYear()} Pixie Dust.`}
						{' '}
						Made with
						{' '}
						<FontAwesomeIcon icon={['fa', 'heart']} className="heart" />
						{' '}
						in Raleigh, NC
					</Typography>
				</Typography>
			</S.LeftContainer>

			{/* Navigation */}
			<S.RightContainer>
				<S.InlineLink to="/about" $isDark={isDark}>
					<Typography tag="span">About</Typography>
				</S.InlineLink>
				<S.InlineLink to="/legal/terms" $isDark={isDark}>
					<Typography tag="span">Terms</Typography>
				</S.InlineLink>
				<S.InlineLink to="/legal/privacy" $isDark={isDark}>
					<Typography tag="span">Privacy</Typography>
				</S.InlineLink>
				<S.LinkDivider />
				<S.InlineLink to="https://www.facebook.com/pixiedustguide" target="_blank" $isDark={isDark}>
					<FontAwesomeIcon icon={['fab', 'facebook-f']} />
				</S.InlineLink>
				<S.InlineLink to="https://www.instagram.com/pixiedustguide" target="_blank" $isDark={isDark}>
					<FontAwesomeIcon icon={['fab', 'instagram']} style={{ fontSize: '1.2rem' }} />
				</S.InlineLink>
			</S.RightContainer>
		</S.CopyrightFooter>
	</S.ContainerPadding>
);


/**
 * Configuration
 */

CopyrightFooter.displayName = 'CopyrightFooter';
CopyrightFooter.propTypes = {
	isLarge: PropTypes.bool,
	isDark: PropTypes.bool,
	showBorder: PropTypes.bool,
};
CopyrightFooter.defaultProps = {
	isLarge: false,
	isDark: false,
	showBorder: true
};
