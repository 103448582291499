/*
 * File: BlogSection.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: May 18, 2023 at 11:39 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

// Services
import { getBlogPosts } from '../../services/blog';

// Components
import { BlogCard } from './BlogCard';
import { ErrorComponent, Pagination, Spinner } from '..';

// Styles
import * as S from './BlogSection.styles';


/**
 * Component
 */

export const BlogSection = ({
	className,
	blogs,
	perRow,
	limit,
	total,
	centeredText,
	showPagination,
	fetchParams,
	locale,
}) => {

	// Create state handlers
	const [pageStatus, setPageStatus] = useState(!blogs ? 'idle' : 'success');
	const [stories, setStories] = useState(blogs);
	const [totalStories, setTotalStories] = useState(total);
	const [currentPage, setCurrentPage] = useState(0);

	// Create reference for components
	const isMounted = useRef(true);
	const shouldReload = useRef(false);
	const enableDynamicLoad = useRef(false);

	// Generate final per row count based on contact
	const storiesPerRow = stories != null && stories.length < perRow ? stories.length : perRow;

	// Handle fetch data for component
	const fetchDataForComponent = async () => {

		// Update page status
		setPageStatus('loading');
		try {

			// Fetch blog topics if necessary
			if (!stories || shouldReload.current === true) {

				// Fetch blog posts
				const { data: datum } = await getBlogPosts({ pageIndex: currentPage, pageSize: limit, ...fetchParams });

				// Set new data state
				if (isMounted.current) {
					setStories(datum.stories.posts);
					setTotalStories(datum.stories.totalPosts);
				}
			}

			// Update state
			shouldReload.current = false;

			// Update page status
			if (isMounted.current) {
				setPageStatus('success');
			}

		} catch (error) {

			// Update page status
			if (isMounted.current) {
				setPageStatus('error');
			}
		}
	};

	// Handle actions on app component state change
	useEffect(() => {

		// Ensure initial component loading is not complete
		if (pageStatus === 'idle') {

			// Fetch data state for component
			fetchDataForComponent();
		}
	}, [pageStatus]);

	// Handle actions on current page change
	useEffect(() => {

		// Ensure initial component loading is complete
		if (enableDynamicLoad.current && pageStatus === 'success') {

			// Update state
			shouldReload.current = true;

			// Fetch new data
			fetchDataForComponent();
		}
	}, [currentPage]);

	// Handle component initialization
	useEffect(() => {

		// Set state
		isMounted.current = true;
		enableDynamicLoad.current = true;

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, []);

	// Handle component render
	const renderComponent = () => {

		// Get final stories to render
		const renderStories = stories || [];

		// Render for status
		if (pageStatus === 'idle' || pageStatus === 'loading') {
			return <Spinner />;
		} if (pageStatus === 'error') {
			return <ErrorComponent locale={locale} />;
		}
		return (
			<>
				{/* Stories Container */}
				<S.StoryGridContainer $perRow={storiesPerRow}>
					{renderStories.map((story) => (
						<BlogCard
							key={story.id}
							centeredText={centeredText}
							perRow={storiesPerRow}
							story={story}
						/>
					))}
				</S.StoryGridContainer>

				{/* Pagination */}
				{showPagination && (
					<S.PaginationContainer>
						<Pagination
							currentPage={currentPage}
							totalPages={Math.ceil(totalStories / limit)}
							updatePage={setCurrentPage}
							position="center"
						/>
					</S.PaginationContainer>
				)}
			</>
		);
	};

	// Render component
	return (
		<S.Wrapper className={className}>
			{renderComponent()}
		</S.Wrapper>
	);
};


/**
 * Configuration
 */

BlogSection.displayName = 'BlogSection';
BlogSection.propTypes = {
	className: PropTypes.string,
	blogs: PropTypes.arrayOf(PropTypes.shape()),
	perRow: PropTypes.number,
	limit: PropTypes.number,
	total: PropTypes.number,
	centeredText: PropTypes.bool,
	showPagination: PropTypes.bool,
	fetchParams: PropTypes.shape(),
	locale: PropTypes.shape()
};
BlogSection.defaultProps = {
	className: null,
	blogs: null,
	perRow: 4,
	limit: 4,
	total: 0,
	centeredText: false,
	showPagination: false,
	fetchParams: {},
	locale: null
};
