/*
 * File: blog.slice.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 26, 2023 at 9:02 PM
 * Copyright © 2023 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: March 31, 2023 at 2:10 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import { createSlice } from '@reduxjs/toolkit';


/**
 * Constants
 */

export const initialState = {
	status: 'idle',
	error: null,
	value: {
		isSet: false,
		topics: [
			{ title: 'Exploring the Parks', slug: 'exploring-the-parks' },
			{ title: 'How-To Guides', slug: 'how-to-guides' },
			{ title: 'Tips & Tricks', slug: 'tips-and-tricks' },
			{ title: 'Trip Planning', slug: 'trip-planning' },
		]
	},
};


/**
 * Slices
 */

export const blogSlice = createSlice({
	name: 'blog',
	initialState,
	reducers: {
		updateBlogTopics: (state, action) => {

			// Set state
			state.value = {
				...state.value,
				topics: action.payload,
				isSet: true
			};
		},
		clearBlogTopics: (state) => {

			// Clear state
			state.value = {
				...initialState.value,
				isSet: true
			};
		},
	},
	extraReducers: {},
});


/**
 * Exports
 */

export const { updateBlogTopics, clearBlogTopics } = blogSlice.actions;

export default blogSlice.reducer;
