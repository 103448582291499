/*
 * File: TextInput.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 9, 2022 at 4:02 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: March 9, 2023 at 11:13 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Components
import { Typography } from '../Typography';

// Styles
import * as S from './TextInput.styles';


/**
 * Component
 */

export const TextInput = forwardRef(({
	id, label, error, type, size, icon, containerClassName, smartField, isDark, ...rest
}, ref) => (!smartField ? (
	<S.Wrapper error={error} className={containerClassName}>
		{label && (
			<Typography tag="label" weight="semibold" htmlFor={id ? `${id}` : null} id={id ? `${id}-label` : null} className="animate">
				{label}
			</Typography>
		)}
		{icon && (
			<S.IconWrapper>
				<FontAwesomeIcon icon={icon} />
			</S.IconWrapper>
		)}
		<S.TextInput ref={ref} id={id} error={error} {...rest} type={type} size={size} withIcon={!!icon} className={rest.className ? `${rest.className} animate` : 'animate'} />
		{error && error.message && (
			<S.FloatingWrapper title={error.message} className="animate errorContainer">
				<Typography tag="p" variation="3" weight="medium" className="animate">
					{error.message}
				</Typography>
			</S.FloatingWrapper>
		)}
	</S.Wrapper>
) : (
	<S.SmartFieldContainer className={containerClassName}>
		<S.SmartFieldset>
			<S.SmartTextInput ref={ref} id={id} error={error} {...rest} type={type} size={size} className={rest.className ? `${rest.className} animate` : 'animate'} isDark={isDark} required />
			<S.SmartLabel htmlFor={id ? `${id}` : null} id={id ? `${id}-label` : null} className="animate" isDark={isDark}>{label}</S.SmartLabel>
		</S.SmartFieldset>
		<S.SmartFloatingWrapper title={error?.message} className={error && error.message ? 'show animate errorContainer' : 'animate errorContainer'} isDark={isDark}>
			<Typography tag="p" variation="3" weight="medium" className="animate">
				{error?.message}
			</Typography>
		</S.SmartFloatingWrapper>
	</S.SmartFieldContainer>
)));


/**
 * Configuration
 */

TextInput.displayName = 'TextInput';
TextInput.propTypes = {
	icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
	type: PropTypes.oneOf(['text', 'password', 'email', 'search', 'number', 'tel']),
	size: PropTypes.oneOf(['fit', 'small', 'medium', 'large']),
	id: PropTypes.string,
	placeholder: PropTypes.string,
	label: PropTypes.string,
	containerClassName: PropTypes.string,
	smartField: PropTypes.bool,
	isDark: PropTypes.bool,
	error: PropTypes.shape(),
};
TextInput.defaultProps = {
	icon: null,
	type: 'text',
	size: 'medium',
	placeholder: '',
	label: '',
	containerClassName: null,
	error: null,
	smartField: false,
	isDark: false,
	id: null
};
