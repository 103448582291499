/*
 * File: App.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on June 16, 2022 at 1:03 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: June 24, 2023 at 7:07 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, {
	useEffect, useRef
} from 'react';
import {
	Routes, Route, useLocation, useSearchParams
} from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Analytics
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

// Utilities
import { toastError, toastMessage } from '../utilities/toaster';
import { filterData } from '../utilities/state';
import { scrollPageTo } from '../utilities/position';

// Styles
import { GlobalStyles } from '../styles/global';
import { DarkTheme, LightTheme } from '../styles/colors';

// Slices
import { updateUser, fetchUser } from '../store/slices/user/user.slice';
import { updateLocale, clearLocale } from '../store/slices/locale/locale.slice';
import { updateConsent, initializeConsent } from '../store/slices/consent/consent.slice';
import { pushComponentState } from '../store/slices/component/component.slice';

// Components
import {
	Spinner,
	CookieBar
} from '../components';

// Routes
import { Routes as RoutesCreator } from './Routes';

// Constants
import { UI_MODE_OPTIONS, ALERT_STYLES, VIEW_MODE_COOKIE } from '../../Constants';
import { getCookie } from '../utilities/cookies';


/**
 * Constants
 */

const isProduction = process.env.ENV === 'production';
const isEU = false; // For development / hot usage only (does not affect production)


/**
 * Component
 */

const App = (data) => {

	// Set component props from server
	let props = data || {};
	const { isHot } = props;

	// Use hooks
	const location = useLocation();
	const dispatch = useDispatch();
	const [searchParams, setSearchParams] = useSearchParams({});

	// Initialize render count
	const renderCount = useRef(-1);

	// Get current user from hook
	const currentUser = useSelector((state) => state.user.value);
	const userStatus = useSelector((state) => state.user.status);

	// Get current consent state from hook
	const consentStatus = useSelector((state) => state.consent.status);
	const currentConsent = useSelector((state) => state.consent.currentValue);
	const previousConsent = useSelector((state) => state.consent.previousValue);

	// Get query parameters from hook
	let isMobileApp = searchParams.get('mobileapp') === 'true';
	if (!isMobileApp) {
		const mobileAppCookie = getCookie(VIEW_MODE_COOKIE);
		if (mobileAppCookie === 'mobileapp') isMobileApp = true;
	}

	// Get current UI mode from hook
	let uiMode = useSelector((state) => state.ui.value);
	if (props?.state?.ui) uiMode = props?.state?.ui;

	// Get component state on client
	if (typeof window !== 'undefined') {
		try { props = JSON.parse(decodeURIComponent(window.document.getElementById('data-state').innerHTML)); } catch (e) { }
	}

	// Define current theme
	const currentTheme = uiMode.mode === UI_MODE_OPTIONS.DARK ? DarkTheme() : LightTheme();

	// Handle actions on component load
	useEffect(() => {

		// Set locale in state
		if (props.locale && props.locale.localeId) dispatch(updateLocale(props.locale));
		else dispatch(clearLocale());

		// Set consent in state
		if (props?.state?.consent) dispatch(updateConsent(props.state.consent));
		else if (isHot === true) dispatch(initializeConsent(isEU));

		// Set component data in state
		if (props.data && Object.keys(props.data).length > 0) {

			// Filter props for route
			const filteredData = filterData(window.location.pathname, props.data);

			// Push primary component state
			dispatch(pushComponentState(filteredData));

		} else {

			// Push primary component state
			dispatch(pushComponentState());
		}

		// Check for alert to display
		if (props?.alert) {

			// Get alert parameters
			const { message, style } = props.alert;

			// Show toast
			setTimeout(() => {
				if (style === ALERT_STYLES.INFO) {
					toastMessage(uiMode, message);
				} else if (style === ALERT_STYLES.ERROR) {
					toastError(uiMode, message);
				}
			}, 400);
		}
	}, []);

	// Handle actions on consent update action
	useEffect(() => {

		// Only update if consent has been set
		if (consentStatus !== 'initial') {

			// Only update if production environment
			if (isProduction) {

				// Check if consent values changed
				const performanceChanged = currentConsent.performanceEnabled !== previousConsent.performanceEnabled;
				const analyticsChanged = currentConsent.analyticsEnabled !== previousConsent.analyticsEnabled;
				const targetingChanged = currentConsent.targetingEnabled !== previousConsent.targetingEnabled;

				// Check if performance consent changed
				if (performanceChanged) {
					if (currentConsent.performanceEnabled === true) {

						// Push GTM trigger
						try {
							window.dataLayer.push({ event: 'consent_given_performance' });
						} catch (err) { }

					} else {

						// Reload page to disable scripts
						window.location.reload(false);

						// Return
						return;
					}
				}

				// Check if analytics consent changed
				if (analyticsChanged) {
					if (currentConsent.analyticsEnabled === true) {

						// Push GTM trigger
						try {
							window.dataLayer.push({ event: 'consent_given_analytics' });
						} catch (err) { }

					} else {

						// Reload page to disable scripts
						window.location.reload(false);

						// Return
						return;
					}
				}

				// Check if targeting consent changed
				if (targetingChanged) {
					if (currentConsent.targetingEnabled === true) {

						// Push GTM trigger
						try {
							window.dataLayer.push({ event: 'consent_given_targeting' });
						} catch (err) { }

					} else {

						// Reload page to disable scripts
						window.location.reload(false);
					}
				}
			}
		}
	}, [currentConsent]);

	// Update render count
	useEffect(() => {
		renderCount.current += 1;
	});

	// Handle actions on route change
	useEffect(() => {

		// Get query parameters
		const queryObj = Object.fromEntries([...searchParams]);

		// Set current user from server props
		const { user } = props;

		// Check if server user exists
		if (user != null && user.activeSession === true) {

			// Get fetched dates
			const serverFetched = user ? new Date(user.lastFetched) : new Date();
			const stateFetched = currentUser ? new Date(currentUser.lastFetched) : serverFetched;

			// Only update state if current user is not newer
			if (stateFetched.getTime() <= serverFetched.getTime()) {

				// Set user from server
				dispatch(updateUser({ ...user, lastFetched: (new Date()).toISOString() }));
			}
		} else if (currentUser == null) {

			// Fetch current user
			dispatch(fetchUser());
		}

		// Check for error query
		if (searchParams.has('error')) {

			// Get error message
			const errorMessage = decodeURIComponent(searchParams.get('error'));

			// Clear error query parameters
			const { error, code, ...additionalParams } = queryObj;
			setSearchParams(additionalParams);

			// Display error toast
			setTimeout(() => {
				toastError(uiMode, errorMessage);
			}, 400);
		}

		// Handle hash scroll
		const { hash } = location;
		if (!hash) {
			scrollPageTo(0, 0, { behavior: 'smooth' });
		} else {
			const id = hash.replace('#', '');
			const element = document.getElementById(id);
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' });
			} else {
				scrollPageTo(0, 0, { behavior: 'smooth' });
			}
		}
	}, [location]);

	// Create routes with props
	const RoutesManifest = RoutesCreator(props, isHot);

	// Set up router render function
	const renderRouter = () => (
		<Routes>
			{RoutesManifest.map((route, index) => {
				const { component: Component } = route;
				return <Route key={route.path || `route-${index}`} path={route.path} element={Component} />;
			})}
		</Routes>
	);

	// Set up component render function
	const renderComponent = () => {
		if ((userStatus === 'idle' && isHot === true) || userStatus === 'loading') {
			return <Spinner showMeta />;
		}
		if (isProduction && currentConsent.performanceEnabled === true) {
			return (
				<GoogleReCaptchaProvider reCaptchaKey={process.env.RECAPTCHA_PUBLIC_KEY}>
					{renderRouter()}
				</GoogleReCaptchaProvider>
			);
		}
		return renderRouter();
	};

	// Render router
	return (
		<ThemeProvider theme={currentTheme}>
			<GlobalStyles />
			{renderComponent()}
			{currentConsent.consentActionTaken !== true && consentStatus !== 'initial' && !isMobileApp && <CookieBar />}
		</ThemeProvider>
	);
};


/**
 * Configuration
 */

App.propTypes = {
	user: PropTypes.shape(),
	locale: PropTypes.shape(),
	state: PropTypes.shape(),
	alert: PropTypes.shape(),
	data: PropTypes.shape(),
	isHot: PropTypes.bool
};
App.defaultProps = {
	user: null,
	locale: {},
	state: {},
	alert: {},
	data: null,
	isHot: false
};


/**
 * Exports
 */

export default App;
