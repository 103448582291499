/*
 * File: TripDetail.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on November 18, 2022 at 3:45 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: September 5, 2023 at 10:55 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	useNavigate, useParams, useSearchParams, useLocation
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Utilities
import { createStateLocale } from '../../../utilities/locale';
import { scrollPageTo } from '../../../utilities/position';
import { toastError, toastSuccess } from '../../../utilities/toaster';
import { globalQuery } from '../../../utilities/state';

// Services
import { deleteTrip, getTrip, refreshTrip } from '../../../services/trip';
import { refreshDisneyAccount } from '../../../services/user';

// Slices
import { updateUser } from '../../../store/slices/user/user.slice';
import { updateTrips } from '../../../store/slices/trips/trips.slice';

// Components
import {
	Meta, AppNavigation, Typography, LocaleLink, Spinner, ErrorComponent, Badge, LoadingModal, ConfirmationModal, IconButton
} from '../../../components';
import { UpdateTitleModal } from './UpdateTitleModal';

// Constants
import { navigationDropdownDelay } from '../../../styles/constants';
import { PARK_ENTITY_OPTIONS, QUERY_ACTIONS } from '../../../../Constants';

// Styles
import * as S from './TripDetail.styles';


/**
 * State
 */

let optionsDropdownTimer = null;


/**
 * Component
 */

const TripDetail = ({
	meta, locale, user
}) => {

	// Get component parameters
	const { id } = useParams();

	// Create state handlers
	const [pageStatus, setPageStatus] = useState('idle');
	const [trip, setTrip] = useState(null);
	const [showOptionsDropdown, setShowOptionsDropdown] = useState(false);
	const [refreshModalVisible, showRefreshModal] = useState(false);
	const [deleteConfirmationVisible, showDeleteConfirmation] = useState(false);
	const [editTitleModalOpen, setEditTitleModalOpen] = useState(false);
	const [deleteInProgress, setDeleteInProgress] = useState(false);

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Create reference for components
	const isMounted = useRef(true);

	// Get current user
	let userObj = useSelector((state) => state.user.value);
	if (!userObj && user) userObj = user;

	// Get current trips from hook
	const tripsState = useSelector((state) => state.trips.value);

	// Get actions from hooks
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	// Get query parameters from hook
	const [searchParams] = useSearchParams();

	// Get park entity parameters
	let mobileAppName = 'My Disney Experience';
	if (trip != null && trip?.parkEntity != null) {
		const parkEntity = PARK_ENTITY_OPTIONS.find((entity) => entity.id === trip?.parkEntity.id);
		mobileAppName = parkEntity.mobileApp;
	}

	// Handle refreshing trip
	const promptRefreshTrip = async () => {
		try {

			// Show refreshing modal
			showRefreshModal(true);

			// Attempt to refresh Disney account
			try {

				// Refresh Disney account
				const { data: { user: refreshUser } } = await refreshDisneyAccount();

				// Update user
				dispatch(updateUser({ ...refreshUser, lastFetched: (new Date()).toISOString() }));

			} catch (e) {

				// Show error
				toastError(uiMode, 'Whoops. We\'re having trouble refreshing your account information. Please try again.');
			}

			// Refresh trip status
			const { data } = await refreshTrip({ id });

			// Update state
			setTrip(data.trip);

			// Update trips
			if (tripsState?.isSet) {
				const newTrips = [...(tripsState?.trips || [])];
				const tripIndex = newTrips.findIndex((v) => v.id === data.trip.id);
				if (tripIndex > -1) newTrips[tripIndex] = data.trip;
				dispatch(updateTrips({ trips: newTrips }));
			}

			// Hide refreshing modal
			showRefreshModal(false);

		} catch (e) {

			// Show error
			toastError(uiMode, 'Whoops. We\'re having trouble refreshing your trip information. Please try again.');

			// Hide refreshing modal
			showRefreshModal(false);
		}
	};

	// Handle prompt to delete trip
	const promptDeleteTrip = async () => {

		// Hide dropdown
		setShowOptionsDropdown(false);

		// Show confirmation modal
		showDeleteConfirmation(true);
	};

	// Handle deleting trip
	const handleDeleteTrip = async () => {
		try {

			// Update deletion state
			setDeleteInProgress(true);

			// Delete trip
			await deleteTrip({ id });

			// Update trips
			if (tripsState?.isSet) {
				const newTrips = [...(tripsState?.trips || [])].filter((v) => v.id !== id);
				dispatch(updateTrips({ trips: newTrips }));
			}

			// Show success state
			toastSuccess(uiMode, 'Your trip has successfully been deleted. Heading back to your trips.');

			// Redirect to trips
			setTimeout(() => {
				navigate(`${stateLocale.localePath}/trips${globalQuery(searchParams)}`);
			}, 2500);

		} catch (e) {

			// Show error
			toastError(uiMode, 'Whoops. We\'re having trouble deleting your trip. Please try again.');

			// Update deletion state
			setDeleteInProgress(false);
		}
	};

	// Handle fetch data for page
	const fetchDataForPage = async () => {

		// Update page status
		setPageStatus('loading');
		try {

			// Fetch trip
			const { data } = await getTrip({ id });

			// Check if component is mounted
			if (isMounted.current) {

				// Set new data state
				setTrip(data.trip);

				// Update trips
				if (tripsState?.isSet) {
					const newTrips = [...(tripsState?.trips || [])];
					const tripIndex = newTrips.findIndex((v) => v.id === data.trip.id);
					if (tripIndex > -1) newTrips[tripIndex] = data.trip;
					dispatch(updateTrips({ trips: newTrips }));
				}

				// Update page status
				setPageStatus('success');
			}
		} catch (error) {

			// Ensure component is mounted
			if (isMounted.current) {

				// Update page status
				setPageStatus('error');
			}
		}
	};

	// Handle actions on route change (dynamic page)
	useEffect(() => {

		// Set page status if necessary
		if (pageStatus !== 'idle') {

			// Reset page status
			setPageStatus('idle');

		} else {

			// Fetch data state for page
			fetchDataForPage();
		}

		// Scroll page to top
		scrollPageTo(0, 0);

	}, [id]);

	// Handle component initialization
	useEffect(() => {

		// Set state
		isMounted.current = true;

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, []);

	// Handle render back button title
	const renderBackButtonTitle = () => {
		const path = location?.state?.back?.path;
		if (!path) {
			return 'Back to Trips';
		} if (path.endsWith('/trips')) {
			return 'Back to Trips';
		} if (path.endsWith('/me') || path.endsWith('/profile')) {
			return 'Back to Profile';
		}
		return 'Back to Trips';
	};

	// Handle render trip status
	const renderStatus = () => {

		// Get current date
		const now = new Date();

		// Render status
		if (!trip?.validParkTickets || !trip?.validParkReservations || (!trip?.validDisneyGeniePlus && trip?.requiresDisneyGeniePlus)
			|| (!userObj.disneyAccountHasPaymentMethod && trip?.requiresDisneyPaymentMethod) || !userObj.hasInternalPaymentMethod) {

			// Get park entity
			const parkEntity = trip?.parkEntity;

			// Create status text
			let status = null;
			let icon = ['fa', 'triangle-exclamation'];
			let statusType = 'warning';
			if (!trip?.validParkTickets) {
				status = (
					<Typography variation="2" weight="medium">
						We aren&apos;t able to find a valid theme park ticket for your visit. Head over and
						{' '}
						<LocaleLink to={`https://${parkEntity.api.domain}/admission/tickets/`} target="_blank">purchase one here</LocaleLink>
						.
					</Typography>
				);
			} else if (!trip?.validParkReservations) {
				status = (
					<Typography variation="2" weight="medium">
						We aren&apos;t able to find a valid theme park reservation for your visit. Head over and
						{' '}
						<LocaleLink to={`https://${parkEntity.api.domain}/experience-updates/park-reservations/`} target="_blank">create one here</LocaleLink>
						.
					</Typography>
				);
			} else if (!userObj.disneyAccountHasPaymentMethod && trip?.requiresDisneyPaymentMethod) {
				status = (
					<Typography variation="2" weight="medium">
						We aren&apos;t able to find a payment method attached to your
						{' '}
						{mobileAppName}
						{' '}
						account. Head over and
						{' '}
						<LocaleLink to={`https://${parkEntity.api.domain}/profile/payment-methods`} target="_blank">add one here</LocaleLink>
						.
					</Typography>
				);
			} else if (!userObj.hasInternalPaymentMethod) {
				status = (
					<Typography variation="2" weight="medium">
						It looks like you haven&apos;t added a payment method to your Pixie Dust account. Head over and
						{' '}
						<LocaleLink to={`/me?action=${QUERY_ACTIONS.ADD_CARD}#account`}>add one here</LocaleLink>
						.
					</Typography>
				);
			} else if (!trip?.validDisneyGeniePlus && trip?.requiresDisneyGeniePlus) {
				icon = ['fa', 'check'];
				statusType = 'success';
				status = (
					<Typography variation="2" weight="medium">
						It looks like Genie+ is needed for your park day.
						{' '}
						{/* Head over and try to
						{' '}
						<LocaleLink to={`https://${parkEntity.api.domain}/plan`} target="_blank">set it up here</LocaleLink>
						.  */}
						If your ticket type requires that Genie+ be purchased the morning of your park day, we will do it for you automatically!
					</Typography>
				);
			}

			// Render status
			return (
				<S.StatusWrapper status={statusType}>
					<FontAwesomeIcon icon={icon} />
					{status}
				</S.StatusWrapper>
			);
		}
		if (now.getTime() >= new Date(trip?.startDate).getTime() && now.getTime() <= new Date(trip?.endDate).getTime()) {

			// Render status
			return (
				<S.StatusWrapper status="success">
					<FontAwesomeIcon icon={['far', 'clock']} />
					<Typography variation="3" weight="medium">We hope you&apos;re having fun on your Disney Trip! Your reservations are scheduled and ready to go.</Typography>
				</S.StatusWrapper>
			);
		}
		if (now.getTime() < new Date(trip?.startDate).getTime()) {

			// Render status
			return (
				<S.StatusWrapper status="success">
					<FontAwesomeIcon icon={['fa', 'check']} />
					<Typography variation="3" weight="medium">Your reservations are scheduled and ready to go!</Typography>
				</S.StatusWrapper>
			);
		}
		return null;
	};

	// Handle render content
	const renderContent = () => {

		// Get current date
		const now = new Date();

		// Check if trip has ended
		const hasEnded = now.getTime() > new Date(trip?.endDate).getTime();
		const isScheduled = trip?.validEventsToSchedule && now.getTime() < new Date(trip?.endDate).getTime();

		// Return content
		if (pageStatus === 'idle' || pageStatus === 'loading') {
			return <Spinner showMeta meta={meta} />;
		}
		if (pageStatus === 'error') {
			return <ErrorComponent isDark />;
		}
		return (
			<>
				{/* Status Banner */}
				<S.StatusBanner>
					{renderStatus()}
				</S.StatusBanner>

				{/* Back Action */}
				<S.BackButton variant="text" size="small" onClick={() => { navigate(location?.state?.back?.path || `${stateLocale.localePath}/trips`); }}>
					<FontAwesomeIcon icon={['fas', 'arrow-left-long']} />
					<Typography variation="2" weight="semibold">{renderBackButtonTitle()}</Typography>
				</S.BackButton>

				{/* Header Section */}
				<S.HeaderSection $collapseOnMobile={!hasEnded}>

					{/* Title Section */}
					<S.TitleSection>

						{/* Title */}
						<Typography tag="h2" weight="semibold">{trip?.name}</Typography>

						{/* Edit Title Button */}
						<IconButton
							size={0.85}
							icon={['fas', 'pen']}
							onClick={() => { setEditTitleModalOpen(true); }}
						/>

					</S.TitleSection>

					{/* Actions */}
					<S.ActionContainer>

						{/* Edit Button */}
						{!hasEnded && (
							<S.ActionButton variation="white" onClick={() => { navigate(`${stateLocale.localePath}/trip/edit/${trip?.id}${globalQuery(searchParams)}`); }}>
								<FontAwesomeIcon icon={['fas', 'pen']} />
								<Typography variant="2" weight="medium">Edit</Typography>
							</S.ActionButton>
						)}

						{/* Refresh Button */}
						{!hasEnded && (
							<S.ActionButton variation="white" variant="outline" onClick={promptRefreshTrip}>
								<FontAwesomeIcon icon={['fas', 'rotate']} />
								<Typography variant="2" weight="medium">Refresh</Typography>
							</S.ActionButton>
						)}

						{/* Action Dropdown */}
						<S.DropdownContainer
							onMouseEnter={() => {
								clearTimeout(optionsDropdownTimer);
								setShowOptionsDropdown(true);
							}}
							onMouseLeave={() => {
								clearTimeout(optionsDropdownTimer);
								optionsDropdownTimer = setTimeout(() => {
									setShowOptionsDropdown(false);
								}, navigationDropdownDelay);
							}}
						>

							{/* Dropdown Toggle */}
							<S.DropdownToggle size={1.3} icon={['fas', 'ellipsis-v']} />

							{/* Dropdown */}
							<S.NavigationDropdown
								className={showOptionsDropdown ? 'animate auth show' : 'auth animate'}
								items={[
									{
										icon: ['far', 'question-circle'],
										title: 'Help',
										link: '/contact'
									},
									{
										icon: ['far', 'trash-can'],
										title: 'Delete trip',
										isDanger: true,
										action: () => { promptDeleteTrip(); }
									}
								]}
							/>
						</S.DropdownContainer>

					</S.ActionContainer>
				</S.HeaderSection>

				{/* Status Badge */}
				{(isScheduled || hasEnded) && (
					<S.BadgeContainer>
						{hasEnded && (
							<Badge type="success">
								<FontAwesomeIcon icon={['fa', 'check']} style={{ marginRight: '5px' }} />
								Completed
							</Badge>
						)}
						{isScheduled && (
							<Badge>
								<FontAwesomeIcon icon={['far', 'clock']} style={{ marginRight: '5px' }} />
								Scheduled
							</Badge>
						)}
					</S.BadgeContainer>
				)}

				{/* Trip Section */}
				<S.TripSection>

					{/* Days Container */}
					<S.DayCollection days={trip?.days} editingEnabled={false} />

					{/* Trip Details */}
					<S.DetailSection>

						{/* Title */}
						<S.TitleContainer>
							<Typography tag="h4">My Trip</Typography>
						</S.TitleContainer>

						{/* Detail Card */}
						<S.TripCard
							trip={trip}
							isDark
							isDetail
						/>

					</S.DetailSection>

					{/* Party */}
					<S.PartySection>

						{/* Title */}
						<S.TitleContainer>
							<Typography tag="h4">Party</Typography>
						</S.TitleContainer>

						{/* Party */}
						<S.Party members={trip?.party} />

					</S.PartySection>
				</S.TripSection>

				{/* Modals */}
				<LoadingModal
					prompt="Refreshing trip..."
					isOpen={refreshModalVisible}
					handleClose={() => { showRefreshModal(false); }}
				/>
				<ConfirmationModal
					isOpen={deleteConfirmationVisible}
					handleClose={() => { showDeleteConfirmation(false); }}
					type="danger"
					title="Delete this trip?"
					message="Are you sure you want to delete this trip? This cannot be undone."
					primaryButtonTitle="Yes, delete"
					secondaryButtonTitle="Cancel"
					isLoading={deleteInProgress}
					primaryButtonAction={handleDeleteTrip}
					secondaryButtonAction={() => { showDeleteConfirmation(false); }}
				/>
				<UpdateTitleModal
					isOpen={editTitleModalOpen}
					handleClose={() => { setEditTitleModalOpen(false); }}
					updateTrip={setTrip}
					trip={trip}
				/>
			</>
		);
	};

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} data={{ trip: trip || { name: 'Loading...' } }} />

			{/* Component Content */}
			<AppNavigation containerClassName="gradientBackground" isFixed={false}>
				<S.Wrapper>
					{renderContent()}
				</S.Wrapper>
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

TripDetail.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
	user: PropTypes.shape(),
};
TripDetail.defaultProps = {
	meta: {},
	locale: {},
	user: null
};


/**
 * Exports
 */

export default TripDetail;
