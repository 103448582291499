/*
 * File: UpdateDisneyAccountModal.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: October 22, 2023 at 11:16 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';
import { useSelector } from 'react-redux';

// Utilities
import { toastError, toastSuccess } from '../../../../utilities/toaster';
import { displayDisneyAuthError } from '../../../../utilities/disney';

// Services
import { attachDisneyAccount, getCurrentUser } from '../../../../services/user';

// Components
import {
	Button, Modal, Typography, TextInput
} from '../../../../components';

// Styles
import * as S from './UpdateDisneyAccountModal.styles';


/**
 * Constants
 */

const MAX_POLLING_ATTEMPTS = 6;


/**
 * State
 */

let pollingTimer = null;


/**
 * Component
 */

export const UpdateDisneyAccountModal = ({
	className, isOpen, handleClose, updateUser
}) => {

	// Get current user
	const user = useSelector((state) => state.user.value);

	// Create state handlers
	const [emailError, setEmailError] = useState(null);
	const [passwordError, setPasswordError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [inputValues, setInputValues] = useState({});

	// Create references
	const pollingCount = useRef(0);

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Handle update Disney account function
	const handleUpdateDisneyAccount = async () => {

		// Get parameters
		const { email, password } = inputValues;

		// Validate parameters
		if (!email || !validator.isEmail(email)) {
			setEmailError({ message: 'Please enter your My Disney Experience email' });
			return;
		}
		if (!password || validator.isEmpty(password, { ignore_whitespace: true })) {
			setPasswordError({ message: 'Please enter your My Disney Experience password' });
			return;
		}

		// Set loading state
		setIsLoading(true);

		// Update user profile
		attachDisneyAccount({ username: email, pass: password }).then(({ data }) => {

			// Show success toast
			toastSuccess(uiMode, 'Success! Your My Disney Experience account has been connected.');

			// Set loading state
			setIsLoading(false);
			setEmailError(null);
			setPasswordError(null);

			// Update user
			if (updateUser) updateUser(data.user);

			// Handle completion actions
			setTimeout(() => {
				if (handleClose) handleClose();
			}, 2500);

		}).catch(({ response }) => {

			// Check if timeout error
			if (response?.status === 503) {

				// Set polling interval to check Disney authentication status
				pollingTimer = setInterval(async () => {

					// Fetch current user
					const { data } = await getCurrentUser();

					// Check if user has Disney auth error
					if (data.user.disneyAccountError != null && data.user.disneyAccountError.message != null) {

						// Cancel interval
						if (pollingTimer != null) clearInterval(pollingTimer);
						pollingTimer = null;

						// Set loading state
						setIsLoading(false);
						setEmailError(null);
						setPasswordError(null);

						// Clear password
						setInputValues({ ...inputValues, password: '' });

						// Display Disney auth error
						displayDisneyAuthError(data.user.disneyAccountError.message, uiMode);

						// Return
						return;
					}

					// Check if disney authentication completed
					if (data.user.onboardingComplete === true) {

						// Cancel interval
						if (pollingTimer != null) clearInterval(pollingTimer);
						pollingTimer = null;

						// Show success toast
						toastSuccess(uiMode, 'Success! Your My Disney Experience account has been connected.');

						// Set loading state
						setIsLoading(false);
						setEmailError(null);
						setPasswordError(null);

						// Update user
						if (updateUser) updateUser(data.user);

						// Handle completion actions
						setTimeout(() => {
							if (handleClose) handleClose();
						}, 2500);
					}

					// Update poll count
					pollingCount.current += 1;

					// Show error if exceeded maxium poll attempts
					if (pollingCount.current > MAX_POLLING_ATTEMPTS) {

						// Cancel interval
						if (pollingTimer != null) clearInterval(pollingTimer);
						pollingTimer = null;

						// Set loading state
						setIsLoading(false);
						setEmailError(null);
						setPasswordError(null);

						// Clear password
						setInputValues({ ...inputValues, password: '' });

						// Show error message
						toastError(uiMode, 'Whoops. We\'re having trouble connecting to your My Disney Experience Account. Please try again.');
					}

				}, 8 * 1000); // 8 seconds

			} else {

				// Set loading state
				setIsLoading(false);
				setEmailError(null);
				setPasswordError(null);

				// Clear password
				setInputValues({ ...inputValues, password: '' });

				// Show error message
				if (response?.data?.message) {
					displayDisneyAuthError(response?.data?.message, uiMode);
				} else {
					toastError(uiMode, 'Whoops. We\'re having trouble connecting to your My Disney Experience Account. Please try again.');
				}
			}
		});
	};

	// Handle on input change action
	const handleOnChange = (event) => {
		const { name, value } = event.target;
		setInputValues({ ...inputValues, [name]: value });
	};

	// Handle actions on component load
	useEffect(() => {
		if (isOpen === true) {

			// Set input values
			setInputValues({ ...inputValues, email: user?.disneyCredentials?.username || '', password: '' });

			// Hide errors
			setEmailError(null);
			setPasswordError(null);
		}
	}, [isOpen]);

	// Render component
	return (
		<Modal className={className} isOpen={isOpen} handleClose={handleClose} clickOutsideClose useWrapper>
			<S.ModalInner>

				{/* Disney Account Icon */}
				<S.LogoMark $uiMode={uiMode?.mode} />

				{/* Content */}
				<Typography tag="h3" weight="bold">Connected Disney Account</Typography>
				<Typography tag="p" variation="1" weight="light" className="subtitle">Update your connected My Disney Experience account information.</Typography>

				{/* Inputs */}
				<TextInput
					label="Disney Account Email"
					placeholder="e.g. hello@acme.com"
					name="email"
					error={emailError}
					value={inputValues.email || ''}
					containerClassName="modalInput"
					autoComplete="email"
					onFocus={() => { setEmailError(null); }}
					onKeyUp={() => { setEmailError(null); }}
					onBlur={() => { setEmailError(null); }}
					onChange={handleOnChange}
				/>
				<TextInput
					label="Disney Account Password"
					type="password"
					name="password"
					placeholder="********"
					error={passwordError}
					value={inputValues.password || ''}
					containerClassName="modalInput"
					autoComplete="password"
					onFocus={() => { setPasswordError(null); }}
					onKeyUp={() => { setPasswordError(null); }}
					onBlur={() => { setPasswordError(null); }}
					onChange={handleOnChange}
				/>

				{/* Actions */}
				<S.ActionContainer>
					<Button disabled={isLoading} isLoading={isLoading} variant="solid" onClick={handleUpdateDisneyAccount}>Update Disney Account</Button>
				</S.ActionContainer>

			</S.ModalInner>
		</Modal>
	);
};


/**
 * Configuration
 */

UpdateDisneyAccountModal.displayName = 'UpdateDisneyAccountModal';
UpdateDisneyAccountModal.propTypes = {
	className: PropTypes.string,
	isOpen: PropTypes.bool,
	handleClose: PropTypes.func,
	updateUser: PropTypes.func
};
UpdateDisneyAccountModal.defaultProps = {
	className: null,
	isOpen: false,
	handleClose: null,
	updateUser: null
};
