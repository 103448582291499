/*
 * File: FAQ.styles.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on May 28, 2023 at 11:19 AM
 * Copyright © 2023 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: June 29, 2023 at 4:03 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Constants
import { largeBreakpoint, mediumBreakpoint } from '../../../styles/constants';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: block;
	padding: 0px 0px 120px;
	margin: 0 auto;
`;

export const HeroSection = styled.div`
	margin: 120px 0px 60px;
`;

export const HeroContentContainer = styled.div`
	text-align: center;
`;

export const TitleContainer = styled.div`
	a,
	a:link,
	a:visited {
		text-decoration: none;
		display: inline-block;
		position: relative;
	}
	a:hover {
		text-decoration: underline;
	}
	.floatingThe {
		position: absolute;
		left: 40px;
		top: -26px;
		font-size: 25px;
	}
	h1 {
		position: relative;
		font-size: 2.8rem;
	}

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		h1 {
			font-size: 3.8rem;
		}
		.floatingThe {
			left: 32px;
			top: -30px;
			font-size: 32px;
		}
	}
	@media only screen and (min-width: ${largeBreakpoint}em) {
		h1 {
			font-size: 4.8rem;
		}
		.floatingThe {
			left: 45px;
			top: -40px;
			font-size: 40px;
		}
	}
`;

export const LinksSection = styled.div`
	text-align: left;
	margin: 0px auto;
	max-width: 750px;
	width: 100%;
`;

export const QuestionLink = styled.div`
	margin: 18px 0px 0px;
`;

export const QuestionsSection = styled.div`
	margin: 0px auto;
	max-width: 750px;
	width: 100%;

	* {
		text-align: left;
	}
`;

export const QuestionContainer = styled.div`
	text-align: left;
	width: 100%;
	margin: 100px 0px 0px;

	img {
		width: 100%;
	}
`;

export const Question = styled.div`
	margin: 0px 0px 20px;
`;

export const Signpost = styled.div`
	position: relative;
	top: -150px;
`;

export const Answer = styled.div`
	margin: 0px 0px;
`;
