/*
 * File: LoadingModal.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: March 2, 2023 at 6:56 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie-player';

// Components
import { Modal, Typography } from '..';

// Animation
import animationData from '../../assets/animations/shining-stars.json';

// Styles
import * as S from './LoadingModal.styles';


/**
 * Component
 */

export const LoadingModal = ({
	className, isOpen, handleClose, prompt
}) => {

	// Handle actions on component load
	useEffect(() => {}, []);

	// Render component
	return (
		<Modal className={className} isOpen={isOpen} handleClose={handleClose} clickOutsideClose={false} showClose={false}>
			<S.ModalInner>

				{/* Animation */}
				<Lottie
					loop
					play
					animationData={animationData}
					style={{
						width: '100%', height: 160, maxWidth: 260, margin: '0px auto'
					}}
				/>

				{/* Content */}
				<Typography tag="h4" weight="medium">{prompt}</Typography>

			</S.ModalInner>
		</Modal>
	);
};


/**
 * Configuration
 */

LoadingModal.displayName = 'LoadingModal';
LoadingModal.propTypes = {
	className: PropTypes.string,
	isOpen: PropTypes.bool,
	handleClose: PropTypes.func,
	prompt: PropTypes.string,
};
LoadingModal.defaultProps = {
	className: null,
	isOpen: false,
	handleClose: null,
	prompt: 'Loading...',
};
