/*
 * File: AttractionSearchWidget.styles.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:53 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: March 30, 2023 at 3:31 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';

// Constants
import { UI_MODE_OPTIONS } from '../../../Constants';

// Styles
import { ColorValuesCreator } from '../../styles/colors';

// Create color values
const ColorValues = ColorValuesCreator();


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	position: relative;
`;

export const DropdownContainer = styled.div`
	width: 100%;
	padding: 10px;
	position: absolute;
	margin-top: 10px;
	height: auto !important;
	z-index: 99;
	background-color: ${({ uiMode, theme }) => (uiMode === UI_MODE_OPTIONS.LIGHT ? theme.primaryBackground : theme.secondaryBackground)} !important;
	border-radius: 8px;
	box-shadow: ${ColorValues.elementShadow};
	opacity: 0;
	visibility: hidden;
	transform: scale(1.05);
	transition: visibility 0s linear 0.3s, opacity 0.3s 0s, transform 0.3s;
	pointer-events: none;
	display: flex;
	flex-direction: column;
	gap: 0px;

	&.show {
		opacity: 1;
		visibility: visible;
		transform: scale(1);
		transition: visibility 0s linear 0s, opacity 0.3s 0s, transform 0.3s;
		pointer-events: all;
	}
`;

export const SearchResult = styled.div`
	display: flex;
	gap: 15px;
	cursor: pointer;
	border-radius: 6px;
	padding: 10px;

	.displayDisabled {
		opacity: ${({ $isValid }) => ($isValid ? 1.0 : 0.5)};
	}
	${({ $isStatic, $isValid }) => !$isStatic && $isValid && css`
		&:hover {
			background-color: ${({ theme }) => theme.secondaryBackground};
		}
	`}
`;

export const ResultImage = styled.div`
	${({ $isStatic }) => ($isStatic ? css`
		width: 46px;
		height: 46px;
	` : css`
		width: 46px;
		height: 46px;
	`)}
	border-radius: 6px;
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${({ theme }) => theme.secondaryBackground};
	background-image: ${({ $image }) => `url('${$image}')`};
	background-size: cover;
	background-repeat: no-repeat;
`;

export const ResultContent = styled.div`
	flex-grow: 1;

	* {
		text-align: left;
	}
	.attractionName {
		color: ${({ theme }) => theme.primaryText};
		line-height: 1.3;
		margin-top: 2px;
	}
	.attractionDetail {
		color: ${({ theme }) => theme.secondaryText};
		margin: 0px 0px 0px;
	}
`;

export const AttractionContainer = styled.div`
	border-radius: 6px;
	border: 1px solid ${({ theme }) => theme.inputBorder};
	padding: 5px;
`;

export const RemoveButtonContainer = styled.div`
	display: flex;
	align-items: center;
`;

export const InvalidContainer = styled.div`
	margin: 8px 0px 0px 0px;
	display: flex;
	gap: 6px;
	align-items: center;

	svg {
		font-size: 0.9rem;
	}
	* {
		color: ${({ theme }) => theme.secondaryText};
	}
`;
