/*
 * File: family.slice.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on November 19, 2022 at 1:21 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: October 2, 2023 at 10:54 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import { createSlice } from '@reduxjs/toolkit';


/**
 * Constants
 */

export const initialState = {
	status: 'idle',
	error: null,
	value: {
		isSet: false,
		family: {
			withPlans: [],
			withoutPlans: [],
			transactional: []
		}
	},
};


/**
 * Slices
 */

export const familySlice = createSlice({
	name: 'family',
	initialState,
	reducers: {
		updateFamily: (state, action) => {

			// Set state
			state.value = {
				...action.payload,
				isSet: true
			};
		},
		clearFamily: (state) => {

			// Clear state
			state.value = {
				...initialState.value,
				isSet: true
			};
		},
	},
	extraReducers: {},
});


/**
 * Exports
 */

export const { updateFamily, clearFamily } = familySlice.actions;

export default familySlice.reducer;
