/*
 * File: RadioGroup.styles.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:53 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: March 30, 2023 at 1:19 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 12px;
`;

export const RadioRow = styled.div`
	display: flex;
	align-items: center;
	gap: 12px;
`;

export const Radio = styled.input`
	cursor: pointer;
	-webkit-appearance: none;
	appearance: none;
	background-color: ${({ theme }) => theme.primaryBackground}; /* For iOS < 15 to remove gradient background */
	margin: 0;
	font: inherit;
	color: ${({ theme }) => theme.layoutBorder};
	width: 20px;
	height: 20px;
	border: 2px solid ${({ theme }) => theme.layoutBorder};
	border-radius: 50%;
	display: grid;
  	place-content: center;

	&:checked {
		border: 2px solid ${({ theme }) => theme.brandPrimaryLightBlue};
	}
	&::before {
		content: "";
		width: 12px;
		height: 12px;
		border-radius: 50%;
		transform: scale(0);
		transition: 120ms transform ease-in-out;
		box-shadow: inset 1em 1em ${({ theme }) => theme.brandPrimaryLightBlue};
		background-color: CanvasText; /* Windows High Contrast Mode */
	}
	&:checked::before {
		transform: scale(1);
	}
	&:focus {
		outline: max(2px, 0.15em) solid ${({ theme }) => theme.layoutBorder};
		outline-offset: max(2px, 0.15em);
	}
`;

export const LabelContainer = styled.div`
	flex-grow: 1;

	.subLabel {
		color: ${({ theme }) => theme.secondaryText};
		margin: -3px 0px 0px 0px;
	}
`;
