/*
 * File: Party.styles.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on December 6, 2022 at 11:53 AM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: April 12, 2023 at 2:27 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { Card } from '../../../../components';

// Styles
import { ColorValuesCreator } from '../../../../styles/colors';

// Constants
import { mediumBreakpoint } from '../../../../styles/constants';

// Create color values
const ColorValues = ColorValuesCreator();


/**
 * Styles
 */

export const Wrapper = styled(Card)`
	padding: 20px 20px;
	display: flex;
	flex-direction: row;
	width: 100%;
	flex-wrap: wrap;
	gap: 20px;
	background-color: ${ColorValues.white};

	.shimmer {
		animation : shimmer 2s infinite;
		background: linear-gradient(to right, ${ColorValues.neutralBackground} 4%, ${ColorValues.neutralHighlight} 25%, ${ColorValues.neutralBackground} 36%);
		background-size: 1000px 100%;
	}
`;

export const FamilyContainer = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	flex-wrap: wrap;
	gap: 20px;

	* {
		color: ${ColorValues.dark};
	}
	& > div {
		width: calc(50% - 10px);

		@media only screen and (min-width: ${mediumBreakpoint}em) {
			width: 96px;
		}
	}
`;
