/*
 * File: TripDetail.styles.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on November 18, 2022 at 3:45 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: June 19, 2023 at 12:59 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';

// Components
import {
	Button, Card, DayCollection as BaseDayCollection, IconButton, NavigationDropdown as Dropdown, TripCard as BaseTripCard
} from '../../../components';
import { Party as BaseParty } from './Party';

// Styles
import { ColorValuesCreator } from '../../../styles/colors';

// Constants
import { mediumBreakpoint } from '../../../styles/constants';

// Create color values
const ColorValues = ColorValuesCreator();


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: block;
`;

export const BackButton = styled(Button)`
	padding: 0px;
	margin: 40px 0px 0px 0px;
	min-width: 200px;
    text-align: left;
    align-items: start;
    justify-content: start;

	svg {
		font-size: 0.9rem;
	}
`;

export const StatusBanner = styled.div`
	margin: 20px 0px 0px 0px;
`;

export const HeaderSection = styled.div`
	display: flex;
	margin: 12px 0px 0px 0px;

	${({ $collapseOnMobile }) => ($collapseOnMobile ? css`
		justify-content: center;
		align-items: flex-start;
		flex-direction: column;
		gap: 15px;

		@media only screen and (min-width: ${mediumBreakpoint}em) {
			flex-direction: row;
			justify-content: start;
			align-items: center;
			gap: 30px;
		}
	` : css`
		flex-direction: row;
		justify-content: start;
		align-items: center;
		gap: 30px;
	`)}
	h2 {
		color: ${ColorValues.white};
		text-align: left;
	}
`;

export const TitleSection = styled.div`
	flex-grow: 1;
	display: flex;
	align-items: center;
	gap: 15px;

	button {
		color: ${ColorValues.white};
		flex-shrink: 0;
	}
`;

export const ActionContainer = styled.div`
	display: flex;
	align-items: center;
`;

export const DropdownContainer = styled.div`
	position: relative;
`;

export const DropdownToggle = styled(IconButton)`
	height: auto;
	color: ${ColorValues.white};
`;

export const ActionButton = styled(Button)`
	margin: 0px 10px 0px 0px;

	span {
		font-size: 0.9rem;
		margin-left: 8px;
	}
	svg {
		font-size: 0.85rem;
	}
`;

export const NavigationDropdown = styled(Dropdown)`
	top: 40px;
	right: -16px;
`;

export const StatusWrapper = styled.div`
	width: 100%;
	background-color: ${({ status }) => (status === 'success' ? ColorValues.statusSuccessBase : ColorValues.brandPrimaryYellow)};
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px 10px;
	gap: 7px;
	border-radius: 6px;

	span {
		color: ${ColorValues.white};
		text-align: left;
	}
	svg {
		color: ${ColorValues.white};
		font-size: 0.75rem;
	}
`;

export const BadgeContainer = styled.div`
	margin: 15px 0px 0px 0px;
`;

export const TripSection = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin: 30px 0px 50px 0px;
	gap: 30px;
	flex-direction: column;

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		flex-direction: row;
	}
`;

export const DayCollection = styled(BaseDayCollection)`
	width: 100%;
`;

export const DetailCard = styled(Card)`
	padding: 10px;
	background-color: ${ColorValues.white};
	color: ${ColorValues.dark};
`;

export const DetailSection = styled.div`
    flex-grow: 1;
`;

export const PartySection = styled.div`
	flex-basis: 100%;

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		flex-basis: 60%;
	}
`;

export const Party = styled(BaseParty)``;

export const TitleContainer = styled.div`
	margin-bottom: 10px;

	h4 {
		color: ${ColorValues.white};
		text-align: left;
		margin: 0px;
	}
`;

export const TripCard = styled(BaseTripCard)`
	width: 100%;
	height: auto;
`;
