/*
 * File: disney.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on October 19, 2023 at 9:56 PM
 * Copyright © 2023 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: October 19, 2023 at 9:59 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';

// Utilities
import { toastError } from './toaster';

// Components
import { Typography } from '../components';

// Constants
import { DISNEY_AUTHENTICATION_ERRORS } from '../../Constants';


/**
 * Utilities
 */

export const displayDisneyAuthError = (msg, uiMode) => {
	let message = msg;
	let options = { duration: 4000 };
	switch (message) {
		case DISNEY_AUTHENTICATION_ERRORS.AUTHORIZATION_ACCOUNT_LOCKED_OUT:
			message = 'Whoops. It looks like there have been too many failed attempts to log into your account. Try again in a few minutes or check your email to update your password.';
			break;
		case DISNEY_AUTHENTICATION_ERRORS.INVALID_AUTHORIZATION_CREDENTIALS:
			message = 'Whoops. It looks like your email or password does not match your Disney account. Please try again.';
			break;
		case DISNEY_AUTHENTICATION_ERRORS.REQUIRES_TERMS_CONDITIONS_ACCEPTANCE:
			message = (
				<Typography center={false} className="linkInherit">
					Whoops. It looks like you need to agree to the Disney Terms and Conditions to continue. First, log in to your
					{' '}
					<a href="https://disneyworld.disney.go.com/login" target="_blank" rel="noreferrer">Disney account</a>
					{' '}
					and then try again.
				</Typography>
			);
			options = { duration: 10000 };
			break;
		default:
			break;
	}
	toastError(uiMode, message, options);
};
