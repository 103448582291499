/*
 * File: ContinueFlowStep.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:24 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: October 2, 2023 at 11:08 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

// Utilities
import { parseFirstLastName } from '../../../../../utilities/utilities';
import { renderProfileImage } from '../../../../utilities/image';
import { getCookie } from '../../../../utilities/cookies';

// Components
import {
	Typography, Button
} from '../../../../components';

// Constants
import { VIEW_MODE_COOKIE } from '../../../../../Constants';

// Styles
import * as S from './ContinueFlowStep.styles';


/**
 * Component
 */

export const ContinueFlowStep = forwardRef(({
	className
}, ref) => {

	// Get current user from hook
	const user = useSelector((state) => state.user.value);

	// Get query parameters from hook
	const [searchParams] = useSearchParams();
	let isMobileApp = searchParams.get('mobileapp') === 'true';
	if (!isMobileApp) {
		const mobileAppCookie = getCookie(VIEW_MODE_COOKIE);
		if (mobileAppCookie === 'mobileapp') isMobileApp = true;
	}

	// Perform actions on component load
	useEffect(() => {}, []);

	// Return component
	return (
		<S.StepContainer ref={ref} className={className}>

			{/* Form */}
			<S.Form>

				{/* Profile Image */}
				{user?.profileImage && <S.ProfileImage image={renderProfileImage(user?.profileImage, '600x600', isMobileApp)} />}

				{/* Content */}
				<Typography tag="h2" weight="bold">{`You're all ready to go, ${parseFirstLastName(user?.name || '')?.firstName}!`}</Typography>
				<Typography tag="p" variation="1" weight="regular">We&apos;re glad to have you aboard! To get started with Pixie Dust, schedule your first Lightning Lane reservation below.</Typography>

				{/* Actions */}
				<S.ButtonLink to="/trip/create">
					<Button size="large" variant="solid" variation="default">Schedule my first reservation</Button>
				</S.ButtonLink>
				<S.ButtonLink to="/trips">
					<Button size="large" variant="text" variation="white">I&apos;ll start scheduling later</Button>
				</S.ButtonLink>
			</S.Form>
		</S.StepContainer>
	);
});


/**
 * Configuration
 */

ContinueFlowStep.displayName = 'ContinueFlowStep';
ContinueFlowStep.propTypes = {
	className: PropTypes.string,
};
ContinueFlowStep.defaultProps = {
	className: null,
};
