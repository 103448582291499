/*
 * File: Emoji.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:32 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: April 26, 2023 at 11:26 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import PropTypes from 'prop-types';
import EmojiMap from 'emojilib';

// Styles
import * as S from './Emoji.styles';


/**
 * Component
 */

export const Emoji = ({ label, symbol, size }) => {

	// Get symbol if necessary
	let fullSymbol = symbol;
	if (!fullSymbol) {
		fullSymbol = Object.keys(EmojiMap).find((key) => EmojiMap[key].indexOf(label) === 0);
	}

	// Return component
	return (
		<S.Emoji
			role="img"
			aria-label={label || ''}
			aria-hidden={label ? 'false' : 'true'}
			size={size}
		>
			{fullSymbol}
		</S.Emoji>
	);
};


/**
 * Configuration
 */

Emoji.displayName = 'Emoji';
Emoji.propTypes = {
	size: PropTypes.number,
	label: PropTypes.string.isRequired,
	symbol: PropTypes.string
};
Emoji.defaultProps = {
	size: 1.75,
	symbol: null
};
