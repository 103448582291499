/*
 * File: DayCard.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: October 2, 2023 at 11:06 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSearchParams } from 'react-router-dom';

// Utilities
import { parseFirstLastName } from '../../../utilities/utilities';
import { parseDateString } from '../../../utilities/dateTime';
import { renderProfileImage } from '../../utilities/image';
import { getCookie } from '../../utilities/cookies';

// Components
import { Typography } from '../Typography';
import { ReservationElement } from '../ReservationElement';
import { ToolTip } from '../ToolTip';

// Constants
import { PARK_ENTITY_OPTIONS, VIEW_MODE_COOKIE } from '../../../Constants';

// Styles
import * as S from './DayCard.styles';


/**
 * Component
 */

export const DayCard = ({
	className, day, trip, dayWidth, isEditing, isCurrent, openCreator, openRemove, onClick
}) => {

	// Get current user from hook
	const user = useSelector((state) => state.user.value);

	// Get query parameters from hook
	const [searchParams] = useSearchParams();
	let isMobileApp = searchParams.get('mobileapp') === 'true';
	if (!isMobileApp) {
		const mobileAppCookie = getCookie(VIEW_MODE_COOKIE);
		if (mobileAppCookie === 'mobileapp') isMobileApp = true;
	}

	// Parse names
	const { firstName, lastName } = parseFirstLastName(user?.name);

	// Parse date
	const formattedDate = parseDateString(day.date, 'YYYY-MM-DD').format('MMMM D, YYYY');

	// Handle party rendering
	const getPartyList = () => {

		// Parse all party members
		let party = [];
		(day.scheduledEvents || []).forEach((ev) => {
			const formattedParty = ev.party.map((member) => {

				// Create profile image
				let profileImage = member.profileImage?.media?.characterSmall?.url || member.profileImage?.url;
				if (member.profileImage?.isInternal || (isMobileApp && member.profileImage?.isDisneyDefault === true && member.profileImage?.internal)) {
					profileImage = member.profileImage?.internal?.['200x200'];
				}

				// Create member
				return {
					...member,
					profileImage
				};
			});
			party.push(...formattedParty);
		});

		// Deduplicate party
		party = party.filter((v, i, a) => a.findIndex((v2) => ((v2.userSWID && v.userSWID && v2.userSWID.toString() === v.userSWID.toString()) || (v2.userXID && v.userXID && v2.userXID.toString() === v.userXID.toString()))) === i);

		// Limit size if necessary
		if (party.length > 7) {
			party.length = 7;
		} else if (party.length === 0) {
			party = [{
				firstName,
				lastName,
				userSWID: user.id,
				userXID: user.id,
				profileImage: renderProfileImage(user.profileImage, '200x200', isMobileApp),
				avatarId: user.id
			}];
		}

		// Return party
		return party;
	};

	// Handle actions on visibility change
	useEffect(() => {
		if (isCurrent && day.scheduledEvents && day.scheduledEvents.length > 0) {

			// Rebuild tooltips
			ReactTooltip.rebuild();
		}

	}, [isCurrent, day]);

	// Handle actions on component load
	useEffect(() => {}, []);

	// Handle render warning status
	const renderWarning = () => {
		if (!day.validParkTicket || !day.validParkReservation || (!day.validDisneyGeniePlus && day.requiresDisneyGeniePlus)
			|| (!user.disneyAccountHasPaymentMethod && trip?.requiresDisneyPaymentMethod)) {

			// Get park entity parameters
			let mobileAppName = 'My Disney Experience';
			if (trip != null && trip?.parkEntity != null) {
				const parkEntity = PARK_ENTITY_OPTIONS.find((entity) => entity.id === trip?.parkEntity.id);
				mobileAppName = parkEntity.mobileApp;
			}

			// Create tooltip
			let tooltip = '';
			let isWarning = false;
			if (!day.validParkTicket) {
				tooltip = 'We aren\'t able to find a valid theme park ticket for this day';
				isWarning = true;
			} else if (!day.validParkReservation) {
				tooltip = 'We aren\'t able to find a valid theme park reservation for this day';
				isWarning = true;
			} else if (!user.disneyAccountHasPaymentMethod && trip?.requiresDisneyPaymentMethod) {
				tooltip = `We aren't able to find a payment method attached to your ${mobileAppName} account`;
				isWarning = true;
			} else if (!day.validDisneyGeniePlus && day.requiresDisneyGeniePlus) {
				tooltip = 'It looks like Genie+ is required for this day. Don\'t worry, we\'ll reserve it for you.';
				isWarning = false;
			}

			// Render status
			return (
				<S.StatusContainer $isWarning={isWarning}>
					<FontAwesomeIcon icon={['fa', 'triangle-exclamation']} data-tip={tooltip} data-for={day.date} />
				</S.StatusContainer>
			);
		}
		return null;
	};

	// Render component
	return (
		<S.Wrapper className={className} $dayWidth={dayWidth} onClick={onClick}>

			{/* Tooltip */}
			{isCurrent && <ToolTip size="medium" id={day.date} />}

			{/* Scroll Container */}
			<S.ScrollContainer>

				{/* Status */}
				{day.scheduledEvents && day.scheduledEvents.length > 0 && renderWarning()}

				{/* Card Header */}
				<S.CardHeader>

					{/* Title */}
					<S.TitleContainer>
						<Typography weight="medium" variation="2">{day.dayOfWeek}</Typography>
						<Typography weight="semibold">{formattedDate}</Typography>
					</S.TitleContainer>

					{/* Party */}
					<S.PartyContainer>
						{getPartyList().map((member) => <S.ProfileImage key={member.userSWID || member.userXID} image={member.profileImage} />)}
					</S.PartyContainer>
				</S.CardHeader>

				{/* Reservation Container */}
				<S.CardContainer>
					{day.scheduledEvents && day.scheduledEvents.length > 0
					&& day.scheduledEvents.map((event, index) => (
						<ReservationElement
							isPlaceholder={false}
							isEditing={isEditing}
							event={event}
							day={day}
							openCreator={openCreator}
							openRemove={openRemove}
							cardIndex={index}
							key={event.id}
						/>
					))}
					{(!day.scheduledEvents || day.scheduledEvents.length === 0 || isEditing) && (
						<ReservationElement
							isPlaceholder
							isEditing={isEditing}
							day={day}
							openCreator={openCreator}
							cardIndex={(day.scheduledEvents || []).length}
						/>
					)}
				</S.CardContainer>
			</S.ScrollContainer>
		</S.Wrapper>
	);
};


/**
 * Configuration
 */

DayCard.displayName = 'DayCard';
DayCard.propTypes = {
	className: PropTypes.string,
	day: PropTypes.shape(),
	trip: PropTypes.shape(),
	openCreator: PropTypes.func,
	openRemove: PropTypes.func,
	dayWidth: PropTypes.number,
	isEditing: PropTypes.bool,
	isCurrent: PropTypes.bool,
	onClick: PropTypes.func
};
DayCard.defaultProps = {
	className: null,
	day: {},
	trip: {},
	openCreator: null,
	openRemove: null,
	dayWidth: 100,
	isEditing: false,
	isCurrent: false,
	onClick: null
};
