/*
 * File: ConfirmationStep.styles.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:25 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: March 7, 2023 at 6:32 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Styles
import { ColorValuesCreator } from '../../../../styles/colors';

// Components
import { Button } from '../../../../components/Button';

// Create color values
const ColorValues = ColorValuesCreator();


/**
 * Styles
 */

export const StepContainer = styled.div``;

export const Form = styled.form`
	width: 100%;
	max-width: 420px;
	margin: 0px auto;

	@media only screen {
		padding-top: 60px;
		padding-bottom: 50px;
	}
	@media only screen and (min-width: 40em) {
		padding-top: 100px;
		padding-bottom: 50px;
	}
	h2 {
		text-align: left;
		color: ${ColorValues.white};
		margin: 10px 0px 10px;
	}
	p {
		text-align: left;
		color: ${ColorValues.white};
   		margin-bottom: 20px;
	}
	button {
		width: 100%;
	}
`;

export const LogoMark = styled.div`
	width: 46px;
	height: 46px;
	background-image: url('${process.env.CDN_URL}/public/assets/logos/pixie-dust-logo-icon.png');
	background-size: cover;
	background-repeat: no-repeat;
`;

export const ActionButton = styled(Button)`
	margin: 20px auto 0px;
`;

export const SetupRow = styled.div`
	display: flex;
	align-items: center;
	margin-top: 20px;
	padding: 5px 20px 10px 0px;
	pointer-events: none;

	&.first {
		margin-top: 30px;
	}
	&.animate {
		transition: all 1s;
		-webkit-transition: all 1s;
		-moz-transition: all 1s;
		-o-transition: all 1s;
	}
`;

export const ProgressHolder = styled.div`
	width: 45px;
	height: 45px;
	flex-shrink: 0;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	&.complete {
		border: 2px solid ${ColorValues.brandPrimaryYellow};
		background-color: ${ColorValues.brandPrimaryYellow};
		box-shadow: ${ColorValues.elementShadow};
	}
`;

export const ContentHolder = styled.div`
	p {
		flex: 1;
		color: ${ColorValues.white};
		margin: 0px 0px 0px 18px;
		position: relative;
		pointer-events: none;
	}
`;

export const CompleteIcon = styled(FontAwesomeIcon)`
	color: ${ColorValues.white};
	width: 24px;
	height: 24px;
	opacity: 0;
	visibility: hidden;
	transform: scale(1.35);
	transition: visibility 0s linear 0.3s, opacity 0.3s 0s, transform 0.3s;
	position: absolute;

	&.show {
		opacity: 1;
		visibility: visible;
		transform: scale(1);
		transition: visibility 0s linear 0s, opacity 0.3s 0s, transform 0.3s;
	}
`;

export const AnimationContainer = styled.div`
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
`;

export const PendingState = styled.div`
	width: 12px;
	height: 12px;
	border-radius: 50%;
	position: absolute;
	background-color: ${ColorValues.white};
`;
