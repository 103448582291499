/*
 * File: global.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on June 16, 2022 at 1:32 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: September 28, 2023 at 11:40 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import { createGlobalStyle } from 'styled-components';

// Constants
import { mobileBreakpoint } from './constants';


/**
 * Styles
 */

export const GlobalStyles = createGlobalStyle`

	/**
	 * Element Styles
	 */

	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}
	html,
	body,
	#root {
		min-height: 100%;
		height: 100%;
		background-color: ${({ theme }) => theme.primaryBackground};

		& > div:first-child {
			height: 100%;
			width: 100%;
		}
	}
	body, button, input, textarea {
		font-family: 'Montserrat', Verdana, Arial, Helvetica, sans-serif;
		color: ${({ theme }) => theme.primaryText};
	}
	.linkInherit {
		a,
		a:link,
		a:visited {
			color: inherit;
		}
	}

	/*
	 * Animation Styles
	 */

	.animate {
		transition: all 0.3s;
		-webkit-transition: all 0.3s;
		-moz-transition: all 0.3s;
		-o-transition: all 0.3s;
	}
	.preload * {
		transition: none !important;
		-webkit-transition: none !important;
		-moz-transition: none !important;
		-ms-transition: none !important;
		-o-transition: none !important;
	}
	.shimmer {
		animation : shimmer 2s infinite;
   		background: linear-gradient(to right, ${({ theme }) => theme.placeholderPrimary} 4%, ${({ theme }) => theme.placeholderHighlight} 25%, ${({ theme }) => theme.placeholderPrimary} 36%);
  		background-size: 1000px 100%;
	}
	.shimmer-element {
		border-radius: 4px;
		color: transparent !important;
		pointer-events: none;
	}
	@keyframes shimmer {
		0% {
			background-position: -1000px 0;
		}
		100% {
			background-position: 1000px 0;
		}
	}

	/**
	 * Element Styles
	 */

	svg.heart * {
		color: ${({ theme }) => theme.brandPrimaryYellow};
	}
	.gradientBackground {
		background: ${({ theme }) => theme.brandPrimaryDarkBlue};
		background: ${({ theme }) => `-moz-linear-gradient(180deg, ${theme.brandPrimaryDarkBlue} 0%, ${theme.brandPrimaryLightBlue} 100%)`};
		background: ${({ theme }) => `-webkit-linear-gradient(180deg, ${theme.brandPrimaryDarkBlue} 0%, ${theme.brandPrimaryLightBlue} 100%)`};
		background: ${({ theme }) => `linear-gradient(180deg, ${theme.brandPrimaryDarkBlue} 0%, ${theme.brandPrimaryLightBlue} 100%)`};
		filter: ${({ theme }) => `progid:DXImageTransform.Microsoft.gradient(startColorstr="${theme.brandPrimaryDarkBlue}",endColorstr="${theme.brandPrimaryLightBlue}",GradientType=1)`};
		position: relative;
	}
	.floatShadow {
		box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.12);
	}
	.monospace {
		font-family: monospace;
	}

	/**
	 * reCAPTCHA Styles
	 */

	.grecaptcha-badge {
		z-index: 99;
	}
	.hide-captcha .grecaptcha-badge {
		visibility: hidden;
	}

	/**
	 * Placeholder Styles
	 */

	input::-webkit-input-placeholder {
		/* WebKit, Blink, Edge */
		color: ${({ theme }) => theme.inputPlaceholder} !important;
	}
	input:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		color: ${({ theme }) => theme.inputPlaceholder} !important;
		opacity: 1;
	}
	input::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		color: ${({ theme }) => theme.inputPlaceholder} !important;
		opacity: 1;
	}
	input:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: ${({ theme }) => theme.inputPlaceholder} !important;
	}
	textarea::-webkit-input-placeholder {
		/* WebKit, Blink, Edge */
		color: ${({ theme }) => theme.inputPlaceholder} !important;
	}
	textarea:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		color: ${({ theme }) => theme.inputPlaceholder} !important;
		opacity: 1;
	}
	textarea::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		color: ${({ theme }) => theme.inputPlaceholder} !important;
		opacity: 1;
	}
	textarea:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: ${({ theme }) => theme.inputPlaceholder} !important;
	}

	/**
	 * Breakpoints
	 */

	.isMobile {
		display: none !important;

		@media only screen and (max-width: ${mobileBreakpoint}em) {
			display: inherit !important;
		}
	}
	.isNotMobile {
		display: inherit !important;

		@media only screen and (max-width: ${mobileBreakpoint}em) {
			display: none !important;
		}
	}

	/**
	 * Paragraph Styles
	 */

	.paragraphContent {
		h1 {
			margin: 0 0 25px;
		}
		h2 {
			margin: 0px 0px 12px;
		}
		h3 {
			margin: 0px 0px 4px;
		}
		a,
		a:link,
		a:visited {
			text-decoration: underline;
			color: inherit !important;
		}
		p {
			line-height: 180%;

			&:not(:last-child) {
				margin-bottom: 20px;
			}
		}
		ul,
		ol {
			margin: 8px 0px 0px 16px;
			margin-bottom: 30px;
			line-height: 180%;
		}
		li {
			margin-bottom: 6px;
		}
		* {
			text-align: left;
			max-width: 100%;
		}
		img {
			margin: 15px 0px 25px;
			border-radius: 8px;
		}
		p:empty:before {
			content: ' ';
			white-space: pre;
		}
		p:empty {
			margin: 0px;
		}
	}
`;
