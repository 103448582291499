/*
 * File: AccountStep.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:24 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: March 7, 2023 at 11:10 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import validator from 'validator';

// Utilities
import { toastError } from '../../../../utilities/toaster';

// Services
import { createUserAccount, deleteCurrentUser } from '../../../../services/user';

// Slices
import { updateUser, clearUser } from '../../../../store/slices/user/user.slice';

// Components
import {
	Typography, TextInput, Button, PasswordInput, Checkbox, LocaleLink, Meta, InputCollection
} from '../../../../components';

// Styles
import * as S from './AccountStep.styles';


/**
 * Component
 */

export const AccountStep = forwardRef(({
	updateStep, updateHeight, className
}, ref) => {

	// Get current user from hook
	const user = useSelector((state) => state.user.value);

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get current locale from hook
	const locale = useSelector((state) => state.locale.value);

	// Create state handlers
	const [nameError, setNameError] = useState(null);
	const [phoneError, setPhoneError] = useState(null);
	const [passwordError, setPasswordError] = useState(null);
	const [passwordRulesError, setPasswordRulesError] = useState(null);
	const [confirmPasswordError, setConfirmPasswordError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [inputValues, setInputValues] = useState({
		...user?.name ? { name: user.name } : undefined,
		...user?.phone ? { phone: user.phone } : undefined,
		marketingAllowed: locale?.geo?.eu !== '1',
		termsAgreed: false
	});

	// Get actions from hooks
	const dispatch = useDispatch();
	const { executeRecaptcha } = useGoogleReCaptcha();

	// Handle create account function
	const handleCreateAccount = async () => {

		// Get parameters
		const {
			name, phone, password, confirmPassword, marketingAllowed, termsAgreed
		} = inputValues;

		// Validate parameters
		if (!name || validator.isEmpty(name, { ignore_whitespace: true })) {
			setNameError({ message: 'Please enter your name' });
			return;
		}
		if (!phone || !validator.isMobilePhone(phone, 'any', { strictMode: true }) || phoneError) {
			setPhoneError({ message: 'Please enter a phone number for your Pixie Dust account' });
			return;
		}
		if (!password || validator.isEmpty(password, { ignore_whitespace: true }) || passwordError || passwordRulesError) {
			setPasswordError({ message: passwordError?.message ? passwordError?.message : 'Please create a password for your Pixie Dust account' });
			return;
		}
		if (!confirmPassword || validator.isEmpty(confirmPassword, { ignore_whitespace: true }) || password !== confirmPassword) {
			setConfirmPasswordError({ message: 'Your passwords must match' });
			return;
		}
		if (!termsAgreed) {
			toastError(uiMode, 'You must agree to our Terms and Guidelines before continuing.');
			return;
		}

		// Set loading state
		setIsLoading(true);

		// Get reCAPTCHA token
		let recaptchaToken = null;
		try { recaptchaToken = await executeRecaptcha('user_create_account'); } catch (e) {}

		// Create user account
		createUserAccount({
			name, phone, pass: password, recaptchaToken, marketingAllowed
		}).then(({ data }) => {

			// Set loading state
			setIsLoading(false);

			// Dispatch new user
			dispatch(updateUser(data.user));

			// Move to next step
			updateStep(3);

		}).catch(({ response }) => {

			// Set loading state
			setIsLoading(false);
			setNameError(null);
			setPhoneError(null);
			setPasswordError(null);
			setConfirmPasswordError(null);

			// Show error message
			if (response?.data?.message) {
				toastError(uiMode, response?.data?.message);
			} else {
				toastError(uiMode, 'Whoops. We\'re having trouble creating your account. Please try again.');
			}
		});
	};

	// Handle go back action
	const goBack = async () => {

		// Delete current user
		await deleteCurrentUser();

		// Dispatch new user
		dispatch(clearUser());

		// Move to previous step
		updateStep(1);
	};

	// Handle on input change action
	const handleOnChange = (event) => {
		const {
			name, value, checked, type
		} = event.target;
		if (type === 'checkbox') {
			setInputValues({ ...inputValues, [name]: checked });
		} else {
			setInputValues({ ...inputValues, [name]: value });
		}
	};

	// Handle actions on user changed
	useEffect(() => {

		// Update email content
		setInputValues({ ...inputValues, email: user?.email });

	}, [user]);

	// Handle actions on locale changed
	useEffect(() => {

		// Update default marketing selection
		if (locale?.geo != null) {
			setInputValues({ ...inputValues, marketingAllowed: locale?.geo.eu !== '1' });
		}
	}, [locale]);

	// Handle actions on value changed
	useEffect(() => {

		// Notify parent of changed height
		updateHeight(true);

	}, [inputValues]);

	// Return component
	return (
		<S.StepContainer ref={ref} className={className}>

			{/* Meta */}
			<Meta
				meta={{
					title: 'Create Account • Pixie Dust',
					description: 'Customize your account to make scheduling your Disney plans even easier.',
					showSocial: false
				}}
			/>

			{/* Form */}
			<S.Form>

				{/* Logo */}
				<S.LogoMark className="isNotMobile" />

				{/* Content */}
				<Typography tag="h2" weight="bold">Welcome to Pixie Dust!</Typography>
				<Typography tag="p" variation="1" weight="regular">Customize your account to make scheduling your Disney plans even easier.</Typography>

				{/* Inputs */}
				<InputCollection className="floatShadow" isDark>
					<TextInput
						label="Your name"
						name="name"
						type="text"
						error={nameError}
						value={inputValues.name || ''}
						autoComplete="name"
						containerClassName="modalInput"
						onFocus={() => { setNameError(null); }}
						onKeyUp={() => { setNameError(null); }}
						onBlur={() => { setNameError(null); }}
						onChange={handleOnChange}
						smartField
						isDark
					/>
					<S.PhoneNumberInput
						label="Phone number"
						name="phone"
						error={phoneError}
						containerClassName="modalInput"
						autoComplete="phoneNumber"
						value={inputValues.phone || ''}
						setError={setPhoneError}
						onChange={handleOnChange}
						smartField
						isDark
					/>
				</InputCollection>
				<InputCollection className="floatShadow spacer" isDark>
					<PasswordInput
						label="Create a password"
						onChange={handleOnChange}
						setError={setPasswordError}
						setRulesError={setPasswordRulesError}
						autoComplete="new-password"
						rulesError={passwordRulesError}
						value={inputValues.password || ''}
						disallowedContent={[
							inputValues.username,
							inputValues.email
						].filter(Boolean)}
						error={passwordError}
						smartField
						isDark
					/>
					<TextInput
						label="Confirm your password"
						type="password"
						name="confirmPassword"
						error={confirmPasswordError}
						value={inputValues.confirmPassword || ''}
						containerClassName="modalInput"
						autoComplete="new-password"
						onFocus={() => { setConfirmPasswordError(null); }}
						onKeyUp={() => { setConfirmPasswordError(null); }}
						onBlur={() => { setConfirmPasswordError(null); }}
						onChange={handleOnChange}
						smartField
						isDark
					/>
				</InputCollection>

				{/* Checkboxes */}
				<S.CheckboxContainer>
					<S.CheckboxRow>
						<Checkbox
							name="marketingAllowed"
							value={inputValues.marketingAllowed || false}
							onChange={handleOnChange}
							isDark
						/>
						<Typography variation="2" weight="regular">
							Yes! I would like to receive updates, special offers, and other information from Pixie Dust
						</Typography>
					</S.CheckboxRow>
					<S.CheckboxRow>
						<Checkbox
							name="termsAgreed"
							value={inputValues.termsAgreed || false}
							onChange={handleOnChange}
							isDark
						/>
						<Typography variation="2" weight="regular">
							I have read and agree to the Pixie Dust
							{' '}
							<LocaleLink to="/legal/terms" target="_blank">Terms</LocaleLink>
							{' and '}
							<LocaleLink to="/legal/privacy" target="_blank">Privacy Policy</LocaleLink>
						</Typography>
					</S.CheckboxRow>
				</S.CheckboxContainer>

				{/* Continue Action */}
				<S.ActionButton disabled={isLoading} isLoading={isLoading} onClick={() => { handleCreateAccount(); }} size="large" className="floatShadow">Continue</S.ActionButton>

				{/* Back Action */}
				<S.BottomActionContainer>
					<Button variant="text" size="small" onClick={() => { goBack(); }} disabled={isLoading}>
						<Typography tag="p" variation="2" weight="medium">
							Go back
						</Typography>
					</Button>
				</S.BottomActionContainer>
			</S.Form>
		</S.StepContainer>
	);
});


/**
 * Configuration
 */

AccountStep.displayName = 'AccountStep';
AccountStep.propTypes = {
	updateStep: PropTypes.func.isRequired,
	updateHeight: PropTypes.func,
	className: PropTypes.string,
};
AccountStep.defaultProps = {
	className: null,
	updateHeight: null,
};
