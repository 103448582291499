/*
 * File: InputCollection.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 9, 2022 at 5:45 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: November 28, 2022 at 11:33 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import * as S from './InputCollection.styles';


/**
 * Component
 */

export const InputCollection = ({
	isDark, className, children
}) => {

	// Create array of children
	const childrenArray = Array.isArray(children) ? children : [children];

	// Render component
	return (
		<S.InputCollection className={className} isDark={isDark}>
			{childrenArray.map((child, index) => {
				const key = `${index}`;
				if (index > 0 && index < childrenArray.length) {
					return (
						<S.FieldContainer key={key}>
							<S.FieldDivider isDark={isDark} key={`divider-${key}`} />
							{child}
						</S.FieldContainer>
					);
				}
				return <S.FieldContainer key={key}>{child}</S.FieldContainer>;
			})}
		</S.InputCollection>
	);
};


/**
 * Configuration
 */

InputCollection.displayName = 'InputCollection';
InputCollection.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	className: PropTypes.string,
	isDark: PropTypes.bool
};
InputCollection.defaultProps = {
	children: [],
	className: null,
	isDark: false
};
