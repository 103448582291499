/*
 * File: Select.styles.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on June 20, 2022 at 1:18 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: January 20, 2023 at 10:12 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';

// Colors
import { ColorValuesCreator } from '../../styles/colors';

// Create color values
const ColorValues = ColorValuesCreator();


/**
 * Styles
 */

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: 999;

	& > label {
		cursor: pointer;
		text-align: left;
	 	color: ${({ theme, error }) => (error ? theme.statusDangerBase : theme.secondaryText)};
	}
`;

export const IconWrapper = styled.div`
	position: absolute;
	right: 2rem;
	display: flex;
	height: 100%;
	justify-content: center;
	align-items: center;
	padding: 0 0.75rem;

	svg {
		color: ${({ theme }) => theme.inputPlaceholder};

		&:hover {
			opacity: 0.8;
		}
	}
`;

export const FloatingWrapper = styled.div`
	position: absolute;
	bottom: -20px;
	color: ${({ theme }) => theme.statusDangerBase};
	max-width: 100%;

	p {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
`;

export const SmartFieldContainer = styled.div`
	position: relative;
`;

export const SmartFieldset = styled.fieldset`
	padding: 0;
	border: 0;
	position: relative;
	margin: 0px auto;
`;

export const SmartLabel = styled.label`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	position: absolute;
	font-weight: 500;
	top: 20px;
	color: ${({ isDark, theme }) => (isDark ? ColorValues.neutralForm : theme.inputPlaceholder)};
	transform: translate3d(0, 0, 0);
	-webkit-transform: translate3d(0, 0, 0);
	transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	cursor: text;
	left: 0;
	right: 0;
	margin: auto;
	text-align: left;
	padding-left: 18px;
	pointer-events: none;

	${({ $isActive }) => $isActive && css`
		font-weight: 600 !important;
		font-size: 11.5px;
		left: 0;
		right: 0;
		margin: auto;
		transform: translate3d(0, -11px, 0);
		-webkit-transform: translate3d(0, -11px, 0);
	`};
`;

export const SmartFloatingWrapper = styled.div`
 	position: absolute;
 	bottom: -20px;
	left: 18px;
    right: 18px;
 	color: ${({ theme }) => theme.statusDangerBase} !important;
	background-color: ${({ isDark, theme }) => (isDark ? ColorValues.white : theme.primaryBackground)};
	box-shadow: ${ColorValues.elementDarkShadow};
	border-radius: 6px;
 	max-width: 100%;
	padding: 6px 10px;
	z-index: 99;
	pointer-events: none;
	opacity: 0;
	visibility: hidden;
	transform: scale(1.05);

 	p {
		text-align: left;
	 	white-space: nowrap;
	 	text-overflow: ellipsis;
	 	overflow: hidden;
		margin: 0px !important;
		color: ${({ theme }) => theme.statusDangerBase} !important;
 	}
	&.show {
		opacity: 1;
		visibility: visible;
		transform: scale(1);
	}
`;
