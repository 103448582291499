/*
 * File: FriendsFamilyMember.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on December 6, 2022 at 11:55 AM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: October 2, 2023 at 11:06 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';

// Utilities
import { getCookie } from '../../utilities/cookies';

// Components
import { Typography } from '../Typography';

// Constants
import { VIEW_MODE_COOKIE } from '../../../Constants';

// Styles
import * as S from './FriendsFamilyMember.styles';


/**
 * Component
 */

export const FriendsFamilyMember = ({
	className, firstName, lastName, profileImage, isLoading, isDisabled, isSelectable, isSelected, onChange
}) => {

	// Get query parameters from hook
	const [searchParams] = useSearchParams();
	let isMobileApp = searchParams.get('mobileapp') === 'true';
	if (!isMobileApp) {
		const mobileAppCookie = getCookie(VIEW_MODE_COOKIE);
		if (mobileAppCookie === 'mobileapp') isMobileApp = true;
	}

	// Render member name
	const memberName = () => {
		let name = firstName;
		if (lastName) {
			name += ` ${lastName.charAt(0)}.`;
		}
		return name;
	};

	// Render member profile image
	const renderProfileImage = (profileImageObj) => {
		let image = profileImageObj?.media?.characterSmall?.url || profileImageObj?.url;
		if (profileImageObj?.isInternal || (isMobileApp && profileImageObj?.isDisneyDefault === true && profileImageObj?.internal)) {
			image = profileImageObj?.internal?.['200x200'];
		}
		return image;
	};

	// Render component
	return (
		<S.Wrapper
			className={className}
			onClick={isSelectable ? onChange : undefined}
			$isSelected={isSelectable && isSelected}
			$isSelectable={isSelectable}
			$isDisabled={isDisabled}
		>
			<S.ProfileImage image={renderProfileImage(profileImage)} className={isLoading && 'shimmer'} />
			<S.NameContainer>
				<Typography tag="p" variation="2" weight="semibold" className={isLoading && 'shimmer shimmer-element'}>{memberName()}</Typography>
			</S.NameContainer>
		</S.Wrapper>
	);
};


/**
 * Configuration
 */

FriendsFamilyMember.displayName = 'FriendsFamilyMember';
FriendsFamilyMember.propTypes = {
	className: PropTypes.string,
	firstName: PropTypes.string,
	lastName: PropTypes.string,
	profileImage: PropTypes.shape(),
	isLoading: PropTypes.bool,
	isDisabled: PropTypes.bool,
	isSelectable: PropTypes.bool,
	isSelected: PropTypes.bool,
	onChange: PropTypes.func
};
FriendsFamilyMember.defaultProps = {
	className: null,
	firstName: '',
	lastName: '',
	profileImage: null,
	isLoading: false,
	isDisabled: false,
	isSelectable: false,
	isSelected: false,
	onChange: null
};
