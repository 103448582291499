/*
 * File: Sidebar.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:51 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: September 2, 2023 at 10:05 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';

// Components
import { Padding } from '../../Padding';
import { Typography } from '../../Typography';

// Styles
import * as S from './Sidebar.styles';


/**
 * Component
 */

export const Sidebar = ({
	toggleOverlay, className
}) => {

	// Get current user from hook
	const user = useSelector((state) => state.user.value);

	// Render component
	return (
		<S.Sidebar className={className}>
			<Padding>

				{/* Mobile Navigation */}
				<S.MobileNavigation>
					<S.NavigationToggle icon={['fa', 'times']} size={1.35} onClick={() => { toggleOverlay(); }} />
				</S.MobileNavigation>

				{/* Primary Navigation */}
				<S.SidebarSection showBorder>

					{/* Logo */}
					<S.LogoMark />

					{/* Navigation Links */}
					<S.PrimaryLink
						to="/"
						onClick={() => {
							toggleOverlay();
						}}
					>
						<Typography tag="h5" weight="semibold">Home</Typography>
					</S.PrimaryLink>
					<S.PrimaryLink
						to="/#features"
						onClick={() => {
							toggleOverlay();
						}}
					>
						<Typography tag="h5" weight="semibold">How it Works</Typography>
					</S.PrimaryLink>
					<S.PrimaryLink
						to="/about"
						onClick={() => {
							toggleOverlay();
						}}
					>
						<Typography tag="h5" weight="semibold">About Us</Typography>
					</S.PrimaryLink>
					<S.PrimaryLink
						to="/faq"
						onClick={() => {
							toggleOverlay();
						}}
					>
						<Typography tag="h5" weight="semibold">FAQ</Typography>
					</S.PrimaryLink>
					<S.PrimaryLink
						to="/blog"
						onClick={() => {
							toggleOverlay();
						}}
					>
						<Typography tag="h5" weight="semibold">Blog</Typography>
					</S.PrimaryLink>
					<S.PrimaryLink
						to="/contact"
						onClick={() => {
							toggleOverlay();
						}}
					>
						<Typography tag="h5" weight="semibold">Contact Us</Typography>
					</S.PrimaryLink>
					{(user && !user.isAnonymous) ? (
						<S.PrimaryLink
							to="/trips"
							onClick={() => {
								toggleOverlay();
							}}
						>
							<Typography tag="h5" weight="semibold">My Trips</Typography>
						</S.PrimaryLink>
					) : (
						<S.PrimaryLink
							to="/join"
							onClick={() => {
								toggleOverlay();
							}}
						>
							<Typography tag="h5" weight="semibold">Get Started</Typography>
						</S.PrimaryLink>
					)}
				</S.SidebarSection>

				{/* Navigation */}
				<S.SidebarSection showBorder={false}>
					<S.LinkSection>
						<S.Link to="/contact#help">Help</S.Link>
						<S.Link to="/legal/terms">Terms</S.Link>
						<S.Link to="/legal/privacy">Privacy</S.Link>
						<S.Link to="/legal/cookie-policy">Cookie Policy</S.Link>
					</S.LinkSection>
					<S.LinkSection>
						<Typography tag="p" className="fullWidth">
							Made with
							{' '}
							<FontAwesomeIcon icon={['fa', 'heart']} className="heart" />
							{' '}
							in Raleigh, NC
						</Typography>
						<Typography tag="p" className="fullWidth">
							{`© ${new Date().getFullYear()} Pixie Dust`}
						</Typography>
					</S.LinkSection>
				</S.SidebarSection>
			</Padding>
		</S.Sidebar>
	);
};


/**
 * Configuration
 */

Sidebar.displayName = 'Sidebar';
Sidebar.propTypes = {
	className: PropTypes.string,
	toggleOverlay: PropTypes.func,
};
Sidebar.defaultProps = {
	className: null,
	toggleOverlay: null,
};
