/*
 * File: TextArea.styles.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on April 15, 2022 at 1:24 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: January 20, 2023 at 10:07 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';

// Colors
import { ColorValuesCreator } from '../../styles/colors';

// Create color values
const ColorValues = ColorValuesCreator();


/**
 * Styles
 */

export const Wrapper = styled.div`
	display: flex;
 	flex-direction: column;
 	position: relative;

 	& > label {
	 	cursor: pointer;
		text-align: left;
	 	color: ${({ theme, error }) => (error ? theme.statusDangerBase : theme.secondaryText)};
 	}
`;

const SIZES = {
	fit: css`
		width: 100%;
		font-size: 0.9rem;
	`,
	small: css`
		font-size: 0.75rem;
		min-height: 2.25rem;
 `,
	medium: css`
		font-size: 1rem;
		min-height: 2.8125rem;
 `,
};

export const TextArea = styled.textarea`
 	appearance: none;
 	padding: 0.75rem 0.75rem;
	border-radius: 6px;
	border: 1px solid ${({ theme }) => theme.inputBorder};
	color: ${({ theme }) => theme.primaryText};
	line-height: normal;
	width: 100%;
	outline: none;
	background-color: ${({ theme }) => theme.inputBackground};

 	${({ size }) => SIZES[size]}

	&:focus {
		border-color: ${({ theme }) => theme.inputFocusBorder};
	}

	&:read-only {
		background-color: ${({ theme }) => theme.inputSecondayBackground};
		border-color: ${({ theme }) => theme.inputBorder};
	}

 	${({ error }) => error && css`
		color: ${({ theme }) => theme.statusDangerBase};
		border-color: ${({ theme }) => theme.statusDangerBase};

		:focus {
			border-color: ${({ theme }) => theme.statusDangerBase};
		}
	`}
`;

export const FloatingWrapper = styled.div`
 	position: absolute;
 	bottom: -20px;
 	color: ${({ theme }) => theme.statusDangerBase};
 	max-width: 100%;

 	p {
		text-align: left;
	 	white-space: nowrap;
	 	text-overflow: ellipsis;
	 	overflow: hidden;
 	}
`;


export const SmartFieldContainer = styled.div`
	position: relative;
`;

export const SmartFieldset = styled.fieldset`
	padding: 0;
	border: 0;
	position: relative;
	margin: 0px auto;
`;

export const SmartTextArea = styled.textarea`
	appearance: none;
	border-radius: 6px;
	border: none;
	color: ${({ isDark, theme }) => (isDark ? ColorValues.dark : theme.primaryText)};
	line-height: normal;
	width: 100%;
	outline: none;
	box-shadow: none;
	text-align: left;
	margin: 0px;
	background-color: ${({ isDark, theme }) => (isDark ? ColorValues.white : theme.primaryBackground)};
	display: block;
	padding: 25px 18px 20px;
	font-weight: 500;

	${({ size }) => SIZES[size]}
	height: ${({ rows }) => `${rows * 30}px`};

	&:focus,
	&:active {
		background-color: ${({ isDark, theme }) => (isDark ? ColorValues.white : theme.primaryBackground)};
		outline: 0;
		border: none;
		box-shadow: none;
	}
	&[data-empty='false'],
	&:valid,
	&:focus,
	&:disabled,
	&:-webkit-autofill {
		outline: 0;
		padding: 25px 18px 10px;
		border: none;
		box-shadow: none;
	}
	&[data-empty='false'] + label,
	&:valid + label,
	&:focus + label,
	&:disabled + label,
	&:-webkit-autofill + label {
		font-weight: 600 !important;
		font-size: 11.5px;
		left: 0;
		right: 0;
		margin: auto;
		transform: translate3d(0, -11px, 0);
		-webkit-transform: translate3d(0, -11px, 0);
	}
	&:read-only {
		background-color: ${({ theme }) => theme.inputSecondayBackground};
		border-color: ${({ theme }) => theme.inputBorder};
	}
`;

export const SmartLabel = styled.label`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	position: absolute;
	font-weight: 500;
	top: 20px;
	color: ${({ isDark, theme }) => (isDark ? ColorValues.neutralForm : theme.inputPlaceholder)};
	transform: translate3d(0, 0, 0);
	-webkit-transform: translate3d(0, 0, 0);
	transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	cursor: text;
	left: 0;
	right: 0;
	margin: auto;
	text-align: left;
	padding-left: 18px;
	pointer-events: none;
`;

export const SmartFloatingWrapper = styled.div`
 	position: absolute;
 	bottom: -20px;
	left: 18px;
    right: 18px;
 	color: ${({ theme }) => theme.statusDangerBase} !important;
	background-color: ${({ isDark, theme }) => (isDark ? ColorValues.white : theme.primaryBackground)};
	box-shadow: ${ColorValues.elementDarkShadow};
	border-radius: 6px;
 	max-width: 100%;
	padding: 6px 10px;
	z-index: 99;
	pointer-events: none;
	opacity: 0;
	visibility: hidden;
	transform: scale(1.05);

 	p {
		text-align: left;
	 	white-space: nowrap;
	 	text-overflow: ellipsis;
	 	overflow: hidden;
		margin: 0px !important;
		color: ${({ theme }) => theme.statusDangerBase} !important;
 	}
	&.show {
		opacity: 1;
		visibility: visible;
		transform: scale(1);
	}
`;
