/*
 * File: PaymentMethodModal.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: April 29, 2023 at 9:18 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import {
	CardElement,
	useElements,
	useStripe
} from '@stripe/react-stripe-js';

// Utilities
import { toastError, toastSuccess } from '../../../../utilities/toaster';

// Services
import { addPaymentMethod, updatePaymentMethod } from '../../../../services/payment';

// Constants
import { UI_MODE_OPTIONS, COUNTRY_OPTIONS } from '../../../../../Constants';

// Components
import {
	Button, Modal, Typography, TextInput, Select
} from '../../../../components';

// Styles
import * as S from './PaymentMethodModal.styles';
import { DarkTheme, LightTheme } from '../../../../styles/colors';
import { validateDateFormat } from '../../../../../utilities/dateTime';


/**
 * Constants
 */

const countryOptions = COUNTRY_OPTIONS.map((country) => ({ label: country.name, value: country.code }));


/**
 * Helpers
 */

const stripeStyles = (theme) => ({
	base: {
		iconColor: theme.primaryText,
		color: theme.primaryText,
		fontSize: '16px',
		fontFamily: '"Lexend", sans-serif',
		fontSmoothing: 'antialiased',
		fontWeight: 500,
		'::placeholder': {
			color: theme.inputPlaceholder,
		},
		':focus': {
			color: theme.primaryText,
		}
	},
	invalid: {
		color: theme.statusDangerBase,
		':focus': {
			color: theme.statusDangerBase
		}
	}
});


/**
 * Component
 */

export const PaymentMethodModal = ({
	className, isOpen, handleClose, updateUser, paymentMethod
}) => {

	// Create state handlers
	const [countryError, setCountryError] = useState(null);
	const [postalCodeError, setPostalCodeError] = useState(null);
	const [expirationDateError, setExpirationDateError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [inputValues, setInputValues] = useState({
		country: 'US'
	});

	// Get Stripe elements
	const elements = useElements();
	const stripe = useStripe();

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get current theme
	const theme = uiMode.mode === UI_MODE_OPTIONS.DARK ? DarkTheme() : LightTheme();

	// Handle add update payment method function
	const handleAddUpdatePaymentMethod = async () => {

		// Handle flow for add / update
		if (!paymentMethod) {

			// Get parameters
			const {
				country
			} = inputValues;

			// Update loading state
			setIsLoading(true);

			// Get card element
			const cardElement = elements.getElement(CardElement);

			// Create token
			let cardToken = null;
			try {
				const { token, error } = await stripe.createToken(cardElement, {
					address_country: country
				});
				if (error && error.message) {

					// Show error message
					toastError(uiMode, error.message);

					// Update loading state
					setIsLoading(false);

					// Return
					return;
				}
				if (token) cardToken = token;

			} catch (e) {

				// Update loading state
				setIsLoading(false);
			}

			// Validate parameters
			if (!country || validator.isEmpty(country, { ignore_whitespace: true })) {
				setCountryError({ message: 'Please select the country of your billing address' });
				return;
			}

			// Add payment method
			addPaymentMethod({ source: cardToken.id }).then(({ data }) => {

				// Show success toast
				toastSuccess(uiMode, 'Success! Your new payment method has been added to your account.');

				// Set loading state
				setIsLoading(false);
				setCountryError(null);

				// Update user
				if (updateUser) updateUser(data.user);

				// Handle completion actions
				setTimeout(() => {
					if (handleClose) handleClose();
				}, 2500);

			}).catch(({ response }) => {

				// Set loading state
				setIsLoading(false);
				setCountryError(null);

				// Show error message
				if (response?.data?.message) {
					toastError(uiMode, 'Whoops. We\'re having trouble adding your new payment method. Please try again.');
				} else {
					toastError(uiMode, 'Whoops. We\'re having trouble adding your new payment method. Please try again.');
				}
			});
		} else {

			// Get parameters
			const {
				expirationDate, country, postalCode
			} = inputValues;

			// Validate parameters
			if (!expirationDate || validator.isEmpty(expirationDate, { ignore_whitespace: true })) {
				setExpirationDateError({ message: 'Please enter your expiration date' });
				return;
			}
			if (!validateDateFormat(expirationDate, 'MM/YYYY')) {
				setExpirationDateError({ message: 'MM/YYYY' });
				return;
			}
			try {
				if (!postalCode || !validator.isPostalCode(postalCode, country || 'US')) {
					setPostalCodeError({ message: 'Please enter your postal code' });
					return;
				}
			} catch (e) {
				if (!postalCode || validator.isEmpty(postalCode, { ignore_whitespace: true })) {
					setPostalCodeError({ message: 'Please enter your postal code' });
					return;
				}
			}
			if (!country || validator.isEmpty(country, { ignore_whitespace: true })) {
				setCountryError({ message: 'Please select the country of your billing address' });
				return;
			}

			// Update loading state
			setIsLoading(true);

			// Update payment method
			updatePaymentMethod({
				id: paymentMethod.id, expirationDate, addressPostal: postalCode, addressCountry: country
			}).then(({ data }) => {

				// Show success toast
				toastSuccess(uiMode, 'Success! Your payment method has been updated.');

				// Set loading state
				setIsLoading(false);
				setCountryError(null);
				setPostalCodeError(null);

				// Update user
				if (updateUser) updateUser(data.user);

				// Handle completion actions
				setTimeout(() => {
					if (handleClose) handleClose();
				}, 2500);

			}).catch(({ response }) => {

				// Set loading state
				setIsLoading(false);
				setCountryError(null);
				setPostalCodeError(null);

				// Show error message
				if (response?.data?.message) {
					toastError(uiMode, 'Whoops. We\'re having trouble updating your payment method. Please try again.');
				} else {
					toastError(uiMode, 'Whoops. We\'re having trouble updating your payment method. Please try again.');
				}
			});
		}
	};

	// Handle on input change action
	const handleOnChange = (event) => {
		const {
			name, value
		} = event.target;
		setInputValues({ ...inputValues, [name]: value });
	};

	// Handle actions on component load
	useEffect(() => {
		if (isOpen) {
			if (elements) {

				// Get card elements
				const cardElement = elements.getElement(CardElement);

				// Clear card elements
				if (cardElement) cardElement.clear();
			}

			// Clear content
			setInputValues({ country: 'US' });
		}
	}, [isOpen, elements]);

	// Render component
	return (
		<Modal className={className} isOpen={isOpen} handleClose={handleClose} clickOutsideClose useWrapper>
			<S.ModalInner>

				{/* Icon */}
				<S.IconContainer><FontAwesomeIcon icon={['fas', 'credit-card']} /></S.IconContainer>

				{/* Content */}
				<Typography tag="h3" weight="bold">{!paymentMethod ? 'Add a payment method' : 'Update payment method'}</Typography>
				<Typography tag="p" variation="1" weight="light" className="subtitle">
					{!paymentMethod
						? (
							<>
								Enter your card details below. We&apos;ll only charge you when you schedule a reservation.
							</>
						) : (
							<>
								Update the details for your card ending in
								{' '}
								<Typography weight="medium" style={{ fontSize: 'inherit' }}>{paymentMethod?.card?.last4}</Typography>
								.
							</>
						)}
				</Typography>

				{/* Inputs */}
				<S.InputContainer>

					{/* Payment Widget */}
					{!paymentMethod ? (
						<>
							<Typography tag="label" weight="semibold" className="animate">
								Card Details
							</Typography>
							<S.ElementContainer>
								<CardElement options={{
									style: stripeStyles(theme)
								}}
								/>
							</S.ElementContainer>
						</>
					) : (
						<S.ElementRow>
							<div>
								<TextInput
									label="Expiration Date"
									placeholder="MM/YYYY"
									name="expirationDate"
									size="large"
									error={expirationDateError}
									value={inputValues.expirationDate || ''}
									autoComplete="expirationDate"
									onFocus={() => { setExpirationDateError(null); }}
									onKeyUp={() => { setExpirationDateError(null); }}
									onBlur={() => { setExpirationDateError(null); }}
									onChange={handleOnChange}
								/>
							</div>
							<div>
								<TextInput
									label="Postal Code"
									placeholder="123456"
									name="postalCode"
									size="large"
									error={postalCodeError}
									value={inputValues.postalCode || ''}
									autoComplete="zipcode"
									onFocus={() => { setPostalCodeError(null); }}
									onKeyUp={() => { setPostalCodeError(null); }}
									onBlur={() => { setPostalCodeError(null); }}
									onChange={handleOnChange}
								/>
							</div>
						</S.ElementRow>
					)}

					{/* Country */}
					<Select
						label="Country"
						name="country"
						size="large"
						options={countryOptions}
						error={countryError}
						values={Array.isArray(inputValues.country) ? inputValues.country : [inputValues.country || '']}
						containerClassName="formInput"
						onFocus={() => { setCountryError(null); }}
						onKeyUp={() => { setCountryError(null); }}
						onBlur={() => { setCountryError(null); }}
						onChange={handleOnChange}
					/>

				</S.InputContainer>

				{/* Actions */}
				<S.ActionContainer>
					<Button disabled={isLoading} isLoading={isLoading} variant="solid" onClick={handleAddUpdatePaymentMethod}>{!paymentMethod ? 'Add Card' : 'Update Card'}</Button>
				</S.ActionContainer>

			</S.ModalInner>
		</Modal>
	);
};


/**
 * Configuration
 */

PaymentMethodModal.displayName = 'PaymentMethodModal';
PaymentMethodModal.propTypes = {
	className: PropTypes.string,
	isOpen: PropTypes.bool,
	handleClose: PropTypes.func,
	updateUser: PropTypes.func,
	paymentMethod: PropTypes.shape()
};
PaymentMethodModal.defaultProps = {
	className: null,
	isOpen: false,
	handleClose: null,
	updateUser: null,
	paymentMethod: null
};
