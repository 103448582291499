/*
 * File: EntitySelectionStep.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:24 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 19, 2023 at 2:22 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, {
	forwardRef
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

// Utilities
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { globalQuery } from '../../../../utilities/state';

// Components
import {
	Typography, Button, Emoji
} from '../../../../components';

// Constants
import { PARK_ENTITY_OPTIONS } from '../../../../../Constants';

// Styles
import * as S from './EntitySelectionStep.styles';


/**
 * Component
 */

export const EntitySelectionStep = forwardRef(({
	className, updateStep, setActiveParkEntity
}, ref) => {

	// Use hooks
	const navigate = useNavigate();

	// Get query parameters from hook
	const [searchParams] = useSearchParams();

	// Get current locale from hook
	const stateLocale = useSelector((state) => state.locale.value);

	// Handle next action
	const handleNext = async (id) => {

		// Set active park entity
		setActiveParkEntity(id);

		// Move to next step
		updateStep(2); // Day selection step
	};

	// Handle back action
	const handleBack = () => {

		// Move to trips
		navigate(`${stateLocale.localePath}/trips${globalQuery(searchParams)}`);
	};

	// Return component
	return (
		<S.StepContainer ref={ref} className={className}>

			{/* Form */}
			<S.Form>

				{/* Icon */}
				<S.IconContainer className="isNotMobile">
					<S.Icon>
						<FontAwesomeIcon icon={['far', 'star']} />
					</S.Icon>
				</S.IconContainer>

				{/* Content */}
				<Typography tag="h2" weight="bold">
					Let&apos;s start with the basics
				</Typography>
				<Typography tag="p" variation="1" weight="regular">Select the Disney park that you&apos;ll be traveling to during your trip.</Typography>

				{/* Selection Container */}
				<S.SelectionContainer>
					{PARK_ENTITY_OPTIONS.map((entity) => (
						<S.SelectionCard className="animate" onClick={() => { handleNext(entity.id); }} key={entity.id}>
							<S.PlaceIcon>
								<Emoji symbol={entity.icon} label={entity.name} size={3.5} />
							</S.PlaceIcon>
							<Typography tag="h4" weight="bold">{entity.name}</Typography>
							<Typography tag="p">{entity.location}</Typography>
						</S.SelectionCard>
					))}
				</S.SelectionContainer>

				{/* Actions */}
				<S.ActionContainer>
					<Button variant="outline" className="backButton" onClick={handleBack}>
						<FontAwesomeIcon icon={['fas', 'arrow-left-long']} />
						<Typography variation="2" weight="semibold">Back to Trips</Typography>
					</Button>
				</S.ActionContainer>
			</S.Form>
		</S.StepContainer>
	);
});


/**
 * Configuration
 */

EntitySelectionStep.displayName = 'EntitySelectionStep';
EntitySelectionStep.propTypes = {
	className: PropTypes.string,
	updateStep: PropTypes.func,
	setActiveParkEntity: PropTypes.func,
};
EntitySelectionStep.defaultProps = {
	className: null,
	updateStep: null,
	setActiveParkEntity: null,
};
