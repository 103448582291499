/*
 * File: EntitySelectionStep.styles.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:25 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 18, 2023 at 11:13 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Styles
import { ColorValuesCreator } from '../../../../styles/colors';

// Constants
import { mediumBreakpoint } from '../../../../styles/constants';
import { Card } from '../../../../components';

// Create color values
const ColorValues = ColorValuesCreator();


/**
 * Styles
 */

export const StepContainer = styled.div``;

export const Form = styled.form`
	width: 100%;
	max-width: 700px;
	margin: 0px auto;

	@media only screen {
		padding-top: 20px;
		padding-bottom: 50px;
	}
	@media only screen and (min-width: 40em) {
		padding-top: 80px;
		padding-bottom: 60px;
	}
	h2 {
		text-align: left;
		color: ${ColorValues.white};
		margin: 10px 0px 10px;
	}
	p {
		text-align: left;
		color: ${ColorValues.white};
   		margin-bottom: 20px;
	}
	button {
		width: 100%;
	}
`;

export const IconContainer = styled.div``;

export const Icon = styled.div`
	display: flex;
	width: 46px;
	height: 46px;
	background-color: ${ColorValues.white};
	border-radius: 50%;
	align-items: center;
	justify-content: center;
	position: relative;

	svg {
		color: ${ColorValues.brandPrimaryDarkBlue};
		font-size: 1.35rem;
	}
`;

export const ActionContainer = styled.div`
	width: 100%;
	max-width: 400px;
	margin: 40px auto 65px;
	text-align: center;

	button {
		width: 200px;
		padding-top: 0.85rem;
		padding-bottom: 0.85rem;
		margin: 0px auto;
	}
	.backButton {
		font-size: 0.8rem;
	}
`;

export const SelectionContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 30px;
	align-items: center;
	text-align: center;
	justify-content: center;
	width: 100%;
	margin: 30px 0px 0px;

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		flex-direction: row;
	}
`;

export const SelectionCard = styled(Card)`
	padding: 40px 20px;
	flex-grow: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	cursor: pointer;
	transform: scale(1);
	box-shadow: ${ColorValues.elementShadow};
	width: 100%;

	&:hover {
		transform: scale(1.02);
	}
	p {
		color: ${({ theme }) => theme.secondaryText};
		margin: 0px;
	}
`;

export const PlaceIcon = styled.div`
	width: 120px;
	height: 120px;
	border-radius: 50%;
	background-size: cover;
	background-repeat: no-repeat;
	background-color: ${({ theme }) => theme.secondaryBackground};
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0px 0px 15px;

	svg {
		color: ${ColorValues.dark};
	}
`;
