/*
 * File: MyTrips.styles.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on December 6, 2022 at 11:54 AM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: March 3, 2023 at 4:00 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { Card, LocaleLink } from '../../../../components';

// Styles
import { ColorValuesCreator } from '../../../../styles/colors';

// Constants
import { mediumBreakpoint } from '../../../../styles/constants';

// Create color values
const ColorValues = ColorValuesCreator();


/**
 * Styles
 */

export const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	flex-wrap: wrap;
	gap: 22px;

	a,
	a:link,
	a:visited {
		text-decoration: none;
	}
`;

export const PromptLink = styled(LocaleLink)`
	width: 100%;

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		width: 227px;
	}
`;

export const AddTripPrompt = styled.div`
	width: 100%;
	height: 100%;
	min-height: 300px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 8px;
	position: relative;

	svg {
		font-size: 3.0rem;
		color: ${ColorValues.white};
	}
	span {
		color: ${ColorValues.white};
		margin-top: 10px;
	}
	&:hover {
		box-shadow: ${ColorValues.elementDarkShadow};
	}
`;

export const AddTripPromptBackground = styled.div`
	background-color: ${ColorValues.white};
	opacity: 0.2;
	position: absolute;
	height: 100%;
	width: 100%;
	border-radius: 8px;
`;

export const EmptyCard = styled(Card)`
	width: 100%;
	padding: 40px 0px;
	background-color: ${ColorValues.white};
	color: ${ColorValues.dark};
`;
