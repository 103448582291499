/*
 * File: Dashboard.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on November 18, 2022 at 3:44 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: May 18, 2023 at 10:36 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Utilities
import { createStateLocale } from '../../../utilities/locale';

// Components
import {
	Meta, AppNavigation, Typography
} from '../../../components';
import { FriendsFamily } from './FriendsFamily';


// Styles
import * as S from './Dashboard.styles';
import { UpcomingTrips } from './UpcomingTrips/UpcomingTrips';


/**
 * Component
 */

const Dashboard = ({ meta, locale }) => {

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Handle component initialization
	useEffect(() => {}, []);

	// Handle render content
	const renderContent = () => (
		<S.Wrapper>

			{/* Upcoming Trips Section */}
			<S.DashboardSection>

				{/* Title */}
				<S.TitleContainer>
					<Typography tag="h4">Upcoming Trips</Typography>
				</S.TitleContainer>

				{/* Upcoming Trips */}
				<UpcomingTrips />

			</S.DashboardSection>

			{/* Friends & Family Section */}
			<S.DashboardSection>

				{/* Title */}
				<S.TitleContainer>
					<Typography tag="h4">Friends & Family</Typography>
				</S.TitleContainer>

				{/* Friends & Family */}
				<FriendsFamily />

			</S.DashboardSection>
		</S.Wrapper>
	);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} />

			{/* Component Content */}
			<AppNavigation containerClassName="gradientBackground" isFixed={false}>
				{renderContent()}
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

Dashboard.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape()
};
Dashboard.defaultProps = {
	meta: {},
	locale: {}
};


/**
 * Exports
 */

export default Dashboard;
