/*
 * File: SchemaScript.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:32 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: November 14, 2022 at 6:36 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import PropTypes from 'prop-types';


/**
 * Component
 */

export const SchemaScript = ({ schema }) => (
	<script
		type="application/ld+json"
		// eslint-disable-next-line react/no-danger
		dangerouslySetInnerHTML={{
			__html: (() => JSON.stringify(schema))()
		}}
	/>
);


/**
 * Configuration
 */

SchemaScript.displayName = 'SchemaScript';
SchemaScript.propTypes = {
	schema: PropTypes.shape().isRequired,
};
SchemaScript.defaultProps = {};
