/*
 * File: Party.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on December 6, 2022 at 11:52 AM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: June 24, 2023 at 4:51 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';

// Utilities
import { getCookie } from '../../../../utilities/cookies';

// Components
import {
	FriendsFamilyMember, EmptyComponent
} from '../../../../components';

// Constants
import { VIEW_MODE_COOKIE } from '../../../../../Constants';

// Styles
import * as S from './Party.styles';


/**
 * Component
 */

export const Party = ({ className, members }) => {

	// Get query parameters from hook
	const [searchParams] = useSearchParams();
	let isMobileApp = searchParams.get('mobileapp') === 'true';
	if (!isMobileApp) {
		const mobileAppCookie = getCookie(VIEW_MODE_COOKIE);
		if (mobileAppCookie === 'mobileapp') isMobileApp = true;
	}

	// Render component
	const renderComponent = () => {
		if (members.length === 0) {
			return (
				<EmptyComponent
					isLight
					title="No party members have been added yet"
					message="Schedule attractions for your trip to see your party here"
					icon={['far', 'star']}
				/>
			);
		}
		return (
			<S.FamilyContainer>
				{members.map((member) => (
					<FriendsFamilyMember
						key={member.userXID || member.userSWID}
						firstName={member.firstName}
						lastName={member.lastName}
						profileImage={member.profileImage?.media ? member.profileImage : { url: member.profileImage }}
						avatarId={member.avatarId}
					/>
				))}
			</S.FamilyContainer>
		);
	};

	// Return component
	return (
		<S.Wrapper className={className}>{renderComponent()}</S.Wrapper>
	);
};


/**
 * Configuration
 */

Party.displayName = 'Party';
Party.propTypes = {
	className: PropTypes.string,
	members: PropTypes.arrayOf(PropTypes.shape())
};
Party.defaultProps = {
	className: null,
	members: []
};
