/*
 * File: Spinner.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:32 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: May 18, 2023 at 12:34 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import Lottie from 'react-lottie-player';
import PropTypes from 'prop-types';

// Components
import { Meta } from '../Meta';

// Animation
import animationData from '../../assets/animations/shining-stars.json';

// Styles
import * as S from './Spinner.styles';


/**
 * Component
 */

export const Spinner = ({ className, showMeta, meta }) => (
	<S.Wrapper className={className}>

		{/* Meta */}
		{showMeta && <Meta meta={meta} />}

		{/* Component Content */}
		<Lottie loop play animationData={animationData} style={{ width: 260, height: 260 }} />

	</S.Wrapper>
);


/**
 * Configuration
 */

Spinner.displayName = 'Spinner';
Spinner.propTypes = {
	className: PropTypes.string,
	showMeta: PropTypes.bool,
	meta: PropTypes.shape()
};
Spinner.defaultProps = {
	className: null,
	showMeta: false,
	meta: { shouldIndex: false }
};
