/*
 * File: About.styles.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on July 10, 2022 at 6:57 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: January 18, 2023 at 10:15 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Constants
import { largeBreakpoint } from '../../../styles/constants';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: block;
	padding: 0px 0px 50px;
	margin: 0 auto;

	p {
		margin-top: 15px;
	}
	@media only screen and (min-width: ${largeBreakpoint}em) {
		padding: 30px 0px 50px;
	}
`;

export const HeroSection = styled.div`
	display: flex;
	gap: 60px;
	margin: 80px 0px 80px;
	flex-direction: column;
	align-items: center;
    justify-content: center;
	position: relative;

	@media only screen and (min-width: ${largeBreakpoint}em) {
		flex-direction: row;
	}
`;

export const ImageContainer = styled.div``;

export const ContentContainer = styled.div`
	width: 100%;
	max-width: 550px;

	* {
		text-align: center;

		@media only screen and (min-width: ${largeBreakpoint}em) {
			text-align: left;
		}
	}
`;

export const CreatorImage = styled.img`
	width: 300px;
	height: auto;
`;

export const ContentSection = styled.div`
	padding: 80px 0px;
	width: 100%;
	max-width: 610px;
	margin: 0px auto;
	position: relative;
	text-align: center;

	h3 {
		margin-top: 8px;
	}
	h3,
	p {
		z-index: 2;
		position: relative;
	}
	button {
		margin: 30px auto;
		z-index: 2;
		position: relative;
	}
`;

export const HeaderDecoration = styled.div`
	width: 100%;
	height: 400px;
	background-image: url('${process.env.CDN_URL}/public/assets/wave-decoration-1.png');
	background-size: cover;
	background-position: 20%;
	background-repeat: no-repeat;
	position: absolute !important;
	left: 0px;
	top: 0px;

	@media only screen and (min-width: ${largeBreakpoint}em) {
		background-position: 50%;
	}
`;

export const FrameDecoration = styled.img`
	width: 100%;
	height: 100%;
	position: absolute !important;
	top: 0px;
	z-index: 1;
`;

export const SocialFeedContainer = styled.div`
	padding: 80px 0px;
	position: relative;
	text-align: center;

	h3 {
		margin-bottom: 25px;
	}
`;
