/*
 * File: ResetPassword.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on November 18, 2022 at 3:58 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: May 31, 2023 at 10:15 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import validator from 'validator';

// Utilities
import { createStateLocale } from '../../../utilities/locale';
import { toastError, toastSuccess } from '../../../utilities/toaster';
import { parseFirstLastName } from '../../../../utilities/utilities';
import { globalQuery } from '../../../utilities/state';

// Services
import { resetPassword, validatePasswordResetAttempt } from '../../../services/password';

// Components
import {
	Meta, AppNavigation, Typography, TextInput, PasswordInput, Spinner, ErrorComponent, InputCollection
} from '../../../components';

// Styles
import * as S from './ResetPassword.styles';


/**
 * Component
 */

const ResetPassword = ({ meta, locale }) => {

	// Get component parameters
	const { aToken, bToken } = useParams();

	// Create state handlers
	const [componentData, setComponentData] = useState({});
	const [pageStatus, setPageStatus] = useState('idle');
	const [passwordError, setPasswordError] = useState(null);
	const [passwordRulesError, setPasswordRulesError] = useState(null);
	const [confirmPasswordError, setConfirmPasswordError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [inputValues, setInputValues] = useState({});

	// Create references for components
	const isMounted = useRef(true);

	// Get actions from hooks
	const navigate = useNavigate();
	const { executeRecaptcha } = useGoogleReCaptcha();

	// Get query parameters from hook
	const [searchParams] = useSearchParams();

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Initialize component data function
	const validateResetAttempt = async () => {

		// Update page status
		setPageStatus('loading');
		try {

			// Fetch data
			const { data } = await validatePasswordResetAttempt({ aToken, bToken });

			// Ensure component is mounted
			if (isMounted.current) {

				// Set new data state
				setComponentData(data);

				// Update page status
				setPageStatus('success');
			}
		} catch (error) {

			// Ensure component is mounted
			if (isMounted.current) {

				// Update page status
				setPageStatus('error');
			}
		}
	};

	// Handle reset user password function
	const resetUserPassword = async () => {

		// Get parameters
		const {
			password, confirmPassword
		} = inputValues;

		// Validate parameters
		if (!password || validator.isEmpty(password, { ignore_whitespace: true }) || passwordError || passwordRulesError) {
			setPasswordError({ message: passwordError?.message ? passwordError?.message : 'Please create a password for your Pixie Dust account' });
			return;
		}
		if (!confirmPassword || validator.isEmpty(confirmPassword, { ignore_whitespace: true }) || password !== confirmPassword) {
			setConfirmPasswordError({ message: 'Your passwords must match' });
			return;
		}

		// Set loading state
		setIsLoading(true);

		// Get reCAPTCHA token
		let recaptchaToken = null;
		try { recaptchaToken = await executeRecaptcha('reset_password'); } catch (e) {}

		// Reset user's password
		resetPassword({
			aToken, bToken, pass: password, recaptchaToken
		}).then(() => {

			// Show success toast
			toastSuccess(uiMode, 'Your password has successfully been updated! We\'ll direct you back to log in.');

			// Clear inputs
			setInputValues({});

			// Handle completion actions
			setTimeout(() => {
				navigate(`${stateLocale.localePath}/login${globalQuery(searchParams)}`);
			}, 2500);

		}).catch(({ response }) => {

			// Set loading state
			setIsLoading(false);
			setPasswordError(null);
			setConfirmPasswordError(null);

			// Clear inputs
			setInputValues({});

			// Show error message
			if (response?.data?.message) {
				toastError(uiMode, response?.data?.message);
			} else {
				toastError(uiMode, 'Whoops. We\'re having trouble updating your password. Please try again.');
			}
		});
	};

	// Handle on input change action
	const handleOnChange = (event) => {
		const { name, value } = event.target;
		setInputValues({ ...inputValues, [name]: value });
	};

	// Handle component initialization
	useEffect(() => {

		// Set state
		isMounted.current = true;

		// Validate reset password attempt
		validateResetAttempt();

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, []);

	// Handle render content
	const renderContent = () => {
		switch (pageStatus) {
			case 'loading':
				return <Spinner showMeta meta={meta} />;
			case 'error':
				return <ErrorComponent locale={stateLocale} isDark />;
			case 'success':
				if (componentData.error) {

					// Create title content
					let title = 'Your reset link has already been used or is no longer valid.';
					if (componentData.error.code === 102) {
						title = 'Your password reset link has expired.';
					}

					// Create description content
					const description = (
						<>
							To reset your password, follow the link below or contact us at
							{' '}
							<a href="mailto:support@pixiedustguide.com?Subject=Reset Password">support@pixiedustguide.com</a>
							{' '}
							for more information.
						</>
					);

					// Return component
					return (
						<ErrorComponent
							locale={stateLocale}
							title={title}
							description={description}
							message=""
							isDark
							buttons={[
								{
									link: '/forgot',
									size: 'large',
									text: 'Forgot Password'
								},
								{
									link: '/',
									size: 'large',
									variant: 'outline',
									variation: 'white',
									text: 'Explore Pixie Dust'
								}
							]}
						/>
					);
				}
				return (
					<S.Form>

						{/* Logo */}
						<S.LogoMark className="isNotMobile" />

						{/* Content */}
						<Typography tag="h2" weight="bold">
							Hi,
							{' '}
							{parseFirstLastName(componentData?.name)?.firstName}
						</Typography>
						<Typography tag="p" variation="1" weight="regular">Create a new password below for your Pixie Dust account.</Typography>

						{/* Inputs */}
						<InputCollection className="floatShadow" isDark>
							<PasswordInput
								label="Create a new password"
								onChange={handleOnChange}
								setError={setPasswordError}
								setRulesError={setPasswordRulesError}
								rulesError={passwordRulesError}
								autoComplete="new-password"
								value={inputValues.password || ''}
								disallowedContent={[
									componentData?.email
								].filter(Boolean)}
								error={passwordError}
								smartField
								isDark
							/>
							<TextInput
								label="Confirm your password"
								type="password"
								name="confirmPassword"
								error={confirmPasswordError}
								value={inputValues.confirmPassword || ''}
								containerClassName="modalInput"
								autoComplete="new-password"
								onFocus={() => { setConfirmPasswordError(null); }}
								onKeyUp={() => { setConfirmPasswordError(null); }}
								onBlur={() => { setConfirmPasswordError(null); }}
								onChange={handleOnChange}
								smartField
								isDark
							/>
						</InputCollection>

						{/* Update Button */}
						<S.ActionButton disabled={isLoading} isLoading={isLoading} onClick={() => { resetUserPassword(); }} size="large" className="floatShadow">Update Password</S.ActionButton>
					</S.Form>
				);
			default:
				return null;
		}
	};

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} />

			{/* Component Content */}
			<AppNavigation containerClassName="gradientBackground" isFixed={false}>
				<S.Wrapper>
					{renderContent()}
				</S.Wrapper>
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

ResetPassword.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape()
};
ResetPassword.defaultProps = {
	meta: {},
	locale: {}
};


/**
 * Exports
 */

export default ResetPassword;
