/*
 * File: TripFlow.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on November 18, 2022 at 3:46 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 18, 2023 at 10:31 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useWindowResize } from 'beautiful-react-hooks';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// Utilities
import { createStateLocale } from '../../../utilities/locale';
import { scrollPageTo } from '../../../utilities/position';

// Services
import { getTrip } from '../../../services/trip';

// Slices
import { updateCurrentTrip, updateTrips } from '../../../store/slices/trips/trips.slice';

// Components
import {
	Meta, AppNavigation, Spinner, ErrorComponent
} from '../../../components';

// Step Components
import { EntitySelectionStep } from './EntitySelectionStep';
import { DaySelectionStep } from './DaySelectionStep';
import { DayAssignmentStep } from './DayAssignmentStep';
import { PaymentMethodStep } from './PaymentMethodStep';
import { ConfirmationStep } from './ConfirmationStep';

// Styles
import * as S from './TripFlow.styles';


/**
 * Component
 */

const TripFlow = ({ meta, locale }) => {

	// Get component parameters
	const { id: urlId } = useParams();

	// Create reference for step components
	const [pageStatus, setPageStatus] = useState('idle');
	const [entitySelectionStepHeight, setEntitySelectionStepHeight] = useState(0);
	const [daySelectionStepHeight, setDaySelectionStepHeight] = useState(0);
	const [dayAssignmentStepHeight, setDayAssignmentStepHeight] = useState(0);
	const [paymentMethodStepHeight, setPaymentMethodStepHeight] = useState(0);
	const [confirmationStepHeight, setConfirmationStepHeight] = useState(0);
	const entitySelectionStepRef = useRef();
	const daySelectionStepRef = useRef();
	const dayAssignmentStepRef = useRef();
	const paymentMethodStepRef = useRef();
	const confirmationStepRef = useRef();

	// Create reference for components
	const isMounted = useRef(true);
	const stripePromiseRef = useRef(null);

	// Get current user
	const userStatus = useSelector((state) => state.user.status);

	// Create state handlers
	const [currentStep, setCurrentStep] = useState((urlId || null) ? 2 : 1);
	const [shouldUpdateHeight, setShouldUpdateHeight] = useState(true);
	const [id, setId] = useState(urlId || null);
	const [activeParkEntity, setActiveParkEntity] = useState(null);

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Get current trips from hook
	const tripsState = useSelector((state) => state.trips.value);

	// Get actions from hooks
	const dispatch = useDispatch();

	// Handle fetch data for page
	const fetchDataForPage = async () => {

		// Update page status
		setPageStatus('loading');
		try {

			// Fetch trip if necessary
			if (id) {

				// Fetch trip
				const { data } = await getTrip({ id });

				// Set new data state
				if (isMounted.current) {

					// Dispatch new state
					dispatch(updateCurrentTrip(data.trip));

					// Update trips
					if (tripsState?.isSet) {
						const newTrips = [...(tripsState?.trips || [])];
						const tripIndex = newTrips.findIndex((v) => v.id === data.trip.id);
						if (tripIndex > -1) newTrips[tripIndex] = data.trip;
						dispatch(updateTrips({ trips: newTrips }));
					}
				}
			} else {

				// Initialize new trip
				dispatch(updateCurrentTrip({ days: [] }));
			}

			// Update page status
			if (isMounted.current) {
				setPageStatus('success');
			}
		} catch (error) {

			// Ensure component is mounted
			if (isMounted.current) {

				// Update page status
				setPageStatus('error');
			}
		}
	};

	// Handle step height updates
	const updateStepHeight = () => {

		// Set component heights
		if (entitySelectionStepRef?.current?.clientHeight) setEntitySelectionStepHeight(entitySelectionStepRef?.current?.clientHeight);
		if (daySelectionStepRef?.current?.clientHeight) setDaySelectionStepHeight(daySelectionStepRef?.current?.clientHeight);
		if (dayAssignmentStepRef?.current?.clientHeight) setDayAssignmentStepHeight(dayAssignmentStepRef?.current?.clientHeight);
		if (paymentMethodStepRef?.current?.clientHeight) setPaymentMethodStepHeight(paymentMethodStepRef?.current?.clientHeight);
		if (confirmationStepRef?.current?.clientHeight) setConfirmationStepHeight(confirmationStepRef?.current?.clientHeight);
	};

	// Handle actions on current trip change
	useEffect(() => {

		// Update state
		if (tripsState?.currentTrip != null && pageStatus === 'success') {

			// Update step height
			setTimeout(() => {
				updateStepHeight();
			}, 100);
		}
	}, [tripsState?.currentTrip]);

	// Handle actions on path change (dynamic page)
	useEffect(() => {

		// Update id based on current url path
		if (window.location.pathname.includes('/trip/create')) {
			setId(null);
		} else {
			const arr = window.location.pathname.split('/edit/');
			setId(arr[arr.length - 1]);
		}

	}, [window.location.pathname]);

	// Handle actions on route change (dynamic page)
	useEffect(() => {

		// Set page status if necessary
		if (pageStatus !== 'idle') {

			// Update if new id is null
			if (id == null) {

				// Update current step
				setCurrentStep(1);

				// Reset page status
				setPageStatus('idle');

				// Fetch data state for page
				fetchDataForPage();
			}
		} else {

			// Fetch data state for page
			fetchDataForPage();
		}

		// Scroll page to top
		scrollPageTo(0, 0);

	}, [id]);

	// Handle component initialization
	useEffect(() => {

		// Set state
		isMounted.current = true;

		// Load Stripe
		stripePromiseRef.current = loadStripe(process.env.STRIPE_PUBLIC_KEY);

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, []);

	// Handle actions on component load
	useEffect(() => {
		if (shouldUpdateHeight) {

			// Set component heights
			updateStepHeight();

			// Update state
			setShouldUpdateHeight(false);
		}
	}, [shouldUpdateHeight]);

	// Handle actions on window resize
	useWindowResize(() => {

		// Set component heights
		updateStepHeight();
	});

	// Get step height for step component
	const getStepHeight = () => {
		switch (currentStep) {
			case 1:
				return entitySelectionStepHeight;
			case 2:
				return daySelectionStepHeight;
			case 3:
				return dayAssignmentStepHeight;
			case 4:
				return paymentMethodStepHeight;
			case 5:
				return confirmationStepHeight;
			default:
				return 0;
		}
	};

	// Handle render content
	const renderContent = () => {
		if (userStatus !== 'succeeded' || pageStatus === 'idle' || pageStatus === 'loading') {
			return <Spinner showMeta meta={meta} />;
		}
		if (pageStatus === 'error') {
			return <ErrorComponent isDark />;
		}
		return (
			<S.Wrapper>
				<S.StepContainer className="animate" stepHeight={getStepHeight()}>
					<S.Step className={currentStep === 1 ? 'animate show' : 'animate'}>
						<EntitySelectionStep ref={entitySelectionStepRef} updateStep={setCurrentStep} updateHeight={updateStepHeight} activeParkEntity={activeParkEntity} setActiveParkEntity={setActiveParkEntity} isVisible={currentStep === 1} />
					</S.Step>
					<S.Step className={currentStep === 2 ? 'animate show' : 'animate'}>
						<DaySelectionStep ref={daySelectionStepRef} updateStep={setCurrentStep} updateHeight={updateStepHeight} activeParkEntity={activeParkEntity} isVisible={currentStep === 2} isEditing={id != null} />
					</S.Step>
					<S.Step className={currentStep === 3 ? 'animate show' : 'animate'}>
						<DayAssignmentStep ref={dayAssignmentStepRef} updateStep={setCurrentStep} updateHeight={updateStepHeight} isVisible={currentStep === 3} />
					</S.Step>
					<S.Step className={currentStep === 4 ? 'animate show' : 'animate'}>
						<Elements
							stripe={stripePromiseRef.current}
							options={{
								fonts: [{
									cssSrc: 'https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap'
								}]
							}}
						>
							<PaymentMethodStep ref={paymentMethodStepRef} updateStep={setCurrentStep} updateHeight={updateStepHeight} isVisible={currentStep === 4} />
						</Elements>
					</S.Step>
					<S.Step className={currentStep === 5 ? 'animate show' : 'animate'}>
						<ConfirmationStep ref={confirmationStepRef} updateStep={setCurrentStep} updateHeight={updateStepHeight} isVisible={currentStep === 5} />
					</S.Step>
				</S.StepContainer>
			</S.Wrapper>
		);
	};

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} />

			{/* Component Content */}
			<AppNavigation containerClassName="gradientBackground" isFixed={false} isLargeContent>
				{renderContent()}
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

TripFlow.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape()
};
TripFlow.defaultProps = {
	meta: {},
	locale: {}
};


/**
 * Exports
 */

export default TripFlow;
