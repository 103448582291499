/*
 * File: DaySelectionStep.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:24 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: November 28, 2023 at 4:24 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, {
	useEffect, forwardRef, useState, useRef
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery, useWindowResize } from 'beautiful-react-hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, useSearchParams } from 'react-router-dom';

// Utilities
import { toastError } from '../../../../utilities/toaster';
import { formatDateString, parseDateString } from '../../../../../utilities/dateTime';
import { globalQuery } from '../../../../utilities/state';

// Services
import { createUpdateTrip } from '../../../../services/trip';

// Slices
import { updateCurrentTrip, updateTrips } from '../../../../store/slices/trips/trips.slice';

// Components
import {
	Typography, Button
} from '../../../../components';

// Constants
import { AMOUNT_PER_RESERVATION_DAY, DEFAULT_TIMEZONE } from '../../../../../Constants';
import { mobileBreakpoint } from '../../../../styles/constants';

// Styles
import * as S from './DaySelectionStep.styles';


/**
 * Component
 */

export const DaySelectionStep = forwardRef(({
	isVisible, className, updateStep, activeParkEntity, isEditing
}, ref) => {

	// Check if mobile screen size
	const isMobile = useMediaQuery(`(max-width: ${mobileBreakpoint}em)`);

	// Create state handlers
	const [isLoading, setIsLoading] = useState(false);
	const [calendarCellHeight, setCalendarCellHeight] = useState(isMobile ? 42 : 56);
	const [currentCalendarValue, setCurrentCalendarValue] = useState(null);

	// Create reference for components
	const calendarRef = useRef();

	// Get actions from hooks
	const dispatch = useDispatch();
	const navigate = useNavigate();

	// Get search parameters
	const [searchParams] = useSearchParams();

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get current locale from hook
	const stateLocale = useSelector((state) => state.locale.value);

	// Get current user
	const user = useSelector((state) => state.user.value);

	// Get current trip
	const { currentTrip, trips, isSet } = useSelector((state) => state.trips.value);

	// Get current locale from hook
	const locale = useSelector((state) => state.locale.value);

	// Handle next action
	const handleNext = async () => {

		// Validate selected dates
		if (!currentCalendarValue || currentCalendarValue.length === 0) {
			toastError(uiMode, 'Please select the dates of your Disney trip to continue.');
			return;
		}

		// Build initial dates
		const calendarValue = !Array.isArray(currentCalendarValue) ? [currentCalendarValue] : currentCalendarValue;
		let dates = calendarValue.filter(Boolean).map((date) => formatDateString(date, 'YYYY-MM-DD', null, false));

		// Get necessary in-between dates
		if (dates.length > 1) {
			const endDate = dates[dates.length - 1];
			let currentDate = dates[0];
			while (currentDate !== endDate) {
				currentDate = parseDateString(currentDate, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD');
				dates.splice(dates.length - 1, 0, currentDate);
			}
		}

		// Deduplicate array
		dates = [...new Set(dates)];

		// Get days from current trip
		const currentDays = currentTrip?.days || [];

		// Build days
		const days = dates.map((date) => {

			// Search for matching date in current days
			const matchingDay = currentDays.find((d) => d.date === date);

			// Return day data
			return matchingDay || { date };
		});

		// Set loading state
		setIsLoading(true);

		// Create new trip
		createUpdateTrip({
			...currentTrip?.id ? {
				id: currentTrip?.id
			} : undefined,
			...currentTrip?.parkEntity ? {
				parkEntity: currentTrip?.parkEntity?.id
			} : {
				parkEntity: activeParkEntity
			},
			days,
			shouldPurchaseGeniePlus: true
		}).then(({ data }) => {

			// Set loading state
			setIsLoading(false);

			// Update trip
			dispatch(updateCurrentTrip(data.trip));

			// Update trips
			if (isSet) {
				const newTrips = [...(trips || [])];
				const tripIndex = newTrips.findIndex((v) => v.id === data.trip.id);
				if (tripIndex > -1) newTrips[tripIndex] = data.trip;
				else newTrips.push(data.trip);
				dispatch(updateTrips({ trips: newTrips }));
			}

			// Move to next step
			updateStep(3); // Day assignment step

			// Update url
			window.history.replaceState(null, null, `${locale.localePath}/trip/edit/${data.trip.id}${globalQuery(searchParams)}`);

		}).catch(({ response }) => {

			// Set loading state
			setIsLoading(false);

			// Show error message
			if (response?.data?.message) {
				toastError(uiMode, 'Whoops. We\'re having trouble updating your trip. Please try again.');
			} else {
				toastError(uiMode, 'Whoops. We\'re having trouble updating your trip. Please try again.');
			}
		});
	};

	// Handle update date range
	const handleUpdateRange = (dates) => {
		setCurrentCalendarValue(dates);
	};

	// Handle update cell height
	const updateCellHeight = () => {
		const cellsPerRow = isMobile ? 7 : 14;
		setCalendarCellHeight((calendarRef.current.clientWidth - 16.0) / cellsPerRow);
	};

	// Handle back action
	const handleBackTrips = () => {

		// Move to trips
		navigate(`${stateLocale.localePath}/trips${globalQuery(searchParams)}`);
	};

	// Handle back action
	const handleBack = () => {

		// Move to previous step
		updateStep(1); // Park selection step
	};

	// Perform actions on visibility change
	useEffect(() => {
		if (isVisible === true) {

			// Set calendar cell height
			setTimeout(() => {
				if (calendarRef?.current) {
					updateCellHeight();
				}
			}, 50);
		}
	}, [isVisible]);

	// Handle actions on window resize
	useWindowResize(() => {

		// Set calendar cell height
		if (calendarRef?.current) {
			updateCellHeight();
		}
	});

	// Perform actions on current trip change
	useEffect(() => {

		// Initialize calendar value
		let value = [];

		// Build days
		const days = currentTrip && currentTrip?.days.length > 0 ? currentTrip?.days.map((day) => parseDateString(day.date, 'YYYY-MM-DD', currentTrip?.parkEntity?.timezone || user?.timezone || DEFAULT_TIMEZONE).toDate()) : [];
		if (days.length === 1) {
			[value] = days;
		} else if (days.length > 1) {
			value = [days[0], days[days.length - 1]];
		}

		// Set calendar value
		setCurrentCalendarValue(value);

	}, [currentTrip]);

	// Return component
	return (
		<S.StepContainer ref={ref} className={className}>

			{/* Form */}
			<S.Form>

				{/* Icon */}
				<S.IconContainer className="isNotMobile">
					<S.Icon>
						<FontAwesomeIcon icon={['far', 'calendar']} />
					</S.Icon>
				</S.IconContainer>

				{/* Content */}
				<Typography tag="h2" weight="bold">When are you going?</Typography>
				<Typography tag="p" variation="1" weight="regular">
					Select the dates of your Disney trip and we&apos;ll help you make reservations! When your trip begins, Pixie Dust will charge you $
					{AMOUNT_PER_RESERVATION_DAY}
					{' '}
					for each day that we reserve something for your party.
				</Typography>

				{/* Calendar */}
				<S.CalendarContainer $cellHeight={calendarCellHeight}>
					<S.BaseCalendar
						inputRef={calendarRef}
						allowPartialRange
						defaultView="month"
						showDoubleView={!isMobile}
						selectRange
						onChange={handleUpdateRange}
						value={currentCalendarValue}
						minDate={new Date()}
						minDetail="year"
						showFixedNumberOfWeeks={false}
						showNeighboringMonth={false}
					/>
				</S.CalendarContainer>

				{/* Actions */}
				<S.ActionContainer>
					<Button disabled={isLoading} isLoading={isLoading} variant="solid" onClick={handleNext}>Select Dates & Continue</Button>
					{isEditing
						? <Button disabled={isLoading} variant="text" className="backButton" onClick={handleBackTrips}>Back to Trips</Button>
						: <Button disabled={isLoading} variant="text" className="backButton" onClick={handleBack}>Back to Park Selection</Button>}
				</S.ActionContainer>

			</S.Form>
		</S.StepContainer>
	);
});


/**
 * Configuration
 */

DaySelectionStep.displayName = 'DaySelectionStep';
DaySelectionStep.propTypes = {
	className: PropTypes.string,
	isVisible: PropTypes.bool,
	updateStep: PropTypes.func,
	activeParkEntity: PropTypes.string,
	isEditing: PropTypes.bool
};
DaySelectionStep.defaultProps = {
	className: null,
	isVisible: false,
	updateStep: null,
	activeParkEntity: null,
	isEditing: false
};
