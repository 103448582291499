/*
 * File: UpdateProfileModal.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: March 7, 2023 at 11:47 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';
import { useSelector } from 'react-redux';

// Utilities
import { toastError, toastSuccess } from '../../../../utilities/toaster';

// Services
import { updateUserAccount } from '../../../../services/user';

// Components
import {
	Button, Modal, Typography, TextInput, PhoneNumberInput
} from '../../../../components';

// Styles
import * as S from './UpdateProfileModal.styles';


/**
 * Component
 */

export const UpdateProfileModal = ({
	className, isOpen, handleClose, updateUser
}) => {

	// Get current user
	const user = useSelector((state) => state.user.value);

	// Create state handlers
	const [nameError, setNameError] = useState(null);
	const [phoneError, setPhoneError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [inputValues, setInputValues] = useState({});

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Handle update user function
	const handleUpdateUser = async () => {

		// Get parameters
		const { name, phone } = inputValues;

		// Validate parameters
		if (!name || validator.isEmpty(name, { ignore_whitespace: true })) {
			setNameError({ message: 'Please enter your name' });
			return;
		}
		if (!phone || !validator.isMobilePhone(phone, 'any', { strictMode: true }) || phoneError) {
			setPhoneError({ message: 'Please enter a phone number for your Pixie Dust account' });
			return;
		}

		// Set loading state
		setIsLoading(true);

		// Update user profile
		updateUserAccount({ name, phone }).then(({ data }) => {

			// Show success toast
			toastSuccess(uiMode, 'Success! Your Pixie Dust profile has been updated.');

			// Set loading state
			setIsLoading(false);
			setNameError(null);
			setPhoneError(null);

			// Update user
			if (updateUser) updateUser(data.user);

			// Handle completion actions
			setTimeout(() => {
				if (handleClose) handleClose();
			}, 2500);

		}).catch(({ response }) => {

			// Set loading state
			setIsLoading(false);
			setNameError(null);
			setPhoneError(null);

			// Show error message
			if (response?.data?.message) {
				toastError(uiMode, 'Whoops. We\'re having trouble updating your profile. Please try again.');
			} else {
				toastError(uiMode, 'Whoops. We\'re having trouble updating your profile. Please try again.');
			}
		});
	};

	// Handle on input change action
	const handleOnChange = (event) => {
		const { name, value } = event.target;
		setInputValues({ ...inputValues, [name]: value });
	};

	// Handle actions on component load
	useEffect(() => {
		if (isOpen === true) {

			// Set input values
			setInputValues({
				...inputValues,
				...user?.name ? { name: user.name } : undefined,
				...user?.phone ? { phone: user.phone } : undefined,
			});

			// Hide errors
			setNameError(null);
			setPhoneError(null);
		}
	}, [isOpen]);

	// Render component
	return (
		<Modal className={className} isOpen={isOpen} handleClose={handleClose} clickOutsideClose useWrapper>
			<S.ModalInner>

				{/* Content */}
				<Typography tag="h3" weight="bold">My Profile</Typography>
				<Typography tag="p" variation="1" weight="light" className="subtitle">Update your Pixie Dust profile details.</Typography>

				{/* Inputs */}
				<TextInput
					label="Your name"
					name="name"
					type="text"
					error={nameError}
					value={inputValues.name || ''}
					autoComplete="name"
					containerClassName="modalInput"
					onFocus={() => { setNameError(null); }}
					onKeyUp={() => { setNameError(null); }}
					onBlur={() => { setNameError(null); }}
					onChange={handleOnChange}
				/>
				<PhoneNumberInput
					label="Phone number"
					name="phone"
					error={phoneError}
					containerClassName="modalInput"
					autoComplete="phoneNumber"
					value={inputValues.phone || ''}
					setError={setPhoneError}
					onChange={handleOnChange}
				/>

				{/* Actions */}
				<S.ActionContainer>
					<Button disabled={isLoading} isLoading={isLoading} variant="solid" onClick={handleUpdateUser}>Update</Button>
				</S.ActionContainer>

			</S.ModalInner>
		</Modal>
	);
};


/**
 * Configuration
 */

UpdateProfileModal.displayName = 'UpdateProfileModal';
UpdateProfileModal.propTypes = {
	className: PropTypes.string,
	isOpen: PropTypes.bool,
	handleClose: PropTypes.func,
	updateUser: PropTypes.func
};
UpdateProfileModal.defaultProps = {
	className: null,
	isOpen: false,
	handleClose: null,
	updateUser: null
};
