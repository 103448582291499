/*
 * File: ReservationElement.styles.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:53 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: March 29, 2023 at 10:16 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';

// Components
import { Badge } from '../Badge';


/**
 * Styles
 */

export const ReservationContainer = styled.div`
	display: flex;
	gap: 10px;
`;

export const TimelineContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;
`;

export const TimelineBubble = styled.div`
	width: 10px;
	height: 10px;
	background-color: ${({ theme }) => theme.placeholderHighlight};
	border-radius: 50%;
`;

export const TimelineLine = styled.div`
	width: 3px;
	border-radius: 2px;
	background-color: ${({ theme }) => theme.placeholderHighlight};
	flex-grow: 1;
`;

export const ReservationTime = styled.div`
	margin: -6px 0px 5px;

	span {
		color: ${({ theme }) => theme.secondaryText};
		font-size: 0.8rem;
	}
`;

export const ContentContainer = styled.div`
	flex-grow: 1;
	padding: 0px 0px 10px 0px;
	position: relative;

	.showOnHover {
		opacity: 0;
	}
	&:hover {
		.showOnHover {
			opacity: 1;
		}
	}
`;

export const AddPrompt = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	padding: 40px 20px;
	background-color: ${({ theme }) => theme.secondaryBackground};
	border-radius: 6px;

	${({ $isEditing }) => $isEditing && css`
		&:hover {
			transform: scale(1.02);
		}
	`}
	svg {
		font-size: 2.5rem;
		color: ${({ theme }) => theme.secondaryText};
		margin: 0px 5px;
		opacity: 0.7;
	}
	span {
		color: ${({ theme }) => theme.secondaryText};
		margin: 10px 0px 0px 0px;
		font-size: 0.9rem;
	}
`;

export const IconContainer = styled.div`
	margin: 0px 0px 0px;
	display: flex;
	gap: 8px;
	flex-wrap: wrap;
`;

export const ReservationCard = styled.div`
	padding: 10px 0px;
`;

export const FlexContainer = styled.div`
	display: flex;
	gap: 15px;
`;

export const ReservationImage = styled.div`
	width: 70px;
	height: 70px;
	border-radius: 6px;
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${({ theme }) => theme.secondaryBackground};
	background-image: ${({ $image }) => `url('${$image}')`};
	background-size: cover;
	background-repeat: no-repeat;
`;

export const ReservationContent = styled.div`
	flex-grow: 1;
	margin-top: -5px;

	* {
		text-align: left;
	}
	.attractionName {
		color: ${({ theme }) => theme.primaryText};
	}
	.attractionType {
		color: ${({ theme }) => theme.secondaryText};
		margin: 4px 0px 0px;
	}
	.attractionTime {
		color: ${({ theme }) => theme.primaryText};
		margin: -4px 0px 0px;
	}
`;

export const StatusBadge = styled(Badge)`
	margin: 0px 0px 10px;
	gap: 6px;

	svg {
		margin-right: 5px;
	}
`;

export const PartyContainer = styled.div`
	display: flex;
	gap: 0px;
	flex-wrap: wrap;
	margin: 5px 10px 0px 0px;
`;

export const ProfileImage = styled.div`
	width: 26px;
	height: 26px;
	border-radius: 50%;
	background-image: ${({ image }) => `url('${image}')`};
	background-size: cover;
	background-repeat: no-repeat;
	background-color: ${({ theme }) => theme.secondaryBackground};
	margin-right: -10px;
	border: 1px solid white;
`;

export const RemoveButtonContainer = styled.div`
	position: absolute;
	top: -3px;
	right: 0px;
	color: ${({ theme }) => theme.secondaryText};
`;
