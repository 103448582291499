/*
 * File: Join.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on November 18, 2022 at 3:45 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: June 2, 2023 at 11:08 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Moduless
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useWindowResize } from 'beautiful-react-hooks';

// Utilities
import { createStateLocale } from '../../../utilities/locale';
import { globalQuery } from '../../../utilities/state';

// Components
import {
	Meta, AppNavigation, Spinner
} from '../../../components';

// Step Components
import { EmailStep } from './EmailStep';
import { AccountStep } from './AccountStep';
import { DisneyAccountStep } from './DisneyAccountStep';
import { ConfirmationStep } from './ConfirmationStep';
import { ContinueFlowStep } from './ContinueFlowStep';

// Constants
import { ROLES } from '../../../../Constants';

// Styles
import * as S from './Join.styles';


/**
 * Component
 */

const Join = ({ meta, locale, user }) => {

	// Create reference for step components
	const [emailStepHeight, setEmailStepHeight] = useState(0);
	const [accountStepHeight, setAccountStepHeight] = useState(0);
	const [disneyAccountStepHeight, setDisneyAccountStepHeight] = useState(0);
	const [confirmationStepHeight, setConfirmationStepHeight] = useState(0);
	const [continueFlowStepHeight, setContinueFlowHeight] = useState(0);
	const emailStepRef = useRef();
	const accountStepRef = useRef();
	const disneyAccountStepRef = useRef();
	const confirmationStepRef = useRef();
	const continueFlowStepRef = useRef();

	// Get query parameters from hook
	const [searchParams] = useSearchParams();

	// Get current user
	let userObj = useSelector((state) => state.user.value);
	const userStatus = useSelector((state) => state.user.status);
	if (!userObj && user) userObj = user;

	// Get initial step
	let initialStep = 1;
	if (userObj && userObj.isAnonymous !== true) {
		if (userObj.accountSetupComplete !== true) {
			initialStep = 2;
		} else if (userObj.onboardingComplete !== true) {
			initialStep = 3;
		} else if (userObj.onboardingConfirmationComplete !== true) {
			initialStep = 4;
		}
	}

	// Create state handlers
	const [currentStep, setCurrentStep] = useState(initialStep);
	const [shouldUpdateHeight, setShouldUpdateHeight] = useState(true);
	const [shouldRedirect, setShouldRedirect] = useState(true);

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Check if user has required role
	const userHasRequiredRole = userObj?.role && userObj?.role?.primary && userObj?.role?.primary === ROLES.STANDARD;

	// Handle component initialization
	useEffect(() => {}, []);

	// Handle actions on step change
	useEffect(() => {
		if (currentStep > 1) {
			setShouldRedirect(false);
		}
	}, [currentStep]);

	// Handle actions on component load
	useEffect(() => {
		if (shouldUpdateHeight) {

			// Set component heights
			setEmailStepHeight(emailStepRef?.current?.clientHeight);
			setAccountStepHeight(accountStepRef?.current?.clientHeight);
			setDisneyAccountStepHeight(disneyAccountStepRef?.current?.clientHeight);
			setConfirmationStepHeight(confirmationStepRef?.current?.clientHeight);
			setContinueFlowHeight(continueFlowStepHeight?.current?.clientHeight);

			// Update state
			setShouldUpdateHeight(false);
		}
	}, [shouldUpdateHeight]);

	// Handle actions on window resize
	useWindowResize(() => {

		// Set component heights
		setEmailStepHeight(emailStepRef?.current?.clientHeight);
		setAccountStepHeight(accountStepRef?.current?.clientHeight);
		setDisneyAccountStepHeight(disneyAccountStepRef?.current?.clientHeight);
		setConfirmationStepHeight(confirmationStepRef?.current?.clientHeight);
		setContinueFlowHeight(continueFlowStepHeight?.current?.clientHeight);
	});

	// Get step height for step component
	const getStepHeight = () => {
		switch (currentStep) {
			case 1:
				return emailStepHeight;
			case 2:
				return accountStepHeight;
			case 3:
				return disneyAccountStepHeight;
			case 4:
				return confirmationStepHeight;
			case 5:
				return continueFlowStepHeight;
			default:
				return 0;
		}
	};

	// Handle render content
	const renderContent = () => (
		<S.Wrapper>
			<S.StepContainer className="animate" stepHeight={getStepHeight()}>
				<S.Step className={currentStep === 1 ? 'animate show' : 'animate'}>
					<EmailStep ref={emailStepRef} updateStep={setCurrentStep} updateHeight={setShouldUpdateHeight} />
				</S.Step>
				<S.Step className={currentStep === 2 ? 'animate show' : 'animate'}>
					<AccountStep ref={accountStepRef} updateStep={setCurrentStep} updateHeight={setShouldUpdateHeight} />
				</S.Step>
				<S.Step className={currentStep === 3 ? 'animate show' : 'animate'}>
					<DisneyAccountStep ref={disneyAccountStepRef} updateStep={setCurrentStep} updateHeight={setShouldUpdateHeight} />
				</S.Step>
				<S.Step className={currentStep === 4 ? 'animate show' : 'animate'}>
					<ConfirmationStep ref={confirmationStepRef} updateStep={setCurrentStep} updateHeight={setShouldUpdateHeight} isVisible={currentStep === 4} />
				</S.Step>
				<S.Step className={currentStep === 5 ? 'animate show' : 'animate'}>
					<ContinueFlowStep ref={continueFlowStepRef} updateStep={setCurrentStep} updateHeight={setShouldUpdateHeight} isVisible={currentStep === 5} />
				</S.Step>
			</S.StepContainer>
		</S.Wrapper>
	);

	// Handle render component
	const renderComponent = () => {

		// Render loading state
		if (userStatus !== 'succeeded') {
			return <Spinner showMeta meta={meta} />;
		}

		// Check user state
		if (shouldRedirect && userObj?.isAnonymous !== true && userObj?.accountSetupComplete === true && userObj?.onboardingComplete === true && userObj?.onboardingConfirmationComplete === true) {

			// Redirect to dashboard
			return <Navigate to={`${stateLocale.localePath}/trips${globalQuery(searchParams)}`} />;
		}
		if (userObj?.isActive !== true) {

			// Redirect to logout
			window.location = '/logout';
			return null;
		}
		if (!userHasRequiredRole) {

			// Redirect to logout
			window.location = '/logout';
			return null;
		}

		// Render component
		return (
			<>
				{/* Meta */}
				<Meta meta={meta} locale={stateLocale} />

				{/* Component Content */}
				<AppNavigation containerClassName="gradientBackground" isFixed={false}>
					{renderContent()}
				</AppNavigation>
			</>
		);
	};

	// Render component
	return renderComponent();
};


/**
 * Configuration
 */

Join.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
	user: PropTypes.shape()
};
Join.defaultProps = {
	meta: {},
	locale: {},
	user: null
};


/**
 * Exports
 */

export default Join;
