/*
 * File: Toggle.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: December 21, 2022 at 4:03 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactToggle from 'react-toggle';

// Styles
import * as S from './Toggle.styles';


/**
 * Component
 */

export const Toggle = ({
	className, isDark, defaultChecked, checked, onChange, ...rest
}) => {

	// Handle actions on component load
	useEffect(() => {}, []);

	// Render component
	return (
		<S.Wrapper className={className} isDark={isDark}>
			<ReactToggle icons={false} defaultChecked={defaultChecked} checked={checked} onChange={onChange} {...rest} />
		</S.Wrapper>
	);
};


/**
 * Configuration
 */

Toggle.displayName = 'Toggle';
Toggle.propTypes = {
	className: PropTypes.string,
	isDark: PropTypes.bool,
	defaultChecked: PropTypes.bool,
	checked: PropTypes.bool,
	onChange: PropTypes.func
};
Toggle.defaultProps = {
	className: null,
	isDark: false,
	defaultChecked: undefined,
	checked: false,
	onChange: null
};
