/*
 * File: PhoneNumberInput.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: March 7, 2023 at 11:41 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import { TextInput } from '../TextInput';

// Styles
import * as S from './PhoneNumberInput.styles';
import 'react-phone-number-input/style.css';


/**
 * Component
 */

export const PhoneNumberInput = ({
	onChange, setError, value, error, smartField, containerClassName, ...rest
}) => {

	// Create state handlers
	const [phone, setPhone] = useState();

	// Handle actions on value changed
	useEffect(() => {

		// Notify parent of change
		onChange({ target: { name: 'phone', value: phone } });

	}, [phone]);

	// Render component
	return (
		<S.PhoneNumberInputContainer smartField={smartField}>
			<S.PhoneNumberInput
				onChange={setPhone}
				value={value}
				name="phone"
				defaultCountry="US"
				countryOptionsOrder={['US', '...']}
				containerClassName={containerClassName ? `${containerClassName} inputContainer` : 'inputContainer'}
				addInternationalOption={false}
				initialValueFormat="national"
				onFocus={() => { setError(null); }}
				onKeyUp={() => { setError(null); }}
				onBlur={() => { setError(null); }}
				inputComponent={TextInput}
				error={error}
				smartField={smartField}
				{...rest}
			/>
		</S.PhoneNumberInputContainer>
	);
};


/**
 * Configuration
 */

PhoneNumberInput.displayName = 'PhoneNumberInput';
PhoneNumberInput.propTypes = {
	onChange: PropTypes.func.isRequired,
	setError: PropTypes.func.isRequired,
	value: PropTypes.string,
	containerClassName: PropTypes.string,
	smartField: PropTypes.bool,
	error: PropTypes.shape(),
};
PhoneNumberInput.defaultProps = {
	value: '',
	error: null,
	containerClassName: '',
	smartField: false
};
