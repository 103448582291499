/*
 * File: PhoneNumberInput.styles.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:54 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: March 7, 2023 at 11:46 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';
import PhoneInput from 'react-phone-number-input';


/**
 * Styles
 */

export const PhoneNumberInputContainer = styled.div`
	position: relative;
	text-align: left;

	.PhoneInputCountryIcon {
		box-shadow: none !important;
	}
	.inputContainer {
		flex-grow: 1;
	}
	${({ smartField }) => !smartField && css`
		.PhoneInputCountry {
			position: absolute;
			bottom: 14px;
			left: 15px;
			z-index: 1;
		}
	`}
`;

export const PhoneNumberInput = styled(PhoneInput)`
	input,
	label {
		padding-left: 5px !important;
	}
	${({ smartField }) => !smartField && css`
		input {
			padding-left: 56px !important;
		}
	`}
	.errorContainer {
		left: 5px !important;
	}
`;

export const InputDecoration = styled.div`
	position: absolute;
	top: 31px;
	left: 12px;
	text-align: left;

	span {
		color: ${({ theme }) => theme.inputPlaceholder};
	}
`;
