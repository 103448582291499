/*
 * File: Login.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on November 18, 2022 at 3:45 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: May 31, 2023 at 10:42 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import PropTypes from 'prop-types';
import validator from 'validator';

// Utilities
import { createStateLocale } from '../../../utilities/locale';
import { toastError } from '../../../utilities/toaster';
import { globalQuery } from '../../../utilities/state';

// Services
import { loginUser } from '../../../services/authentication';

// Slices
import { updateUser } from '../../../store/slices/user/user.slice';

// Components
import {
	Meta, AppNavigation, Typography, TextInput, Button, InputCollection
} from '../../../components';

// Styles
import * as S from './Login.styles';


/**
 * Component
 */

const Login = ({ meta, locale }) => {

	// Create state handlers
	const [usernameError, setUsernameError] = useState(null);
	const [passwordError, setPasswordError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [inputValues, setInputValues] = useState({});

	// Get actions from hooks
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { executeRecaptcha } = useGoogleReCaptcha();

	// Get query parameters from hook
	const [searchParams] = useSearchParams();

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Handle log in function
	const handleLogin = async () => {

		// Get parameters
		const { username, password } = inputValues;

		// Validate parameters
		if (!username || validator.isEmpty(username, { ignore_whitespace: true })) {
			setUsernameError({ message: 'Please enter your email or username' });
			return;
		}
		if (!password || validator.isEmpty(password, { ignore_whitespace: true })) {
			setPasswordError({ message: 'Please enter your password' });
			return;
		}

		// Set loading state
		setIsLoading(true);

		// Get reCAPTCHA token
		let recaptchaToken = null;
		try { recaptchaToken = await executeRecaptcha('user_login'); } catch (e) {}

		// Log in user
		loginUser({ username, pass: password, recaptchaToken }).then(({ data }) => {

			// Dispatch new user
			dispatch(updateUser(data.user));

			// Navigate to the dashboard
			navigate(`${stateLocale.localePath}/trips${globalQuery(searchParams)}`);

		}).catch(({ response }) => {

			// Set loading state
			setIsLoading(false);
			setUsernameError(null);
			setPasswordError(null);

			// Clear password
			setInputValues({ ...inputValues, password: '' });

			// Show error message
			if (response?.data?.message) {
				toastError(uiMode, response?.data?.message);
			} else {
				toastError(uiMode, 'Whoops. We can\'t find an account with those details. Please try again.');
			}
		});
	};

	// Handle on input change action
	const handleOnChange = (event) => {
		const { name, value } = event.target;
		setInputValues({ ...inputValues, [name]: value });
	};

	// Handle sign up action function
	const signUpFunction = () => {
		navigate(`${stateLocale.localePath}/join${globalQuery(searchParams)}`);
	};

	// Handle forgot password action function
	const forgotPasswordFunction = () => {
		navigate(`${stateLocale.localePath}/forgot${globalQuery(searchParams)}`);
	};

	// Handle component initialization
	useEffect(() => {}, []);

	// Handle render content
	const renderContent = () => (
		<S.Wrapper>
			<S.Form>

				{/* Logo */}
				<S.LogoMark className="isNotMobile" />

				{/* Content */}
				<Typography tag="h2" weight="bold">Welcome back!</Typography>
				<Typography tag="p" variation="1" weight="regular">Log in to create a trip, schedule your Lightning Lanes, and discover more magic.</Typography>

				{/* Inputs */}
				<InputCollection className="floatShadow" isDark>
					<TextInput
						label="Email or Username"
						name="username"
						type="text"
						error={usernameError}
						value={inputValues.username || ''}
						containerClassName="modalInput"
						autoComplete="email"
						onFocus={() => { setUsernameError(null); }}
						onKeyUp={() => { setUsernameError(null); }}
						onBlur={() => { setUsernameError(null); }}
						onChange={handleOnChange}
						smartField
						isDark
					/>
					<TextInput
						label="Password"
						type="password"
						name="password"
						error={passwordError}
						value={inputValues.password || ''}
						containerClassName="modalInput"
						autoComplete="current-password"
						onFocus={() => { setPasswordError(null); }}
						onKeyUp={() => { setPasswordError(null); }}
						onBlur={() => { setPasswordError(null); }}
						onChange={handleOnChange}
						smartField
						isDark
					/>
				</InputCollection>

				{/* Forgot Password Button */}
				<S.ForgotPasswordContainer>
					<S.ForgotPasswordButton
						disabled={isLoading}
						variant="text"
						errorOffset={passwordError != null}
						className="animate"
						onClick={() => { forgotPasswordFunction(); }}
					>
						<Typography tag="p" variation="3" weight="medium">Forgot Password?</Typography>
					</S.ForgotPasswordButton>
				</S.ForgotPasswordContainer>

				{/* Log In Button */}
				<Button disabled={isLoading} isLoading={isLoading} onClick={() => { handleLogin(); }} size="large" className="floatShadow">Log In</Button>

				{/* Create Account Button */}
				<S.BottomActionContainer>
					<Button variant="text" size="small" onClick={() => { signUpFunction(); }} disabled={isLoading}>
						<Typography tag="p" variation="2" weight="medium">
							Don&apos;t have an account?
							{' '}
							<Typography decoration="underline" weight="medium" style={{ fontSize: 'inherit' }}>
								Sign Up
							</Typography>
						</Typography>
					</Button>
				</S.BottomActionContainer>
			</S.Form>
		</S.Wrapper>
	);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} />

			{/* Component Content */}
			<AppNavigation containerClassName="gradientBackground" isFixed={false}>
				{renderContent()}
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

Login.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape()
};
Login.defaultProps = {
	meta: {},
	locale: {}
};


/**
 * Exports
 */

export default Login;
