/*
 * File: DaySelectionStep.styles.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:25 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: June 18, 2023 at 12:28 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';
import Calendar from 'react-calendar';

// Styles
import { ColorValuesCreator } from '../../../../styles/colors';
import 'react-calendar/dist/Calendar.css';

// Create color values
const ColorValues = ColorValuesCreator();


/**
 * Styles
 */

export const StepContainer = styled.div``;

export const Form = styled.form`
	width: 100%;
	max-width: 800px;
	margin: 0px auto;

	@media only screen {
		padding-top: 20px;
		padding-bottom: 50px;
	}
	@media only screen and (min-width: 40em) {
		padding-top: 80px;
		padding-bottom: 60px;
	}
	h2 {
		text-align: left;
		color: ${ColorValues.white};
		margin: 10px 0px 10px;
	}
	p {
		text-align: left;
		color: ${ColorValues.white};
   		margin-bottom: 20px;
	}
	button {
		width: 100%;
	}
`;

export const IconContainer = styled.div``;

export const Icon = styled.div`
	display: flex;
	width: 46px;
	height: 46px;
	background-color: ${ColorValues.white};
	border-radius: 50%;
	align-items: center;
	justify-content: center;

	svg {
		color: ${ColorValues.brandPrimaryDarkBlue};
		font-size: 1.2rem;
	}
`;

export const CalendarContainer = styled.div`
	.react-calendar {
		.react-calendar__month-view__days__day {
			${({ $cellHeight }) => $cellHeight && css`
				height: ${$cellHeight}px !important;
			`}
		}
	}
`;

export const BaseCalendar = styled(Calendar)`
	margin: 40px 0px 0px;
	border-radius: 8px;
	overflow: hidden;
	box-shadow: ${ColorValues.elementShadow};
	border: none !important;
	width: 100% !important;

	&.react-calendar {
		background-color: ${ColorValues.white};
		padding-bottom: 10px;
		color: ${ColorValues.dark};

		span {
			color: ${ColorValues.dark};
		}
		button {
			color: ${ColorValues.dark};
		}
		.react-calendar__tile {
			color: ${ColorValues.dark};
		}
		.react-calendar__year-view__months__month {
			border-radius: 6px;
		}
		.react-calendar__tile:hover {
			background-color: ${ColorValues.neutralBackground};
			opacity: 0.8;
		}
		.react-calendar__tile:disabled,
		.react-calendar__month-view__days__day--neighboringMonth {
			background-color: ${ColorValues.white};
			opacity: 0.3;
		}
		.react-calendar__tile--now {
			background-color: ${ColorValues.neutralBackground};
		}
		.react-calendar__month-view__days__day {
			&.react-calendar__tile--now {
				border-radius: 50%;
			}
			&.react-calendar__tile--rangeEnd {
				border-top-right-radius: 50%;
				border-bottom-right-radius: 50%;
				border-top-left-radius: 0px;
				border-bottom-left-radius: 0px;
			}
			&.react-calendar__tile--rangeStart {
				border-top-left-radius: 50%;
				border-bottom-left-radius: 50%;
				border-top-right-radius: 0px;
				border-bottom-right-radius: 0px;
			}
			&.react-calendar__tile--rangeBothEnds {
				border-radius: 50%;
			}
		}
		.react-calendar__tile--hasActive,
		.react-calendar__tile--active {
			background-color: ${ColorValues.brandPrimaryLightBlue} !important;
			color: ${ColorValues.white} !important;
		}
		.react-calendar__tile:enabled:hover,
		.react-calendar__tile:enabled:focus {
			background-color: ${ColorValues.neutralBackground};
		}
		.react-calendar__tile--hover {
			background-color: ${ColorValues.neutralBackground};
		}
		abbr[title] {
			text-decoration: none;
		}
		.react-calendar__navigation button {
			background-color: ${ColorValues.white};
		}
		.react-calendar__navigation button:hover {
			opacity: 0.8;
		}
		.react-calendar__navigation button:disabled {
			opacity: 0.3 !important;
		}
	}
`;

export const ActionContainer = styled.div`
	width: 100%;
	max-width: 400px;
	margin: 40px auto 65px;

	button {
		flex-grow: 1;
   	 	flex-basis: 0;
		padding-top: 0.85rem;
		padding-bottom: 0.85rem;
	}
	.backButton {
		font-size: 0.8rem;
	}
`;
