/*
 * File: AttractionSearchWidget.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 19, 2023 at 2:23 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'beautiful-react-hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Services
import { searchAttractions } from '../../services/disney';

// Components
import { Emoji } from '../Emoji';
import { EmptyComponent } from '../EmptyComponent';
import { IconButton } from '../IconButton';
import { TextInput } from '../TextInput';
import { Typography } from '../Typography';

// Constants
import {
	AVAILABLE_SCHEDULED_EVENT_TYPES, ICONS, PARKS, PARK_ENTITY_OPTIONS, SCHEDULED_EVENT_TYPES
} from '../../../Constants';

// Styles
import * as S from './AttractionSearchWidget.styles';


/**
 * Component
 */

export const AttractionSearchWidget = ({
	className, parkEntity, attraction, updateAttraction, updateParkId, updateEventType, isVisible, existingEvents
}) => {

	// Create state handlers
	const [searchText, setSearchText] = useState('');
	const [showOptionsDropdown, setShowOptionsDropdown] = useState(false);
	const [searchResults, setSearchResults] = useState([]);

	// Create references for elements
	const isMounted = useRef(true);

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Handle perform attraction search
	const handlePerformSearch = useDebouncedCallback(async (query, entity) => {
		if (isMounted.current) {

			// Perform search
			try {
				const { data: { attractions } } = await searchAttractions({
					query,
					limit: 4,
					eventTypes: AVAILABLE_SCHEDULED_EVENT_TYPES,
					parkEntity: entity
				});
				setSearchResults(attractions);
			} catch (e) {}
		}
	}, [], 400);

	// Handle actions on visibility change
	useEffect(() => {
		if (isVisible) {

			// Reset state
			setSearchText('');
			setShowOptionsDropdown(false);
			setSearchResults([]);

			// Perform initial search
			handlePerformSearch(null, parkEntity);
		}
	}, [isVisible]);

	// Handle actions on component load
	useEffect(() => {

		// Initialize mounted state
		isMounted.current = true;

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, []);

	// Render search result
	const renderSearchResult = (result, isStatic = false) => {

		// Get parameters
		const {
			id, name, associations, type, access, images, operatorId
		} = result;

		// Get park id
		let parkId = null;
		if (associations.includes('magic-kingdom')) parkId = PARKS.MAGIC_KINGDOM.id;
		else if (associations.includes('epcot')) parkId = PARKS.EPCOT.id;
		else if (associations.includes('animal-kingdom')) parkId = PARKS.ANIMAL_KINGDOM.id;
		else if (associations.includes('hollywood-studios')) parkId = PARKS.HOLLYWOOD_STUDIOS.id;
		else if (associations.includes('disneyland')) parkId = PARKS.DISNEYLAND.id;
		else if (associations.includes('disney-california-adventure')) parkId = PARKS.CALIFORNIA_ADVENTURE.id;

		// Get location
		let location = PARK_ENTITY_OPTIONS.find((entity) => entity.id === parkEntity).name;
		if (parkId != null) {
			location = Object.values(PARKS).find((park) => park.id === parkId).name;
		}

		// Update event type
		const handleUpdateEventType = () => {
			if (result.type === 'dining') {
				updateEventType(SCHEDULED_EVENT_TYPES.DINING);
			} else if (result.type === 'attraction') {
				if (result.access.flex === true) {
					updateEventType(SCHEDULED_EVENT_TYPES.LL);
				} else if (result.access.individual === true) {
					updateEventType(SCHEDULED_EVENT_TYPES.ILL);
				} else if (result.access.virtualQueue === true) {
					updateEventType(SCHEDULED_EVENT_TYPES.VIRTUAL_QUEUE);
				}
			}
		};

		// Check rules for validity of result
		let isValid = true;
		let isDisabled = false;
		let invalidMessage = null;
		if (!isStatic) {

			// Check if attraction has already been reserved for day
			if (existingEvents.some((eventObj) => eventObj.attraction?.attractionId === `${operatorId}`)) {
				isValid = false;
				invalidMessage = 'This attraction has been reserved for your park day';
			}
			if (isValid && existingEvents.some((eventObj) => eventObj.dining?.diningId === `${operatorId}`)) {
				isValid = false;
				invalidMessage = 'This restaurant has been reserved for your park day';
			}

			// Calculate number of ILL reservations made per day
			let illReservationsMade = 0;
			existingEvents.forEach((eventObj) => { if (eventObj.eventType === SCHEDULED_EVENT_TYPES.ILL) illReservationsMade += 1; });

			// Check if more than two ILL reservations have been made for day
			if (isValid && illReservationsMade === 2 && type === 'attraction' && access.individual === true) {
				isValid = false;
				invalidMessage = 'Two Individual Lightning Lane reservations can be made each day';
			}

			// Check if more than 10 reservations have been made for day
			if (existingEvents.length >= 10) {
				isValid = false;
				isDisabled = true;
				invalidMessage = 'Up to ten reservations can be made on each day';
			}
		}

		// Render result
		return (
			<S.SearchResult
				key={id}
				className="animate"
				onClick={!isStatic && !isDisabled ? () => {
					updateAttraction(result);
					updateParkId(parkId);
					handleUpdateEventType();
				} : undefined}
				$isStatic={isStatic}
				$isValid={!isDisabled}
			>
				<S.ResultImage $image={images?.cover?.[200]} $isStatic={isStatic} className="displayDisabled">
					{!images?.cover?.[200] && (
						<Emoji
							symbol={ICONS[Object.values(PARKS).find((p) => p.id === parkId).primaryLookup].icon}
							label="Park Icon"
							size={1.1}
						/>
					)}
				</S.ResultImage>
				<S.ResultContent>
					<Typography tag="p" variation="1" weight="semibold" className="attractionName displayDisabled">{name}</Typography>
					<Typography tag="p" variation="3" weight="medium" className="attractionDetail displayDisabled">{location}</Typography>
					{!isValid && (
						<S.InvalidContainer>
							<FontAwesomeIcon icon={['far', 'circle-question']} />
							<Typography variation="3" weight="medium">{invalidMessage}</Typography>
						</S.InvalidContainer>
					)}
				</S.ResultContent>
				{isStatic && (
					<S.RemoveButtonContainer>
						<IconButton
							size={0.9}
							icon={['fas', 'times']}
							className="animate"
							onClick={() => {
								updateAttraction(null);
								updateParkId(null);
								updateEventType(null);
							}}
						/>
					</S.RemoveButtonContainer>
				)}
			</S.SearchResult>
		);
	};

	// Render component
	return (
		<S.Wrapper className={className}>

			{/* Search Bar */}
			{!attraction ? (
				<TextInput
					placeholder="Search..."
					type="search"
					icon={['fas', 'magnifying-glass']}
					value={searchText}
					onKeyDown={(e) => {
						if (e.keyCode === 13) {
							e.target.blur();
						}
					}}
					onChange={(e) => {
						setSearchText(e.target.value);
						handlePerformSearch(e.target.value, parkEntity);
					}}
					onFocus={() => {
						setShowOptionsDropdown(true);
					}}
					onBlur={() => {
						setShowOptionsDropdown(false);
					}}
				/>
			) : (
				<S.AttractionContainer>{renderSearchResult(attraction, true)}</S.AttractionContainer>
			)}

			{/* Options Dropdown */}
			<S.DropdownContainer className={showOptionsDropdown ? 'animate show' : 'animate'} uiMode={uiMode?.mode}>
				{searchResults.length > 0 ? searchResults.map((result) => (
					renderSearchResult(result, false)
				)) : (
					<EmptyComponent
						icon={['fas', 'magnifying-glass']}
						title="No results found"
						message="Try adjusting your search to find what you're looking for."
					/>
				)}
			</S.DropdownContainer>

		</S.Wrapper>
	);
};


/**
 * Configuration
 */

AttractionSearchWidget.displayName = 'AttractionSearchWidget';
AttractionSearchWidget.propTypes = {
	className: PropTypes.string,
	parkEntity: PropTypes.string,
	attraction: PropTypes.shape(),
	updateAttraction: PropTypes.func,
	updateEventType: PropTypes.func,
	updateParkId: PropTypes.func,
	isVisible: PropTypes.bool,
	existingEvents: PropTypes.arrayOf(PropTypes.shape())
};
AttractionSearchWidget.defaultProps = {
	className: null,
	parkEntity: null,
	attraction: null,
	updateAttraction: null,
	updateEventType: null,
	updateParkId: null,
	isVisible: true,
	existingEvents: []
};
