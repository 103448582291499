/*
 * File: index.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on June 16, 2022 at 1:35 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: February 26, 2023 at 9:11 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import { configureStore } from '@reduxjs/toolkit';

// Reducers
import blogReducer from './slices/blog/blog.slice';
import componentReducer from './slices/component/component.slice';
import localeReducer from './slices/locale/locale.slice';
import tripsReducer from './slices/trips/trips.slice';
import userReducer from './slices/user/user.slice';
import consentReducer from './slices/consent/consent.slice';
import uiReducer from './slices/ui/ui.slice';
import familyReducer from './slices/family/family.slice';


/**
 * Store
 */

export default configureStore({
	reducer: {
		blog: blogReducer,
		component: componentReducer,
		user: userReducer,
		locale: localeReducer,
		consent: consentReducer,
		ui: uiReducer,
		trips: tripsReducer,
		family: familyReducer
	},
});
