/*
 * File: ConfettiBurst.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: March 19, 2023 at 9:10 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import ConfettiExplosion from 'react-confetti-explosion';
import { useWindowResize } from 'beautiful-react-hooks';

// Styles
import * as S from './ConfettiBurst.styles';


/**
 * Component
 */

export const ConfettiBurst = ({
	fireAway, targetRef, force, duration, particleCount, className
}) => {

	// Create state handlers
	const [confettiTarget, setConfettiTarget] = useState({ x: 0, y: 0 });
	const [shouldUpdateTarget, setShouldUpdateTarget] = useState(true);
	const [shouldFire, setShouldFire] = useState(false);
	const [isInProgress, setIsInProgress] = useState(false);

	// Create references
	const isInProgressRef = useRef();
	const isMounted = useRef(true);

	// Set current loop value
	isInProgressRef.current = isInProgress;

	// Handle actions on component load
	useEffect(() => {

		// Initialize temporary state
		isMounted.current = true;

		// Perform actions if should update target
		if (shouldUpdateTarget) {

			// Get bounding rectangle
			const boundingRectangle = targetRef?.current?.getBoundingClientRect();

			// Set target position
			if (!isInProgress && boundingRectangle != null) {
				setConfettiTarget({ x: boundingRectangle.x, y: boundingRectangle.y });
			}

			// Update state
			setShouldUpdateTarget(false);

			// Attach scroll listener
			if (isMounted.current) {
				document.addEventListener('scroll', () => {
					if (targetRef?.current) {

						// Get bounding rectangle
						const innerBoundingRectangle = targetRef?.current?.getBoundingClientRect();

						// Set target position
						if (!isInProgressRef.current && innerBoundingRectangle != null && isMounted.current) {
							setConfettiTarget({ x: innerBoundingRectangle.x, y: innerBoundingRectangle.y });
						}
					}
				}, true);
			}
		}

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, [shouldUpdateTarget, targetRef]);

	// Handle actions on fire action update
	useEffect(() => {

		// Update internal fire parameter
		setShouldFire(fireAway);

	}, [fireAway]);

	// Handle actions on internal fire action update
	useEffect(() => {
		if (shouldFire) {

			// Set is in progress
			setIsInProgress(true);

			// Reset should fire after animation
			setTimeout(() => {
				if (isMounted.current) {
					setShouldFire(false);
					setIsInProgress(false);
				}
			}, duration);
		}
	}, [shouldFire, duration]);

	// Handle actions on window resize
	useWindowResize(() => {

		// Get bounding rectangle
		const boundingRectangle = targetRef?.current?.getBoundingClientRect();

		// Set target position
		if (!isInProgress && boundingRectangle != null) {
			setConfettiTarget({ x: boundingRectangle.x, y: boundingRectangle.y });
		}
	});

	// Render component
	return (
		<S.ConfettiContainer className={className}>
			<S.ConfettiPoint $x={confettiTarget.x} $y={confettiTarget.y}>
				{shouldFire && (
					<ConfettiExplosion
						force={force}
						duration={duration}
						particleCount={particleCount}
						height={1000}
						width={1000}
					/>
				)}
			</S.ConfettiPoint>
		</S.ConfettiContainer>
	);
};


/**
 * Configuration
 */

ConfettiBurst.displayName = 'ConfettiBurst';
ConfettiBurst.propTypes = {
	className: PropTypes.string,
	fireAway: PropTypes.bool,
	targetRef: PropTypes.shape(),
	force: PropTypes.number,
	duration: PropTypes.number,
	particleCount: PropTypes.number,
};
ConfettiBurst.defaultProps = {
	className: null,
	fireAway: false,
	targetRef: null,
	force: 0.4,
	duration: 3000,
	particleCount: 80,
};
