/*
 * File: DisneyAccountStep.styles.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:25 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: December 4, 2022 at 6:59 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Styles
import { ColorValuesCreator } from '../../../../styles/colors';

// Components
import { Button } from '../../../../components/Button';

// Create color values
const ColorValues = ColorValuesCreator();


/**
 * Styles
 */

export const StepContainer = styled.div``;

export const Form = styled.form`
	width: 100%;
	max-width: 400px;
	margin: 0px auto;

	@media only screen {
		padding-top: 60px;
		padding-bottom: 50px;
	}
	@media only screen and (min-width: 40em) {
		padding-top: 100px;
		padding-bottom: 50px;
	}
	h2 {
		text-align: left;
		color: ${ColorValues.white};
		margin: 10px 0px 10px;
	}
	p {
		text-align: left;
		color: ${ColorValues.white};
   		margin-bottom: 20px;
	}
	button {
		width: 100%;
	}
`;

export const LogoMark = styled.div`
	width: 46px;
	height: 46px;
	background-image: url('${process.env.CDN_URL}/public/assets/logos/my-disney-experience-logo.png');
	background-size: cover;
	background-repeat: no-repeat;
`;

export const ActionButton = styled(Button)`
	margin: 20px auto 0px;
`;

export const Instructions = styled.div`
	margin-top: 20px;

	p {
		text-align: center;
	}
`;
