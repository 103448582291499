/*
 * File: Home.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on July 10, 2022 at 6:57 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: September 28, 2023 at 11:41 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useWindowResize } from 'beautiful-react-hooks';

// Utilities
import { createStateLocale } from '../../../utilities/locale';
import { globalQuery } from '../../../utilities/state';

// Constants
import { AMOUNT_PER_RESERVATION_DAY } from '../../../../Constants';

// Components
import {
	Meta, AppNavigation, Typography, Button, Emoji, SchemaScript
} from '../../../components';

// Styles
import * as S from './Home.styles';


/**
 * Component
 */

const Home = ({ meta, locale }) => {

	// Create state handlers
	const [shouldUpdateHeight, setShouldUpdateHeight] = useState(true);

	// Create reference for components
	const [heroHeight, setHeroHeight] = useState(0);
	const heroRef = useRef();

	// Use hooks
	const navigate = useNavigate();

	// Get query parameters from hook
	const [searchParams] = useSearchParams();

	// Get CDN URL
	const CDNURL = process.env.CDN_URL;

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Handle actions on component load
	useEffect(() => {
		if (shouldUpdateHeight) {

			// Set component heights
			setHeroHeight(heroRef?.current?.clientHeight);

			// Update state
			setShouldUpdateHeight(false);
		}
	}, [shouldUpdateHeight]);

	// Handle actions on window resize
	useWindowResize(() => {

		// Set component heights
		setHeroHeight(heroRef?.current?.clientHeight);
	});

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} data={{}} locale={stateLocale} />

			{/* Schema.org */}
			<SchemaScript schema={{
				'@context': 'http://schema.org',
				'@type': ['WebPage'],
				'@id': `${process.env.APP_URL}/#WebPage`,
				name: meta.title,
				description: meta.description,
				url: `${process.env.APP_URL}${stateLocale.localePath}`,
				inLanguage: stateLocale.localeBaseId,
				isPartOf: {
					'@id': `${process.env.APP_URL}/#WebSite`
				},
				publisher: {
					'@id': 'https://www.pixiedustguide.com/#Organization',
				},
			}}
			/>

			{/* Component Content */}
			<AppNavigation isTopbarTransparent>
				<S.Wrapper>

					{/* Header Decoration */}
					<S.HeaderDecoration decorationHeight={heroHeight} />

					{/* Hero Section */}
					<S.HeroSection ref={heroRef}>

						{/* Community Update */}
						<S.CommunityUpdateContainer>
							<Typography tag="p" variation="1">
								<Typography weight="semibold">Community Update!</Typography>
								{' '}
								We are working on a feature to allow making Disney dining reservations more than 60 days before your trip. No more getting up at 5:45am to get a reservation at Chef Mickey’s or Space 220
								{' '}
								<Emoji symbol="😀" label="stress" size={1.1} />
							</Typography>
						</S.CommunityUpdateContainer>

						{/* Content */}
						<S.HeroContentContainer>
							<Typography tag="h1" weight="bold">
								Schedule your Lightning Lanes.
								{' '}
								<Emoji symbol="⚡️" size={2.8} label="Lightning Lane" />
							</Typography>
							<Typography tag="p">
								No need to wake up early and stress over reserving your Disney World & Disneyland Lightning Lanes. Schedule your reservations months in advance, from the comfort of home. The whole family’s included.
							</Typography>
							<Button
								size="large"
								onClick={() => {
									navigate(`/join${globalQuery(searchParams)}`);
								}}
							>
								Give it a try!
							</Button>
						</S.HeroContentContainer>
					</S.HeroSection>

					{/* Easy Scheduling - Section */}
					<S.ContentPairSection id="about">

						{/* Content */}
						<S.ContentPairContainer>
							<S.ContentPairContent>
								<Typography tag="h4" weight="semibold">Easy Scheduling</Typography>
								<Typography tag="h2" weight="bold">We&apos;ll do all the hard work.</Typography>
								<Typography tag="p" weight="light">Scheduling your Lightning Lanes is easy. Just pick your days, attractions, and add your party members. And on the big day, we’ll wake up early to make sure everything gets reserved so you don&apos;t have to. That way, you can focus on the fun parts.</Typography>
							</S.ContentPairContent>
						</S.ContentPairContainer>

						{/* Image */}
						<S.ImagePairContainer>
							<S.PairImage src={`${process.env.CDN_URL}/public/assets/family-computer.png`} alt="Scheduling Reservations with Pixie Dust" />
						</S.ImagePairContainer>
					</S.ContentPairSection>

					{/* At Your Fingertips - Section */}
					<S.ContentPairSection id="about">

						{/* Content */}
						<S.ContentPairContainer>
							<S.ContentPairContent>
								<Typography tag="h4" weight="semibold">At Your Fingertips</Typography>
								<Typography tag="h2" weight="bold">You’ve got a friend in us.</Typography>
								<Typography tag="p" weight="light">Once your reservation is made, we’ll send you a confirmation text with your Lightning Lane itinerary for the day.</Typography>
							</S.ContentPairContent>
						</S.ContentPairContainer>

						{/* Image */}
						<S.ImagePairContainer>
							<S.PairImage src={`${process.env.CDN_URL}/public/assets/using-phone-park.png`} alt="Using Pixie Dust On The Go" />
						</S.ImagePairContainer>
					</S.ContentPairSection>

					{/* Disney Park Decoration */}
					<S.DecorationContainer>
						<S.DisneyParkDecoration src={`${process.env.CDN_URL}/public/assets/wave-decoration-park.png`} alt="Pixie Dust Walt Disney World & Disneyland" />
					</S.DecorationContainer>

					{/* Cost Section */}
					<S.PricingSection id="pricing">

						{/* Content */}
						<S.FeatureContentContainer>

							{/* Title */}
							<Typography tag="h4" weight="semibold">Pricing</Typography>

							{/* Pricing */}
							<S.PricingContainer>
								<Typography className="percentage" weight="semibold">
									$
									{AMOUNT_PER_RESERVATION_DAY.toFixed(0)}
								</Typography>
								<Typography>per Disney park day</Typography>
							</S.PricingContainer>
							<Typography tag="h3">That&apos;s it.</Typography>
							<Typography tag="p" weight="light" style={{ fontSize: '1.2rem', marginTop: '8px' }}>
								No limits on guests or attractions. Going solo? $
								{AMOUNT_PER_RESERVATION_DAY.toFixed(0)}
								. Bringing the whole family? $
								{AMOUNT_PER_RESERVATION_DAY.toFixed(0)}
								.
							</Typography>

							{/* Pricing Example */}
							<S.ExampleContainer>
								<Typography tag="p" weight="light">
									<Typography weight="semibold">For example,</Typography>
									{' '}
									reserving 3 people for 4 days:
									{' '}
									<Typography weight="semibold" className="highlight">
										$
										{(AMOUNT_PER_RESERVATION_DAY * 4).toFixed(0)}
									</Typography>
									{' '}
									to schedule all your Lightning Lanes for your whole trip!
								</Typography>
								<S.ParkBadge />
							</S.ExampleContainer>
							<Typography tag="p" weight="light" variation="3">You won’t be charged at all until the day of your trip</Typography>
						</S.FeatureContentContainer>
					</S.PricingSection>

					{/* Feature Section */}
					<S.FeatureSection id="features">

						{/* Device Holder */}
						<div className="devicesHolder devicesHolderHero">
							<div className="phoneSmall">
								<img alt="Pixie Dust Mobile" src={`${CDNURL}/public/assets/mockups/phone-small-hero.png`} width="211px" height="375px" />
							</div>
							<div className="tabletPortrait">
								<img alt="Pixie Dust Tablet" src={`${CDNURL}/public/assets/mockups/tablet-portrait-hero.png`} width="436px" height="583" />
							</div>
						</div>

						{/* Content */}
						<S.ContentContainer>
							<Typography tag="h4" weight="semibold">Features</Typography>
							<Typography tag="h2" weight="bold">Much more than the bare necessities.</Typography>
							<Typography tag="p" weight="light">We built Pixie Dust to make our Disney trips easier, less stressful, and more enjoyable. We&apos;re always working on new features to add even more magic.</Typography>
						</S.ContentContainer>

						{/* Features */}
						<S.FeatureContainer>
							<S.Feature>
								<Emoji symbol="⚡️" label="look" size={2.5} />
								<Typography tag="p" weight="light">Schedule your Lightning Lanes months in advance.</Typography>
							</S.Feature>
							<S.Feature>
								<Emoji symbol="🔒" label="card" size={2.5} />
								<Typography tag="p" weight="light">Securely connect your My Disney Experience account.</Typography>
							</S.Feature>
							<S.Feature>
								<Emoji symbol="💬" label="email" size={2.5} />
								<Typography tag="p" weight="light">Get a text as soon as your reservations are made.</Typography>
							</S.Feature>
							<S.Feature>
								<Emoji symbol="😴" label="look" size={2.5} />
								<Typography tag="p" weight="light">We&apos;ll handle reserving Genie+ for your day. No more waking up early.</Typography>
							</S.Feature>
							<S.Feature>
								<Emoji symbol="🏰" label="shopping" size={2.5} />
								<Typography tag="p" weight="light">Make reservations for everyone in your party with just a few clicks.</Typography>
							</S.Feature>
							<S.Feature>
								<Emoji symbol="😎" label="cart" size={2.5} />
								<Typography tag="p" weight="light">Enjoy more time and less stress on your Disney park day.</Typography>
							</S.Feature>
							<S.Feature>
								<Emoji symbol="🍽️" label="shipping" size={2.5} />
								<S.ComingSoonBadge><Typography weight="semibold" variation="3">Coming Soon</Typography></S.ComingSoonBadge>
								<Typography tag="p" weight="light">Schedule your dining reservations more than 60 days before your trip.</Typography>
							</S.Feature>
						</S.FeatureContainer>
					</S.FeatureSection>

					{/* Get Started Section */}
					<S.FeatureSection id="contact">

						{/* Content */}
						<S.FeatureContentContainer>
							<Typography tag="h2" weight="bold">Ready to get started?</Typography>
							<Typography tag="p" style={{ fontSize: '1.2rem' }} weight="light">It&apos;s completely free to sign up and schedule your Disney days! We&apos;ll only ask for payment info if you&apos;re ready for us to make your reservations.</Typography>
							<Button size="large" onClick={() => { navigate(`${stateLocale.localePath}/join${globalQuery(searchParams)}`); }}>Give it a try!</Button>
						</S.FeatureContentContainer>
					</S.FeatureSection>
				</S.Wrapper>
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

Home.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
};
Home.defaultProps = {
	meta: {},
	locale: {}
};


/**
 * Exports
 */

export default Home;
