/*
 * File: ReservationElement.jsx
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: October 2, 2023 at 11:06 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSearchParams } from 'react-router-dom';

// Utilities
import { getCookie } from '../../utilities/cookies';
import { parseDateString } from '../../../utilities/dateTime';
import { isSameMember } from '../../../utilities/utilities';

// Components
import { Emoji } from '../Emoji';
import { Typography } from '../Typography';
import { IconButton } from '../IconButton';

// Constants
import {
	ICONS, PARKS, SCHEDULED_EVENT_TYPES, VIEW_MODE_COOKIE
} from '../../../Constants';

// Styles
import * as S from './ReservationElement.styles';


/**
 * Component
 */

export const ReservationElement = ({
	className, isPlaceholder, isEditing, day, event, openCreator, openRemove, cardIndex
}) => {

	// Get query parameters from hook
	const [searchParams] = useSearchParams();
	let isMobileApp = searchParams.get('mobileapp') === 'true';
	if (!isMobileApp) {
		const mobileAppCookie = getCookie(VIEW_MODE_COOKIE);
		if (mobileAppCookie === 'mobileapp') isMobileApp = true;
	}

	// Handle icon set generation
	const generateIconSet = () => {
		let icons = [];
		const availableIcons = Object.values(ICONS).filter((icon) => icon.standard).map((icon) => icon.icon);
		while (icons.length < 4) {
			icons.push(availableIcons[Math.floor(Math.random() * availableIcons.length)]);
			icons = [...new Set(icons)];
		}
		return icons;
	};

	// Handle reservation time generation
	const generateReservationTime = (forTimeline) => {
		if (isPlaceholder) {
			return cardIndex === 0 ? 'Start of day' : 'Next reservation';
		}
		if (event.actualReservationTime) {
			return parseDateString(event.actualReservationTime, 'HH:mm').format('h:mm A');
		} if (event.earliestPossible) {
			if (event.eventType === SCHEDULED_EVENT_TYPES.LL) {

				// Check if event is first LL of the day for party
				let llBeforeEvent = 0;
				for (let i = 0; i < day.scheduledEvents.length; i += 1) {
					const eventObj = day.scheduledEvents[i];
					if (eventObj.id === event.id) break;
					if (eventObj.eventType === SCHEDULED_EVENT_TYPES.LL) {

						// Get party members
						const { party: currentParty } = event;
						const { party: eventParty } = eventObj;

						// Check if party has overlapping members
						if (currentParty.some((memberA) => eventParty.some((memberB) => isSameMember(memberA, memberB)))
							|| eventParty.some((memberA) => currentParty.some((memberB) => isSameMember(memberA, memberB)))) {
							llBeforeEvent += 1;
						}
					}
				}
				if (llBeforeEvent === 0) { // First LL of the day for party
					return forTimeline ? 'Start of day' : 'Earliest available';
				}
				return forTimeline ? 'Next available' : 'Next available';
			}
			return forTimeline ? 'Start of day' : 'Earliest available';
		}
		return `Around ${parseDateString(event.preferredTime, 'HH:mm').format('h:mm A')}`;
	};

	// Handle reservation type generation
	const generateReservationType = () => {
		switch (event.eventType) {
			case SCHEDULED_EVENT_TYPES.LL:
				return 'Lightning Lane';
			case SCHEDULED_EVENT_TYPES.ILL:
				return 'Lightning Lane';
			case SCHEDULED_EVENT_TYPES.DINING:
				return 'Dining Reservation';
			case SCHEDULED_EVENT_TYPES.VIRTUAL_QUEUE:
				return 'Virtual Queue';
			default:
				return 'Reservation';
		}
	};

	// Handle party rendering
	const getPartyList = () => {

		// Parse all party members
		const { party } = event;

		// Return party
		return party.map((member) => {

			// Create profile image
			let profileImage = member.profileImage?.media?.characterSmall?.url || member.profileImage?.url;
			if (member.profileImage?.isInternal || (isMobileApp && member.profileImage?.isDisneyDefault === true && member.profileImage?.internal)) {
				profileImage = member.profileImage?.internal?.['200x200'];
			}

			// Create member
			return {
				...member,
				profileImage
			};
		});
	};

	// Create state handlers
	const [iconSet] = useState(generateIconSet());

	// Get event validity
	const eventIsValid = !(!day.validParkTicket || !day.validParkReservation || (!day.validDisneyGeniePlus && day.requiresDisneyGeniePlus));

	// Handle actions on component load
	useEffect(() => {}, []);

	// Render status badge
	const renderStatusBadge = () => {
		if (event.isComplete) {
			return (
				<S.StatusBadge size="medium" type="success">
					<FontAwesomeIcon icon={['fas', 'check']} />
					<Typography variation="3" weight="medium">Complete</Typography>
				</S.StatusBadge>
			);
		} if (eventIsValid) {
			return (
				<S.StatusBadge size="medium">
					<FontAwesomeIcon icon={['far', 'clock']} />
					<Typography variation="3" weight="medium">Scheduled</Typography>
				</S.StatusBadge>
			);
		}
		return null;
	};

	// Render component
	return (
		<S.ReservationContainer className={className}>

			{/* Timeline */}
			<S.TimelineContainer>
				<S.TimelineBubble />
				<S.TimelineLine />
			</S.TimelineContainer>

			{/* Content Container */}
			<S.ContentContainer>

				{/* Reservation Time */}
				<S.ReservationTime>
					<Typography weight="medium">{generateReservationTime(true)}</Typography>
				</S.ReservationTime>

				{/* Remove Reservation Button */}
				{!isPlaceholder && !event.isComplete && isEditing && (
					<S.RemoveButtonContainer className="animate showOnHover">
						<IconButton
							size={0.9}
							icon={['far', 'trash-can']}
							className="animate"
							onClick={() => { openRemove(event); }}
						/>
					</S.RemoveButtonContainer>
				)}

				{/* Reservation */}
				{isPlaceholder ? (
					<S.AddPrompt onClick={isEditing ? () => { openCreator(); } : undefined} className="animate" $isEditing={isEditing}>
						<S.IconContainer>
							{isEditing
								? <FontAwesomeIcon icon={['fa', 'circle-plus']} className="animate" />
								: iconSet.map((icon) => <Emoji symbol={icon} label={icon} key={icon} size={1.2} />)}
						</S.IconContainer>
						<Typography weight="medium">{isEditing ? <>Add a reservation</> : <>New reservation</>}</Typography>
					</S.AddPrompt>
				)
					: (
						<S.ReservationCard onClick={isEditing && !event.isComplete ? () => { openCreator(event); } : undefined}>

							{/* Status Badge */}
							{renderStatusBadge()}

							{/* Content */}
							<S.FlexContainer>

								{/* Entity Image */}
								<S.ReservationImage $image={event.attraction?.attraction?.images?.cover?.[200]}>
									{!event.attraction?.attraction?.images?.cover?.[200] && (
										<Emoji
											symbol={ICONS[Object.values(PARKS).find((p) => p.id === event.parkId).primaryLookup].icon}
											label="Park Icon"
											size={1.4}
										/>
									)}
								</S.ReservationImage>

								{/* Reservation Content */}
								<S.ReservationContent>
									<Typography tag="p" variation="2" weight="bold" className="attractionName">{event.attraction?.name || event.dining?.name}</Typography>
									<Typography tag="p" variation="3" weight="medium" className="attractionType">{generateReservationType()}</Typography>
									<Typography tag="p" weight="medium" className="attractionTime">{generateReservationTime(false)}</Typography>

									{/* Party */}
									<S.PartyContainer>
										{getPartyList().map((member) => <S.ProfileImage key={member.userSWID || member.userXID} image={member.profileImage} />)}
									</S.PartyContainer>
								</S.ReservationContent>
							</S.FlexContainer>
						</S.ReservationCard>
					)}
			</S.ContentContainer>
		</S.ReservationContainer>
	);
};


/**
 * Configuration
 */

ReservationElement.displayName = 'ReservationElement';
ReservationElement.propTypes = {
	className: PropTypes.string,
	isPlaceholder: PropTypes.bool,
	isEditing: PropTypes.bool,
	event: PropTypes.shape(),
	day: PropTypes.shape(),
	openCreator: PropTypes.func,
	openRemove: PropTypes.func,
	cardIndex: PropTypes.number
};
ReservationElement.defaultProps = {
	className: null,
	isPlaceholder: false,
	isEditing: false,
	event: {},
	day: {},
	openCreator: null,
	openRemove: null,
	cardIndex: 0
};
