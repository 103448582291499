/*
 * File: index.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on November 18, 2022 at 3:39 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: November 18, 2022 at 3:39 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Utilities
import api from '../api';


/**
 * Services
 */

export const beginPasswordReset = (data) => api.post('/password/forgot', data);

export const validatePasswordResetAttempt = (data) => api.get('/password/reset', { params: data });

export const resetPassword = (data) => api.post('/password/reset', data);
