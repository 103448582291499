/*
 * File: index.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on January 20, 2023 at 11:46 PM
 * Copyright © 2023 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: February 26, 2023 at 8:36 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Utilities
import api from '../api';


/**
 * Services
 */

export const postContactSubmission = (data) => api.post('/contact', data);
