/*
 * File: ReservationCreatorModal.styles.js
 * Project: pixie-dust-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:53 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: August 2, 2023 at 2:57 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';

// Constants
import { mediumBreakpoint } from '../../styles/constants';

// Styles
import { ColorValuesCreator } from '../../styles/colors';

// Create color values
const ColorValues = ColorValuesCreator();


/**
 * Styles
 */

export const ModalInner = styled.div`
	padding: 0px 0px 30px;
	text-align: left;
	width: 100%;
	max-height: 90%;
	min-height: 640px;
	display: flex;
	flex-direction: column;

	.subtitle {
		margin: 5px 0 35px;
		padding: 0 0px;
	}
`;

export const PaneContainer = styled.div`
	display: flex;
	gap: 40px;
	flex-direction: column;
	flex-grow: 1;

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		flex-direction: row;
	}
`;

export const ReservationPane = styled.div`
	flex-grow: 1;
    flex-basis: 0;
	padding: 0px 0px 80px 0px;

	* {
		text-align: left;
	}
`;

export const PartyPane = styled.div`
	flex-grow: 1;
    flex-basis: 0;

	h4 {
		text-align: left;
		color: ${({ theme }) => theme.primaryText};
		font-size: 1.2rem;
	}
`;

export const Divider = styled.div`
	background-color: ${({ theme }) => theme.layoutBorder};
	height: 1px;

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		height: auto;
		width: 1px;
	}
`;

export const ActionContainer = styled.div`
	margin: 20px 0px 0px 0px;

	button {
		margin: 0px 0px 0px auto;
		width: 100px;
	}
`;

export const IconContainer = styled.div`
	width: 50px;
	height: 50px;
	align-items: center;
	justify-content: center;
	background-color: ${({ $type, theme }) => ($type === 'danger' ? theme.statusDangerLighter : theme.secondaryBackground)};
	border-radius: 5px;
	display: inline-flex;
	margin: 0px 0px 15px 0px;

	svg {
		color: ${({ $type, theme }) => ($type === 'danger' ? theme.statusDangerBase : theme.primaryText)};
		font-size: 1.15rem;
	}
`;

export const FamilyContainer = styled.div`
	padding: 25px 0px;
	display: flex;
	flex-direction: row;
	width: 100%;
	flex-wrap: wrap;
	gap: 10px;
	color: ${({ theme }) => theme.primaryText};

	*:not(svg,path) {
		color: ${({ theme }) => theme.primaryText};
	}
	.shimmer {
		animation : shimmer 2s infinite;
		background: linear-gradient(to right, ${ColorValues.neutralBackground} 4%, ${ColorValues.neutralHighlight} 25%, ${ColorValues.neutralBackground} 36%);
		background-size: 1000px 100%;
	}
	& > div {
		width: calc(50% - 5px);

		@media only screen and (min-width: ${mediumBreakpoint}em) {
			width: 114px;
		}
	}
`;

export const SelectorContainer = styled.div`
	margin: 30px 0px 35px 0px;

	& > p {
		color: ${({ theme }) => theme.secondaryText};
		margin: 0px 0px 8px 0px;
	}
`;

export const TimeToolbar = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	margin: 20px 0px 10px 0px;

	* {
		color: ${({ theme }) => theme.primaryText};
		cursor: pointer;
	}
`;

export const TimeContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
`;

export const TimeGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 12px;

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		grid-template-columns: repeat(4, 1fr);
	}
`;

export const TimeSelector = styled.div`
	border: 1px solid ${({ theme }) => theme.layoutBorder};
	border-radius: 5px;
	text-align: center;
	padding: 10px;
	cursor: pointer;

	* {
		color: ${({ theme }) => theme.secondaryText};
	}
	${({ $isSelected }) => $isSelected && css`
		background-color: ${({ theme }) => theme.brandPrimaryLightBlue};
		border: 1px solid ${({ theme }) => theme.brandPrimaryLightBlue};

		* {
			color: ${ColorValues.white};
		}
	`}
`;
